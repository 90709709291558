import {
  Button,
  Card,
  Col,
  Flex,
  Layout,
  message,
  Row,
  Select,
  Spin,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import LoadingBar from "react-top-loading-bar";
import BusinessProcessHeader from "../businessProcess/BusinessProcessHeader";
import {
  getAllMenuPortfolio,
  getMenuPortfolio,
} from "../../utils/settingCommon";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import DCLineChart from "./data-completeness/DCLineChart";
import DCBarCharts from "./data-completeness/DCBarCharts";
import DCViewDetails from "./data-completeness/DCViewDetails";
import { useCompletenessReport } from "../../Context/completenessReportProvider";

const DataCompleteness = ({ routeModuleName, ...props }) => {
  const loadingBarRef = useRef(null);
  const [portfolioData, setPortfolioData] = useState({});
  const [selectedTags, setSelectedTags] = useState([]);
  // State to store the previous selection
  const [previousTags, setPreviousTags] = useState([]);

  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [completenessData, setCompletenessData] = useState([]);

  const { state, dispatch } = useCompletenessReport();

  useEffect(() => {
    const initialPortfolio = getAllMenuPortfolio()[0];
    dispatch({
      type: "EMPTY_COMPLETENESS_REPORT",
    });
    const fetchData = async () => {
      setSelectedTags([initialPortfolio?.moduleName]);
      setPreviousTags([initialPortfolio?.moduleName]);
      await fetchCompletenessReport(initialPortfolio?.moduleName, "add");
    };
    fetchData();
  }, []);

  useEffect(() => {
    setCompletenessData(state?.data);
  }, [state]);

  // Handler for onChange event
  const handleChange = (value) => {
    // Determine which tags were added or removed
    const addedTags = value.filter((tag) => !previousTags.includes(tag));
    const removedTags = previousTags.filter((tag) => !value.includes(tag));

    // Update state with the new selection
    setSelectedTags(value);
    setPreviousTags(value);
    // Call API if new tags were added

    // Function to call API
    const processTags = async (url, tag) => {
      try {
        setLoading(true);
        setDisabled(true);
        if (addedTags.length > 0) {
          const newTag = addedTags[addedTags.length - 1];
          await fetchCompletenessReport(newTag, "add");
        }

        if (removedTags.length > 0) {
          const removedTag = removedTags[removedTags.length - 1];
          await fetchCompletenessReport(removedTag, "remove");
        }
      } catch (error) {
        console.error("API error:", error);
      } finally {
        setLoading(false);
        setDisabled(false);
      }
    };

    processTags();
  };
  const handleClear = () => {
    setSelectedTags([]);
    setPreviousTags([]);
    dispatch({
      type: "EMPTY_COMPLETENESS_REPORT",
    });
  };

  const handleGetDetail = async (id, page, pageSize) => {
    try {
      const findPortfolio = getAllMenuPortfolio()?.find(
        (item) => item?.id === id
      );

      if (!findPortfolio) {
        return message.error("Portfolio not found.");
      }

      const payload = {
        moduleName: findPortfolio?.routeModuleName,
        id: findPortfolio?.id,
        details: true,
        page,
        limit: pageSize,
      };

      const response = await PortfolioSettingServices?.getCompletenessReport(
        payload
      );
      if (response.data.code === 200) {
        return response?.data?.data;
      } else {
        message.error(response.data.message || "Internal server error.");
      }
    } catch (error) {
      console.error("Error fetching details:", error);
      return [];
    }
  };

  const fetchCompletenessReport = async (tag, type) => {
    try {
      setLoading(true);
      setDisabled(true);
      const findPortfolio = getAllMenuPortfolio()?.find(
        (item) => item?.moduleName === tag
      );

      if (!findPortfolio) {
        setLoading(false);
        setDisabled(false);
        return message.error("Portfolio not found.");
      }
      const payload = {
        moduleName: findPortfolio?.routeModuleName,
        id: findPortfolio?.id,
        details: false,
      };
      if (type === "add") {
        const response = await PortfolioSettingServices?.getCompletenessReport(
          payload
        );
        if (response.data.code === 200) {
          // message.success(`${findPortfolio?.name} fetch successfully.`);
          await dispatch({
            type: "ADD_COMPLETENESS_REPORT",
            payload: response.data.data,
          });
        } else {
          message.error(response.data.message || "Internal server error.");
        }
      } else {
        await dispatch({
          type: "DELETE_COMPLETENESS_REPORT",
          payload: payload,
        });
        // message.success(`${findPortfolio?.name} removed successfully.`);
      }
    } catch (error) {
      console.error("API error:", error);
    } finally {
      setLoading(false);
      setDisabled(false);
    }
  };

  return (
    <React.Fragment>
      <LoadingBar color="#1f1f1f" ref={loadingBarRef || loading} />
      <Layout>
        <Layout.Content className="data-completeness-landscape-report">
          <section>
            <BusinessProcessHeader
              activePage={0}
              setActivePage={() => {}}
              heading={props?.heading}
              matrix={false}
              grid={false}
              list={false}
              addBtn={false}
              bulk_delete={false}
              showActionBtn={false}
            />
          </section>
          <section>
            <Flex gap={16} justify="flex-start" className="mt-2">
              <Flex gap={16} justify="space-between" align="center">
                <div className="h3 pb-0 mb-0">Portfolios</div>
                <div>
                  <Select
                    mode="tags"
                    style={{
                      minWidth: "150px",
                      maxWidth: window?.innerWidth / 2,
                    }}
                    placeholder={"Select Portfolio"}
                    allowClear
                    onChange={handleChange}
                    onClear={handleClear}
                    value={selectedTags}
                    disabled={disabled}
                  >
                    {getAllMenuPortfolio()?.map((item, index) => {
                      return (
                        <Select.Option value={item.moduleName} key={index}>
                          <span
                            className="portfolio-option-color"
                            style={{ "--color": item?.color }}
                          ></span>
                          {item.name}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </Flex>
            </Flex>
          </section>
          <section>
            <Card className="mt-2">
              <Spin size="small" spinning={loading}>
                <Row className="bg-light">
                  <Col flex={`350px`} className="dc-heading-1">
                    Portfolio Completeness
                  </Col>
                  <Col flex={`auto`} className="dc-heading-1">
                    Incomplete Records
                  </Col>
                </Row>
                {(completenessData?.length > 0 &&
                  completenessData?.map((item, index) => {
                    const data1 = [
                      {
                        labelName: "",
                        value: Number(item?.completePercentage) || 0,
                      },
                    ];

                    const propertiesData = item?.properties?.map((p) => {
                      return { type: p?.label, value: Number(p?.value) || 0 };
                    });

                    const relationsData = item?.relations?.map((r) => {
                      return {
                        type: r?.displayName,
                        value: Number(r?.missingRelations) || 0,
                      };
                    });

                    return (
                      <Row key={index}>
                        <Col flex={`350px`} className="dc-heading-1">
                          <DCLineChart portfolioData={item} data={data1} />
                        </Col>
                        <Col flex={`auto`} className="dc-heading-1">
                          <DCViewDetails
                            data={propertiesData}
                            portfolioData={item}
                            dataRelations={relationsData || []}
                            handleGetDetail={handleGetDetail}
                          />
                          <hr />
                        </Col>
                      </Row>
                    );
                  })) || (
                  <div className="no-results-message">No record found!</div>
                )}
              </Spin>
            </Card>
          </section>
        </Layout.Content>
      </Layout>
    </React.Fragment>
  );
};

DataCompleteness.defaultProps = {
  routeModuleName: "Dataobject",
};

export default DataCompleteness;

const randomIntFromInterval = (min, max) => {
  // min and max included
  return parseInt(Math.floor(Math.random() * (max - min + 1) + min));
};
