import React, { createContext, useContext, useReducer } from "react";

// Create the context
const GlobalTagContext = createContext();

// Hook to use the context
export const useGlobalTag = () => useContext(GlobalTagContext);

// Initial state
const initialState = {
  data: [],
  loading: false,
  error: false,
  isEdit: false,
  completed: false,
};

// Reducer function to handle state changes
const GlobalTagReducer = (state, action) => {
  switch (action?.type) {
    case "SET_ALL_TAGS":
      return {
        ...state,
        data: action.payload,
        loading: false,
        error: false,
        completed: true,
      };
    case "ADD_TAG":
      return {
        ...state,
        data: [...state.data, action.payload],
        loading: false,
        error: false,
        completed: true,
      };
    case "UPDATE_TAG":
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        loading: false,
        error: false,
        completed: true,
      };
    case "DELETE_TAG":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
        loading: false,
        error: false,
        completed: true,
      };
    case "EMPTY_TAGS":
      return {
        ...state,
        data: [],
        moduleName: "",
        isEdit: false,
        loading: false,
        error: false,
        completed: false,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: true,
        error: false,
      };
    case "SET_ERROR":
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case "SET_MODULE_NAME":
      return {
        ...state,
        moduleName: action.payload,
      };
      case "SET_MODEL_DATA":
        return {
          ...state,
          data: action.payload,
          loading: false,
          error: false,
        };
    default:
      return state;
  }
};

// Provider component to wrap the application and provide the state
export const GlobalTagProvider = ({ children }) => {
  const [state, dispatch] = useReducer(GlobalTagReducer, initialState);

  return (
    <GlobalTagContext.Provider value={{ state, dispatch }}>
      {children}
    </GlobalTagContext.Provider>
  );
};
