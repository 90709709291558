import { useSaveState } from '../../../Context/StateProviderData';

const UpdateGlobalState = () => {
    const { state,setState } = useSaveState();

    const updateLocale = ({key,items}) =>{
        if(state[key]?.length>0){
            let a1 = state[key]?.map(item=>{ return item?.id });
            let a2 = items?.map(item=>{ return item?.id });
            
            let filters = a2.filter(n => !a1.includes(n));
            if(filters?.length>0){
                setState({key,value:[...new Map([...state[key],...items].map(item =>[item["id"], item])).values()]})
            }
        }else{
            setState({key,value:items})
        }
    }

    return {
        updateLocale,
        state,
        setState,
    }
}

UpdateGlobalState.defaultProps = {

}

export default UpdateGlobalState
