import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Select, Form, Divider, message, Spin } from "antd";
import CreatableSelect from "react-select/creatable";
import PortfolioTagServices from "../../../../services/services/PortfolioTagService";
import { usePortfolioTag } from "../../../../Context/portfolioTagProvider";
const { Option } = Select;

const TagsOptions = ({ tagOptions, form, formData, moduleName }) => {
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const [options, setOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [spinning, setSpinning] = useState(false);

  useEffect(() => {
    setOptions(
      tagOptions?.map((option) => {
        return { ...option, value: option?.id, label: option?.name };
      })
    );
  }, [tagOptions]);

  const handleChange = (tags) => {
    form.setFieldsValue({ ...form.getFieldValue("tags"), tags });
  };

  const handleCreate = async (inputValue) => {
    try {
      // Replace with your API call
      setSpinning(true)
      let formValues = form.getFieldsValue();
      const payload = { name: inputValue, moduleName };
      const response = await PortfolioTagServices.create(payload);

      if (Object.keys(response?.data)?.length === 0) {
        return message.error("Error adding new tag");
      }
      //await dispatchTag({ type: "ADD_TAG", payload: response?.data });
      const newTag = response?.data;
      const newOption = {
        ...response?.data,
        value: newTag.id,
        label: newTag.name,
      };
      setOptions((prevOptions) => [...prevOptions, newOption]);
      let updatedOptions = [];
      if (form?.getFieldValue("tags")) {
        updatedOptions = [...form.getFieldValue("tags"), newOption];
      } else {
        updatedOptions = [newOption];
      }
      console.log("updatedOptions",updatedOptions)
      // handleChange(updatedOptions);
      await new Promise(resolve => setTimeout(resolve, 500));
      form.setFieldsValue({ ...formValues, ['tags']:updatedOptions });
    } catch (error) {
      console.error(error);
      message.error("Name already exist, Name must be unique.");
    }finally{
      setSpinning(false)
    }
  };

  const handleDelete = (option) => {
    setOptions((prevOptions) =>
      prevOptions.filter((opt) => opt.value !== option.value)
    );
    const selectedTags = form.getFieldValue("tags") || [];
    form.setFieldsValue({
      tags: selectedTags.filter((tag) => tag.value !== option.value),
    });
  };

  const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div>{data.label}</div>
        <div
          style={{ cursor: "pointer", color: "red" }}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(data);
          }}
        >
          Delete
        </div>
      </div>
    );
  };

  return (
    <Spin spinning={spinning}>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            Tags
          </Typography.Title>
        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item
            name={"tags"}
            rules={[
              {
                required: false,
                message: `Tag is required!`,
              },
            ]}
            wrapperCol={{ span: 16 }}
            className="mb-2 text-start"
          >
            <CreatableSelect
              isMulti
              // value={selectedTags}
              options={options}
              onChange={handleChange}
              onCreateOption={handleCreate}
              placeholder="Select or create tags"
              className="react-select-container"
              classNamePrefix="react-select"
              // components={{ Option: CustomOption }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
    </Spin>
  );
};

export default TagsOptions;
