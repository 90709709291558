import React, { useEffect, useState } from "react";
import BasicInformation from "./BasicInformation";
import LifeCycleStage from "./LifeCycleStage";
import AdditionalInformation from "./AdditionalInformation";
import InformationTags from "./InformationTags";
import InformationPeople from "./InformationPeople";
import {
  lifeCycles,
  businessValidationSchema,
  initialBusinessValues,
} from "../../../../validation/businessValidationSchema";
import { Form, Formik } from "formik";
import { Button, message, Form as AntForm } from "antd";
import BusinessApplicationServices from "../../../../services/services/BusinessApplicationService";
import { useApplicationlink } from "../../../../Context/linkProvider";
import {
  applicationsList,
  convertBusinessApplicationKeysToCase,
} from "../../../../utils/businessApplication";
import { useLocation, useNavigate } from "react-router";
import { useBusinessApplication } from "../../../../Context/businessApplication";
import { tabsList } from "../../../../utils/linksCommon";
import { Update_Application_ChangeLog } from "../../../../utils/ChangeLogs";
import { SaveRecentlyView_Portfolio } from "../../../../utils/ResentViewedPortfolio";
import DynamicForm from "../../../dynamicForm/DynamicForm";
import { useMenu } from "../../../../Context/MenuProvider";
import { useParams } from "react-router-dom";
import { changeConfirmLocale } from "antd/es/modal/locale";
import ScenarionServices from "../../../../services/services/ScenarioServices";

import {
  addIsTextToProperties,
  deepFindByName,
  isCamelCase,
  isPascalCase,
  transformText,
  SavePortfolioItemsCheckModified,
} from "../../../../utils/Common";

import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider";
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";
import { usePortfolioTag } from "../../../../Context/portfolioTagProvider";
import TagsOptions from "./TagsOptions";

import { useGlobalTag } from "../../../../Context/GlobalTagProvider";
import useFetchGlobalProp from "../../../../hooks/useFetchGlobalProp";

import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";

const TabInformations = ({
  setActiveTabKey,
  toggleTabDisabled,
  id: modelId,
  formItems,
  setFormItems,
  //fetchAllPortfolio,
  ...props
}) => {
  const [applicationForm] = AntForm.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  const [fieldItems, setFieldItems] = useState({});
  const [initialFormValues, setInitialFormValues] = useState(
    /* initialBusinessValues */ {}
  );
  const { state, dispatch } = useApplicationlink();
  const {
    state: businessApplicationState,
    dispatch: businessApplicationDispatch,
  } = useBusinessApplication();
  const [loading, setLoading] = useState(false);
  const [allTagsList, setAllTagsList] = useState([]);
  const { stateMenu, dispatchMenu } = useMenu();
  const [list, setList] = useState([]);
  const [menuID, setMenuID] = useState();

  const { state: stateChangeLog, dispatch: dispatchChangeLog } =
    useApplicationChangeLogs();
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;

  const { state: PortfolioSettingState, dispatch: PortfolioSettingDispatch } =
    usePortfolioSetting();
  const { loading: portfolioLoading, fetchPortfolio } = usePortfolioFetcher();

  ///////////////////////////////////////////////////////////
  const [globalTagsList, setGlobalTagsList] = useState([]);
  const { state: stateGlobalTag, dispatch: dispatchGlobalTag } = useGlobalTag();
  const { loading: GlobalTagLoading, error: GlobaltagError } = stateGlobalTag;
  const { error: errorGTag, fetchGlobalPropsData } = useFetchGlobalProp();

  let { id = null, moduleName, scenarioId } = useParams();

  if (id !== "add") {
    modelId = id;
  }

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(PortfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  ////////////////////////////////////////////////
  useEffect(() => {
    fetchAllPortfolio();
    fetchLogData({ moduleName, modelId });
  }, [modelId, moduleName]);

  useEffect(() => {
    if (PortfolioSettingState?.data?.length) {
      // const portfolioValues = deepFindByName(
      //   PortfolioSettingState?.data,
      //   staticSettingPortfolioList?.businessCapability,
      //   false
      // );

      const portfolioValues = PortfolioSettingState?.data.find(
        (item) => transformText(item.moduleName, "pascal") === moduleName
      );

      if (portfolioValues?.moduleName) {
        dispatchTag({ type: "EMPTY_TAGS" });
        dispatchTag({
          type: "SET_MODULE_NAME",
          payload: portfolioValues?.moduleName,
        });
      }

      setFormItems(addIsTextToProperties(portfolioValues, "Level"));
    }
  }, [PortfolioSettingState?.data, modelId, moduleName]);
  ///////////////////////////////////////////////////////////////

  const formatPropertyName = (name) => {
    // Convert name to lower case and remove leading/trailing spaces
    let formattedName = name.toLowerCase().trim();
    // Replace hyphens and underscores with spaces
    formattedName = formattedName.replace(/[_-]/g, " ");
    // Convert camelCase to Camel Case
    formattedName = formattedName.replace(/([a-z])([A-Z])/g, "$1 $2");
    // Capitalize the first letter of each word
    formattedName = formattedName.replace(/\b\w/g, (c) => c.toUpperCase());
    return formattedName;
  };

  const checkTextCase = (name) => {
    if (isPascalCase(name) || isCamelCase(name)) {
      return name; // If already in PascalCase or camelCase, return unchanged
    } else {
      return transformText(name, "camel"); // Convert to camelCase otherwise
    }
  };

  const fetchLogData = async ({ moduleName, modelId }) => {
    try {
      console.log("Log.... fetch Datasssss", moduleName, modelId);

      const response = await ChangeLogsServices.getChangeLogsList({
        moduleName,
        modelId,
      });

      const newData = response.data.data;
      await dispatchChangeLog({ type: "SET_LINK", payload: newData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const updateValuesForChangeLog = (data, propss) => {
    let oldList = {};
    Object.keys(data).map((dataItem) => {
      propss.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties
            ?.sort((a, b) =>
              formatPropertyName(a.name).localeCompare(
                formatPropertyName(b.name)
              )
            )
            ?.map((property, index) => {
              //if (dataItem === checkTextCase(property.name)) {
              if (dataItem === property.name) {
                if (property?.type === "Single select") {
                  property?.propertyOptions.map((propvalue) => {
                    if (data[dataItem] == null) {
                      oldList[dataItem] = "";
                    } else if (propvalue?.id === data[dataItem]) {
                      oldList[dataItem] = propvalue?.name;
                    }
                  });
                } else if (property?.type === "Multi select") {
                  let lst = data[dataItem];
                  if (lst?.length) {
                    let propValue = "";
                    for (let i = 0; i < lst?.length; i++) {
                      property?.propertyOptions.map((propvalue) => {
                        if (propvalue?.id === lst[i]) {
                          // console.log("data  Select Name >>>>", propvalue?.name);
                          if (propValue == "") {
                            propValue = propvalue?.name;
                          } else {
                            propValue = propValue + ", " + propvalue?.name;
                          }
                        }
                      });
                    }
                    oldList[dataItem] = propValue;
                  } else {
                    oldList[dataItem] = "";
                  }
                } else {
                  oldList[dataItem] = data[dataItem];
                }
              }
            });
      });
    });
    return oldList;
  };

  let response;
  const handleSubmit = async (values /*{ setSubmitting }*/) => {
    try {
      setLoading(true);
      // Perform API POST request
      //Old method
      // const newValues = convertBusinessApplicationKeysToCase(values);
      //New method

      const { displayname } = values;
      if (!displayname) {
        return message.error(`Display Name is Required`);
      }

      const newValues = {
        ...values,
        tags: values?.tags?.map((item) => item?.id),
      };

      // if(props?.locationData?.scenarioId && id==='add'){
      //   newValues.scenarioId = props?.locationData?.scenarioId;
      // }

      if (scenarioId && id === "add") {
        newValues.scenarioId = scenarioId;
        newValues.sourceItemType = "New";
      }

      response =
        //modelId === null || modelId === undefined
        id === "add"
          ? await BusinessApplicationServices.create(newValues)
          : await BusinessApplicationServices.update(id, newValues);

      const newData = response?.data?.id || null;

      //Update Data modification for scenario portfolio Item
      if (scenarioId && id !== "add") {
        const datalist = await BusinessApplicationServices.getById(id);
    
        console.log("datalist",datalist);

        const portfolioValues = PortfolioSettingState?.data.find(
          (item) => transformText(item.moduleName, "pascal") === moduleName
        );
        if (portfolioValues?.moduleName) {
          // await SavePortfolioItemsCheckModified({
          //   moduleName: portfolioValues?.moduleName,
          //   sourceItemId: parseInt(id, 10),
          //   targetItemId: datalist?.data?.sourceItemId,
          // });
          const objdata = {
            source_id: parseInt(id, 10), 
            modulename: portfolioValues?.moduleName,
            tab_name: "Information",
          };
          await ScenarionServices.checkscenariomodifyrecord(objdata);
        }
      }
      //

      const oldValues = updateValuesForChangeLog(
        list,
        formItems?.portfolioSections
      );

      const newValuesLog = updateValuesForChangeLog(
        newValues,
        formItems?.portfolioSections
      );

      modelId === null || modelId === undefined
        ? Update_Application_ChangeLog(
            response.data?.id,
            oldValues,
            newValuesLog,
            "Add",
            applicationsList.businessApplication,
            formItems?.portfolioSections
          )
        : Update_Application_ChangeLog(
            modelId,
            oldValues,
            newValuesLog,
            "Update",
            applicationsList.businessApplication,
            formItems?.portfolioSections
          );

      if (modelId === null || modelId === undefined) {
        await fetchLogData({ moduleName, modelId: newData });
      } else {
        await fetchLogData({ moduleName, modelId });
      }
      await fetchData();

      // Handle success response
      modelId === null || modelId === undefined
        ? message.success("Created Successfully")
        : message.success("Updated Successfully");

      modelId = newData;

      updateBussinessModuleId(newData);

      if (scenarioId) {
        setTimeout(() => {
          navigate(`/scenario-list-v2/${scenarioId}`);
        }, 1000);
      } else {
        setTimeout(async () => {
          // setSubmitting(false);

          // Navigate to the TabLinks tab
          // const query = new URLSearchParams({
          //   modelId: newData,
          //   modelName: applicationsList.businessApplication,
          // }).toString();

          // // Replace the current URL with the updated location
          // navigate({ search: query });
          await dispatch({
            type: "SET_BUSINESS_APPLICATION_MODEL",
            //payload: applicationsList.businessApplication,
            //payload: stateMenu?.MenuID?.moduleName,
            payload: moduleName,
          });
          await dispatch({ type: "SET_BUSINESSAPPLICATIONID", newData });
          navigate(`/portfolio/${moduleName}/${newData}`);
          // setActiveTabKey("tab_connections");
          // toggleTabDisabled("tab_connections", false);
        }, 1000);
      }
    } catch (error) {
      //message.error("Failed to submit form. Please try again.");
      message.error(error?.response?.data?.error || error?.message);
      console.error("API Error:", error);
      // setSubmitting(false);
    } finally {
      setLoading(false);
    }
  };

  const updateBussinessModuleId = async (id) => {
    await dispatch({ type: "SET_BUSINESSAPPLICATIONID", payload: id });
    await dispatch({
      type: "SET_BUSINESS_APPLICATION_MODEL",
      //payload: applicationsList.businessApplication,
      payload: moduleName,
    });
    await businessApplicationDispatch({ type: "SET_IS_EDIT", payload: true });
  };
  useEffect(() => {
    setFieldItems(formItems);
  }, [formItems, moduleName, modelId]);

  useEffect(() => {
    setMenuID(stateMenu);
    // fetchTagsData();
  }, [moduleName, modelId]);

  //////////////////////////////////////////////////////////////////////////
  //Fetch tags items
  const fetchGlobalProp = async () => {
    try {
      await fetchGlobalPropsData();
    } catch (error) {
      console.error("Error fetching Global Prop:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchGlobalProp();
  }, [moduleName, modelId]);

  useEffect(() => {
    setGlobalTagsList(stateGlobalTag.data);
  }, [stateGlobalTag?.data, moduleName, modelId]);

  //////////////////////////////////////////////////////////////////////////

  // Get all tags old
  const fetchTagsData = async () => {
    try {
      setLoading(true);
      const response = await BusinessApplicationServices.getAllTagsList();
      // console.log("response-tags-list", response.data.data);
      const newData = response.data.data;
      await businessApplicationDispatch({
        type: "SET_ALL_TAGS",
        payload: newData,
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    setAllTagsList(stateTag.data);
  }, [stateTag?.data, moduleName, modelId]);

  // useEffect(() => {
  //   setAllTagsList(businessApplicationState.allTags);
  // }, [businessApplicationState.allTags]);

  const updateAllToggleTabEnable = (tabsList) => {
    tabsList?.map((item) => toggleTabDisabled(item?.name, false));
  };

  useEffect(() => {
    if (modelId != null) {
      updateBussinessModuleId(modelId);
      updateAllToggleTabEnable(tabsList);
    }
  }, [modelId, businessApplicationState.isEdit, moduleName]);

  useEffect(() => {
    if (modelId && businessApplicationState.isEdit) {
      fetchData();
    }
  }, [modelId, businessApplicationState.isEdit, moduleName]);

  const fetchData = async () => {
    try {
      setLoading(true);

      const response = await BusinessApplicationServices.getById(modelId);

      setList(response.data);

      //Add Recent View portfolio
      if (stateMenu?.MenuID != undefined) {
        // SaveRecentlyView_Portfolio(
        //   stateMenu.MenuID.MenuName,
        //   window.location.href,
        //   response?.data?.displayname,
        //   response?.data?.id,
        //   stateMenu.MenuID.color
        // );

        const portfolioData = PortfolioSettingState?.data.find(
          (item) => transformText(item.moduleName, "pascal") === moduleName
        );

        SaveRecentlyView_Portfolio(
          //stateMenu.MenuID.moduleName,
          portfolioData.name,
          window.location.href,
          response?.data.displayname,
          response?.data.id,
          // stateMenu.MenuID.color
          portfolioData.color
        );
      }

      const newData = response.data;
      //Old method
      // const convertedResponse =
      //   response.data &&
      //   convertBusinessApplicationKeysToCase(response.data, false);
      // New Method
      const convertedResponse = (response.data && response.data) || {};

      const newResponse = {
        applicationName: convertedResponse?.nameInSourceSystem,
        appId: convertedResponse?.referenceID,
        lifeCycleStage: convertedResponse?.status,
        ...convertedResponse,
      };

      await businessApplicationDispatch({
        type: "SET_ONE_BUSINESS_APPLICATION",
        payload: newResponse || {},
      });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    if (
      businessApplicationState.isEdit &&
      modelId &&
      businessApplicationState.getOneBusinessApplication
    ) {
      setInitialFormValues((prev) => ({
        ...prev,
        ...businessApplicationState.getOneBusinessApplication,
      }));
      // setInitialFormValues(businessApplicationState.getOneBusinessApplication);
    }
  }, [businessApplicationState.getOneBusinessApplication, moduleName, modelId]);

  return (
    <>
      <DynamicForm
        data={fieldItems?.portfolioSections || []}
        form={applicationForm}
        formData={initialFormValues}
        labelCol={8}
        wrapperCol={16}
        createAndUpdate={handleSubmit}
        tagOptions={allTagsList}
        tagLoading={tagLoading}
        moduleName={formItems?.moduleName || null}
        GlobalTagOptions={globalTagsList}
      />

      {/* <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={businessValidationSchema}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, values, initialValues }) => {
          console.log("form-values", values, initialValues);
          return (
            <Form>
              <BasicInformation />
              <hr />
              <LifeCycleStage
                options={lifeCycles.map((lifeCycle) => ({
                  label: lifeCycle,
                  value: lifeCycle,
                }))}
              />
              <hr />
              <AdditionalInformation />
              <hr />
              <InformationTags tagOptions={allTagsList} />
              <hr />
              ////<InformationPeople />
              <Button
                type="primary"
                htmlType="submit"
                className="btn btn-theme py-1"
                ghost
                disabled={loading && isSubmitting}
              >
                Submit
              </Button>
            </Form>
          );
        }}
      </Formik> */}
    </>
  );
};

TabInformations.defaultProps = {
  toggleTabDisabled: () => {},
};

export default TabInformations;
