import React, { useEffect, useState, useRef } from "react";
import {
    Row,
    Col,
    Tooltip,
    Button,
    message,
    Flex,
    Spin,
    Pagination,
    Modal,
    Checkbox
} from "antd";
import {
    UnorderedListOutlined,
    PlusOutlined,
    ExclamationCircleFilled,
} from "@ant-design/icons";
import BusinessCapabilityListCards from "./business-capability-list/BusinessCapabilityListCards";
import ActionsBtn from "../applicationlist/ActionsBtn";
import { useNavigate } from "react-router";
import FilterRightSideBar from "../applicationlist/filter/FilterRightSideBar";
import usePortfolioFetcher from "../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import {
    PageTitleHeading,
    debounceTimeOut,
    deepFindByName,
    default_pageSize,
    default_pageSizeOptions,
    getSelectProperties,
    removeEmptyArrObjOrval,
    removeEmptyKeys,
} from "../../utils/Common";
import { staticSettingPortfolioList } from "../../utils/settingCommon";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import businessCapabilityServices from "../../services/services/BusinessCapabilityService";
import useDebounce from "../../helper/useDebounce";
import { useParams } from "react-router-dom";
import gridSvg from "./../../assets/Two columns layout.svg";
import { UpdateChangeLog } from "../../utils/ChangeLogs";
import { useMenu } from "../../Context/MenuProvider";
import { usePortfolioTag } from "../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../hooks/useFetchPortfolioTag";
import LoadingBar from "react-top-loading-bar";
import { SaveRecentlyView_Portfolio, RemoveRecentViewed } from "../../utils/ResentViewedPortfolio";
import BusinessProcessHeader from "../businessProcess/BusinessProcessHeader";
import { useLocation } from "react-router";

const lifeCycleStagesColors = {
    active: "#FFB381",
    phaseIn: "#FFF280",
    phaseOut: "#FC819E",
    endOfLife: "#B2B377",
    color5: "#B5C0D0",
    color6: "#BFEF82",
    color7: "#90DDF9",
};

const sortByValue = [
    { value: "level", title: "Level" },
    { value: "displayname", title: "Name" },
];

const BusinessCapabilityList = ({ basePath,...props }) => {
    const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
        usePortfolioSetting();
    const navigate = useNavigate();
    const [filterLevel, setFilterLevel] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [filterBy, setFilterBy] = useState("");
    const [showFilter, setShowFilter] = useState(false);
    const [loading, setLoading] = useState(false);
    const [portfolioData, setPortfolioData] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({sortBy: 'level,ASC'});
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(default_pageSize);
    const [lists, setLists] = useState([]);
    const [newLists, setNewLists] = useState([]);
    const { moduleName: moduleNamePath } = useParams();
    const { stateMenu, dispatchMenu } = useMenu();
    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { loading: tagLoading, error: tagError } = stateTag;
    const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();
    const ref = useRef(null);
    const location = useLocation()
    // Submit filter state it should work only apply is clicked
    const [filterSubmit, setFilterSubmit] = useState(false);
    const [selectedRelationFilters, setSelectedRelationFilters] = useState({});
    const [filterOptions, setFilterOptions] = useState({});
    const [bulkUpdateIds, setBulkUpdateIds] = useState([]);        
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);    
    const [deleteStart,setDeleteStart] = useState(false);

    useEffect(()=>{
        console.log("testing portfolio two state location",location);
        if(location?.state){
            console.log('testing portfolio one', "page",location?.state?.page, "limit",location?.state?.limit);
            setPage(location?.state?.page)
            setLimit(location?.state?.limit)
            }

    },[location?.state])

    const getPageNumber =()=>{
        let paze ;
        // console.log("testing location?.state?.page",location?.state?.page );
        
        if(location?.state?.page){
            paze=location?.state?.page
        }else{
            paze=page
        }
        return paze
    }

    const getPageLimit =()=>{
        let limitz ;
        // console.log("testing location?.state?.page",location?.state?.page );
        
        if(location?.state?.limit){
            limitz=location?.state?.limit
        }else{
            limitz=limit
        }
        return limitz
    }

    useEffect(()=>{
        if(bulkUpdateIds.length>0){
            setBulkDeleteButton(true)
        }else{
            setBulkDeleteButton(false)
        }
    },[bulkUpdateIds])

    //Get portfolios
    //Get all settings
    const fetchAllPortfolio = async () => {
        try {
            dispatchTag({ type: "EMPTY_TAGS" });
            const response = await fetchPortfolio(
                portfolioSettingDispatch,
                1,
                100,
            );
            if (response) {
                // message.success("Portfolio fetched successfully.");
            } else {
                message.error("Portfolio not found.");
            }
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    useEffect(() => {
        if (portfolioSettingState?.data?.length) {
            const portfolioValues = deepFindByName(
                portfolioSettingState?.data,
                staticSettingPortfolioList?.businessCapability,
                false,
            );
            if (portfolioValues?.portfolioSections?.length) {
                if (portfolioValues?.moduleName) {
                  dispatchTag({ type: "EMPTY_TAGS" });
                  dispatchTag({
                    type: "SET_MODULE_NAME",
                    payload: portfolioValues?.moduleName,
                  });
                }
                setPortfolioData(
                    getSelectProperties(
                        portfolioValues?.portfolioSections,
                        true,
                    ),
                );
            } else {
                setPortfolioData([]);
            }
        } else {
            fetchAllPortfolio();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState?.data]);

    const fetchFilteredData = async (filter,pageNumber,pageLimit) => {
        try {
            setLoading(true);
            if (ref.current) {ref.current.continuousStart()}
            const listz = await businessCapabilityServices.getList();
            setNewLists(listz.data.data)
            if (portfolioSettingState?.data?.length) {
                setLists([]);
                filter = removeEmptyKeys(filter);
                const portfolio = await deepFindByName(
                    portfolioSettingState?.data,
                    staticSettingPortfolioList?.businessCapability,
                    false,
                );
                const updatedFilter = {
                    moduleName: portfolio?.moduleName || "",
                    ...filter,
                };

                const response =
                    await PortfolioSettingServices?.getFilteredPortfolios(
                        updatedFilter,
                        pageNumber,
                        pageLimit
                    );
                if (response?.data?.data?.length) {
                    setTimeout(() => {
                        setLoading(false);
                        setTotalRecords(response?.data?.totalCount);
                        setLists(response?.data?.data);
                    }, 500);
                } else {
                    //message.info("No records found.");
                    setTotalRecords(response?.data?.totalCount || 0);
                    setLists(response?.data?.data);
                }
            } else {
                setLists([]);
                setTotalRecords(0);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false);
            setFilterSubmit(false);
            if (ref.current) {ref.current.complete()}
        }
    };

    useEffect(() => {
        setLists([]);
        setTotalRecords(0);
        const pageNumber = getPageNumber()
        const pageLimit = getPageLimit()
        fetchFilteredData(selectedFilters,pageNumber,pageLimit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState]);

    // Filter and debounce
    const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
    useEffect(() => {
        if (filterSubmit) {
            fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter),page,limit);
        }
        //export with filter
        setFilterOptions({...removeEmptyArrObjOrval(debouncedFilter),page:page,limit:limit});
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSubmit,debouncedFilter, page, limit]);

    //Fetch tags items
  const fetchAllPortfolioTags = async () => {
    try {
      await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (stateTag?.moduleName) {
      fetchAllPortfolioTags();
    }
  }, [stateTag?.moduleName]);

    const setItem = (item, type) => {
        if (type.type === "edit") {
        } else {
            showConfirm(item);
        }
    };


    const showConfirm = (item) => {
        let id = item?.id;
        Modal.confirm({
            title: 'Are you sure want to delete?',
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    let result;
                    result =
                        await businessCapabilityServices.deleteBusinessCapability(
                            id,
                        );
                    if (result) {
                        let obj = {
                            id: localStorage.getItem("id"),
                            NameInSourceSystem: "",
                            DisplayName: localStorage.getItem("DisplayName"),
                            Description: "",
                            LevelID: "",
                        };

                        let field_OldData = {
                            DisplayName: item?.displayname || "",
                            Description: item?.description || "",
                            LevelID: item?.levelid || "",
                        };

                        await UpdateChangeLog(
                            obj.id,
                            field_OldData,
                            obj,
                            "Delete",
                            stateMenu?.MenuID?.moduleName,
                        );

                          //Remove From Home Screen 
                          RemoveRecentViewed( {modelName:"",  modelID:id});
                          const filterdIds = bulkUpdateIds.filter((value)=>value !== id)
                        setBulkUpdateIds(filterdIds)
                          
                        setLists([]);
                        fetchFilteredData(selectedFilters,page,limit);
                        setTotalRecords(0);
                        message.success(result?.data?.msg);
                        // setId(null)
                    } else {
                        message.error(result?.data?.msg);
                    }
                } catch (error) {
                    message.error(error?.message);
                }
            },
            onCancel() {
                console.log("Cancel");
            },
        });
    };

    useEffect(()=>{
        if(deleteStart === true && bulkUpdateIds.length>0){
            Modal.confirm({
                title: 'Are you sure want to delete?',
                icon: <ExclamationCircleFilled />,
                async onOk() {
                    try {
                        let result;
                        result =
                            await PortfolioSettingServices.delete_bulk_Portfolio_ids(
                                {
                                    data:{
                                        id:bulkUpdateIds,
                                        moduleName:moduleNamePath
                                    }
                                }
                            );
                        console.log('testing results', result);
                            
                        if (result) {
                            // let obj = {
                            //     id: localStorage.getItem("id"),
                            //     NameInSourceSystem: "",
                            //     DisplayName: localStorage.getItem("DisplayName"),
                            //     Description: "",
                            //     LevelID: "",
                            // };
    
                            // let field_OldData = {
                            //     DisplayName: item?.displayname || "",
                            //     Description: item?.description || "",
                            //     LevelID: item?.levelid || "",
                            // };
    
                            // await UpdateChangeLog(
                            //     obj.id,
                            //     field_OldData,
                            //     obj,
                            //     "Delete",
                            //     stateMenu?.MenuID?.moduleName,
                            // );
    
                        //Remove From Home Screen 
                        //RemoveRecentViewed( {modelName:"",  modelID:id});
                              
                        //     setLists([]);
                                
                                setDeleteStart(false)
                                setBulkUpdateIds([])
                                fetchFilteredData(selectedFilters,page,limit);
                        //     setTotalRecords(0);
                        //     message.success(result?.data?.msg);
                                message.success("Deleted Successfully");
                            // setId(null)
                        } else {
                            message.error(result?.data?.msg);
                        }
                    } catch (error) {
                        console.log(error);
                        
                        message.error(error?.message);
                    }
                },
                onCancel() {
                    console.log("Cancel");
                    setDeleteStart(false)
                },
            });
        }
    },[deleteStart === true])

    const onCheckboxChange = (e) =>{
        let {value,checked} = e?.target;
        let ary = [...bulkUpdateIds];
        if(checked){
            ary.push(value);
        }else{
            ary = ary.filter(f=>f?.toString()!== value?.toString())
        }
        setBulkUpdateIds(ary);
    }
    return (
        <>
        <LoadingBar color="#1f1f1f" ref={ref} />
        <div className="applicationlist-page ">
             
            <div className="left_pnlbx">
                <div className="container-fluid">
                <BusinessProcessHeader 
                    activePage={props.activeGridStyle}
                    setActivePage={props.setActiveGridStyle}
                    heading={'Business Capability'}
                    addBtnTooltip={`Level 1`}
                    bulk_delete={bulkDeleteButton}
                    setDeleteStart={setDeleteStart}
                    lists={newLists}
                    filterOptions={filterOptions}
                />
                {/* <Row gutter={[8, 8]} className="mx-0 text-left mt-2">
                    <Col span={24} className="p-2"> */}
                        <Spin className="loading_bx" size="small" spinning={loading}>
                            <div
                                className="overflow-y pr-2 listview_bx"
                                style={{ maxHeight: window?.innerHeight - 200 }}
                            >
                                <Checkbox.Group className="w-100">
                                <BusinessCapabilityListCards
                                    {...props}
                                    setItem={setItem}
                                    setModalData={() => {}}
                                    lists={lists}
                                    filterLevel={filterLevel}
                                    filterText={filterText}
                                    filterBy={filterBy}
                                    loading={loading}
                                    basePath={basePath}
                                    isListCheckbox={true}
                                    onCheckboxChange={onCheckboxChange}
                                    page={page}
                                    limit={limit}
                                />
                                </Checkbox.Group>
                            </div>
                            {totalRecords > 0 && (
                                <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                <span className="mt-2" style={{fontSize:14}}> {bulkUpdateIds?.length} Selected {`${bulkUpdateIds?.length>1 ? "Items": "Item"}`} </span>
                                <Pagination
                                    className="mt-2 text-right"
                                    align={"center"}
                                    total={totalRecords}
                                    showTotal={(total) =>
                                        `Total ${total} Items`
                                    }
                                    defaultPageSize={limit || default_pageSize}
                                    defaultCurrent={page}
                                    pageSizeOptions={default_pageSizeOptions}
                                    showSizeChanger
                                    onChange={(p, ps) => {
                                        setFilterSubmit(true);
                                        setLimit(ps);
                                        setPage(p);
                                    }}
                                />
                            </div>
                            
                            )}
                        </Spin>
                    {/* </Col>
                </Row> */}
                </div>
            </div>
             
            {/* <div className="right_pnlbx"> */}
                <>
                    <FilterRightSideBar
                        activeGridStyle={props.activeGridStyle}
                        lifeCycleStagesColors={lifeCycleStagesColors}
                        showFilter={showFilter}
                        setShowFilter={setShowFilter}
                        portfolioData={portfolioData}
                        selectedFilters={selectedFilters}
                        // setSelectedFilters={setSelectedFilters}
                        setSelectedFilters={(value) => {setSelectedFilters(value)}}
                        sortValues={sortByValue}
                        optionalLevel={true}
                        //Filter relations
                        filterSubmit={filterSubmit}
                        setFilterSubmit={(value)=>{setFilterSubmit(value)}}
                        selectedRelationFilters={selectedRelationFilters}
                        setSelectedRelationFilters={setSelectedRelationFilters}
                    />
                </>
            {/* </div> */}
        </div>
        </>
    );
};

export default BusinessCapabilityList;
