import React, { useState, useRef, useEffect } from "react";
import { RightCircleOutlined, SyncOutlined } from "@ant-design/icons";
import { Button, Dropdown, Flex, Popconfirm, Progress, Space, Spin, Switch, Tooltip, message } from "antd";
import ImportApplicationData from "./ImportApplicationData";
import ImportSvg from "./../../assets/import.svg";
import ExportSvg from "./../../assets/Export.svg";
import PdfFileSvg from "./../../assets/Pdf File.svg";
import ExcelFileSvg from "./../../assets/Excel File.svg";
import CsvFileSvg from "./../../assets/Csv File.svg";
import DataImportScenarioServices from "../../services/services/DataImportScenarioServices";
import { useParams } from "react-router-dom";
import * as XLSX from "xlsx";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import { render } from 'react-dom';
import ReactToPdf from 'react-to-pdf';
import {
  addIsTextToProperties,
  baseUrl,
  deepFindByName,
  exportWitStatic,
  formatUrl,
  objectToQueryParams,
  transformText
} from "../../utils/Common";
import { jobStatusMessages, notificationType, placements, showNotification } from "../../utils/notificationUtil";
import { checkRole } from "../../helper/useUserData";

//const ref = React.createRef();



const ActionsBtn = ({exportWithFilter={},...props}) => {

  const items2 = [
    // {
    //   key: "export_to_pdf",
    //   label: (
    //     <Button
    //       type="link"
    //       icon={<img src={PdfFileSvg} alt={`PDF`} width={16} />}
    //       className="text-dark"
    //       block
    //       onClick={() => {
    //         exportExcelFile("pdf");
    //       }}
    //     >
    //       {" "}
    //       PDF{" "}
    //     </Button>
    //   ),
    // },
    {
      key: "export_to_xlx",
      label: (
        <Button
          type="link"
          icon={<img src={ExcelFileSvg} alt={`XLS`} width={16} />}
          className="text-dark"
          block
          onClick={() => {
            exportExcelFile("xlsx",true);
          }}
        >
          XLS ALL
        </Button>
      ),
    },
    {
      key: "export_to_csv",
      label: (
        <Button
          type="link"
          icon={<img src={CsvFileSvg} alt={`CSV`} width={16} />}
          className="text-dark"
          block
          onClick={() => {
            exportExcelFile("csv",true);
          }}
        >
          CSV ALL
        </Button>
      ),
    },
  ];


  //With filters
  const itemsFilter = [
    {
      key: "export_to_xlx_filter",
      label: (
        <Button
          type="link"
          icon={<img src={ExcelFileSvg} alt={`XLSFILTER`} width={16} />}
          className="text-dark"
          block
          onClick={() => {
            exportExcelFile("xlsx_filter",false);
          }}
        >
          XLS Filter
        </Button>
      ),
    },
    {
      key: "export_to_csv_filter",
      label: (
        <Button
          type="link"
          icon={<img src={CsvFileSvg} alt={`CSVFILTER`} width={16} />}
          className="text-dark"
          block
          onClick={() => {
            exportExcelFile("csv_filter",false);
          }}
        >
          CSV Filter
        </Button>
      ),
    },
  ];

  const userData = localStorage.getItem("userData");

  const [openImportModal, setOpenImportModal] = useState(false);
  const [openPDFModal, setOpenPDFModal] = useState(false);

  const [filter, setFilter] = useState(exportWithFilter || {});
  const [DDOption, SetDDOption] = useState(items2);
  
  let { moduleName } = useParams();
  const importExportStatusData = JSON.parse(localStorage.getItem("importExportStatus"));

  const [isImportInProgress, setIsImportInProgress] = useState(false); 
  const [isExportInProgress, setIsExportInProgress] = useState(false);
  const [exportAbortController, setExportAbortController] = useState(null);
  const [importAbortController, setImportAbortController] = useState(null);

  const [exportAuto, setExportAuto] = useState(false);
  const [exportPercent, setExportPercent] = useState(-50);
  const exportTimerRef = useRef(null)

  const [importAuto, setImportAuto] = useState(false);
  const [importPercent, setImportPercent] = useState(-50);
  const importTimerRef = useRef(null)


  // const downloadFile = (url) => {
  //   // Extract filename from URL
  //   const formattedUrl = formatUrl(url);
  //   const filename = formattedUrl.substring(formattedUrl.lastIndexOf('/') + 1);

  //   const link = document.createElement("a");
  //   link.href = url;
  //   link.download = filename;

  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // };

  useEffect(() => {
    // Relative URL from backend
    const relativeUrl =
      "/public/export/excel/Information%20Object_1722508858180_export.xlsx";

    // Construct the full URL
    const fileUrl = `${baseUrl}${formatUrl(relativeUrl)}`;
    // downloadFile(fileUrl);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      const importExportStatusUpdated = JSON.parse(localStorage.getItem("importExportStatus"));
      if (importExportStatusUpdated?.length > 0) {
        try {
          const userInfo = JSON.parse(userData);
          const findExport = importExportStatusUpdated.find(
            (item) =>
              item?.tableName === moduleName &&
              item?.type === "export" &&
              item?.userEmail === userInfo?.email
          );
          setIsExportInProgress(findExport && findExport?.status === "started" || false);

          const findImport = importExportStatusUpdated.find(
            (item) =>
              item?.tableName === moduleName &&
              item?.type === "import" &&
              item?.userEmail === userInfo?.email
          );
          setIsImportInProgress(findImport && findImport?.status === "started" || false);

        } catch (error) {
          console.error("Error parsing userData:", error);
        }
      }else{
        setIsExportInProgress(false)
        setIsImportInProgress(false)
      }
    }, 3000); // 3000 milliseconds = 3 seconds

    // Cleanup interval on component unmount or when dependencies change
    return () => clearInterval(intervalId);
  }, [moduleName]);
  
  
  
  
   
  useEffect(()=>{

  },[openPDFModal])

  useEffect(() => {
    if (exportWithFilter && Object.keys(exportWithFilter)?.length > 0) {
      const { sortBy, limit, page, searchAllFields, searchFields, ...rest } =
        exportWithFilter;
      const newItems =
        searchFields && searchFields?.length > 1
          ? { searchFields, ...rest }
          : { ...rest };
      setFilter(exportWithFilter);
      if (Object.keys(newItems)?.length > 0) {
        SetDDOption([...items2, ...itemsFilter]);
      } else {
        SetDDOption(items2);
      }
    }
  }, [exportWithFilter]);
  
  // const items = [
  //     {
  //         key: Math.random(),
  //         value:'import_application',
  //         label: <Button type="link" block onClick={()=>{setOpenImportModal(!openImportModal)}} className="text-dark"> Import </Button>,
  //     },
  //     {
  //         type: 'divider',
  //     },
  //     {
  //         key: Math.random(),
  //         value:'export_application',
  //         label: <Button type="link" block onClick={()=>{setShowExportSubMenu(!showExportSubMenu)}} className="text-dark"> Export </Button>,
  //         children:[
  //             {
  //                 key: 'export_to_pdf',
  //                 label: <Button type="link" className="text-dark" block> PDF </Button>,
  //             },
  //             {
  //                 key: 'export_to_xlx',
  //                 label: <Button type="link" className="text-dark" block> XLS </Button>,
  //             },
  //             {
  //                 key: 'export_to_csv',
  //                 label: <Button type="link" className="text-dark" block> CSV </Button>,
  //             },
  //         ],
  //     },
  // ]

  // Set auto-width for columns
  const autoWidth = async (ws) => {
    const colWidth = ws['!cols'] || [];
    const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
    data.forEach(row => {
      row.forEach((cell, i) => {
        const length = cell ? cell.toString().length : 10;
        colWidth[i] = { wch: Math.max(colWidth[i] ? colWidth[i].wch : 0, length) };
      });
    });
    ws['!cols'] = colWidth;
  };

  // Apply styles
  const setSheetStyles = async (ws) => {
    const range = XLSX.utils.decode_range(ws['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!ws[cell_ref]) continue;
        if (R === 0) { // Header row
  
          ws[cell_ref].s = {
            font: { bold: true, color: { rgb: "FFFFFF" } },
            fill: { fgColor: { rgb: "000000" } },
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } }
            }, 
            alignment: { horizontal: "center", vertical: "center" }
          };
           
        } else { // Data cells
          ws[cell_ref].s = {
            border: {
              top: { style: "thin", color: { rgb: "000000" } },
              bottom: { style: "thin", color: { rgb: "000000" } },
              left: { style: "thin", color: { rgb: "000000" } },
              right: { style: "thin", color: { rgb: "000000" } }
            }
          };
        }
      }
    }
  };

  // Function to move the id field to the last column in each object, if it exists
  const moveIdToLastColumn = (data) => {
    return data.map((obj) => {
      if ("id" in obj) {
        const { id, ...rest } = obj;
        return { ...rest, id };
      }
      return obj;
    });
  };

  // Function to update progress
const updateProgress = (currentStep, totalSteps,stateProcess) => {
  const progress = (currentStep / totalSteps) * 100;
  stateProcess(progress);
};

const updateImportExportStatus = (id)=>{
  const IEStatus = JSON.parse(localStorage.getItem("importExportStatus"));
  const updatedIEStatus = IEStatus?.filter((item) => item?.id !== id);
  localStorage.setItem("importExportStatus", JSON.stringify(updatedIEStatus));
}

  const exportExcelFile = async (type,exportAll=false) => {
    const controller = new AbortController();
    setExportAbortController(controller);
    setIsExportInProgress(true);
    let newJobId= null;
    try {
      let mmm = "";
      let view = "";

      // if (moduleName == "BusinessApplication") {
      //   mmm = "Application";
      //   view="List";
      // } else if (moduleName == "BusinessCapability") {
      //   mmm = "Business Capability";
      //   view="Grid";
      // }
      // else if (moduleName == "BusinessProcesses") {
      //   mmm = "Business Process";
      //   view="Grid";
      // }

      if (
        moduleName == "BusinessCapability" ||
        moduleName == "BusinessProcesses"
      ) {
        view = "Grid";
      } else {
        view = "List";
      }

      const response1 = await PortfolioSettingServices.getList({
        page: 1,
        limit: 200,
      });

      const moduleNameNew = response1?.data?.data?.find(
        (item) => transformText(item.moduleName, "pascal") == moduleName
      );

      const isFound = exportWitStatic.includes(moduleNameNew?.moduleName)
        ? 1
        : 0;

      const createJob = await DataImportScenarioServices.ExportAllPortfolio(
        {
          modelName: moduleNameNew.name,
          tableName: moduleName,
          view: view,
          unknownSource: isFound,
          isDataExport: 1,
          filters: !exportAll
            ? { moduleName: moduleNameNew?.moduleName, ...filter }
            : {},
          type:
            type === "xlsx_filter"
              ? "xlsx"
              : type === "csv_filter"
              ? "csv"
              : type,
          userData: JSON.parse(userData),
          jobId: null,
        },
        { signal: controller.signal }
      );

      // Check if jobid is present in the second response
      const createJobResponse = createJob?.data?.data;
      newJobId = createJobResponse.id;
      if (newJobId) {

        const importExportStatus = {
          ...createJobResponse,
          tableName: moduleName,
        };
        const IEStatus = JSON.parse(localStorage.getItem("importExportStatus"));
        const setImportExportStatus =
          IEStatus && IEStatus?.length > 0
            ? [...IEStatus, importExportStatus]
            : [importExportStatus];
        localStorage.setItem(
          "importExportStatus",
          JSON.stringify(setImportExportStatus)
        );

        showNotification(
          notificationType.info,
          `${jobStatusMessages.started.message} for exporting ${moduleNameNew.name}`,
          jobStatusMessages.started.description
        );

        const response = await DataImportScenarioServices.ExportAllPortfolio(
          {
            modelName: moduleNameNew.name,
            tableName: moduleName,
            view: view,
            unknownSource: isFound,
            isDataExport: 1,
            filters: !exportAll
              ? { moduleName: moduleNameNew?.moduleName, ...filter }
              : {},
            type:
              type === "xlsx_filter"
                ? "xlsx"
                : type === "csv_filter"
                ? "csv"
                : type,
            userData: JSON.parse(userData),
            jobId: newJobId,
          },
          { signal: controller.signal }
        );

        if (response?.data?.data) {
          const responseJob =
            await DataImportScenarioServices.getImportExportStatus(newJobId);
          if (responseJob) {
            const localStorageIEStatus = JSON.parse(
              localStorage.getItem("importExportStatus")
            );
            const removeCompletedProcess = localStorageIEStatus?.filter(
              (item) => item?.id !== newJobId
            );
            localStorage.setItem(
              "importExportStatus",
              JSON.stringify(removeCompletedProcess)
            );

            showNotification(
              notificationType.success,
              `${jobStatusMessages.completed.message} for ${moduleNameNew.name}`,
              jobStatusMessages.completed.description,
              6
            );
          }
        }
        if (
          type == "xlsx" ||
          type == "csv" ||
          type === "csv_filter" ||
          type === "xlsx_filter"
        ) {
          // Check if 'id' is present in the data
          const idPresent = /* response?.data?.data.some((obj) => "id" in obj); */ false;
          let dataWithIdLast = response?.data?.data?.map(({ id, ...rest }) => rest);

          if (idPresent) {
            // Move id field to the last column if it exists
            dataWithIdLast = moveIdToLastColumn(response?.data?.data);
          }

          // Define cell styles

          const worksheet = XLSX.utils.json_to_sheet(dataWithIdLast);

          // Apply auto-width and styles to the worksheet
          await autoWidth(worksheet);
          await setSheetStyles(worksheet);

          if (idPresent) {
            // Hide the last column (id) if it exists
            const lastColumnIndex = Object.keys(dataWithIdLast[0]).length - 1;
            worksheet["!cols"] = worksheet["!cols"] || [];
            worksheet["!cols"][lastColumnIndex] = { hidden: true };
          }

          // Freeze the first row
          // worksheet['!freeze'] = { xSplit: 0, ySplit: 1 };

          // Create a new workbook and add the worksheet
          const workbook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(workbook, worksheet, moduleNameNew.name);
          //let buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
          //XLSX.write(workbook, { bookType: "xlsx", type: "binary" });

          const fileType =
            type === "xlsx_filter"
              ? "xlsx"
              : type === "csv_filter"
              ? "csv"
              : type;
          XLSX.writeFile(workbook, moduleNameNew.name + "." + fileType);

        } else if (type == "pdf") {
          setOpenPDFModal(true);
        }
      }
    } catch (error) {
      updateImportExportStatus(newJobId);
      showNotification(
        notificationType.error,
        `The export is failed`,
        'Due to some query issue export is failed.',
      );
      console.log("error",error)
      console.error("Error fetching Template Data:", error);
      message.error("Something went wrong.");

    }finally {
      setIsExportInProgress(false);
    }
  };

  const handleExportAbort = async () => {
    if (exportAbortController) {
      exportAbortController.abort();
      showNotification(notificationType.info,'Export process aborted.');
      setIsExportInProgress(false);
    }
  };

  

  return (
    <>
      <Space nowrap={"true"} direction="horizontal">
        {props?.showImport && checkRole('import') &&!isImportInProgress && (
          <Tooltip title={`Import`}>
            <Button
              className=""
              icon={<img src={ImportSvg} alt={`Import`} width={25} />}
              onClick={() => {
                setOpenImportModal(!openImportModal);
              }}
            />
          </Tooltip>
        )}
        {isImportInProgress && (
          <Tooltip title={`Import in progress will update you after complete.`}>
            <Button icon={<SyncOutlined style={{ color: "#2A609D" }} width={25} spin />} className=""/>
          </Tooltip>
        )}
        {props?.showExport && checkRole('export') && !isExportInProgress && (
          <Dropdown menu={{ items: DDOption }} placement="bottom">
            <Tooltip title={`Export`}>
              <Button
                icon={<img src={ExportSvg} alt={`Export`} width={25} />}
              />
            </Tooltip>
          </Dropdown>
        )}
        {/* {isExportInProgress && (
          <Popconfirm
            title="Are you sure you want to abort the export process?"
            onConfirm={handleExportAbort}
            okText="Yes"
            cancelText="No"
          >
            <Tooltip title={`Export in progress`}>
              <Flex align="center" gap="middle">
                <Progress type="circle" percent={exportPercent} size={30} showInfo={true}/>
              </Flex>
            </Tooltip>
          </Popconfirm>
        )} */}
        {isExportInProgress && (
          <Tooltip title={`Export in progress will update you after complete.`}>
            <Button icon={<SyncOutlined style={{ color: "#2A609D" }} width={25} spin />} className="pt-2"/>
          </Tooltip>
        )}
      </Space>
      <ImportApplicationData
        open={openImportModal}
        setOpen={setOpenImportModal}
        setOpenPDF={setOpenPDFModal}
        openPDF={openPDFModal}
        setIsImportInProgress={setIsImportInProgress}
        isImportInProgress={isImportInProgress}
        {...props}
      />
    </>
  );
};

ActionsBtn.props = {
  showImport:true,
  showExport:true,
  exportWithFilter:{},
}

export default ActionsBtn;
