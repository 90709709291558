import { Button, Input, Divider, Upload, message } from "antd";
import React, { useEffect, useState } from "react";
import { checkRole } from "../../../../helper/useUserData";
import { PaperClipOutlined } from '@ant-design/icons';
import axios from "axios";
import CommentServices from "../../../../services/services/CommentServices";

const { TextArea } = Input;

const CreateComment = ({ setActiveTabKey,refreshData, setRefreshData, ...props}) => {

  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [comments, setComments] = useState('');
  

  const handleUpload = async () => {
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('image', file);
    });
    setUploading(true);

    if(comments.length === 0){
      message.error("Please Enter Comment")
      setUploading(false)
      return;
    }
  
    try {
      let res = []
      if(fileList.length>0){
      const response = await fetch(`${process.env.REACT_APP_API_URL}etl/uploadMedia`, {
        method: 'POST',
        body: formData,
      });
      res = await response.json();
    }
      


      let userName = await localStorage.getItem("userData")
      userName = JSON.parse(userName)
      let obj = {
        "username": userName?.name,
        "comment": comments,
        "urls": res?.blobUrls || [],
        'parentid':0,
        'modulename': props?.moduleName,
        'moduleid': props?.id
      };
      await CommentServices.create(obj);
      console.log('testing uploaded urls', res?.blobUrls);
      message.success('upload successfully.');
      setFileList([]);
      setComments("")
    } catch (error) {
      message.error('upload failed.');
    } finally {
      setUploading(false);
      setRefreshData(!refreshData)
    }
  };
  
  const propss = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList([...fileList, file]);
      return false;
    },
    onUploadProgress: (progressEvent) => {
      const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      message.loading(`Uploading... ${percent}%`, 0);
    },
    fileList,
  };




  useEffect(() => {
    const elements = document.getElementsByClassName('ant-upload-list')[0];
    elements.classList.add('ant-upload-list-one');
    //const customDiv = `<div>Uploaded Files</div> ` 
    const customDiv = document.createElement('div');
    customDiv.style.fontWeight = 'bold';
    customDiv.className = 'custom-upload-header'; // Add a custom class for additional styling if needed
    
    // Create and add the icon
    const icon = document.createElement('span');
    icon.innerHTML = '<svg viewBox="64 64 896 896" focusable="false" data-icon="paper-clip" width="1.2em" height="1.2em" fill="currentColor" aria-hidden="true"><path d="M779.3 196.6c-94.2-94.2-247.6-94.2-341.7 0l-261 260.8c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l261-260.8c32.4-32.4 75.5-50.2 121.3-50.2s88.9 17.8 121.2 50.2c32.4 32.4 50.2 75.5 50.2 121.2 0 45.8-17.8 88.8-50.2 121.2l-266 265.9-43.1 43.1c-40.3 40.3-105.8 40.3-146.1 0-19.5-19.5-30.2-45.4-30.2-73s10.7-53.5 30.2-73l263.9-263.8c6.7-6.6 15.5-10.3 24.9-10.3h.1c9.4 0 18.1 3.7 24.7 10.3 6.7 6.7 10.3 15.5 10.3 24.9 0 9.3-3.7 18.1-10.3 24.7L372.4 653c-1.7 1.7-2.6 4-2.6 6.4s.9 4.7 2.6 6.4l36.9 36.9a9 9 0 0012.7 0l215.6-215.6c19.9-19.9 30.8-46.3 30.8-74.4s-11-54.6-30.8-74.4c-41.1-41.1-107.9-41-149 0L463 364 224.8 602.1A172.22 172.22 0 00174 724.8c0 46.3 18.1 89.8 50.8 122.5 33.9 33.8 78.3 50.7 122.7 50.7 44.4 0 88.8-16.9 122.6-50.7l309.2-309C824.8 492.7 850 432 850 367.5c.1-64.6-25.1-125.3-70.7-170.9z"></path></svg>'; // Example of directly using SVG; replace with your icon code
    icon.style.marginRight = '8px'; // Space between icon and text

    const textSpan = document.createElement('span');
    textSpan.textContent = 'Uploaded Files:';
    textSpan.style.fontWeight = 'bold'; // Make the text bold
    textSpan.style.fontFamily = 'sans-serif'

    // Append the icon and text to the div
    customDiv.appendChild(icon);
    customDiv.appendChild(textSpan);

    // Find the upload list element
    const uploadList = document.getElementsByClassName('ant-upload-list')[0];
    if (!document.querySelector('.custom-upload-header')) {
      // Insert the custom div before the upload list
      uploadList.insertAdjacentElement('afterbegin', customDiv);
    }
  }, [])

  return (
    <div className="flex flex-col items-start gap-4 p-2">
      {/* <div className="text-sm font-bold">Comments</div> */}
      <div className="txtdes">
        <h4>Comments</h4>
        <p>(this screen shows comments and replies left by users against the current object current object)</p>
      </div>

      <div className="w-3/4">
        <TextArea
          placeholder="Add Comments"
          className="w-full h-3/4 border border-2 rounded-lg shadow-md"
          autoSize={{ minRows: 6, maxRows: 100 }}
          value={comments}
          onChange={(e) => setComments(e.target.value)}
        />
      </div>
      <div>
        <Upload {...propss} >
          <Button className="h-auto w-36 mr-2" >Attach Files</Button>
          {checkRole('save') && <Button
            type="primary"
            className="px-4 h-auto w-36"
            onClick={(e) => {
              e.stopPropagation();
              handleUpload();
            }}
            loading={uploading}
          >
            Post
          </Button>}
        </Upload>
      </div>
    </div>
  );
};

export default CreateComment;
