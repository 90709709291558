import { CheckOutlined, CopyOutlined, DeleteOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Flex, Typography } from 'antd';
import React from 'react'
import { useNavigate } from "react-router";
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { checkRole } from '../../../helper/useUserData';

export default function BusinessProcessButtonsV1({moduleName,item,basePath,...props}) {

    const navigate = useNavigate();
    return (
        <Flex gap={4} className="btnbx">
            {props?.showButtons?.view && (
            <EyeOutlined
                className="cursor-pointer ml-2"
                onClick={() => {

                    navigate(basePath+'/'+item?.id  )
                }}
                style={{ fontSize: "20px" }}
            />
            )}
            {props?.showButtons?.edit && checkRole('update') &&(
            <EditOutlined
                className="cursor-pointer ml-2 text-blue-500"
                onClick={() => {
                    navigate(basePath+'/'+item?.id  )
                }}
                style={{ fontSize: "20px" }}
            />
            )}
            <Typography.Text 
                copyable={{ 
                    text: window.location.origin+getMenuPortfolio({routeModuleName:moduleName,getRouteByRouteModule:true})+"/"+item?.id ,
                    icon:[<CopyOutlined style={{ fontSize: 20 }}/>,<CheckOutlined style={{ fontSize: 20 }}/>],
                    tooltips:['Copy Url','Copied'],
                }}
            />
            {props?.showButtons?.delete && checkRole('delete') && (
            <DeleteOutlined
                className="cursor-pointer ml-2 text-danger"
                onClick={() => {
                    props?.setItemDelete(item, { type: "delete" });
                }}
                style={{ fontSize: "20px" }}
            />
            )}
        </Flex>
    )
}
