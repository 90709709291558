import './BusinessCapabilityMaster.scss';
import { useState, useRef} from 'react';
import BusinessCapabilityList from './BusinessCapabilityList';
import BusinessCapabilityGrid from './BusinessCapabilityGrid';
import { useLocation } from 'react-router';

const BusinessCapabilityMaster = (props) => {
    const location = useLocation()
    const mainDivRef = useRef(null);
    const [activeGridStyle, setActiveGridStyle] = useState(1);

    useState(()=>{
        setActiveGridStyle(location?.state?.defaultActiveKey || 1)
    },[])
    return <>
        
        <div className='mb-0' ref={mainDivRef}>
            {activeGridStyle===2 ? <BusinessCapabilityGrid 
                                        activeGridStyle={activeGridStyle}
                                        setActiveGridStyle={setActiveGridStyle }
                                        width={mainDivRef?.current?.clientWidth}
                                        basePath={location?.pathname}
                                    /> : '' }
            {activeGridStyle===1 ? <BusinessCapabilityList 
                                        activeGridStyle={activeGridStyle}
                                        setActiveGridStyle={setActiveGridStyle }
                                        basePath={location?.pathname}
                                        showButtons={{
                                            view:false,
                                            edit:false,
                                            delete:true,
                                        }}
                                    /> : '' }
        </div>
    </>;
}

export default BusinessCapabilityMaster;