import React, { createContext, useContext, useReducer } from "react";
const PlantContext = createContext();
export const usePlant = () => useContext(PlantContext);

const initialState = {
    data: [],
    loading: false,
    error: false,
    allTags: [],
    isEdit: false,
    getOnePlant: {},
};

const plantReducer = (state, action) => {
    switch (action.type) {
        case "SET_PLANT":
            return {
                ...state,
                data: [...state.data, ...action.payload],
            };
        case "ADD_PLANT":
            return {
                ...state,
                data: [...state.data, action.payload],
            };
        case "UPDATE_PLANT":
            return {
                ...state,
                data: state.data.map((item) =>
                    item.id === action.payload.id ? action.payload : item,
                ),
            };
        case "DELETE_PLANT":
            return {
                ...state,
                data: state.data.filter(
                    (item) => item.id !== action.payload.id,
                ),
            };
        case "EMPTY_PLANT":
            return {
                ...state,
                data: [],
            };
        case "SET_ALL_TAGS":
            return {
                ...state,
                allTags: action.payload,
            };
        case "SET_IS_EDIT":
            return {
                ...state,
                isEdit: action.payload,
            };
        case "SET_ONE_PLANT":
            return {
                ...state,
                getOnePlant: action.payload,
            };
        default:
            return state;
    }
};

export const PlantProvider = ({ children }) => {
    const [state, dispatch] = useReducer(plantReducer, initialState);

    return (
        <PlantContext.Provider value={{ state, dispatch }}>
            {children}
        </PlantContext.Provider>
    );
};
