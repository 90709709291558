import React from 'react';
import LevelGroupingMaster from '../components/LevelGroupingMaster/LevelGroupingMaster';
import {useLocation} from 'react-router-dom';

function LevelGroupingMasterPage(props) {
  const location = useLocation();

  const levelGroupingState = localStorage.getItem("levelGroupingState");
  localStorage.setItem("levelGroupingState", JSON.stringify(location.state));
  
  return (
    <>
        <LevelGroupingMaster pathData = {location.state || JSON.parse(levelGroupingState)}/>
    </>
  )
}

export default LevelGroupingMasterPage;
