import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getList(searchTerm = '') {
  const url = `${API_BASE_URL}globalsearch?q=${searchTerm}`;
  let response = await http.get(url);
  return response;
}

const GlobalSearchServices = {
  getList
};

export default GlobalSearchServices;
