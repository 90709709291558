import React, { useEffect, useState, useRef } from "react";
import { Button, Col, Empty, Flex, Modal, Tooltip, message } from "antd";
import Tree from "react-d3-tree";
import { FullscreenOutlined, PaperClipOutlined } from "@ant-design/icons";
import "./ConnectionsGraph.scss";
import useDynamicNavigate from "../../../../utils/navigateUtils";
import { convertToNormalText, dynamicRoutesLinks, getPortfolioColor, getPortfolioID } from "../../../../utils/portfolioRelationshipCommon";
import { useMenu } from "../../../../Context/MenuProvider";
import { useApplicationlink } from "../../../../Context/linkProvider";
import { transformText } from '../../../../utils/Common';
import { cleanData } from "jquery";
import FullScereenContent from "../../../../utils/FullScereenContent";
import { getMenuPortfolio } from "../../../../utils/settingCommon";
const firstRender = { current: true };
const myTreeData = [
  // Your tree data here...
];

const test = {
  shape: "rect",
  shapeProps: {
    width: 0,
    height: 0,
    x: -20,
    y: 20,
    stroke: "#2F80ED",
  },
};

const truncateText = (text, maxLength = 20) => {
  if (text.length <= maxLength) return text;
  return text.slice(0, maxLength) + "...";
};

const isTextLong = (text) => {
  return text?.length > 25;
};

const handleLink = async (values, navigateTo, portfolioList, stateMenu, dispatchMenu) => {
  const colorPortfolio = getPortfolioColor(values?.parentTitle, portfolioList);
  const portfolioID = getPortfolioID(values?.parentTitle, portfolioList);

  let redirectLink = dynamicRoutesLinks?.find(
    (item) => item?.moduleName === values?.parentTitle
  );

  const menuPortfolio = getMenuPortfolio({ name: values?.parentTitle });
  if (menuPortfolio && !redirectLink) {
    redirectLink = {
      moduleName: menuPortfolio?.routeModuleName,
      link: '/' + menuPortfolio?.portfolioRoute + '/',
      type: menuPortfolio?.moduleName,
    };
  }
  if (values?.parentTitle && redirectLink && values?.data?.id) {
    
    //  await dispatchMenu({
    //   type: "MenuDetail",
    //   payload: { Name: redirectLink?.link, MenuName: values?.parentTitle, ID: portfolioID, moduleName:values?.parentTitle,  color: colorPortfolio },
    // });
      
    // dispatch({
    //   type: "SET_BUSINESS_APPLICATION_MODEL",
    //   //payload: applicationsList.businessApplication,
    //   payload:stateMenu?.MenuID?.moduleName,
    // });

    const newLink = redirectLink?.link +values?.parentTitle +'/'+ values?.data?.id  ;
    navigateTo(newLink, true);
  } else {
    message.error("Link not found.");
  }
};

const renderCard = ({
  nodeDatum,
  toggleNode,
  foreignObjectProps = {},
  navigateTo,
  portfolioList,
  stateMenu,
  dispatchMenu,
  toggledNodes,
  setToggledNodes,
  clickHandle
}) => {
  let iconBtn;
  let caption = "";

  portfolioList.map((item) => {
    const portfolio_tmp = transformText(item.moduleName.replace("_", " "), "pascal");
    if (portfolio_tmp === nodeDatum.title || item.moduleName === nodeDatum.title) {
      caption = item.name;
    }
  });

  const handleIconClick = () => {
    if (nodeDatum?.__rd3t?.depth === 2) {
      handleLink(nodeDatum, navigateTo, portfolioList, stateMenu, dispatchMenu);
    }
  };

  const handleToggleNode = () => {
    clickHandle(nodeDatum?.title)
    const newToggledNodes = { ...toggledNodes, [nodeDatum.__rd3t.id]: !toggledNodes[nodeDatum.__rd3t.id] };
    setToggledNodes(newToggledNodes);
    toggleNode();
  };

  if (nodeDatum?.__rd3t?.depth === 1) {
    iconBtn = (
      <span className="cu-border-22 pull-right">
        {nodeDatum.children.length === 0 ? 1 : nodeDatum.children.length}
      </span>
    );
  } else if (nodeDatum?.__rd3t?.depth === 2) {
    iconBtn = (
      <span className="pull-right">
        <PaperClipOutlined />
      </span>
    );
  }

  return (
    <React.Fragment>
      <foreignObject
        {...foreignObjectProps}
        width="225"
        height="300"
        x="-100"
        y="-18"
      >
        <div
          className="card card-body p-2 rounded-0"
          onClick={handleToggleNode}
          style={{ background: nodeDatum?.background }}
        >
          {nodeDatum?.__rd3t?.depth === 2 ? (
            <Flex className="mb-0" justify="space-between" gap={8}>
              {isTextLong(nodeDatum.title) ? (
                <Tooltip title={convertToNormalText(nodeDatum.title)}>
                  <span className="cu-text-ellipsis">{nodeDatum.title}</span>
                  {/* <span>{truncateText(convertToNormalText(nodeDatum.title))}</span> */}
                </Tooltip>
              ) : (
                <span className="cu-text-ellipsis">{convertToNormalText(nodeDatum.title)}</span>
              )}
              <span className="pull-right" onClick={handleIconClick}>
                <PaperClipOutlined />
              </span>
            </Flex>
          ) : (
            <div className={`mb-0 text-nowrap text-left ${nodeDatum.title}`}>
              {isTextLong(nodeDatum.title) ? (
                <Tooltip title={convertToNormalText(nodeDatum.title)}>
                  <span>{nodeDatum.title}</span>
                  {/* <span>{truncateText(convertToNormalText(nodeDatum.title))}</span> */}
                </Tooltip>
              ) : (
                nodeDatum?.__rd3t?.depth === 1 ? (
                  <span>{convertToNormalText(caption)}</span>
                ) : (
                  <span>
                    {nodeDatum.title}
                    {/* {convertToNormalText(nodeDatum.title)} */}
                  </span>
                )
              )}
              {iconBtn}
            </div>
          )}
        </div>
      </foreignObject>
    </React.Fragment>
  );
};

const ConnectionsGraph = ({ graphData, portfolioList, loading, ...props }) => {
  const navigateTo = useDynamicNavigate();
  const [open, setOpen] = useState(false);
  const [openvar, setOpenvar] = useState([]);
  const [openvar1, setOpenvar1] = useState([]);
  const { stateMenu, dispatchMenu } = useMenu();
  const { state, dispatch } = useApplicationlink();
  const [toggledNodes, setToggledNodes] = useState({});
  const [memorizedGraphData, setMemorizedGraphData] = useState(graphData);
  
  useEffect(()=>{
    localStorage.removeItem("user_connection")
  },[])

  useEffect(()=>{
    const localData = JSON.parse(localStorage.getItem("user_connection"))||[]
    setOpenvar(localData)
    setOpenvar1(localData)
  },[graphData])

  const clickHandle = (value) => {
  if (openvar.indexOf(value) !== -1) {
    const newVal = openvar.filter((elem) => elem !== value);
    setOpenvar(newVal);
    localStorage.setItem("user_connection", JSON.stringify(newVal))
  } else {
    setOpenvar([...openvar, value]);
    localStorage.setItem("user_connection", JSON.stringify([...openvar, value]))
  }
};


  useEffect(() => {
    if (JSON.stringify(memorizedGraphData) !== JSON.stringify(graphData)) {
      setMemorizedGraphData(graphData);
    }
    
  }, [graphData]);

  const restoreOldData =()=>{
    if ( memorizedGraphData.length > 0 && !loading  )
      {
        let time = 500
        openvar.forEach((selector)=>{
          setTimeout(() => {
            const cardElement1 = document.querySelector(`.${selector}`);
            if (cardElement1) {
              cardElement1.click();
              time = time+10;
            }
          }, time);
        })
        setTimeout(() => {
          localStorage.setItem("user_connection", JSON.stringify(openvar1))
        }, 550);
      }else{
        // console.log("error")
      }
  }
  
  useEffect(() => {
   restoreOldData()
  }, [loading === false]);
  
  const handleClick = (text) => alert(text);
  return (
    <div id="connection-charts">
      <div className="App">
        {(memorizedGraphData?.length && (
          <FullScereenContent setOpen={setOpen} title={open ? `This screen shows the relationship between current object and other objects in the portfolio` : ``}>
            <div
              id="treeWrapper"
              style={open ? { width: "100%", height: window?.innerHeight + 100 } : { width: "100%", height: "45vh" }}
              className="px-2"
            >
              <Tree
                data={memorizedGraphData}
                nodeSvgShape={test}
                pathFunc="step"
                separation={{ siblings: 2, nonSiblings: 2 }}
                orientation="horizontal"
                translate={{ x: 100, y: 150 }}
                nodeSize={{ x: 350, y: 25 }}
                allowForeignObjects={false}
                initialDepth={0.02}
                zoomable={open}
                renderCustomNodeElement={(rd3tProps) =>
                  renderCard({
                    ...rd3tProps,
                    // handleClick,
                    navigateTo,
                    portfolioList,
                    stateMenu,
                    dispatchMenu,
                    toggledNodes,
                    setToggledNodes,
                    clickHandle
                  })
                }
              />
            </div>
          </FullScereenContent>
        )) || (
          <Empty
            description={
              <>
                <div className="line-height-normal my-5">
                  <div className="fs-4">No Current Relations</div>
                  <small className="w-100">
                    (You can create relations below)
                  </small>
                </div>
              </>
            }
            image={false}
            imageStyle={{ height: 0 }}
          />
        )}
      </div>
    </div>
  );
};

export default ConnectionsGraph;
