import React, { useEffect, useState } from "react";
import LinkList from "./LinkList";
import AddLinks from "./AddLinks";
import { dummyData } from "../../../../validation/linksSchema";
import { Divider } from "antd";
import { useApplicationlink } from "../../../../Context/linkProvider";
import linkServices from "../../../../services/services/LinkService";
import { applicationsList } from "../../../../utils/businessApplication";
import {  useParams } from "react-router-dom";

const TabLinks = ({
  setActiveTabKey,
  toggleTabDisabled,
  id: modelId,
  moduleName: moduleName,
}) => {
  const { state, dispatch } = useApplicationlink();
  const [businessApplicationId, setbusinessApplicationId] = useState(null);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const { moduleName:moduleNamePath } = useParams();
  
 
  const fetchData = async ({ moduleName, moduleID }) => {
    try {
 
      setLoading(true);
      const response = await linkServices.getListByModule({
        moduleName,
        moduleID,
      });

      const newData = response.data.data || [];

      await dispatch({ type: "SET_LINK", payload: newData });
      await dispatch({ type: "SET_BUSINESSAPPLICATIONID", payload: modelId });
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };
 
  useEffect(() => {
    setbusinessApplicationId(state.businessApplicationId);
    
    fetchData({
      moduleName: moduleNamePath,
      moduleID: modelId || state.businessApplicationId,
    });
  }, [state.businessApplicationId, modelId]);

  /*useEffect(() => {
    setData(state.links);  
  }, []);*/

  useEffect(() => {
    setData(state.links);  
  }, [state.links, modelId]);

  return (
    <>
      <LinkList
        data={data}
        businessApplicationId={businessApplicationId}
        moduleName={moduleNamePath}
      />
      {/* <Divider className="my-4 bg-gray-500" /> */}
      <AddLinks
        businessApplicationId={businessApplicationId}
        moduleName={moduleNamePath}
      />
    </>
  );
};

export default TabLinks;
