import { Button, Flex, Form, Modal, Spin, Table, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSaveState } from "../../../Context/StateProviderData";
import { ExclamationCircleFilled } from "@ant-design/icons";
import TextWithTooltip from "../../../utils/TextWithTooltip";
import {
  AddRowBtn,
  ClearAllFilterBtn,
  DeleteRowBtn,
  SaveBtn,
} from "./BulkEntryBtns";
import UpdateColumnData, { columnsRelationPortfolio } from "./UpdateColumnData";
import GetColumnFilters from "./GetColumnFilters";
//import UpdateGlobalState from "../../../components/portfolio/common/UpdateGlobalState";
import { getMenuPortfolio } from "../../../utils/settingCommon";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import ScenarioServices from "../../../services/services/ScenarioServices";
import { cleanData } from "jquery";

import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import UpdateGlobalState from "../../portfolio/common/UpdateGlobalState";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../../hooks/useFetchPortfolioTag";
import { useGlobalTag } from "../../../Context/GlobalTagProvider";
import useFetchGlobalProp from "../../../hooks/useFetchGlobalProp";
import PortfolioTagServices from "../../../services/services/PortfolioTagService";
import TemplateService from "../../../services/services/TemplateService";
import { checkRole } from "../../../helper/useUserData";

//const IntegrationGrid = ({portfolioData,...props}) =>{
const ScenarioIntegrationGrid = ({
  ScenarioId,
  moduleID,
  copyScenarioID,
  freeColumnName,
  integrationGridData,
  handleBulkUpdate_new
}) => {
  const tableRef = useRef(null);
  const [form] = Form.useForm();
  const [state, setState] = useState({});
  // const { state } = useSaveState();
  // console.log("state",state);
  //const { updateLocale } = UpdateGlobalState();

  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { fetchTagsData } = useFetchPortfolioTag();
  const { state: stateGlobalTag, dispatch: dispatchGlobalTag } = useGlobalTag();
  const { error: errorGTag, fetchGlobalPropsData } = useFetchGlobalProp();

  const [columns, setColumns] = useState(null);
  const [selectedTableRows, setSelectedTableRows] = useState([]);
  const [dataSource, setDataSource] = useState([]);
  const [spinning, setSpinning] = useState(false);
  const [refAddBtn, setRefAddBtn] = useState(false);
  const [addClickCount, setAddClickCount] = useState(0);
  const [filteredData, setFilteredData] = useState(null);
  const [isFiltered, setIsFiltered] = useState(false);
  const [filteredInfo, setFilteredInfo] = useState({});
  const [oldFormData, setOldFormData] = useState([]);
  const [portfolioData, setPortfolioData] = useState(null);
  const [portfolioDatanew, setPortfolioDatanew] = useState(null);
  const [relationShipDropdowns, setRelationShipDropdowns] = useState({});

  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();
  //const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();

  useEffect(() => {
    setPortfolioDatanew(getMenuPortfolio({ routeModuleName: "Integration" }));
  }, []);
  useEffect(() => {
    if (portfolioDatanew) {
      getStateData();
    }
  }, [portfolioDatanew]);
  useEffect(() => {}, [copyScenarioID]);

  //Portfolio Setting
  const fetchPortfolio = async (page = 1, limit = 100) => {
    try {
      const response = await PortfolioSettingServices.getList({ page, limit });
      const newData = response.data.data;

      setPortfolioData(newData);
    } catch (error) {
      console.error("Something went wrong", error);
    } finally {
    }
  };

  useEffect(()=>{

    handleBulkUpdate_Data();

  },[integrationGridData])

  useEffect(() => {
    if (portfolioData && state) {
      UpdateColumnData({
        columns,
        setColumns,
        portfolioData,
        form,
        state,
        freeColumnName,
        relationShipDropdowns,
        setRelationShipDropdowns,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioData, state, relationShipDropdowns]);

  useEffect(() => {
    if (portfolioSettingState?.data?.length) {
      setPortfolioData(portfolioSettingState?.data);
    }
  }, [portfolioSettingState?.data]);

  // useEffect(() => {
  //   //Portfolio Setting
  //   // fetchPortfolio();

  //   //Fetch Fetch data from copied Scenario
  //   handleBulkUpdate();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  //Fetch Fetch data from copied Scenario
  // const handleBulkUpdate = async () => {
  //   setSpinning(true);

  //   //portfolioData?.services?.bulkGet({bulk_ids:props?.bulkUpdateIds}).then(async (response)=>{

  //   // await ScenarioServices.getIntegrationOfScenario({
  //   //   id: ScenarioId,
  //   //   moduleID,
  //   // })

  //   //getScenarioIntegration_tocopy
  //   await ScenarioServices.getScenarioIntegration_GridData({
  //     scenario_id: ScenarioId,
  //   })
  //     .then(async (response) => {
  //       if (response?.data) {
  //         let responseItems = response?.data;

  //         let statsss = { business_application: [] }; //
  //         if (columnsRelationPortfolio?.length > 0) {
  //           columnsRelationPortfolio?.forEach((element) => {
  //             statsss[element] = [];
  //           });
  //         }

  //         responseItems?.forEach((items) => {
  //           for (const [key, listData] of Object.entries(
  //             items?.relationships
  //           )) {
  //             statsss[key] = [...statsss[key], ...listData];
  //           }
  //         });

  //         setRelationShipDropdowns(statsss);
  //         // for (let [key, items] of Object.entries(statsss)) {
  //         //     items = [...new Map(items.map(item =>[item["id"], item])).values()]
  //         //     // updateLocale({key,items,})
  //         // }

  //         await new Promise((resolve) => setTimeout(resolve, 100));
  //         let items = responseItems?.map((element, dataIndex) => {
  //           let key = dataIndex + Math.random();
  //           return {
  //             key,
  //             id: element?.id,
  //             rowData: element,
  //           };
  //         });

  //         await new Promise((resolve) => setTimeout(resolve, 1000));

  //         let items2 = {};
  //         items.forEach((item) => {
  //           let key = item?.key;
  //           // item.rowData.sourceApp = (item?.rowData.sourceApp?.length > 0) ? item?.rowData.sourceApp[0]:"";
  //           // item.rowData.targetApp = (item?.rowData.targetApp?.length > 0) ? item?.rowData.targetApp[0]:"";
  //           items2[key] = {
  //             ...item?.rowData,
  //           };
  //         });

  //         form.setFieldsValue(items2);
  //         // setSpinning(false);

  //         setDataSource(items);
  //         setOldFormData(responseItems);
  //       }
  //     })
  //     .catch((err) => {
  //       console.log(err);
  //       setSpinning(false);
  //     })
  //     .finally((e) => {
  //       setSpinning(false);
  //     });
  // };


  const handleBulkUpdate_Data = async () => {
    setSpinning(true); 
     
        if (integrationGridData) {
          let responseItems = integrationGridData;

          let statsss = { business_application: [] }; //
          if (columnsRelationPortfolio?.length > 0) {
            columnsRelationPortfolio?.forEach((element) => {
              statsss[element] = [];
            });
          }

          responseItems?.forEach((items) => {
            for (const [key, listData] of Object.entries(
              items?.relationships
            )) {
              statsss[key] = [...statsss[key], ...listData];
            }
          });

          setRelationShipDropdowns(statsss);
          // for (let [key, items] of Object.entries(statsss)) {
          //     items = [...new Map(items.map(item =>[item["id"], item])).values()]
          //     // updateLocale({key,items,})
          // }

          await new Promise((resolve) => setTimeout(resolve, 100));
          let items = responseItems?.map((element, dataIndex) => {
            let key = dataIndex + Math.random();
            return {
              key,
              id: element?.id,
              rowData: element,
            };
          });

          await new Promise((resolve) => setTimeout(resolve, 1000));

          let items2 = {};
          items.forEach((item) => {
            let key = item?.key;
            // item.rowData.sourceApp = (item?.rowData.sourceApp?.length > 0) ? item?.rowData.sourceApp[0]:"";
            // item.rowData.targetApp = (item?.rowData.targetApp?.length > 0) ? item?.rowData.targetApp[0]:"";
            items2[key] = {
              ...item?.rowData,
            };
          });

          form.setFieldsValue(items2);
          // setSpinning(false);

          setDataSource(items);
          setOldFormData(responseItems);
          setSpinning(false);

        }
       
  };
  

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableRows(selectedRows);
      //refAddBtn.current.focus();
    },
  };

  const removeRows = () => {
    let data = dataSource.filter(
      (a) => !selectedTableRows.map((b) => b.key)?.includes(a.key)
    );
    setDataSource([...data]);
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    setIsFiltered(
      Object.values(filters)
        .map((item) => {
          return item;
        })
        .filter((f) => f !== null)?.length === 0
        ? false
        : true
    );
    setFilteredInfo(filters);
    setFilteredData(extra?.currentDataSource);
  };

  const onFilter = (value, record, field) => {
    let value2 = form.getFieldValue(record?.key)[field?.fieldName];
    if (field?.fieldType === "Free text") {
      return value2?.startsWith(value);
    } else if (field?.fieldType === "Single select") {
      return value2?.toString() === value?.toString();
    } else if (field?.fieldType === "Multi select") {
      return value2?.includes(value);
    } else if (field?.fieldType === "Date") {
      return value2?.startsWith(value);
    } else if (field?.fieldType === "Time") {
      return value2?.startsWith(value);
    } else if (field?.fieldType === "Date time") {
      return value2?.startsWith(value);
    } else if (field?.fieldType === "Numeric") {
      return value2?.toString() === value?.toString();
    } else if (field?.fieldType === "Long text") {
      return value2?.startsWith(value);
    } else if (field?.fieldType === "Custom Single select Module") {
      return value2?.toString() === value?.toString();
    } else {
      return value2?.startsWith(value);
    }
  };

  const onFinish = (values) => {
    setSpinning(true);
    try {
      let data = Object.keys(values)?.map((key) => {
        
        let item = values[key];

        item.id = dataSource.find(
          (f) => f.key?.toString() === key?.toString()
        )?.id;

        if (columnsRelationPortfolio?.length) {
          columnsRelationPortfolio.forEach((portfolioCol) => {
            item[portfolioCol] = !Array?.isArray(item[portfolioCol])
              ? item[portfolioCol]
                ? [item[portfolioCol]]
                : []
              : item[portfolioCol];
            item[portfolioCol + "Connection"] = relationShipDropdowns[
              portfolioCol
            ]
              .filter((f) => item[portfolioCol].includes(f?.id))
              ?.map((item) => {
                return {
                  displayname: item?.displayname,
                  id: item?.id,
                };
              });
          });
        }

        if (columnsRelationPortfolio?.length) {
          for (const portfolioCol of columnsRelationPortfolio) {
            item[portfolioCol] = !Array?.isArray(item[portfolioCol])
              ? item[portfolioCol]
                ? [item[portfolioCol]]
                : []
              : item[portfolioCol];
            item[portfolioCol + "Connection"] = relationShipDropdowns[
              portfolioCol
            ]
              .filter((f) => item[portfolioCol].includes(Number(f?.id)))
              ?.map((item) => {
                return {
                  displayname: item?.displayname,
                  id: item?.id,
                };
              });
          }
        }
        item.sourceApp = !Array?.isArray(item["sourceApp"])
          ? item["sourceApp"]
            ? [item["sourceApp"]]
            : []
          : item["sourceApp"];
        item.sourceAppConnection = relationShipDropdowns["business_application"]
          .filter((f) => item["sourceApp"].includes(f?.id))
          ?.map((item) => {
            return {
              displayname: item?.displayname,
              id: item?.id,
            };
          });
        item.targetApp = !Array?.isArray(item["targetApp"])
          ? item["targetApp"]
            ? [item["targetApp"]]
            : []
          : item["targetApp"];
        item.targetAppConnection = relationShipDropdowns["business_application"]
          .filter((f) => item["targetApp"].includes(f?.id))
          ?.map((item) => {
            return {
              displayname: item?.displayname,
              id: item?.id,
            };
          });

        item.relationships = getRelationshipsUpdate(item);
        item.scenario_id = ScenarioId;
        return item;
      });

      //ScenarioServices.createScenarioIntegration_SaveGridData({ data })
      ScenarioServices.createScenarioIntegration_UpdateGridData({ data })
        ?.then(async (response) => {
          if (response?.data) {
            if (response?.data?.length > 0) {
              // let keys = Object.keys(values)?.map((key) => key?.toString());
              // let tempDataSource = [...dataSource];
              // let leftTempData = tempDataSource.filter(
              //   (f) => !keys?.includes(f?.key?.toString())
              // );
              // setFilteredInfo({});
              // setIsFiltered(false);
              // //setDataSource(leftTempData);
              // setDataSource(null);
              // setSelectedTableRows(null);

              await new Promise((resolve) => setTimeout(resolve, 1000));
              message.success("Record Saved Successfully");
               
            }
          } else {
            message.error("Failed to save data.");
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error?.response?.data?.error) {
            message.error(error?.response?.data?.error);
          }
          setSpinning(false);
        });
    } catch (error) {
    } finally {
      setSpinning(false);
    }
  };

  const getRelationshipsUpdate = (rowData) => {
    let newFormValues = rowData;
    let oldFormValues = oldFormData.find(
      (f) => f.id?.toString() === rowData?.id?.toString()
    );
    let oldValues;
    let newValues;

    let relationships = {};
    // Source App RelationShips:---------------------------------------------
    oldValues = oldFormValues["sourceApp"];
    newValues = newFormValues["sourceApp"];
    relationships["sourceApp"] = {
      moduleName: "BusinessApplication",
      oldValues: oldValues,
      newValues: newValues,
      deleted: oldValues?.filter((n) => !newValues?.includes(n)) || [],
      added: newValues?.filter((n) => !oldValues?.includes(n)) || [],
    };

    // Target App RelationShips:---------------------------------------------
    oldValues = oldFormValues["targetApp"];
    newValues = newFormValues["targetApp"];
    relationships["targetApp"] = {
      moduleName: "BusinessApplication",
      oldValues: oldValues,
      newValues: newValues,
      deleted: oldValues?.filter((n) => !newValues?.includes(n)) || [],
      added: newValues?.filter((n) => !oldValues?.includes(n)) || [],
    };
 
    if (columnsRelationPortfolio?.length > 0) {
      columnsRelationPortfolio?.forEach((moduleName) => {
        let portfolioMenu = getMenuPortfolio({ moduleName });
        oldValues = oldFormValues[moduleName];
        newValues = newFormValues[moduleName];
        if (!Array?.isArray(newValues)) {
          oldValues = oldFormValues[moduleName]
            ? [oldFormValues[moduleName]]
            : [];
          newValues = !Array?.isArray(newFormValues[moduleName])
            ? [newFormValues[moduleName]]
            : newFormValues[moduleName];
        }
        relationships[moduleName] = {
          moduleName: portfolioMenu?.routeModuleName,
          oldValues: oldValues,
          newValues: newValues,
          deleted: oldValues?.filter((n) => !newValues?.includes(n)) || [],
          added: newValues?.filter((n) => !oldValues?.includes(n)) || [],
        };
      });
    }
    return relationships;
  };

  const getStateData = async () => {
    let result = {};
    // Tags
    let PortfolioTagRes = await PortfolioTagServices?.getAllTags({
      moduleName: portfolioDatanew?.moduleName,
      addAssociation: false,
    });
    if (PortfolioTagRes?.data?.data?.length > 0) {
      result.tags = {
        [portfolioDatanew?.moduleName]: PortfolioTagRes?.data?.data,
      };
    }
    await new Promise((resolve) => setTimeout(resolve, 200));
    // Global Properties
    let TemplateRes = await TemplateService?.getTemplateList();
    if (TemplateRes?.data?.data?.length > 0) {
      result.globalProperties = TemplateRes?.data?.data;
    }
    setState({ ...state, ...result });
  };
  const deleteScenarioIntegrationItem = async (items) => {
    const response = await ScenarioServices.ScenarioIntegrationItemDeletion(
      items
    );
    if (response.status == "200") {
       //handleBulkUpdate();
      handleBulkUpdate_new();
    }
  };

  const deleteScenarioItem = async () => {
    if (selectedTableRows && selectedTableRows.length) {
      const dataArray = {};

      Modal.confirm({
        title: "",
        icon: <ExclamationCircleFilled />,
        content: "Do you want to delete selected data?",
        onOk() {
          deleteScenarioIntegrationItem(selectedTableRows);
        },
        onCancel() {},
      });
    } else {
      console.log("Select atleast one row to update data ");
    }
  };

  return (
    <Spin spinning={spinning}>
      <Form
        form={form}
        className="intigration-bulk-entry-form"
        onFinish={(values) => {
          // if (!isFiltered) {
          //   onFinish(values);
          // } else {
       
          if (selectedTableRows && selectedTableRows.length) {
            const dataArray = {};
            selectedTableRows.map((item) => {
              dataArray[item.key.toString()] = values[item.key.toString()];
            });

            // Modal.confirm({
            //   title: "",
            //   icon: <ExclamationCircleFilled />,
            //   content: "Do you want to save selected data?",
            //   onOk() {
            //    // onFinish(values);
            //    onFinish(dataArray);
            //   },
            //   onCancel() {},
            // });


            onFinish(dataArray);
            
          } else {
            console.log("Select atleast one row to update data ");
          }
          //}
        }}
        initialValues={{}}
        ref={tableRef}
      >
        <Table
          rowClassName={() => "editable-row"}
          className="mb-2 bulk-entry-table"
          bordered
          dataSource={dataSource}
          loading={spinning}
          hasData={true}
          columns={columns?.map((item) => ({
            ...item,
            title: (
              <TextWithTooltip
                text={item?.title}
                characterLimit="auto"
                isTooltip
              />
            ),
            //// filters:dataSource.length>0 ? getColumnFilters({field:item,form,dataSource,tableFilterRowData:state}):[],
            filterSearch: true,
            filteredValue: filteredInfo[item?.dataIndex] || null,
            onFilter: (value, record) => onFilter(value, record, item),
            onFilterDropdownOpenChange: (visible) => {
              if (visible) {
                let cols = [...columns];
                cols = cols?.map((item) => {
                  item.filters = GetColumnFilters({
                    field: item,
                    form,
                    state: relationShipDropdowns,
                  });
                  return item;
                });
                setColumns(cols);
              }
            },
          }))}
          size="small"
          pagination={false}
          scroll={{
            x: window?.innerWidth - 300,
            ...(dataSource?.length > 10
              ? { y: window?.innerHeight - 200 }
              : {}),
          }}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          onChange={onTableChange}
          footer={() => {
            return (
              <Flex gap={8} justify="space-between" align="center">
                <Flex gap={8}>
                  <ClearAllFilterBtn setFilteredInfo={setFilteredInfo} />
                </Flex>
                <Flex gap={8}>
                  <SaveBtn />
                  {checkRole('delete') && <Button type="primary" onClick={deleteScenarioItem}>
                    Delete
                  </Button>}
                </Flex>
              </Flex>
            );
          }}
        />
      </Form>
    </Spin>
  );
};

ScenarioIntegrationGrid.defaultProps = {};
export default ScenarioIntegrationGrid;
