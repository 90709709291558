import React, { useState, useEffect } from 'react';
import { Col, Row,Divider, Card,Table, Checkbox  } from 'antd'; 
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";
import ChangeLogList from  "./ChangeLogsList";

import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider"; 
import { applicationsList } from "../../../../utils/businessApplication";
import moment from 'moment';
import TextWithTooltip from './../../../../utils/TextWithTooltip';
import { checkRole } from '../../../../helper/useUserData';


const TabChangeLogAlerts = ({ setActiveTabKey, toggleTabDisabled, id: modelId, moduleName:moduleName }) => {
    //const [data1,setData1]=useState();
    const { state, dispatch } = useApplicationChangeLogs();
    const [businessApplicationId, setbusinessApplicationId] = useState(null);
    const [loading, setLoading] = useState(false);
    const [data, setData] = useState();
    const fetchData = async ({ moduleName, modelId }) => {
        try {
            setLoading(true);
            const response = await ChangeLogsServices.getChangeLogsList({
                moduleName,
                modelId,
            });

            const newData = response.data.data;
            await dispatch({ type: "SET_LINK", payload: newData });
        } catch (error) {
            setLoading(false);
            console.error("Error fetching data:", error);
        }
    }; 

    useEffect(() => {
        setbusinessApplicationId(state.businessApplicationId);
        fetchData({
            moduleName: moduleName,
            modelId: modelId,
        });

      }, [state.businessApplicationId]);
    
      useEffect(() => {
        setData(state.data);
      }, [state.data]);

    const columns = [
        {
            title: 'Change Description',
            dataIndex: 'DataField',
            key: 'DataField', 
            render:(text)=><TextWithTooltip text={text} characterLimit={35}/>
        },
        {
            title: 'Old Value',
            dataIndex: 'OldValue',
            key: 'OldValue',
            render:(text)=><TextWithTooltip text={text} characterLimit={35}/>
        },
        {
            title: 'New Value',
            dataIndex: 'NewValue',
            key: 'NewValue',
            render:(text)=><TextWithTooltip text={text} characterLimit={35}/>
        },
        {
            title: 'User',
            key: 'user_name',
            dataIndex: 'user_name', 
            width:'175px',
            render: (_, { user_name }) => (
                user_name?.name 
            ),
        },
        {
            title: 'Date Time',
            key: 'createdAt',
            dataIndex: 'createdAt',
            width:'175px', 
            render: (_, { createdAt }) => (
                new Date( createdAt).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric',
                    hour:'2-digit',
                    minute: '2-digit',
                    second: '2-digit',
                })
            ),
        },
      ];

    return <> 
        <Row className="justify-content-start">
            <Col span={24}>
                {/* <h5>Change log/Alerts</h5> */}
                 <div className="txtdes">
                 <p>
                    <span>(this screen shows changes done to current object and also shows users who have subscribed to be notified if current object gets changed )</span>
                    &emsp;
                    {checkRole('checkbox') && <Checkbox><span className='fw-medium'>Add me as Subscriber</span></Checkbox>}
                 </p>
                 </div>
                <Divider/>
                <Row className="justify-content-start">
                    <Col span={24}>
                      <Table columns={columns} dataSource={data} rowKey={'id'}/>  
                    </Col>
                    <Col span={8}>
                    
                    </Col>
                </Row>  
            </Col>
        </Row>
    </>;
}

export default TabChangeLogAlerts;