import {
  AppstoreAddOutlined,
  CloseSquareFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import {
  Badge,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Form,
  Input,
  Layout,
  Modal,
  Pagination,
  Select,
  Spin,
  Tooltip,
  Typography,
  message,
  Table,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  PageTitleHeading,
  debounceTimeOut,
  default_pageSize,
  getSelectProperties,
  removeEmptyArrObjOrval,
} from "../../../utils/Common";
import FormFields from "../../../utils/FormFields";
// import { staticSettingPortfolioList } from "../../../utils/settingCommon";
// import PortfolioRelationshipServices from "../../../services/services/PortfolioRelationshipService";
// import { normalizeString } from "../../../utils/linksCommon";
// import FilterRightSideBar from "../../applicationlist/filter/FilterRightSideBar";
// import useDebounce from "../../../helper/useDebounce";
// import { dynamicRoutesLinks } from "../../../utils/portfolioRelationshipCommon";
import useDynamicNavigate from "../../../utils/navigateUtils";
// import Delayed from "../../../utils/Delayed";
// import { PortfolioItemDetails } from "../../portfolio/common/PortfolioCommon";
import { useLocation } from "react-router";
// import TextWithTooltip from "../../../utils/TextWithTooltip";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
// import { getMenuPortfolio } from "../../../utils/settingCommon";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
// import IntegrationServices from "../../../services/services/IntegrationServices";
// import ScenarioItemsServices from "../../../services/services/ScenarioItemsService";
// import {
//   addIsTextToProperties,
//   deepFindByName,
//   objectToQueryParams,
//   transformText,
// } from "../../../utils/Common";
import ScenarioServices from "../../../services/services/ScenarioServices";

const { Option } = Select;

const styleBar = {
  overflow: "auto",
  minHeight: window.innerHeight / 2,
};

const rotate90 = {
  marginTop: "50px",
  marginLeft: "-10px",
};

const date = new Date();
var deadlines = [];
for (let index = date.getFullYear(); index <= date.getFullYear() + 2; index++) {
  for (let ind = 1; ind <= 4; ind++) {
    deadlines.push({
      value: "Q" + ind + "-" + index,
      label: "Q" + ind + "-" + index,
    });
  }
}

const changeTypeDummyValues = [
  { id: 1, label: "Retire", value: 1 },
  { id: 2, label: "Replace", value: 2 },
  { id: 3, label: "Upgrade", value: 3 },
  { id: 4, label: "Retain", value: 4 },
];

const ScenarioListSelection = ({
  heading,
  setOpenPortfolioList,
  selectedModuleItems,
  selectedPortfolioItems,
  setSelectedPortfolioItems,
  // portfolioloading,
  // setPortfolioLoading,
  loading: portfolioloading,
  setLoading: setPortfolioLoading,
  setPage,
  page,
  limit,
  setLimit,
  scenarioId,
  setOpenChoosePortfolioType,
  portfolioTypeForm,
  onFormSubmit,
  selectedItemId,
  setSelectedItemId,
  changeTypeModal,
  setChangeTypeModal,
  changeTypeForm,
  changeTypeLoading,
  setChangeTypeLoading,
  portfolioItemIds,
  showConfirmDeleteScenarioItem,
  settingPortfolioList,
  setRelationshipPortfolioId,
  getItems,
  showFilter,
  setShowFilter,
  filterData,
  setFilterData,
  selectedFilters,
  setSelectedFilters,
  totalRecords,
  setTotalRecords,
  changeTypeData,
  setOpenPortfolioList_changeType,
  setselectedItemIDRows,
  filterSubmit,
  setFilterSubmit,
  selectedRelationFilters,
  setSelectedRelationFilters,
  moduleName,
  setScenarioModal,
  getAllScenariosItems,
  alreadySelectedRows,
  setAlreadySelectedRows,
  setCopyScenarioID,
  setActiveIntegrationPanel,
  setScenarioIntegrationImportModal,
   
  ...props
}) => {
  const location = useLocation();
  const [openReplaceWithForm] = Form.useForm();
  const [isOpenReplaceWithModel, openReplaceWithModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  const [changeTypeFormData, setChangeTypeFormData] = useState({});
  const [addRelationhipModal, setRelationhipModal] = useState(false);
  const [selectedPortfolio, setSelectedPortfolio] = useState([]);
  const [relationshipItems, setRelationshipItems] = useState({});
  const [relationsList, setRelationsList] = useState([]);
  const navigateTo = useDynamicNavigate();
  const [changeTypeName, setChangeTypeName] = useState("");

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [changeTypeForm1] = Form.useForm();
  const [columns, setColumns] = useState(null);
  const [portfolioData, setPortfolioData] = useState(null);

  const { fetchPortfolio } = usePortfolioFetcher();
  const { state: PortfolioSettingState, dispatch: PortfolioSettingDispatch } =
    usePortfolioSetting();
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const [selectedTableRows, setSelectedTableRows] = useState([]);

  //const [scenarios,setScenarios] = useState([]);

  const getAllScenarios = async () => {
    setLoading(true);
    let obj = {
      intiativeId: "",
      visibility: "",
      scenarioTypeID: "",
      search: "",
      page: 1,
      limit: 20000,
      isScenarioType: true,
      isUser: true,
    };

    try {
      let response = await ScenarioServices?.getAllScenariosV2(obj);
      if (response?.data?.data) {
        //setList(response?.data?.data);
        //setTotalRecords(response?.data?.totalCount);

        const dataWithKey = response?.data?.data.map((item) => ({
          ...item,
          key: item.id,
        }));
        setList(dataWithKey);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
  };

  const fetchAllPortfolio = async () => {
    try {
      const response = await PortfolioSettingServices.getList({
        page: 1,
        limit: 200,
      });

      setPortfolioData(response?.data?.data);

      let tempColumns = [];

      const portfolioValues = response?.data?.data.filter(
        (item) => item.moduleName == "integration"
      );

      // Portfolio Items
      portfolioValues?.forEach((row) => {
        row?.portfolioSections
          .sort((a, b) => a.position - b.position)
          ?.forEach((col) => {
            col?.properties
              .sort((a, b) => a.position - b.position)
              ?.forEach((col1) => {
                let colData = {
                  title: col1?.displayName,
                  //dataIndex: Math.random(),
                  dataIndex: col1?.name,
                  fieldName: col1?.name,
                  data: col1,
                  width: 150,
                  //   render: (text, record, index) => {
                  //      return <FormField field={col1} index={record?.key}/>
                  //   },
                };
                tempColumns.push(colData);
              });
          });
      });

      setColumns(tempColumns);
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    let tempColumns = [];

    let colData = {
      title: "id",
      dataIndex: "id",
      width: 150,
    };
    //tempColumns.push(colData);

    colData = {
      title: "Name",
      dataIndex: "name",
      width: 150,
    };
    tempColumns.push(colData);

    colData = {
      title: "Description",
      dataIndex: "description",
      width: 150,
    };

    tempColumns.push(colData);

    setColumns(tempColumns);

    setselectedItemIDRows([]);
    getAllScenarios();
  }, []);

  const SaveCopyIntegrationFormScenario = async (data) => {
    const response = await ScenarioServices.copyIntegrationFormScenario(data);
    if (response?.data?.code === 200) {
      message.success(response?.data?.msg);
      getAllScenariosItems();
      setScenarioModal(false);
    } else {
      message.error(response?.data?.msg);
    }
  };

  return (
    <div>
      <Layout>
        <Layout.Content className="py-4">
          {/* <div className="app-list"> */}
            <div className="popup_headerbx">
              <div className="inputsec_bx">
                <Typography.Title level={4}  className="m-0" >
                  {"Scenario List"}
                </Typography.Title>

                {/* <Checkbox checked={selectAll} onChange={handleSelectAll} />
                    <span>Select All </span>
                    {(selectedRows.length && (
                      <h5>{"   (" + selectedRows.length + " selected )"} </h5>
                    )) ||
                      ""} */}
              </div>

              <div className="bulk-operation">
                <div className="formctrl">
                  <Form
                    form={changeTypeForm1}
                    // labelCol={{ span: 12 }}
                    // wrapperCol={{ span: 16 }}
                    initialValues={{}}
                    className=" "
                    onFinish={async (formData) => {
                      if (selectedTableRows.length) {
                        const selectedRows = [];
                        selectedTableRows.forEach((itm) => {
                          selectedRows.push(itm.id);
                        });

                        if (scenarioId !== selectedRows[0]) {
                          formData.NewScenarioId = scenarioId;
                          formData.portfolioID = "9";
                          formData.existingScenarioId = selectedRows[0];

                          setCopyScenarioID(selectedRows[0]);
                          //setActiveIntegrationPanel(2);
                          
                          //SaveCopyIntegrationFormScenario(formData);
                          setScenarioModal(false);
                          setScenarioIntegrationImportModal(true);
                         // add("asd");
                        } else {
                          message.error(
                            "Select another Scenario to copy the Integration Items"
                          );
                        }
                        //formData.setselectedItemIDRows=setselectedItemIDRows;
                        //onFormSubmit(formData);
                      } else {
                        message.error("Select atleast one record ");
                      }
                    }}
                  >
                    <div className="inputbx">
                      <Button
                        type={`primary`}
                        htmlType="submit"
                        loading={loading}
                      >
                        Add
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          {/* </div> */}

          <Spin spinning={portfolioloading || loading}>
            <div
              // className="overflow-y"
              // style={{ height: window.innerHeight / 1.5 }}
              // onScroll={handleScroll}
            >
              <TableFormData
                columns={columns}
                list={list}
                setSelectedTableRows={setSelectedTableRows}
                selectedTableRows={selectedTableRows}
                setselectedItemIDRows={setselectedItemIDRows}
                setAlreadySelectedRows={setAlreadySelectedRows}
              />
            </div>
          </Spin>
        </Layout.Content>
        {/* {(settingPortfolioList?.length > 0 && ( */}
        {/* <Layout.Sider
          theme={`light`}
          width={showFilter ? `250px` : `60px`}
          className="border-left p-3 pt-0"
          style={styleBar}
        >
          <div className=" mt-2">
             <Filter portfolioData={settingPortfolioList} />  
             <FilterRightSideBar
              activeGridStyle={1}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              portfolioData={filterData}
              selectedFilters={selectedFilters}
              setSelectedFilters={(value) => {
                setPage(1);
                setSelectedFilters(value);
              }}
              optionalLevel={
                selectedModuleItems?.name ===
                staticSettingPortfolioList.businessCapability
              }
              allowSlider={true}
              //Filter relations
              filterSubmit={filterSubmit}
              setFilterSubmit={setFilterSubmit}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
            />  
          </div>
        </Layout.Sider> */}
        {/* )) || <></>} */}
      </Layout>
    </div>
  );
};

ScenarioListSelection.propTypes = {};

export default ScenarioListSelection;

const TableFormData = ({
  columns,
  list,
  setSelectedTableRows,
  selectedTableRows,
  setselectedItemIDRows,
  setAlreadySelectedRows,
  alreadySelectedRows,
}) => {
  const [spinning, setSpinning] = useState(false);
  const [refAddBtn, setRefAddBtn] = useState(false);
  const [selectionType, setSelectionType] = useState("radio");
  // const [selectionType, setSelectionType] = useState('radio');

  const rowSelection = {
    selectedRowKeys: alreadySelectedRows,
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedTableRows(selectedRows);
      setAlreadySelectedRows(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      // Column configuration not to be checked
      name: record.name,
    }),
  };

  useEffect(() => {
    setSpinning(true);
    setTimeout(() => {
      setSpinning(false);
    }, 2000);
  }, []);

  return (
    //<Spin spinning={spinning}>
      <Table
        rowClassName={() => "editable-row"}
        className="mb-2 bulk-entry-table"
        bordered
        dataSource={list}
        columns={columns}
        size="small"
        scroll={{ y: window.innerHeight/2, x: window?.innerWidth - 300 }}
        rowSelection={{
          type: selectionType,
          ...rowSelection,
        }}
      />
    //</Spin>
  );
};
