 
  import { 
    Form, 
    Select, 
    Row,
    Col,
  } from "antd";
  import React, {  useState } from "react";
  import useDynamicNavigate from "../../../utils/navigateUtils";
  import { useLocation } from "react-router";
  import IntegrationGrid from "./integrationgrid";
 
  const { Option } = Select;
  
  const styleBar = {
    overflow: "auto",
    minHeight: window.innerHeight / 2,
  };
  
  const rotate90 = {
    marginTop: "50px",
    marginLeft: "-10px",
  };
  
  const date = new Date();
  var deadlines = [];
  for (let index = date.getFullYear(); index <= date.getFullYear() + 2; index++) {
    for (let ind = 1; ind <= 4; ind++) {
      deadlines.push({
        value: "Q" + ind + "-" + index,
        label: "Q" + ind + "-" + index,
      });
    }
  }
  
  const changeTypeDummyValues = [
    { id: 1, label: "Retire", value: 1 },
    { id: 2, label: "Replace", value: 2 },
    { id: 3, label: "Upgrade", value: 3 },
    { id: 4, label: "Retain", value: 4 },
  ];
  
  const IntegrationGridImport = ({ 
    // heading,
    // setOpenPortfolioList,
      selectedModuleItems,
    // selectedPortfolioItems,
    // setSelectedPortfolioItems,
     
    // loading: portfolioloading,
    // setLoading: setPortfolioLoading,
    setPage,
    page,
    limit,
    setLimit,
    // scenarioId,
    // setOpenChoosePortfolioType,
    // portfolioTypeForm,
    // onFormSubmit,
    // selectedItemId,
    // setSelectedItemId,
    // changeTypeModal,
    // setChangeTypeModal,
    // changeTypeForm,
    // changeTypeLoading,
    // setChangeTypeLoading,
    // portfolioItemIds,
    // showConfirmDeleteScenarioItem,
    // settingPortfolioList,
    // setRelationshipPortfolioId,
    // getItems,
     showFilter,
     setShowFilter,
     filterData,
     setFilterData,
     selectedFilters,
     setSelectedFilters,
    // totalRecords,
    // setTotalRecords,
    // changeTypeData,
    // setOpenPortfolioList_changeType,
    // setselectedItemIDRows,
      filterSubmit,
    setFilterSubmit,
    selectedRelationFilters,
    setSelectedRelationFilters,
    ScenarioId,
    moduleID,
    copyScenarioID,
    freeColumnName,
    handleBulkUpdate_new,
    setScenarioIntegrationImportModal,
     ...props
  }) => {
    const location = useLocation();
    
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    const [changeTypeFormData, setChangeTypeFormData] = useState({});
    
    const [selectedPortfolio, setSelectedPortfolio] = useState([]);
    
    const navigateTo = useDynamicNavigate();
    const [changeTypeName, setChangeTypeName] = useState("");
  
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectAll, setSelectAll] = useState(false);
    const [changeTypeForm1] = Form.useForm();
    
    
    
    return (
      <div>
        <div className="d-flex justify-content-between modelpopup_bx py-4">
          <div id="portfolioList" className="container-fluid popup_leftpnlbx border-0">
            {/* <Row gutter={[8, 8]} className="mx-0 text-left mt-2"> */}
             
                <div className="popup_headerbx">
                <h3>Select Integration to import from other senario </h3>
                </div>
              
  
              {/* <Spin spinning={portfolioloading || loading}> */}
                {/* <div
                  className="overflow-y"
                  style={{ height: window.innerHeight / 1.5 }}
                  onScroll={handleScroll}
                > */}
                <Col flex={`auto`}>
                 <IntegrationGrid
                      ScenarioId={ScenarioId}
                      moduleID={moduleID}
                      copyScenarioID={copyScenarioID}
                      freeColumnName={freeColumnName}
                      handleBulkUpdate_new={handleBulkUpdate_new}
                      setScenarioIntegrationImportModal={setScenarioIntegrationImportModal}
                    />
                </Col>
                {/* </div> */}
                {/* {(list?.length && (
                  <Pagination
                    className="mt-2 text-right"
                    total={totalRecords}
                    showTotal={(total) => `Total ${total} items`}
                    defaultPageSize={default_pageSize}
                    defaultCurrent={page}
                    showSizeChanger={false}
                    // pageSizeOptions={[10, 20, 50, 100, 200, 500, 1000, "Show All"]}
                    onChange={(p, ps) => {
                      setFilterSubmit(true);
                      setLimit(ps);
                      setPage(p);
                    }}
                  />
                )) || <div></div>} */}
              {/* </Spin> */}
            {/* </Row> */}
          </div>
          {/* {(settingPortfolioList?.length > 0 && ( */}
  
          {/* <div className="modelpopup_filterbx"> */}
            {/* <Filter portfolioData={settingPortfolioList} /> */}
            {/* <FilterRightSideBar
              activeGridStyle={1}
              showFilter={showFilter}
              setShowFilter={setShowFilter}
              portfolioData={filterData}
              selectedFilters={selectedFilters}
              setSelectedFilters={(value) => {
                setPage(1);
                setSelectedFilters(value);
              }}
              optionalLevel={
                selectedModuleItems?.name ===
                staticSettingPortfolioList.businessCapability
              }
              allowSlider={true}
              //Filter relations
              filterSubmit={filterSubmit}
              setFilterSubmit={(value) => {
                setPage(1);
                setFilterSubmit(value);
              }}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
            /> */}
          {/* </div> */}
  
          {/* )) || <></>} */}
        </div>
  
        
      </div>
    );
  };
  
  IntegrationGridImport.propTypes = {};
  
  export default IntegrationGridImport;
  