import { Column } from "@ant-design/plots";
import React, { useEffect, useState } from "react";
import { Margin } from "react-to-pdf";

const DCBarCharts = ({ portfolioData, data, ...props }) => {
  const config = {
    data,
    xField: "type",
    yField: "value",
    // paddingLeft: 0,
    // paddingRight: 0,
    // paddingBottom: 0,
    // marginLeft: 0,
    // marginBottom: 20,
    style: {
      fill: portfolioData?.color,
      width: 50,
    },
    scale: {
      x: { padding: 0.1 },
    },
    label: {
      // Display the value inside the bars
      position: "bottom", // Can also be 'top', 'bottom', etc.
      style: {
        fill: "#000", // Label text color
        fontSize: 14,
        fontWeight: "bold",
      },
      content: (originData) => {
        return originData.value; // Display the value
      },
    },
    axis: {
      x: {
        tick: false,
        title: false,
        size: 50,
        labelFormatter: (datum, index) => {
          return datum.split(" ").join("\n");
        },
      },
      y: {
        grid: false,
        tick: false,
        label: false,
        title: false,
      },
    },
    legend: false,
    width: 100 * data?.length,
    height: 200,
  };

  return (
    <React.Fragment>
      <div>
        <Column {...config} />
      </div>
    </React.Fragment>
  );
};

export default React.memo(DCBarCharts);
