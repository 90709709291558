import { Flex, Form, Modal, Spin, Table, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { useSaveState } from "../../../../Context/StateProviderData";
import { ExclamationCircleFilled } from "@ant-design/icons";
import TextWithTooltip from "../../../../utils/TextWithTooltip";
import { AddRowBtn, ClearAllFilterBtn, DeleteRowBtn, SaveBtn } from "./BulkEntryBtns";
import UpdateColumnData, { columnsRelationPortfolio } from "./UpdateColumnData";
import GetColumnFilters from "./GetColumnFilters";
import UpdateGlobalState from "../../common/UpdateGlobalState";
import { getMenuPortfolio } from "../../../../utils/settingCommon";
import { forEach } from "jszip";

const TableFormData = ({portfolioData,...props}) =>{
    const tableRef = useRef(null);
    const [form] = Form.useForm();
    const { state } = useSaveState();
    const {updateLocale} = UpdateGlobalState();

    const [columns, setColumns] = useState(null);
    const [selectedTableRows,setSelectedTableRows] = useState([]);
    const [dataSource, setDataSource] = useState([]);
    const [spinning, setSpinning] = useState(false);
    const [refAddBtn, setRefAddBtn] = useState(false);
    const [addClickCount, setAddClickCount] = useState(0);
    const [filteredData, setFilteredData] = useState(null);
    const [isFiltered, setIsFiltered] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [oldFormData, setOldFormData] = useState([]);

    useEffect(()=>{
        if(portfolioData && state){
            UpdateColumnData({
                columns,
                setColumns,
                portfolioData,
                form,
                state,
            })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[ portfolioData, state]);

    useEffect(() => {
        if(props?.bulkUpdateIds?.length>0){
            handleBulkAdd();
        }else{
            handleAdd();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleBulkAdd = async () =>{
        setSpinning(true);
        portfolioData?.services?.bulkGet({bulk_ids:props?.bulkUpdateIds}).then(async (response)=>{
            if(response?.data){
                let responseItems = response?.data;

                let statsss={};
                // Relationship Portfolio
                if(portfolioData?.portfolioConnected?.length > 0){
                    portfolioData?.portfolioConnected?.forEach(portfolio => {
                        statsss[portfolio?.moduleName]=[];
                    });
                }
                
                responseItems?.forEach(async (items)=>{
                    Object.values(items?.relationships)?.forEach(async (element) => {
                        let m = portfolioData?.portfolioConnected?.find(f=>f.routeModuleName===element?.moduleName);
                        if(!statsss[m?.moduleName]){
                            statsss[m?.moduleName] = [];
                        }
                        statsss[m?.moduleName]=[...statsss[m?.moduleName],...element?.tags];
                    });
                });
                

                for (let [key, items] of Object.entries(statsss)) {
                    let arrayData = [];
                    if(items){
                        arrayData = [...items];
                    }
                    if(state[key]){
                        arrayData = [...arrayData,...state[key]];
                    }

                    items = [...new Map(arrayData.map(item =>[item["id"], item])).values()]
                    updateLocale({key,items})
                }
                
                await new Promise(resolve => setTimeout(resolve, 100));
                let items = responseItems?.map((element,dataIndex)=>{
                    let key = dataIndex+Math.random();
                    return {
                        key,
                        id:element?.id,
                        rowData:element,
                    };
                })
                
                // await new Promise(resolve => setTimeout(resolve, 1000));

                let items2 = {};
                items.forEach(item=>{
                    let key=item?.key; 

                    let relationships = {};
                    if(portfolioData?.portfolioConnected?.length > 0){
                        portfolioData?.portfolioConnected?.forEach(portfolio => {
                            relationships[portfolio?.moduleName]=[];
                        });
                    }
                    Object.values(item?.rowData?.relationships)?.forEach(async (element) => {
                        let m = portfolioData?.portfolioConnected?.find(f=>f.routeModuleName===element?.moduleName);
                        if(m){
                            relationships[m?.moduleName] = (element?.tags?.length > 0) ? element?.tags?.map(f=>(f?.id ? Number(f?.id):0)) : [];
                        }
                    });

                    items2[key]={
                        ...item?.rowData,
                        ...relationships,
                    }
                });

                let oldFormData2 = responseItems?.map(item=>{
                    let relationships = {};
                    if(portfolioData?.portfolioConnected?.length > 0){
                        portfolioData?.portfolioConnected?.forEach(portfolio => {
                            relationships[portfolio?.moduleName]=[];
                        });
                    }
                    Object.values(item?.relationships)?.forEach(async (element) => {
                        let m = portfolioData?.portfolioConnected?.find(f=>f.routeModuleName===element?.moduleName);
                        if(m){
                            relationships[m?.moduleName] = (element?.tags?.length > 0) ? element?.tags?.map(f=>(f?.id ? Number(f?.id):0)) : [];
                        }
                    });
                    return {...item,...relationships};
                })

                form.setFieldsValue(items2)
                setDataSource(items);
                setOldFormData(oldFormData2);
            }
        }).catch(err=>{
            console.log(err);
            setSpinning(false);
        }).finally((e)=>{
            setSpinning(false);
        })
    }

    const handleAdd =async (rows=1) => {
        setSpinning(true);
        let tempCount = addClickCount;
        const newData = {
            key:tempCount,
        };

        columns?.forEach(item=>{
            newData[item?.fieldName]="";
        })

        let items = Array.from({length: rows}, (v, i) => {
            let c= tempCount;
            tempCount+=1;
            return {
                ...newData,
                // eslint-disable-next-line no-useless-computed-key
                ["key"]:c+Math.random(),
            }
        })
        await new Promise(resolve => setTimeout(resolve, 500));
        setDataSource([...dataSource, ...items]);
        setAddClickCount(tempCount)
        setSpinning(false);
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSpinning(true);
            setSelectedTableRows(selectedRows);
            refAddBtn?.current?.focus();
            setSpinning(false);
        },
    };

    const removeRows = () =>{
        let data = dataSource.filter(a => !selectedTableRows.map(b=>b.key)?.includes(a.key))
        setDataSource([...data]);
    }

    const onTableChange = (pagination, filters, sorter, extra) => {
        setIsFiltered(Object.values(filters).map(item=>{ return item; }).filter(f=>f!==null)?.length===0?false:true)
        setFilteredInfo(filters);
        setFilteredData(extra?.currentDataSource)
    };

    const onFilter = (value,record,field) =>{
        let value2 = form.getFieldValue(record?.key)[field?.fieldName];
        if(field?.fieldType==="Free text"){    
            return value2?.startsWith(value);
        }else if(field?.fieldType==="Single select"){
            return value2?.toString()===value?.toString();
        }else if(field?.fieldType==="Multi select"){
            return value2?.includes(value);
        }else if(field?.fieldType==="Date"){
            return value2?.startsWith(value);
        }else if(field?.fieldType==="Time"){
            return value2?.startsWith(value);
        }else if(field?.fieldType==="Date time"){
            return value2?.startsWith(value);
        }else if(field?.fieldType==="Numeric"){
            return value2?.toString()===value?.toString();
        }else if(field?.fieldType==="Long text"){
            return value2?.startsWith(value);
        }else if(field?.fieldType==="Custom Single select Module"){
            return value2?.toString()===value?.toString();
        }else{
            return value2?.startsWith(value);
        }
    }

    const onFinish = (values) => {
        setSpinning(true);
        try {
            
            let data = Object.keys(values)?.map(key=>{
                let item = values[key];
                if(props?.bulkUpdateIds?.length>0){
                    item.id = dataSource.find(f=>f.key?.toString()===key?.toString())?.id;
                }

                if(portfolioData?.portfolioConnected?.length){
                    portfolioData?.portfolioConnected?.filter(f=>f?.moduleName!=="business_application")?.forEach(portfolio => {
                        item[portfolio?.moduleName]=(!Array?.isArray(item[portfolio?.moduleName]))?(item[portfolio?.moduleName]?[item[portfolio?.moduleName]]:[]):item[portfolio?.moduleName];
                        // item[portfolio?.moduleName+'Connection'] = state[portfolio?.moduleName].filter(f=>item[portfolio?.moduleName].includes(Number(f?.id)))?.map(item=>{
                        //     return {
                        //         displayname:item?.displayname,
                        //         id:item?.id,
                        //     }
                        // });
                    })
                }
                item.sourceApp=(!Array?.isArray(item['sourceApp']))?(item['sourceApp']?[item['sourceApp']]:[]):item['sourceApp'];
                item.sourceAppConnection = state['business_application'].filter(f=>item['sourceApp'].includes(f?.id))?.map(item=>{
                    return {
                        displayname:item?.displayname,
                        id:item?.id,
                    }
                });
                item.targetApp=(!Array?.isArray(item['targetApp']))?(item['targetApp']?[item['targetApp']]:[]):item['targetApp'];
                item.targetAppConnection = state['business_application'].filter(f=>item['targetApp'].includes(f?.id))?.map(item=>{
                    return {
                        displayname:item?.displayname,
                        id:item?.id,
                    }
                });

                if(props?.bulkUpdateIds?.length>0){
                    item.relationships = getRelationshipsUpdate(item)
                }else{
                    item.relationships = getRelationships(item)
                }
                
                return item;
            })

            if(data?.length===0){
                return message.error("Nothing to save.")
            }

            data = data?.map(item=>{
                if(portfolioData?.portfolioConnected?.length > 0){
                    portfolioData?.portfolioConnected?.forEach(element => {
                        delete item[element?.moduleName];
                    });
                }
                return item;
            });

            if(props?.bulkUpdateIds?.length>0){
                portfolioData?.services?.bulkUpdate({data})?.then(async (response)=>{
                    if(response?.data){
                        if(response?.data?.length>0){
                            // let keys = Object.keys(values)?.map(key=>(key?.toString()));
                            // let tempDataSource = [...dataSource];
                            // let leftTempData = tempDataSource.filter(f=>!keys?.includes(f?.key?.toString()));
                            // setFilteredInfo({});
                            // setIsFiltered(false);
                            // setDataSource(leftTempData);
                            
                            handleBulkAdd();
                            await new Promise(resolve => setTimeout(resolve, 1000));
                            message.success("Record updated Successfully");
                        }
                    }else{
                        message.error("Failed to save data.")
                    }
                }).catch((error) => {
                    console.log("error",error)
                    if(error?.response?.data?.error){
                        message.error(error?.response?.data?.error);
                    }                
                    setSpinning(false);
                });
            }else{
                portfolioData?.services?.bulkCreate({data})?.then(async (response)=>{
                    if(response?.data){
                        if(response?.data?.length>0){
                            let keys = Object.keys(values)?.map(key=>(key?.toString()));
                            let tempDataSource = [...dataSource];
                            let leftTempData = tempDataSource.filter(f=>!keys?.includes(f?.key?.toString()));
                            setFilteredInfo({});
                            setIsFiltered(false);
                            setDataSource(leftTempData);
                            await new Promise(resolve => setTimeout(resolve, 1000));
                            message.success("Record Saved Successfully");
                        }
                    }else{
                        message.error("Failed to save data.")
                    }
                }).catch((error) => {
                    console.log("error",error)
                    if(error?.response?.data?.error){
                        message.error(error?.response?.data?.error);
                    }                
                    setSpinning(false);
                });
            }
            
        } catch (error) {
            
        } finally{
            setSpinning(false);
        }
    }

    const getRelationships =(rowData)=>{

        let relationships = {};
        // Source App RelationShips:---------------------------------------------
        relationships['sourceApp']={
            moduleName:"BusinessApplication",
            values:rowData['sourceApp'] || "",
        }

        // Target App RelationShips:---------------------------------------------
        relationships['targetApp']={
            moduleName:"BusinessApplication",
            values:rowData['targetApp'] || "",
        }

        if(portfolioData?.portfolioConnected?.length){
            portfolioData?.portfolioConnected?.filter(f=>f?.moduleName!=="business_application")?.forEach(portfolio => {
                relationships[portfolio?.moduleName]={
                    moduleName:portfolio?.routeModuleName,
                    values:rowData[portfolio?.moduleName] || "",
                }
            })
        }

        return relationships;
    };

    const getRelationshipsUpdate =(rowData)=>{
        let newFormValues = rowData;
        let oldFormValues = oldFormData.find(f=>f.id?.toString()===rowData?.id?.toString());
        let oldValues;
        let newValues;
        
        let relationships = {};
        // Source App RelationShips:---------------------------------------------
        oldValues = oldFormValues['sourceApp'];
        newValues = newFormValues['sourceApp'];
        relationships['sourceApp']={
            moduleName:"BusinessApplication",
            oldValues:oldValues,
            newValues:newValues,
            deleted:oldValues?.filter(n => !newValues?.includes(n))|| [],
            added:newValues?.filter(n => !oldValues?.includes(n)) || [],
        }
        
        // Target App RelationShips:---------------------------------------------
        oldValues = oldFormValues['targetApp'];
        newValues = newFormValues['targetApp'];
        relationships['targetApp']={
            moduleName:"BusinessApplication",
            oldValues:oldValues,
            newValues:newValues,
            deleted:oldValues?.filter(n => !newValues?.includes(n))|| [],
            added:newValues?.filter(n => !oldValues?.includes(n)) || [],
        }

        if(portfolioData?.portfolioConnected?.length){
            portfolioData?.portfolioConnected?.filter(f=>f?.moduleName!=="business_application")?.forEach(portfolio => {
                oldValues = oldFormValues[portfolio?.moduleName] || [];
                newValues = newFormValues[portfolio?.moduleName] || [];
                if(!Array?.isArray(newValues)){ 
                    oldValues=oldFormValues[portfolio?.moduleName]?[oldFormValues[portfolio?.moduleName]]:[];
                    newValues=(!Array?.isArray(newFormValues[portfolio?.moduleName]))?[newFormValues[portfolio?.moduleName]]:newFormValues[portfolio?.moduleName];  
                }
                relationships[portfolio?.moduleName]={
                    moduleName:portfolio?.routeModuleName,
                    oldValues:oldValues,
                    newValues:newValues,
                    deleted:oldValues?.filter(n => !newValues?.includes(n))|| [],
                    added:newValues?.filter(n => !oldValues?.includes(n)) || [],
                }
            })
        }
        return relationships;
    };

    return (
        <Spin spinning={spinning}>
            <Form
                form={form}
                className='intigration-bulk-entry-form'
                onFinish={(values)=>{
                    if(!isFiltered){
                        onFinish(values)
                    }else{
                        Modal.confirm({
                            title: 'Do you want to save only filtered items?',
                            icon: <ExclamationCircleFilled />,
                            content: 'Clear all filters to save full Data.\nSaved  items is removed from Current Data.',
                            onOk() {
                                onFinish(values)
                            },
                            onCancel() {},
                        });
                    }
                }}
                initialValues={{}}
                ref={tableRef}
            >
                <Table
                    rowClassName={() => "editable-row"}
                    className='mb-2 bulk-entry-table'
                    bordered
                    dataSource={dataSource}    
                    loading={spinning}
                    hasData={true}
                    columns={columns?.map(item=>({ 
                        ...item,
                        title:<TextWithTooltip text={item?.title} characterLimit="auto" isTooltip/>,
                        // filters:dataSource.length>0 ? getColumnFilters({field:item,form,dataSource,tableFilterRowData:state}):[],
                        filterSearch: true,
                        filteredValue: filteredInfo[item?.dataIndex] || null,
                        onFilter: (value, record) => onFilter(value,record,item),
                        onFilterDropdownOpenChange: (visible) => {
                            if (visible) {
                                let cols = [...columns]
                                cols = cols?.map(item=>{
                                    item.filters = GetColumnFilters({field:item,form,state});
                                    return item;
                                });
                                setColumns(cols)
                            }
                        },
                    }))}
                    size="small"
                    pagination={false}
                    scroll={{ x:window?.innerWidth-300,...(dataSource?.length>10?{y: window?.innerHeight-200}:{}) }}
                    rowSelection={{
                        type: 'checkbox',
                        ...rowSelection,
                    }}
                    onChange={onTableChange}
                    footer={() => {
                        return (
                            <Flex gap={8} justify='space-between' align="center">
                                <Flex gap={8}>
                                    {props?.bulkUpdateIds?.length===0 && <DeleteRowBtn removeRows={removeRows}/>}
                                    {props?.bulkUpdateIds?.length===0 && <AddRowBtn handleAdd={handleAdd} setRef={setRefAddBtn} setSpinning ={setSpinning}/>}
                                    <ClearAllFilterBtn setFilteredInfo={setFilteredInfo}/>
                                </Flex>
                                <SaveBtn/>
                            </Flex>
                        );
                    }}
                />
            </Form>
        </Spin>
    );
}

TableFormData.defaultProps = {
    bulkUpdateIds:[]
}

export default TableFormData