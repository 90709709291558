import React, { createContext, useContext, useReducer } from "react";
const CompletenessReportContext = createContext();
export const useCompletenessReport = () =>
  useContext(CompletenessReportContext);

const initialState = {
  data: [],
  loading: false,
  error: false,
};

const completenessReportReducer = (state, action) => {
  switch (action.type) {
    case "SET_COMPLETENESS_REPORT":
      return {
        state,
        data: action.payload,
      };

    case "ADD_COMPLETENESS_REPORT":
      return {
        ...state,
        data: [...state.data, action.payload],
      };
    case "UPDATE_COMPLETENESS_REPORT":
      return {
        ...state,
        data: state.data.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
      };
    case "DELETE_COMPLETENESS_REPORT":
      return {
        ...state,
        data: state.data.filter((item) => item.id !== action.payload.id),
      };
    case "EMPTY_COMPLETENESS_REPORT":
      return {
        ...state,
        data: [],
        loading: false,
        error: false,
      };
    default:
      return state;
  }
};

export const CompletenessReportProvider = ({ children }) => {
  const [state, dispatch] = useReducer(completenessReportReducer, initialState);

  return (
    <CompletenessReportContext.Provider value={{ state, dispatch }}>
      {children}
    </CompletenessReportContext.Provider>
  );
};
