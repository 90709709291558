import React from 'react';
import { Layout, Col, Row  } from 'antd';
import './AppHeader.scss';
import { Link } from 'react-router-dom';
import { svgLogo } from '../../../assets/images';
import HeaderMenus from './HeaderMenus';
import HeaderMenusItems from './HeaderMenusItems';
import GlobalSearch from './GlobalSearch';
import "bootstrap";
import UserService from '../../../services/UserService';



const { Header } = Layout;

function AppHeader() {
    return (
        <Header className="header">
            <nav className="menuBar">
                <Row>
                    <Col span={4} className="logo">
                        <Link to="/">
                            {/* <img src={svgLogo} alt="logo" /> */}
                        </Link>
                    </Col>
                    <Col span={5}>
                        <GlobalSearch />
                    </Col>
                    <Col flex="none" className='text-center'>
                        { 
                            UserService.hasRole(['Admin', 'Editor']) ?  "" : <span className='header-blinker' >You have view only access. For elevated access please contact <a href='mailto:saroj.kumar.bisi@volvocars.com??subject=Upgrade CA Tool Access in QA Environment'>support</a></span>
                        }
                    </Col>
                    <Col span={6}>
                        <HeaderMenus menus={HeaderMenusItems}/>
                    </Col>
                </Row>
            </nav>
        </Header>
    );
}

export default AppHeader;
