import React from "react";
import BusinessApplicationGrid from "../portfolio/application/BusinessApplicationGrid";
import { useLocation } from "react-router";


const BusinessApplicationLandscapeGrid = ({...props}) => {
    let location = useLocation();
    let params = new URLSearchParams(location.search);
    let searchData = params.get("search");
    let iframeData = JSON.parse(searchData);
    
    return (
        <React.Fragment>
            <BusinessApplicationGrid
                HeaderContent={false}
                showFullContent={true}
                moduleName={iframeData?.moduleName}
                activePage={1}
                {...props}
                showFilterContent={false}
                iframeData={iframeData}
                showColorIndicator={iframeData.showColorIndicator || (iframeData?.moduleName==="business_application") || false}
            />
        </React.Fragment>
    )
}

BusinessApplicationLandscapeGrid.defaultProps = {

}

export default BusinessApplicationLandscapeGrid;
