import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import UserService from './services/UserService';
import HttpService from './services/HttpService';
import { ConfigProvider } from 'antd';
import { Neo4jProvider, createDriver } from 'use-neo4j';
import CombineProviders from './Context/CombineProviders';
import {BusinessApplicationProvider} from './Context/businessApplication';
import {BusinessCapabilityProvider} from './Context/businessCapability';
import {BusinessProcessProvider} from './Context/businessProcess';
import {PlantProvider} from './Context/plant';
import {InitiativeProvider} from './Context/initiative';
import {LinkProvider} from './Context/linkProvider';
import {ChangeLogsProvider} from './Context/changeLogsProvider';
import { PortfolioRelationshipProvider } from './Context/portfolioRelationshipProvider';
import { PortfolioSEttingProvider } from './Context/portfolioSettingProvider';

import {PortfolioConnectionProvider} from './Context/portfolioConnectionProvider';
import {DiagramProvider} from './Context/diagramProvider';


import { MenuProvider } from './Context/MenuProvider';
import { ScenarioListProvider } from './Context/ScenarioListProvider';
import { PortfolioTagProvider } from './Context/portfolioTagProvider';
import { GlobalTagProvider } from './Context/GlobalTagProvider';
import { StateProviderData } from './Context/StateProviderData';
import { CompletenessReportProvider } from './Context/completenessReportProvider';

const { REACT_APP_NEO4J_HOST, REACT_APP_NEO4J_PORT, REACT_APP_NEO4J_USERNAME, REACT_APP_NEO4J_PASSWORD } = process.env;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement);
// const driver = createDriver('bolt+s',REACT_APP_NEO4J_HOST, REACT_APP_NEO4J_PORT, REACT_APP_NEO4J_USERNAME, REACT_APP_NEO4J_PASSWORD);
const driver = createDriver('bolt+s', REACT_APP_NEO4J_HOST, REACT_APP_NEO4J_PORT, REACT_APP_NEO4J_USERNAME, REACT_APP_NEO4J_PASSWORD);
 
const providers = [BusinessApplicationProvider,LinkProvider,ChangeLogsProvider,PortfolioRelationshipProvider,BusinessCapabilityProvider,BusinessProcessProvider,PortfolioSEttingProvider,PortfolioConnectionProvider,MenuProvider,DiagramProvider,ScenarioListProvider,PlantProvider,InitiativeProvider,PortfolioTagProvider,GlobalTagProvider,StateProviderData,CompletenessReportProvider];


const renderApp = () => {
root.render(
  // <React.StrictMode>
  // </React.StrictMode>
  <ConfigProvider
    theme={{
      token: {
        colorPrimary: '#2a609d',
      }
    }}
  >
    <Neo4jProvider driver={driver} database={"neo4j"}>
    <CombineProviders providers={providers}>
        <App />
      </CombineProviders>
    </Neo4jProvider>
  </ConfigProvider>
);
};

UserService.initKeycloak(renderApp);
HttpService.configure();

