import { Button, Flex, Layout, Tabs, Tooltip } from 'antd';
import React, { useRef, useState } from 'react';
import LoadingBar from 'react-top-loading-bar';
import BusinessProcessHeader from '../businessProcess/BusinessProcessHeader';
import { PlusOutlined, UnorderedListOutlined } from '@ant-design/icons';
import GenerateReport from './business-capability-summary-report/GenerateReport';
import SummaryReportList from './business-capability-summary-report/SummaryReportList';

const BusinessCapabiltySummaryReports = ({...props}) => {
    const loadingBarRef = useRef(null);
    const [tab,setTab] = useState(1);

    const Buttons = () => {
        return <React.Fragment>
            <Flex gap={8}>
                <Tooltip title="Generate Report">
                    <Button icon={<PlusOutlined />} onClick={()=>{setTab(1)}}/>
                </Tooltip>
                <Tooltip title="List Report">
                    <Button icon={<UnorderedListOutlined />} onClick={()=>{setTab(2)}}/>
                </Tooltip>
            </Flex>
        </React.Fragment>
    }
    
    return (
        <React.Fragment>
            <LoadingBar color='#1f1f1f' ref={loadingBarRef} />
            <Layout>
                <Layout.Content>
                    <section>
                        <BusinessProcessHeader 
                            activePage={0}
                            setActivePage={()=>{}}
                            heading={"Business Capabilty Summary Report"}
                            matrix={false}
                            grid={false}
                            list={false}
                            addBtn={false}
                            bulk_delete={false}
                            showActionBtn={false}
                            Buttons={<Buttons/>}
                        />
                    </section>
                    <section className='mt-2'>
                        {tab===1 && <GenerateReport/>}
                        {tab===2 && <SummaryReportList/>}
                    </section>
                </Layout.Content>
            </Layout>
        </React.Fragment>
    )
}

export default BusinessCapabiltySummaryReports
