import React, { useState } from 'react';
import FishboneChart from '../fishbone/FishboneChart';
import ScenarioServices from '../../../services/services/ScenarioServices';
import { useEffect } from 'react';
import { COLORS } from '../../../utils/Common';

var monthNameList = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
// monthNameList.reverse();
var legendsValue = [
    {text:"New",value:"New",color:"#4afa58",},
    {text:"Remove",value:"Deleted",color:"#e90303",},
    {text:"Modified",value:"Modified",color:"#ff9641",},
    {text:"No Change",value:"No Change",color:"#ff37a5",},
]


const FishboneIntegrationChart = ({scenarioId,...props}) => {
    const [fishboneChartData,setFishboneChartData] = useState([]);
    const [legends,setLegends] = useState([]);
    const [tbdData,setTbdData] = useState([]);

    useEffect(()=>{
        timelineViewData(scenarioId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[scenarioId]);

    const generateLegends = async (scenarioData) => {
        let leg = [];
        await scenarioData?.forEach(async (sd)=>{
            await sd?.children?.forEach(async (s)=>{
                let text = s?.sourceitemtype;
                let value = s?.sourceitemtype;
                if(value==="Deleted"){ text="Remove"; }
                leg.push({color:"",text,value})
                // leg.push({color:s?.color,text:s?.name})
            })
        });
        leg = [...new Map(leg.map(item => [item["value"], item])).values()];

        return leg?.map((s,i)=>{
            return {
                ...s,
                color:legendsValue?.find(f=>f.value===s.value)?.color ? legendsValue?.find(f=>f.value===s.value)?.color : COLORS[i],
            }
        })
    }

    const generateFishboneChartData = async (scenarioData) => {
        let legs = await generateLegends(scenarioData);
        let scenarioData1 = await scenarioData?.filter(f=>f?.deadline_month!==null);
        let scenarioData2 = await scenarioData?.filter(f=>f?.deadline_month===null);
        let timelineData = [];
        let timelineTBDData = [];
        
        if(scenarioData1?.length>0){
            scenarioData1 = await scenarioData1?.map(sd=>{
                sd.children = sd.children?.map(child=>{
                    child.sourceitemtypeColor = legs?.find(f=>f.value===child?.sourceitemtype)?.color;
                    return child;
                })
                return sd;
            })
    
            const years = [...new Set(scenarioData1?.map(item=>item?.deadline_year))].filter(f=>f!==null);
            // years.reverse();
    
            let maxYear = Math.max(...years);
            console.log("maxYear",maxYear);
            
            years?.forEach(async (year)=>{
                monthNameList?.forEach(async (month)=>{
                    let l = scenarioData1.find(f=>(f?.deadline_year===year && f?.deadline_month?.toLowerCase()===month?.toLowerCase()));
                    if(l){
                        timelineData.push(l);
                    }else if(timelineData?.length>0){ //
                        let l2 = {
                            deadline_month: month,
                            deadline_year : year,
                            children:[],
                        }
                        timelineData.push(l2);
                    }
                });
            });
            // timelineData?.reverse();

            // for (let index = 0; index < timelineData.length; index++) {
            //     const element = timelineData[index];
            //     if (element?.children?.length>0) {
            //         break;
            //     }else{
            //         delete timelineData[index];
            //     }
            // }
            // timelineData?.reverse();
        }

        if(scenarioData2?.length>0){
            scenarioData2?.forEach(f=>{
                let children = f?.children?.length > 0 ? f?.children : [];
                if(children?.length>0){
                    children = children?.map(child=>{
                        child.sourceitemtypeColor = legs?.find(f=>f.value===child?.sourceitemtype)?.color;
                        return child;
                    })
                    timelineTBDData = [...timelineTBDData,...children];
                }
            });
        }
        
        setFishboneChartData(timelineData);
        setLegends(legs);
        setTbdData(timelineTBDData);
    }

    const timelineViewData =async (id) =>{
        let scenarioData = await ScenarioServices?.getScenarioTimelineRecords(id);
        scenarioData = await scenarioData?.data;
        if(scenarioData?.length>0){
            await generateFishboneChartData(scenarioData);
        }
    }

    return (
        <React.Fragment>
            <FishboneChart lists={fishboneChartData} legends={legends} tbdData={tbdData}/>
        </React.Fragment>
    )
}

FishboneIntegrationChart.defaultProps = {}

export default FishboneIntegrationChart