
import ScenarioSvg from './../../assets/Scenario.svg';
import CreatingDiagramSvg from './../../assets/FlowDiagram.svg';
import BusinessCapabilityMappingSvg from './../../assets/BusinessCapabilityMapping.svg';
import SettingSvg from './../../assets/Setting.svg';
import { Link } from 'react-router-dom';
import { Col, Row } from 'antd';
const HomeQuickLink = ( {person}) => {
    // const encode = plantumlEncoder.encode(props.src);
  

    return (
    <> 
        <div className='quickGuid'>
            <h3 className='my-3'>Quick Links</h3>
            <Link to="/start-new-scenario">
                <Row className="align-items-center">
                    <Col span={4}><img src={ScenarioSvg} alt={`Create Scenario`} className="iconimg"/></Col>
                    <Col span={20}>Create Scenario</Col>
                </Row>
            </Link>
            <Link to="/application-landscape">
                <Row className="align-items-center">
                    <Col span={4}><img src={CreatingDiagramSvg} alt={`Application Landscape`} className="iconimg"/></Col>
                    <Col span={20}>Application Landscape</Col>
                </Row>
            </Link>
            <Link to="/integration-landscape">
                <Row className="align-items-center">
                    <Col span={4}><img src={CreatingDiagramSvg} alt={`Integration Landscape`} className="iconimg"/></Col>
                    <Col span={20}>Integration Landscape</Col>
                </Row>
            </Link>
            <Link to="/portfolio/BusinessCapability">
                <Row className="align-items-center">
                    <Col span={4}><img src={BusinessCapabilityMappingSvg} alt={`Business Capability`} className="iconimg"/></Col>
                    <Col span={20}>Business Capability</Col>
                </Row>
            </Link>
            <Link to="/others-settings">
                <Row className="align-items-center">
                    <Col span={4}><img src={SettingSvg} alt={`Settings`} className="iconimg"/></Col>
                    <Col span={20}>Settings</Col>
                </Row>
            </Link>
        </div>
        </>
    );
};
export default HomeQuickLink;