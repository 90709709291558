const convertBusinessApplicationKeysToCase = (
  obj,
  convertToUpperCase = true
) => {
  const newObj = {};
  for (let key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      console.log("keys-data", key);
      // let newKey = key;
      // if (
      //   convertToUpperCase &&
      //   (key === "applicationName" ||
      //     key === "appId" ||
      //     key === "description" ||
      //     key === "lifeCycleStage" ||
      //     key === "additionalAttributes" ||
      //     key === "tags")
      // ) {
      //   newKey = key.toUpperCase();
      // } else if (!convertToUpperCase) {
      //   newKey = key.toLowerCase();
      // }
      // newObj[newKey] = obj[key];
      if (
        key === "applicationName" ||
        key === "appId" ||
        key === "description" ||
        key === "lifeCycleStage" ||
        key === "additionalAttributes" ||
        key === "tags"
      ) {
        newObj[key] = obj[key];
      } else if (!convertToUpperCase) {
        newObj[key.charAt(0).toLowerCase() + key.slice(1)] = obj[key];
      } else {
        newObj[key.charAt(0).toUpperCase() + key.slice(1)] = obj[key];
      }
    }
  }
  return newObj;
};

const applicationsList = {
  businessApplication: "BusinessApplication",
  businessCapability: "BusinessCapability",
};

const getDynamicMessage = (response, isDelete = false) => {
 
  // Extracting property name
  const propertyNameRegex = /\"(.*?)\"/;
  const propertyNameMatch = response?.match(propertyNameRegex);
  const propertyName = propertyNameMatch ? propertyNameMatch[1] : "";

  // Extracting table name
  const tableNameRegex = /relation \\"(.*?)\\"/;
  const tableNameMatch = response?.match(tableNameRegex);
  const tableName = tableNameMatch ? tableNameMatch[1] : "";

  // Constructing dynamic message
  const dynamicMessage = `Property name "${propertyName}" already exists in table ${tableName}.`;

  return dynamicMessage;
};
export {
  convertBusinessApplicationKeysToCase,
  applicationsList,
  getDynamicMessage,
};
