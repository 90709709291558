import "./StartNewScenarioV2.scss";
import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Modal,
  Form,
  message,
  Spin,
  Flex,
  Pagination,
  Select,
  DatePicker,
  Tooltip,
  Row,
  Col,
} from "antd";
import ActionsBtn from "../applicationlist/ActionsBtn";
import { ExclamationCircleFilled, NodeExpandOutlined, PlusOutlined } from "@ant-design/icons";
import listSvg from "./../../assets/List.svg";
import gridSvg from "./../../assets/Two columns layout.svg";
import FormFields from "../../utils/FormFields";
import ApplicationList from "./portfolio/ApplicationList";
import IntegrationList from "./portfolio/IntegrationList";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import {
  PageTitleHeading,
  debounceTimeOut,
  default_pageSize,
  removeEmptyArrObjOrval,
  getSelectProperties,
  default_pageSizeOptions,
} from "../../utils/Common";
import ScenarioListData from "./ScenarioListData";
import BusinessProcessList from "./portfolio/BusinessProcessList";
import BusinessCapabilityList from "./portfolio/BusinessCapabilityList";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../hooks/usePortfolioFetcher";
import { normalizeString } from "../../utils/linksCommon";
import PortfolioRelationshipServices from "../../services/services/PortfolioRelationshipService";
import BusinessApplicationListCards from "../applicationlist/BusinessApplicationListCards";
import { applicationsList } from "../../utils/businessApplication";
import {
  getMenuPortfolio,
  staticSettingPortfolioList,
} from "../../utils/settingCommon";
import { useNavigate, useParams } from "react-router";
import ScenarioItemsServices from "../../services/services/ScenarioItemsService";
import useDebounce from "../../helper/useDebounce";
import { Link } from "react-router-dom";
import ScenarioServices from "../../services/services/ScenarioServices";
import { usePortfolioTag } from "../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../hooks/useFetchPortfolioTag";
import ChangeTypeList from "./portfolio/ChangeTypeList";
import { SaveRecentlyView_Portfolio } from "../../utils/ResentViewedPortfolio";
import { useMenu } from "../../Context/MenuProvider";
import { logDOM } from "@testing-library/react";
import FilterRightSideBar from "../../../src/components/applicationlist/filter/FilterRightSideBar";
import {
  deepFindByName,
  transformText,
  removeEmptyKeys,
  getRecentlyView_ScenarioName
} from "../../utils/Common";
import FishboneIntegrationChart from "./portfolio/FishboneIntegrationChart";
import { checkRole } from "../../helper/useUserData";


const { confirm } = Modal;

const { Option } = Select;
const modalContentHeight = window?.innerHeight - 350;
const choosePortfolioList = [
  { label: "Select", value: "" },
  { label: "Application", value: 1 },
  { label: "Business Process", value: 2 },
  { label: "Business Capabilities", value: 3 },
];

const ScenarioListV2 = (props) => {
  const navigate = useNavigate();
  const [portfolioTypeForm] = Form.useForm();
  const [changeTypeForm] = Form.useForm();
  const [changeTypeForm_Edit] = Form.useForm();
  const [isOpenChoosePortfolioType, setOpenChoosePortfolioType] =
    useState(false);
  const [isOpenPortfolioList, setOpenPortfolioList] = useState(false);
  const [portfolioOpenRecord, setPortfolioOpenRecord] = useState({
    value: "",
    label: "",
  });
  const [recordData, addRecordData] = useState([]);
  const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();

  const [settingPortfolioList, setSettingPortfolioList] = useState([]);
  const [searchTextApplication, setSearchTextApplication] = useState("");
  const [selectedModuleItems, setSelectedModuleItems] = useState({});
  const [selectedPortfolioItems, setSelectedPortfolioItems] = useState([]);

  const [filterLevel, setFilterLevel] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [filterBy, setFilterBy] = useState("");
  const [addedData, setAddedData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [changeTypeLoading, setChangeTypeLoading] = useState(false);

  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(default_pageSize);
  const { id: scenarioId = null } = useParams();
  const [selectedItemId, setSelectedItemId] = useState([]);
  const [selectedItemReplacewithId, setSelectedItemReplacewithId] = useState(
    []
  );
  const [changeTypeModal, setChangeTypeModal] = useState(false);
  const [changeTypeModal_ChangeTypeList, setChangeTypeModal_ChangeTypeList] =
    useState(false);

  const [portfolioItemIds, setPortfolioItemIds] = useState({});
  const [relationshipPortfolioId, setRelationshipPortfolioId] = useState(null);
  const [replaceWith, setReplaceWith] = useState(null);
  const [selectedItemIDRows, setselectedItemIDRows] = useState([]);

  //   Filter state
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  
  

  const [changeTypeData, setChangeTypeData] = useState([]);
  const { stateMenu, dispatchMenu } = useMenu();

  const [commentModel, setCommentModel] = useState(false);
  const [comment, setComment] = useState("");
  const [changeTypeName, setChangeTypeName] = useState("");
  const [moduleName_ScenarioListData, setModuleName_ScenarioListData] =
    useState("");

  const [portfolioName_ScenarioListData, setPortfolioName_ScenarioListData] =
    useState("");

  const [editFlag, setEditFlag] = useState(false);

  const [changeTypeModal_Edit, setChangeTypeModal_Edit] = useState(false);
  const [isOpenPortfolioList_changeType, setOpenPortfolioList_changeType] =
    useState(false);

  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;
  const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();
  const [addRelationhipModal, setRelationhipModal] = useState(false);
  const [list, setList] = useState([]);
  const [relationsList, setRelationsList] = useState([]);
  const [changeTypeFormData, setChangeTypeFormData] = useState({});
  const [PortfolioItemID, setPortfolioItemID] = useState({});
  const [PortfolioItemID_temp, setPortfolioItemID_temp] = useState({});
  const [changeTypeName_temp, setChangeTypeName_temp] = useState("");

  // Submit filter state it should work only apply is clicked
  const [filterSubmit, setFilterSubmit] = useState(false);
  const [selectedRelationFilters, setSelectedRelationFilters] = useState({});

  const [integrationModal, setIntegrationModal] = useState(false);
  const [showListModal, setShowListModal] = useState(false);
  const [freeText, setfreeText] = useState("Data Not Found");
  const [activeContent, setActiveContent] = useState(1);
  const [integrationGridData, setIntegrationGridData] = useState([]);

 // const scenarioName = localStorage.getItem("scenarioName");
 const scenarioName=getRecentlyView_ScenarioName(scenarioId);

 console.log("scenarioName",scenarioName);
 
  const fetchAllChangeTypes = async () => {
    try {
      setLoading(true);
      const response = await ScenarioServices.getAllChangeTypes();
      if (response && response?.data?.code === 200) {
        const responseData = response?.data?.data || [];
        const options = responseData?.map((item) => {
          return { ...item, label: item?.name, value: Number(item?.id) };
        });
        setChangeTypeData(options);

        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Scenario changes types not found.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Scenario changes types:", error);
      message.error("Error fetching Scenario changes types.");
      setLoading(false);
    }
  };

  const fetchAllPortfolioTags = async () => {
    try {
      await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      // message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchAllChangeTypes();

    SaveRecentlyView_Portfolio(
      "Scenario",
      window.location.href,
      scenarioName,
      scenarioId,
      "#E1D7C6"
    );

    handleBulkUpdate_new();
  }, []);

  useEffect(() => {
    setPortfolioItemID_temp(PortfolioItemID);
    setChangeTypeName_temp(changeTypeName);
  }, [PortfolioItemID, changeTypeName]);

  // useEffect(() => {
  //   setPortfolioItemID_temp(PortfolioItemID);
  //   setChangeTypeName_temp(changeTypeName);
  // }, [PortfolioItemID?.changeTypeId]);

  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      // message.error("Something went wrong.");
    }
  };

  const getBusinessPortfolioListByModuleName = async (
    selectedItem,
    filters = {}
  ) => {
    try {
      setLoading(true);
      if (!editFlag) {
        setSelectedPortfolioItems([]);
        const { moduleName, name } = selectedItem;

        const list = await PortfolioRelationshipServices.getModuleData({
          moduleName: moduleName,
          filters: {
            ...filters,
            limit: filters?.limit || limit,
            page: filters?.page || page,
          },
        });
        if (list?.data?.data?.length > 0) {
          setTimeout(() => {
            setSelectedPortfolioItems(
              list?.data?.data?.map((item) => ({
                ...item,
                moduleName: moduleName,
              })) || []
            );
            setTotalRecords(list?.data?.totalCount);

            if (moduleName === "integration") {
              setIntegrationModal(true);
            } else {
              setOpenPortfolioList(true);
            }
            setLoading(false);
            //   setOpenChoosePortfolioType(false);
          }, 300);
        } else {
          setTimeout(() => {
            setSelectedPortfolioItems([]);
            setLoading(false);
          }, 300);
          message.info(`${name} items not found`);
        }
        setFilterSubmit(false);
      }
    } catch (error) {
      setFilterSubmit(false);
      setLoading(false);
      message.error(`Portfolio not found`);
      console.error(error);
    }
  };

  const getBusinessPortfolioListByModuleName_edit = async (
    selectedItem,
    filters = {}
  ) => {
    try {
      setLoading(true);
      setSelectedPortfolioItems([]);
      const { moduleName, name } = selectedItem;

      const list = await PortfolioRelationshipServices.getModuleData({
        moduleName: moduleName,
        filters: {
          ...filters,
          limit: filters?.limit || limit,
          page: filters?.page || page,
        },
      });
      if (list?.data?.data?.length > 0) {
        setTimeout(() => {
          setSelectedPortfolioItems(
            list?.data?.data?.map((item) => ({
              ...item,
              moduleName: moduleName,
            })) || []
          );
          setTotalRecords(list?.data?.totalCount);
          //setOpenPortfolioList_changeType(true);
          setLoading(false);
          //   setOpenChoosePortfolioType(false);
        }, 300);
      } else {
        setTimeout(() => {
          setSelectedPortfolioItems([]);
          setLoading(false);
        }, 300);
        message.info(`${name} items not found`);
      }
    } catch (error) {
      setLoading(false);
      message.error(`Portfolio not found`);
      console.error(error);
    }
  };

  useEffect(() => {
    if (portfolioSettingState?.data?.length) {
      const extractedData = portfolioSettingState?.data.map((item) => ({
        ...item,
        name: item.name || "",
        moduleName: item.moduleName || "",
        id: item.id || null,
        color: item.color || "#000",
        value: item?.moduleName,
        label: normalizeString(item?.name),
      }));
      setSettingPortfolioList(extractedData);
    } else {
      fetchAllPortfolio();
    }
  }, [portfolioSettingState?.data]);

  useEffect(() => {
    if (selectedModuleItems?.moduleName) {
      const removedItems = removeEmptyArrObjOrval(selectedFilters);
      getBusinessPortfolioListByModuleName(
        selectedModuleItems,
        removedItems || {}
      );
    }
  }, [selectedModuleItems, selectedModuleItems?.moduleName, page, limit]);

  useEffect(() => {
    if (moduleName_ScenarioListData) {
      const removedItems = removeEmptyArrObjOrval(selectedFilters);
      getBusinessPortfolioListByModuleName_edit(
        { moduleName: moduleName_ScenarioListData },
        removedItems || {}
      );
    }
  }, [isOpenPortfolioList_changeType]);

  // Tag useEffect start
  useEffect(() => {
    if (stateTag?.moduleName) {
      fetchAllPortfolioTags();
    }
  }, [stateTag?.moduleName]);
  // Tag useEffect end

  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
  useEffect(() => {
    if (filterSubmit) {
      fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
    }
  }, [filterSubmit, debouncedFilter]);

  const fetchFilteredData = async (filter) => {
    try {
      setLoading(true);
      if (Object.keys(removeEmptyKeys(filter))?.length > 0) {
        // setList([])
        // const portfolio = await deepFindByName(
        //   portfolioSettingState?.data,
        //   "Integration",
        //   false
        // );

        const portfolio = await deepFindByName(
          portfolioSettingState?.data,
          selectedModuleItems.name,
          false
        );

        const updatedFilter = {
          moduleName: portfolio?.moduleName || "",
          ...filter,
        };

        const response = await PortfolioSettingServices?.getFilteredPortfolios(
          updatedFilter,
          page,
          limit
        );
        if (response?.data?.data?.length) {
          setTimeout(() => {
            setLoading(false);
            setSelectedPortfolioItems(
              response?.data?.data?.map((item) => ({
                ...item,
                //moduleName: "Integration",
                moduleName:portfolio?.moduleName
              })) || []
            );
            setTotalRecords(response?.data?.totalCount || 0);
          }, 500);
        } else {
          //message.info("No records found.");
          setSelectedPortfolioItems(
            response?.data?.data?.map((item) => ({
              ...item,
              //moduleName: "Integration",
              moduleName:portfolio?.moduleName
            })) || []
          );
          setTotalRecords(response?.data?.totalCount || 0);
        }
        console.log("response-filter-result", response);
      } else {
        setSelectedPortfolioItems(selectedPortfolioItems);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data. Please try again.");
    } finally {
      setFilterSubmit(false);
      setLoading(false);
    }
  };


  const groupedItemIds = (data) => {
    return data?.reduce((acc, obj) => {
      const { item_id: itemId, id } = obj;
      const name = obj?.portfolioSettings?.moduleName;
      acc[name] = acc[name] || [];
      acc[name].push({ id, itemId });
      return acc;
    }, {});
  };

  const getAllScenariosItems = async () => {
    try {
      setLoading(true);
      const response =
        await ScenarioItemsServices.getScenariosItemsByScenarioId(scenarioId);

      if (response && response?.data?.code === 200) {
        const responseData = response?.data?.data || [];
        await addRecordData(responseData);
        await setPortfolioItemIds(await groupedItemIds(responseData));

        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Scenario items not found.");
      }
      setLoading(false);
    } catch (error) {
      console.error("Error fetching Scenario items:", error);
      // message.error("Something went wrong.");
      setLoading(false);
    }
  };

  useEffect(() => {
    getAllScenariosItems();
  }, [scenarioId]);

  useEffect(() => {}, [selectedItemIDRows]);

  const handleBulkUpdate_new = async () => {
    //getScenarioIntegration_tocopy
    await ScenarioServices.getScenarioIntegration_GridData({
      scenario_id: scenarioId,
    })
      .then(async (response) => {
        if (response?.data) {
          let responseItems = response?.data;
          setIntegrationGridData(responseItems);
          const ids = responseItems.map(item => item.integration_id);

          console.log("responseItems",responseItems);

          setAddedData(ids);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally((e) => {});
  };

  const addNewValue = (id, itemId, portfolioSettingsId = null) => {
    let portfolioType = portfolioTypeForm?.getFieldValue()?.portfolioType;
    if (portfolioSettingsId) {
      const findType = portfolioSettingState?.data?.find(
        (item) => item?.id === portfolioSettingsId
      );
      if (findType) {
        portfolioType = findType?.moduleName;
      }
    }

    setPortfolioItemIds((prevState) => {
      // Retrieve the current array associated with the portfolio type
      const currentItems = prevState[portfolioType] || [];

      // Push the new value into the array
      const updatedItems = [...currentItems, { id: id, itemId: itemId }];

      // Return the updated state
      return {
        ...prevState,
        [portfolioType]: updatedItems,
      };
    });
  };

  const onFormSubmit = async (formData) => {
    try {
      setChangeTypeLoading(true);

      let formValues = [];

      if (selectedItemIDRows.length) {
        selectedItemIDRows.map((selecteID) => {
          if (formData.changeType == "Replace") {
            formValues.push({
              ...formData,
              scenarioId,
              //itemId: selectedItemId,
              itemId: selecteID,
              portfolioSettingsId: relationshipPortfolioId
                ? relationshipPortfolioId
                : selectedModuleItems?.portfolioId,
              replaceWith: replaceWith,
            });
          } else {
            formValues.push({
              ...formData,
              scenarioId,
              //itemId: selectedItemId,
              itemId: selecteID,
              portfolioSettingsId: relationshipPortfolioId
                ? relationshipPortfolioId
                : selectedModuleItems?.portfolioId,
            });
          }
        });
      } else {
        if (formData.changeType == "Replace") {
          formValues = [
            {
              ...formData,
              scenarioId,
              id: PortfolioItemID?.id || null,
              itemId: selectedItemId,
              portfolioSettingsId: relationshipPortfolioId
                ? relationshipPortfolioId
                : selectedModuleItems?.portfolioId,
              replaceWith: replaceWith,
            },
          ];
        } else {
          formValues = [
            {
              ...formData,
              scenarioId,
              id: PortfolioItemID?.id || null,
              itemId: selectedItemId,
              portfolioSettingsId: relationshipPortfolioId
                ? relationshipPortfolioId
                : selectedModuleItems?.portfolioId,
              //replaceWith: replaceWith,
            },
          ];
        }
      }

      const action = editFlag == false ? "Add" : "Update";

      const arrayFormValues = { items: formValues, action: action };

      if (!scenarioId) {
        return message.error("Scenario not found.");
      }

      //   const response =
      //     scenarioId === null || scenarioId === undefined
      //       ? await ScenarioItemsServices.createScenarioItem(arrayFormValues)
      //       : await ScenarioItemsServices.updateScenarioItem({
      //           arrayFormValues,
      //           id: scenarioId,
      //         });

      const response = await ScenarioItemsServices.createScenarioItem(
        arrayFormValues
      );
      if (response?.data?.code === 200) {
        let responseVal = response?.data?.data;
        message.success("Updated Successfully.");

        //Refresh Data
        await getAllScenariosItems();

        setselectedItemIDRows([]);

        if (responseVal?.length > 0) {
          await responseVal?.map(
            async (item) =>
              await addNewValue(
                item?.id,
                item?.item_id,
                item?.portfolio_settings_id
              )
          );
        }

        setChangeTypeModal_ChangeTypeList(false);
        // setOpenPortfolioList(false);

        setTimeout(() => {
          changeTypeForm.resetFields();
          //   portfolioTypeForm.resetFields();
          //   setOpenPortfolioList(false);

          //setOpenPortfolioList_changeType(false);
          setChangeTypeModal(false);

          setOpenPortfolioList_changeType(false);
          //   setOpenChoosePortfolioType(false);
          setChangeTypeLoading(false);
        }, 1000);
      } else {
        message.error("Change type submitting failed.");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to submit form. Please try again.");
    } finally {
      setChangeTypeLoading(false);
    }
  };

  const removeValue = (idToRemove, portfolioType) => {
    // const portfolioType = portfolioTypeForm?.getFieldValue()?.portfolioType;

    setPortfolioItemIds((prevState) => {
      // Retrieve the current array associated with the portfolio type
      const currentItems = prevState[portfolioType] || [];

      // Filter out the item with the specified idToRemove
      const updatedItems = currentItems.filter(
        (item) => item.id !== idToRemove
      );

      // Return the updated state
      return {
        ...prevState,
        [portfolioType]: updatedItems,
      };
    });
  };

  const removeScenarioItemState = async ({ id, itemId, portfolioName }) => {
    await addRecordData((prevItems) =>
      prevItems.filter((item) => item.id !== id)
    );
    await removeValue(id, portfolioName);
  };

  const removeScenarioItem = async ({
    id = null,
    name = null,
    itemId = null,
    portfolioName = "",
  }) => {
    try {
      setLoading(true);
      const response = await ScenarioItemsServices.deleteScenarioItem(id);
      if (response && response?.data?.code === 200) {
        await removeScenarioItemState({ id, itemId, portfolioName });
        //message.success(`${name} Deleted Successfully.`);
        message.success(`Deleted Successfully.`);
      } else {
        message.error(`Failed to removed ${name}`);
      }
      setLoading(false);
    } catch (error) {
      console.error(error);
      message.error(error?.message || `Failed to delete ${name}`);
    }
  };

  const showConfirmDeleteScenarioItem = ({
    id = null,
    name = null,
    itemId = null,
    portfolioName = "",
  }) => {
    confirm({
      title: (
        <div className="font-normal text-wrap" style={{ fontWeight: 600 }}>
          Are you sure you want to delete?{" "}
          {/* <span className="font-semibold">{name}</span> from scenario list? */}
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          await removeScenarioItem({
            id,
            name,
            itemId,
            portfolioName,
          });
        } catch (error) {
          console.error("error", error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const onClickScenario = () => {
    let portfolioType = portfolioTypeForm?.getFieldValue("portfolioType");
    if (portfolioType) {
      let portfolio = getMenuPortfolio({ moduleName: portfolioType });
      let link = `${portfolio?.route}/add`;
      navigate(link, { state: { scenarioId } });
    }
  };

  const ChangeType = ({
    type,
    name,
    label,
    formClassName,
    options,
    onChange,
    rules,
  }) => {
    return (
      <Form.Item
        label={label}
        name={name}
        className={formClassName}
        rules={rules}
      >
        <Select placeholder="Select" onChange={onChange}>
          {options.map((option) => (
            <Option key={option.value} value={option.value}>
              {option.label}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
  };

  const handleAddRelationship = async ({ id, moduleName }) => {
    // try {
    //   const addedData = await addPortfolioRelationship(
    //     { id, moduleName },
    //     fetchDataAfterOperation
    //   );
    //   setListApplication(
    //     listApplication?.map((item) => {
    //       return { ...item, relationShipId: addedData[0]?.id || null };
    //     }) || []
    //   );
    //   setFilterApplication(
    //     filterApplication?.map((item) => {
    //       return { ...item, relationShipId: addedData[0]?.id || null };
    //     }) || []
    //   );
    // } catch (error) {
    //   console.error("Error adding relationship:", error);
    //   console.error("Error adding relationship:", error);
    // }
  };

  const handleRemoveRelationship = async ({ id, moduleName, uniqueid }) => {
    // try {
    //   Modal.confirm({
    //     centered: true,
    //     title: "Do you want to unlink this item?",
    //     icon: <ExclamationCircleFilled />,
    //     // content: 'Some descriptions',
    //     onOk() {
    //       removePortfolioRelationship(id, fetchDataAfterOperation, uniqueid);
    //     },
    //     onCancel() {
    //       // console.log('Cancel');
    //     },
    //   });
    // } catch (error) {
    //   console.error("Error removing relationship:", error);
    // }
  };

  const SaveIntegrationData = async () => {
    try {
      if (addedData?.length) {
        let formValues = [];

        const action = "Add";

        const arrayFormValues = {
          items: addedData,
          scenarioId: scenarioId,
          action: action,
        };

        if (!scenarioId) {
          return message.error("Scenario not found.");
        }

        if (addedData?.length) {
          addedData?.map((selecteID) => {
            formValues.push({
              // ...formData,
              scenarioId,
              itemId: selecteID,
              portfolioSettingsId: 9, //passed fixed integration id
            });
          });
        }

        const arrayFormValues_ScenarioItem = {
          items: formValues,
          action: action,
        };

        //Save in Scenario Item
        const response1 = await ScenarioItemsServices.createScenarioItem(
          arrayFormValues_ScenarioItem
        );

        //Save Data  Scenario Integration Table
        const response =
          await ScenarioItemsServices.createScenarioIntegrationItems(
            arrayFormValues
          );

        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          message.success("Saved Successfully.");

          setIntegrationModal(!integrationModal);
          setAddedData([]);
        }
      } else {
        console.log("Select Integration to save ");
      }
    } catch (error) {
      console.error(error);
      message.error("Failed to submit form. Please try again.");
    } finally {
      setChangeTypeLoading(false);
    }
  };

  return (
    <div className="my-2 text-left scenario-list-v2">
      <div className="d-flex justify-content-between">
        <div>
          <PageTitleHeading
            text={`${scenarioName ? `${scenarioName}` : `Scenario`}`}
            width={50}
            wordBreak={true}
          />
          <div>
            <button
              type="button"
              className={`btn btn-sm btn-outline-secondary mr-1`}
              onClick={()=>{ setActiveContent(1) }}
            >
              <img src={listSvg} alt={`List Application`} width={`20px`} />
            </button>
            {/* <button
              type="button"
              className={`btn btn-sm btn-outline-secondary mr-1`}
              onClick={()=>{ setActiveContent(2) }}
            >
              <img src={gridSvg} alt={`Grid Application`} width={`20px`} />
            </button> */}
            <button 
              type="button"
              className={`btn btn-sm btn-outline-secondary mr-1`}
              onClick={()=>{ setActiveContent(3) }}
            >
              <NodeExpandOutlined />
            </button>
          </div>
        </div>
        <Flex justify="end" align="top" className="my-3" gap={8}>
          <div>{/* <ActionsBtn name={`Business Capability`} /> */}</div>
          <div>
            <Link
              to="/list-scenario"
              className="btn btn-sm btn-outline-secondary"
            >
              <i className="fa fa-arrow-left"></i> Back
            </Link>
          </div>
        </Flex>
      </div>
      <hr className="my-2" />
      {
        ((()=>{
          switch (activeContent) {
            case 1:
              return <React.Fragment>
                <div className="d-flex justify-content-between my-3">
                  <div>
                    <PageTitleHeading text={`Item List`} size={4} className={`my-0`} />
                    <p>
                      Below is list of all portfolio entities that are part of this
                      scenario
                    </p>
                  </div>
                  <div className="ml-auto">
                    {checkRole('add') && <Button
                      type="primary"
                      onClick={() => {
                        setOpenChoosePortfolioType(true);
                        setEditFlag(false);
                      }}
                    >
                      Add Change Item
                    </Button>}
                  </div>
                </div>
                <div>
                  <div className="p-3">
                    <ScenarioListData
                      data={recordData}
                      scenarioId={scenarioId}
                      showConfirmDeleteScenarioItem={showConfirmDeleteScenarioItem}
                      setCommentModel={setCommentModel}
                      setComment={setComment}
                      setChangeTypeModal_Edit={setChangeTypeModal_Edit}
                      setModuleName_ScenarioListData={setModuleName_ScenarioListData}
                      setPortfolioName_ScenarioListData={
                        setPortfolioName_ScenarioListData
                      }
                      setPortfolioItemID={setPortfolioItemID}
                      setChangeTypeName={setChangeTypeName}
                      changeTypeData={changeTypeData}
                      setSelectedItemId={setSelectedItemId}
                      setRelationshipPortfolioId={setRelationshipPortfolioId}
                      setEditFlag={setEditFlag}
                      getAllScenariosItems={getAllScenariosItems}
                      integrationGridData={integrationGridData}
                      handleBulkUpdate_new={handleBulkUpdate_new}
                    />
                  </div>
                </div>
                {recordData?.length === 0 && !loading && (
                  <Card bordered className={`bordered text-center rounded-0`}>
                    <div className="text-center">
                      <Button
                        icon={<PlusOutlined />}
                        shape="circle"
                        className="text-center"
                        onClick={() => {
                          setOpenChoosePortfolioType(true);
                        }}
                      />
                    </div>
                  </Card>
                )}
              </React.Fragment>
            case 3:
              return <React.Fragment>
                <FishboneIntegrationChart
                  scenarioId={scenarioId}
                />
              </React.Fragment>
            default:
              return <React.Fragment>

              </React.Fragment>
          }
        })())
      }
      

      <Modal
        title="Choose Portfolio Type"
        open={isOpenChoosePortfolioType}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          portfolioTypeForm.resetFields();
          dispatchTag({ type: "EMPTY_TAGS" });
          setOpenChoosePortfolioType(false);
          setSelectedModuleItems({});
          setPortfolioOpenRecord({ value: "", label: "" });
          getAllScenariosItems();
          setShowFilter(false);
          setFilterData([]);
          setSelectedFilters({});
          setEditFlag(false);
          handleBulkUpdate_new();
        }}
        width={400}
        closable
      >
        <Form
          form={portfolioTypeForm}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 24 }}
          initialValues={{}}
          onFinish={async (formData) => {
            await fetchAllChangeTypes();
            setEditFlag(false);

            let record = settingPortfolioList?.find(
              (f) => f.value === formData?.portfolioType
            );

            //Dispatch Tag
            dispatchTag({ type: "EMPTY_TAGS" });
            dispatchTag({
              type: "SET_MODULE_NAME",
              payload: record?.moduleName,
            });

            setSelectedModuleItems({
              moduleName: record?.moduleName,
              name: record?.name,
              portfolioId: record?.id,
            });

            setPage(1);
            setPortfolioOpenRecord(record);
            setShowFilter(false);
            setSelectedFilters({});

            setFilterData(getSelectProperties(record?.portfolioSections, true));
          }}
        >
          <FormFields
            type="select"
            name="portfolioType"
            options={settingPortfolioList}
            rules={[
              {
                required: true,
                message: "Please select Portfolio Type",
              },
            ]}
            formClassName="my-5"
          />
          <div className="d-flex justify-content-between">
            <div>
              <Button
                type={`primary`}
                htmlType="submit"
                className="w-100 mb-5"
                loading={loading}
              >
                Pick existing
              </Button>
            </div>
            <div>&emsp;OR&emsp;</div>
            <div>
              <Button onClick={onClickScenario} className="w-100 mb-5">
                Add New
              </Button>
            </div>
          </div>
        </Form>
      </Modal>

      <Modal
        destroyOnClose
        header={false}
        footer={false}
        requiredMark={true}
        maskClosable={false}
        open={changeTypeModal_Edit}
        onCancel={() => {
          dispatchTag({ type: "EMPTY_TAGS" });
          changeTypeForm_Edit?.resetFields();
          setChangeTypeModal_Edit(false);
          setOpenChoosePortfolioType(false);
          setSelectedModuleItems({});
          setPortfolioOpenRecord({ value: "", label: "" });
          getAllScenariosItems();
        }}
      >
        <Form
          form={changeTypeForm_Edit}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={PortfolioItemID_temp}
          className="mt-4"
          onFinish={async (formData) => {
            // await setChangeTypeLoading(true);

            const changeTypeId = changeTypeData.find(
              (type) => type.name === changeTypeName
            );

            formData["changeType"] = changeTypeName;
            formData["changeTypeId"] = changeTypeId.id;
            onFormSubmit(formData);
          }}
        >
          <ChangeType
            type={`select`}
            name={`changeTypeId`}
            label={`Change Type`}
            formClassName={`mb-2`}
            options={changeTypeData}
            rules={[
              {
                required: true,
                message: "Please select Change Type",
              },
            ]}
            onChange={(value) => {
              const selectedChangeType = changeTypeData.find(
                (type) => type.value === value
              );
              setChangeTypeFormData({
                ...changeTypeFormData,
                ["changeType"]: value,
              });
              setChangeTypeName(
                selectedChangeType ? selectedChangeType.name : ""
              );
            }}
          />
          {changeTypeName === "Upgrade" && (
            <FormFields
              type={`input`}
              name={`version`}
              label={`Version`}
              formClassName={`mb-2`}
              rules={[{ required: true, message: "Please enter Version!" }]}
            />
          )}
          {changeTypeName === "Replace" && (
            <>
              <FormFields
                type={`input`}
                name={`replaceWith`}
                label={`Replace with`}
                formClassName={`mb-2`}
                rules={[{ required: true, message: "" }]}
              />

              <Button
                type={`primary`}
                icon={<PlusOutlined />}
                onClick={() => {
                  setOpenPortfolioList_changeType(true);

                  setChangeTypeName("Replace");

                  setChangeTypeModal_Edit(false);

                  let record = settingPortfolioList?.find(
                    (f) => f.name === portfolioName_ScenarioListData
                  );
                  //Dispatch Tag
                  dispatchTag({ type: "EMPTY_TAGS" });
                  dispatchTag({
                    type: "SET_MODULE_NAME",
                    payload: record?.moduleName,
                  });

                  setEditFlag(true);
                  setSelectedModuleItems({
                    moduleName: record?.moduleName,
                    name: record?.name,
                    portfolioId: record?.id,
                  });
                }}
              ></Button>

              
            </>
          )}
          <FormFields
            type={`textarea`}
            label={`Comment`}
            name={`comment`}
            formClassName={`mb-2`}
            rows={3}
          />
          <Form.Item
            label={`Deadline`}
            name="deadline"
            formClassName={`mb-2`}
            key={`deadline`}
            rules={[
              {
                required: false,
                message: "Please select deadline",
              },
            ]}
            onChange={(value) => {
              // setChangeTypeFormData({
              //   ...changeTypeFormData,
              //   ["deadline"]: value,
              // });
            }}
          >
            <DatePicker className="mb-2 text-start w-full" />
          </Form.Item>

          <Button
            type={`primary`}
            htmlType={`submit`}
            className="w-100"
            loading={changeTypeLoading}
          >
            Submit
          </Button>
        </Form>
      </Modal>

      {/* ApplicationList Model */}
      <Modal
        title={false}
        header={false}
        open={isOpenPortfolioList}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setShowFilter(false);
          setFilterData([]);
          setOpenPortfolioList(false);
          setSelectedModuleItems({});
          setSelectedFilters({});
        }}
        width={window.innerWidth / 1.3}
        // className="cu-modal-p-0"
        closable
        centered
      >
        <div className="popupbox_sec">
          <ApplicationList
            heading={selectedModuleItems?.name}
            setOpenPortfolioList={setOpenPortfolioList}
            isOpenPortfolioList={isOpenPortfolioList}
            selectedModuleItems={selectedModuleItems}
            selectedPortfolioItems={selectedPortfolioItems}
            setSelectedPortfolioItems={setSelectedPortfolioItems}
            // portfolioloading={loading}
            loading={loading}
            setLoading={setLoading}
            // setPortfolioLoading={setLoading}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            isOpenChoosePortfolioType={isOpenChoosePortfolioType}
            setOpenChoosePortfolioType={setOpenChoosePortfolioType}
            scenarioId={scenarioId}
            portfolioTypeForm={portfolioTypeForm}
            onFormSubmit={onFormSubmit}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
            changeTypeModal={changeTypeModal}
            setChangeTypeModal={setChangeTypeModal}
            changeTypeForm={changeTypeForm}
            changeTypeLoading={changeTypeLoading}
            setChangeTypeLoading={setChangeTypeLoading}
            portfolioItemIds={portfolioItemIds}
            showConfirmDeleteScenarioItem={showConfirmDeleteScenarioItem}
            settingPortfolioList={settingPortfolioList}
            setRelationshipPortfolioId={setRelationshipPortfolioId}
            getItems={getBusinessPortfolioListByModuleName}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            filterData={filterData}
            setFilterData={setFilterData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            totalRecords={totalRecords}
            setTotalRecords={setTotalRecords}
            changeTypeData={changeTypeData}
            setOpenPortfolioList_changeType={setOpenPortfolioList_changeType}
            setselectedItemIDRows={setselectedItemIDRows}
            filterSubmit={filterSubmit}
            setFilterSubmit={setFilterSubmit}
            selectedRelationFilters={selectedRelationFilters}
            setSelectedRelationFilters={setSelectedRelationFilters}
            {...props}
          />
        </div>
      </Modal>

      {/* ChangeTypeList Model */}
      <Modal
        title={false}
        header={false}
        open={isOpenPortfolioList_changeType}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setShowFilter(false);
          setFilterData([]);
          setOpenPortfolioList_changeType(false);
          setSelectedModuleItems({});
          setSelectedFilters({});
        }}
        width={window.innerWidth / 1.3}
        // className="cu-modal-p-0"
        closable
      >
        <div className="m-4 my-2 ">
          <ChangeTypeList
            heading={selectedModuleItems?.name}
            setOpenPortfolioList_changeType={setOpenPortfolioList_changeType}
            isOpenPortfolioList={isOpenPortfolioList}
            selectedModuleItems={selectedModuleItems}
            selectedPortfolioItems={selectedPortfolioItems}
            setSelectedPortfolioItems={setSelectedPortfolioItems}
            // portfolioloading={loading}
            loading={loading}
            setLoading={setLoading}
            // setPortfolioLoading={setLoading}
            page={page}
            setPage={setPage}
            limit={limit}
            setLimit={setLimit}
            isOpenChoosePortfolioType={isOpenChoosePortfolioType}
            setOpenChoosePortfolioType={setOpenChoosePortfolioType}
            scenarioId={scenarioId}
            portfolioTypeForm={portfolioTypeForm}
            onFormSubmit={onFormSubmit}
            selectedItemId={selectedItemId}
            setSelectedItemId={setSelectedItemId}
            changeTypeModal={changeTypeModal}
            setChangeTypeModal={setChangeTypeModal}
            changeTypeForm={changeTypeForm}
            changeTypeLoading={changeTypeLoading}
            setChangeTypeLoading={setChangeTypeLoading}
            portfolioItemIds={portfolioItemIds}
            showConfirmDeleteScenarioItem={showConfirmDeleteScenarioItem}
            settingPortfolioList={settingPortfolioList}
            setRelationshipPortfolioId={setRelationshipPortfolioId}
            setReplaceWith={setReplaceWith}
            getItems={getBusinessPortfolioListByModuleName}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            filterData={filterData}
            setFilterData={setFilterData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
            totalRecords={totalRecords}
            setTotalRecords={setTotalRecords}
            changeTypeData={changeTypeData}
            selectedItemIDRows={selectedItemIDRows}
            changeTypeModal_ChangeTypeList={changeTypeModal_ChangeTypeList}
            setChangeTypeModal_ChangeTypeList={
              setChangeTypeModal_ChangeTypeList
            }
            setSelectedItemReplacewithId={setSelectedItemReplacewithId}
            filterSubmit={filterSubmit}
            setFilterSubmit={setFilterSubmit}
            selectedRelationFilters={selectedRelationFilters}
            setSelectedRelationFilters={setSelectedRelationFilters}
            {...props}
          />
        </div>
      </Modal>

      <Modal
        // title={
        //   // <div className="d-flex justify-content-between popup_headerbx">
        //   //   <div>
        //   //     <PageTitleHeading text={"Integration List"} className={`my-0`} />
        //   //   </div>
        //   //   <div className="inputbx">
        //   //     <Button
        //   //       type={`primary`}
        //   //       htmlType="submit"
        //   //       loading={loading}
        //   //       onClick={SaveIntegrationData}
        //   //     >
        //   //       Save All Added Integrations
        //   //     </Button>
        //   //   </div>
        //   //   <div></div>
        //   // </div>
        // }
        centered
        open={integrationModal}
        onOk={() => setShowListModal(!showListModal)}
        onCancel={() => {
          setShowListModal(!showListModal);
          setPage(1);
          setLimit(default_pageSize);
          setSelectedFilters({});
          setSelectedRelationFilters({});
          localStorage.removeItem("selectedFilters");
          localStorage.removeItem("selectedFiltersRelation");
          setIntegrationModal(!integrationModal);
        }}
        width={window.innerWidth - 400}
        footer={false}
        closeIcon={true}
      >
        <div className="d-flex justify-content-between modelpopup_bx">
          <div id="portfolioList" className="container-fluid popup_leftpnlbx">
            <div className="popup_headerbx">
              <div className="inputsec_bx">
                <PageTitleHeading
                  text={"Integration List"}
                  className={`my-0`}
                />
              </div>
              <div className="inputbx">
                <Button
                  type={`primary`}
                  htmlType="submit"
                  loading={loading}
                  onClick={SaveIntegrationData}
                >
                  Submit
                </Button>
              </div>
              <div></div>
            </div>
            {/* <Row gutter={[8, 8]} className="mx-0 text-left mt-2">
              <Col span={24} className="p-2"> */}
            <Spin className="loading_bx" size="small" spinning={loading}>
              <div
                className="overflow-y listview_bx"
                style={{ maxHeight: modalContentHeight }}
              >
                <BusinessApplicationListCards
                  //cardModuleName={moduleName?.replace(" ", "")}
                  //moduleName={moduleNameTemp}
                  filterLevel={filterLevel}
                  filterText={filterText}
                  filterBy={filterBy}
                  //lists={listApplication}
                  lists={selectedPortfolioItems}
                  addRemove={true}
                  setAddedData={setAddedData}
                  addedData={addedData}
                  handleAddRelationship={handleAddRelationship}
                  handleRemoveRelationship={handleRemoveRelationship}
                  //dataLoading={dataLoading}
                  freeText={freeText}
                />
              </div>
              {totalRecords > 0 && (
                <Pagination
                  className="mt-2 text-right"
                  total={totalRecords}
                  showTotal={(total) => `Total ${total} items`}
                  defaultPageSize={default_pageSize}
                  defaultCurrent={page}
                  pageSizeOptions={default_pageSizeOptions}
                  showSizeChanger
                  onChange={(p, ps) => {
                    setFilterSubmit(true);
                    setLimit(ps);
                    setPage(p);
                    console.log("Page...", p);
                    console.log("PageLimit...", ps);
                  }}
                />
              )}
            </Spin>
            {/* </Col>
            </Row> */}
          </div>
          <div className="popup_rightpnlbx">
            {/* filter  */}
            <FilterRightSideBar
              activeGridStyle={1}
              selectedFilters={selectedFilters}
              portfolioData={filterData || []}
              setShowFilter={setShowFilter}
              showFilter={showFilter}
              setSelectedFilters={(value) => {
                setPage(1);
                setSelectedFilters(value);
              }}
              allowSlider={false}
              // optionalLevel={
              //   moduleName === staticSettingPortfolioList.businessCapability
              // }
              optionalLevel={
                selectedModuleItems?.name ===
                staticSettingPortfolioList.businessCapability
              }
              //Filter relations
              filterSubmit={filterSubmit}
              setFilterSubmit={(value) => {
                setPage(1);
                setFilterSubmit(value);
              }}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
              filterData={filterData}
              setFilterData={setFilterData}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ScenarioListV2;
