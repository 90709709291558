import { Bar } from "@ant-design/plots";
import React, { useEffect, useState } from "react";

const data = [
  {
    labelName: "",
    value: 30,
  },
];
const DCLineCharts = ({ portfolioData, data, ...props }) => {
  const config = {
    data,
    xField: "labelName",
    yField: "value",
    paddingRight: 0,
    paddingBottom: 0,
    marginLeft: 0,
    marginBottom: 0,
    style: {
      fill: portfolioData?.color,
    },
    children: [
      {
        type: "interval",
        yField: "value",
        style: { maxWidth: 80 },
        label: { position: "inside" },
        interaction: {
          elementHighlight: true,
          elementHighlightByColor: { background: true },
        },
      },
    ],
    markBackground: {
      label: {
        text: ({ originData }) => {
          return `${(originData.value / 1000) * 100}% | ${originData.value}`;
        },
        position: "right",
        dx: 80,
        style: {
          fill: "#aaa",
          fillOpacity: 1,
          fontSize: 14,
        },
      },
      style: {
        fill: "#eee",
      },
    },
    scale: {
      y: {
        domain: [0, 100],
      },
    },
    axis: {
      x: {
        tick: false,
        title: false,
      },
      y: {
        grid: false,
        tick: false,
        label: false,
        title: false,
      },
    },
    interaction: {
      elementHighlightByColor: false,
    },
    height: 40,
    autoFit: true,
  };

  return (
    <React.Fragment>
      <div className="w-100">
        <Bar {...config} />
        <h3>&nbsp;&nbsp;{portfolioData?.name}</h3>
      </div>
    </React.Fragment>
  );
};

export default React.memo(DCLineCharts);
