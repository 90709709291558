import React, { useEffect, useState } from 'react';
import { getMenuPortfolio, } from '../../../../utils/settingCommon';
import { Spin, message } from 'antd';
import { usePortfolioTag } from '../../../../Context/portfolioTagProvider';
import { usePortfolioSetting } from '../../../../Context/portfolioSettingProvider';
import usePortfolioFetcher from '../../../../hooks/usePortfolioFetcher';
import useFetchPortfolioTag from '../../../../hooks/useFetchPortfolioTag';
import { useGlobalTag } from '../../../../Context/GlobalTagProvider';
import useFetchGlobalProp from '../../../../hooks/useFetchGlobalProp';
import Delayed from '../../../../utils/Delayed';
import { useSaveState } from '../../../../Context/StateProviderData';
import TableFormData from '../bulkentry/TableFormData';
import { removeEmptyArrObjOrval } from '../../../../utils/Common';
import PortfolioSettingServices from '../../../../services/services/PortfolioSettingsService';
// set the defaults

const TabBulkEntry = ({moduleName,...props}) => {

    const [ portfolioData, setPortfolioData] = useState(null);
    const [ loading, setLoading] = useState(true);
    const [ loading2, setLoading2] = useState(true);
    const { fetchPortfolio } = usePortfolioFetcher();
    const { state: PortfolioSettingState, dispatch: PortfolioSettingDispatch } = usePortfolioSetting();
    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { fetchTagsData } = useFetchPortfolioTag();
    const { state: stateGlobalTag, dispatch: dispatchGlobalTag } = useGlobalTag(); 
    const { error:errorGTag, fetchGlobalPropsData  } = useFetchGlobalProp();
    const { state:portfolioState,setState } = useSaveState();
    
    useEffect(()=>{
        callAllData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[moduleName]);

    let callAllData = async () =>{
        await fetchAllPortfolio();
        let pm =await getMenuPortfolio({routeModuleName:moduleName});
        await fetchGlobalProp();
        await getFilteredPortfoliosMenus(pm);
        await setPortfolioData(pm);
        setLoading(false);
    }

    useEffect(() => {
        if (PortfolioSettingState?.data?.length) {
            if (portfolioData?.moduleName) {
                dispatchTag({ type: "EMPTY_TAGS" });
                dispatchTag({
                    type: "SET_MODULE_NAME",
                    payload: portfolioData?.moduleName,
                });
            }
        }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [PortfolioSettingState?.data]);  

    const fetchAllPortfolio = async () => {
        try {
            const response = await fetchPortfolio(PortfolioSettingDispatch,1,100,);
            if (response) {
                // message.success("Portfolio fetched successfully.");
            } else {
                message.error("Portfolio not found.");
            }
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    // Tag useEffect start
    useEffect(() => {
        if (stateTag?.moduleName) {
            fetchAllPortfolioTags();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateTag?.moduleName]);

    const fetchAllPortfolioTags = async () => {
        try {
            await fetchTagsData();
        } catch (error) {
            console.error("Error fetching portfolio:", error);
            message.error("Something went wrong.");
        }
    };

    const fetchGlobalProp = async () => {
        try {
            await fetchGlobalPropsData();
        } catch (error) {
            console.error("Error fetching Global Prop:", error);
            message.error("Something went wrong.");
        }
    };

    // Gloabl Properties
    useEffect(() => {
        if (stateGlobalTag) {
            setState({key:'globalProperties',value:stateGlobalTag?.data})
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stateGlobalTag]);

    const getFilteredPortfoliosMenus =async (portfolioMenus) => {
        try {
            setLoading(true);
            await portfolioMenus?.portfolioConnected.forEach(async (element,i) => {
                setLoading(true);
                let updatedFilter = {
                    moduleName: element?.moduleName || "",
                    sortBy: "displayname,ASC",
                };
                updatedFilter = removeEmptyArrObjOrval(updatedFilter);
                if(!portfolioState[element?.moduleName]){
                    const response = await PortfolioSettingServices?.getFilteredPortfolios( updatedFilter, 1, 20);
                    let results = [];
                    if(portfolioState[element?.moduleName]){
                        results=[...results,...portfolioState[element?.moduleName]];
                    }
                    if(response?.data?.data){
                        results=[...results,...response?.data?.data]
                    }
                    let items = [...new Map(results.map(item =>[item["id"], item])).values()]
                    await setState({key:element?.moduleName,value:items});
                }
                
                if((portfolioMenus?.portfolioConnected?.length-1)===i){
                    setLoading2(false);
                    setLoading(false);
                }
            });
        } catch (error) {
            console.log("error",error);
        } finally{
            setLoading(false);
        }
    }
    
    return (
        <React.Fragment>
            <Spin className="loading_bx" size="small" spinning={loading}>
                {!loading2 && <Delayed waitBeforeShow={2000}>
                    <TableFormData 
                        portfolioData={portfolioData}
                        bulkUpdateIds={props?.bulkUpdateIds}
                    />
                </Delayed>}
            </Spin>
        </React.Fragment>
    )
}

TabBulkEntry.defaultProps = {
    moduleName:"Integration",
}
export default TabBulkEntry;