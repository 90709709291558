import { DownloadOutlined, HistoryOutlined } from '@ant-design/icons';
import { render } from '@testing-library/react';
import { Button, Table } from 'antd';
import React from 'react';

const SummaryReportList = ({...props}) => {
    const columns = [
        {
            title: 'Business Capability',
            dataIndex: 'displayname',
            key: 'displayname',
        },
        {
            title: 'Action',
            dataIndex: 'id',
            key: 'id',
            width:200,
            render : (value,data) =>{
                if(value%2){
                    return <Button type="primary" shape="round" size={`small`} icon={<DownloadOutlined />}>Download</Button>;
                }else{
                    return <Button type="primary" shape="round" size={`small`} loading style={{ background: "#FF885B", borderColor: "#FF885B" }}>In Progress</Button>;;
                }
            }
        },
    ];

    return (
        <React.Fragment>
            <Table 
                size="small" 
                dataSource={dataSource} 
                columns={columns} 
            />;
        </React.Fragment>
    )
}

export default SummaryReportList

const dataSource = [
    {
      "id": 46,
      "referenceid": "413",
      "displayname": "Accounting & Bookkeeping",
      "attributes": {},
      "parentid": 6,
      "sourceid": 0,
      "level": "L2",
      "description": null,
      "levelid": "6.1",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-15T15:42:20.623Z",
      "updatedAt": "2024-09-15T15:42:20.623Z"
    },
    {
      "id": 44,
      "referenceid": "413",
      "displayname": "Advertising & Campaign Management",
      "attributes": {},
      "parentid": 5,
      "sourceid": 0,
      "level": "L2",
      "description": null,
      "levelid": "5.2",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-15T15:40:10.558Z",
      "updatedAt": "2024-09-15T15:40:10.558Z"
    },
    {
      "id": 15,
      "referenceid": "413",
      "displayname": "Behavioral Analysis",
      "attributes": {},
      "parentid": 11,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "1.1.2",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:31:13.510Z",
      "updatedAt": "2024-09-14T12:31:13.510Z"
    },
    {
      "id": 48,
      "referenceid": "413",
      "displayname": "Budgeting & Forecasting",
      "attributes": {},
      "parentid": 6,
      "sourceid": 0,
      "level": "L2",
      "description": null,
      "levelid": "6.3",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-15T15:43:14.993Z",
      "updatedAt": "2024-09-15T15:43:14.993Z"
    },
    {
      "id": 19,
      "referenceid": "413",
      "displayname": "Contact Information Management",
      "attributes": {},
      "parentid": 12,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "1.2.3",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:32:15.831Z",
      "updatedAt": "2024-09-14T12:32:15.831Z"
    },
    {
      "id": 53,
      "referenceid": "413",
      "displayname": "Contract Management",
      "attributes": {},
      "parentid": 49,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "7.1.2",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-15T15:45:38.269Z",
      "updatedAt": "2024-09-15T15:45:38.269Z"
    },
    {
      "id": 57,
      "referenceid": "413",
      "displayname": "Cost Analysis",
      "attributes": {},
      "parentid": 50,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "7.2.3",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-15T15:47:34.794Z",
      "updatedAt": "2024-09-15T15:48:04.506Z"
    },
    {
      "id": 13,
      "referenceid": "413",
      "displayname": "Customer Loyalty Programs",
      "attributes": {},
      "parentid": 1,
      "sourceid": 0,
      "level": "L2",
      "description": null,
      "levelid": "1.3",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:18:43.348Z",
      "updatedAt": "2024-09-14T12:18:43.348Z"
    },
    {
      "id": 1,
      "referenceid": "413",
      "displayname": "Customer Management",
      "attributes": {},
      "parentid": 0,
      "sourceid": 0,
      "level": "L1",
      "description": null,
      "levelid": "1",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T11:56:16.012Z",
      "updatedAt": "2024-09-14T11:56:16.012Z"
    },
    {
      "id": 12,
      "referenceid": "413",
      "displayname": "Customer Profile Management",
      "attributes": {},
      "parentid": 1,
      "sourceid": 0,
      "level": "L2",
      "description": null,
      "levelid": "1.2",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:18:25.544Z",
      "updatedAt": "2024-09-14T12:18:25.544Z"
    },
    {
      "id": 11,
      "referenceid": "413",
      "displayname": "Customer Segmentation",
      "attributes": {},
      "parentid": 1,
      "sourceid": 0,
      "level": "L2",
      "description": null,
      "levelid": "1.1",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:17:45.395Z",
      "updatedAt": "2024-09-14T12:17:45.395Z"
    },
    {
      "id": 10,
      "referenceid": "413",
      "displayname": "Customer Service & Support",
      "attributes": {},
      "parentid": 0,
      "sourceid": 0,
      "level": "L1",
      "description": null,
      "levelid": "10",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:01:23.325Z",
      "updatedAt": "2024-09-14T12:01:23.325Z"
    },
    {
      "id": 60,
      "referenceid": "413",
      "displayname": "Delivery Optimization",
      "attributes": {},
      "parentid": 51,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "7.3.3",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-15T15:49:21.795Z",
      "updatedAt": "2024-09-15T15:49:21.795Z"
    },
    {
      "id": 14,
      "referenceid": "413",
      "displayname": "Demographic Analysis",
      "attributes": {},
      "parentid": 11,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "1.1.1",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:19:15.232Z",
      "updatedAt": "2024-09-14T12:19:15.232Z"
    },
    {
      "id": 58,
      "referenceid": "413",
      "displayname": "Distribution Network Design",
      "attributes": {},
      "parentid": 51,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "7.3.1",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-15T15:48:30.151Z",
      "updatedAt": "2024-09-15T15:48:30.151Z"
    },
    {
      "id": 22,
      "referenceid": "413",
      "displayname": "Engagement Strategies",
      "attributes": {},
      "parentid": 13,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "1.3.3",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:33:02.168Z",
      "updatedAt": "2024-09-14T12:33:02.168Z"
    },
    {
      "id": 6,
      "referenceid": "413",
      "displayname": "Financial Management",
      "attributes": {},
      "parentid": 0,
      "sourceid": 0,
      "level": "L1",
      "description": null,
      "levelid": "6",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T11:59:48.089Z",
      "updatedAt": "2024-09-14T11:59:48.089Z"
    },
    {
      "id": 47,
      "referenceid": "413",
      "displayname": "Financial Reporting",
      "attributes": {},
      "parentid": 6,
      "sourceid": 0,
      "level": "L2",
      "description": null,
      "levelid": "6.2",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-15T15:42:41.996Z",
      "updatedAt": "2024-09-15T15:42:41.996Z"
    },
    {
      "id": 37,
      "referenceid": "413",
      "displayname": "Fraud Detection",
      "attributes": {},
      "parentid": 27,
      "sourceid": 0,
      "level": "L3",
      "description": null,
      "levelid": "3.2.3",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T13:07:16.462Z",
      "updatedAt": "2024-09-14T13:07:16.462Z"
    },
    {
      "id": 8,
      "referenceid": "413",
      "displayname": "Human Resources Management",
      "attributes": {},
      "parentid": 0,
      "sourceid": 0,
      "level": "L1",
      "description": null,
      "levelid": "8",
      "isDeleted": false,
      "scenarioId": null,
      "sourceItemId": null,
      "scenarioitemid": 0,
      "sourceItemType": null,
      "createdAt": "2024-09-14T12:00:33.168Z",
      "updatedAt": "2024-09-14T12:00:33.168Z"
    }
]