import React, { useEffect, useState } from "react";
import { Card, Checkbox, Button, message, Input } from "antd";
import { normalizeString } from "../../utils/linksCommon";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { useDebounce } from 'use-debounce';
import { checkRole } from "../../helper/useUserData";

const ConnectionTab = ({ selectedMenuText, items, selectedMenu, fetchAllPortfolio }) => {
  const { state, dispatch } = usePortfolioSetting();
  const [checkedValues, setCheckedValues] = useState([]);
  const [loading, setLoading] = useState(false);
  const [sortConnections, setSortConnections] = useState("ASC");
  const [searchText, setSearchText] = useState("");
  const [currentTabItem, setcurrentTabItem] = useState(null);
  const [newCurrentTabItem] = useDebounce(currentTabItem, 100);

  useEffect(()=>{
    console.log("item updated",items);
  },[items])

  useEffect(() => {
    const initialCheckedValues = items
      ?.filter((item) => item.checked)
      ?.map((item) => item.id);
    setCheckedValues(initialCheckedValues || []);
  }, [items]);

  useEffect(() => {
    if (items) {
      setcurrentTabItem(items);
    }
  }, [items]);

  const searchByTextOrder = (value) => {
    if (value === undefined || value === null || value === "") {
      // If no value is provided, reset to the original list
      setcurrentTabItem(items); // Assuming you have an originalTabItem to reset to
    } else {
      // Filter items by name including the value
      const filteredItems = currentTabItem.filter((item) =>
        item.name?.toLowerCase().includes(value?.toLowerCase())
      );
      setcurrentTabItem(filteredItems);
    }
  };

  const aceOrder = () => {
    setcurrentTabItem(
      [...currentTabItem]?.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    );
  };

  const deceOrder = () => {
    setcurrentTabItem(
      [...currentTabItem]?.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      })
    );
  };


  const handleCheckboxChange = (value) => {
    const isChecked = checkedValues?.includes(value);
    if (isChecked) {
      setCheckedValues(checkedValues?.filter((item) => item !== value));
    } else {
      setCheckedValues([...checkedValues, value]);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const findPortfolio =
        state?.data?.find((item) => item?.id === selectedMenu) || {};
      if (!findPortfolio) {
        return message.error("Portfolio not found. Please try again.");
      }

      findPortfolio.portfolioConnectedIds = checkedValues;
      const response = await PortfolioSettingServices.update(
        selectedMenu,
        findPortfolio
      );
      const newData = response?.data || null;
      message.success("Updated Successfully");
      await dispatch({ type: "UPDATE_PORTFOLIO_SETTING", payload: newData });
      fetchAllPortfolio()
    } catch (error) {
      message.error("Failed to update connection. Please try again.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };


  return (
    <div>
      {/* <div className="h5 mb-0">{selectedMenuText} Relations</div> */}
      <div>
        ({selectedMenuText} can be connected to the following portfolio types.)
      </div>
      <Card
        title={
          <>
            {sortConnections === "ASC" ? (
              <SortAscendingOutlined
                className="cursor-pointer"
                onClick={() => {
                  setSortConnections("DESC");
                  deceOrder();
                }}
              />
            ) : (
              <SortDescendingOutlined
                className="cursor-pointer"
                onClick={() => {
                  setSortConnections("ASC");
                  aceOrder();
                }}
              />
            )}
          </>
        }
        className={`application-tab-card cu-border my-2`}
        styles={{body:{ padding: 5 }}}
        extra={
          <div className="d-flex justify-content-end">
            <Input.Search
              style={{ width: "150px", marginLeft: "20px" }}
              placeholder="Search"
              onChange={(e) => {
                if(e.target.value.length === 0){
                  searchByTextOrder(e.target.value);
                }
              }}
              onSearch={(value) => {
                searchByTextOrder(value);
              }}
              onPressEnter={(e) => {
                e.stopPropagation()
                searchByTextOrder(e.target.value);
              }}
            ></Input.Search>
          </div>
        }
      >
        <div
          className={`overflow-y p-2`}
          style={{ height: window.innerHeight / 2 }}
        >
          {newCurrentTabItem && newCurrentTabItem.length ? (
            newCurrentTabItem.map((item, index) => {
              if(item.id != selectedMenu){
              return (
              <div className="card card-body p-2 mb-2" key={index}>
                <div>
                  <Checkbox
                    checked={checkedValues.includes(item.id)}
                    onChange={() => handleCheckboxChange(item.id)}
                  >
                    {normalizeString(item.name)}
                  </Checkbox>
                </div>
              </div>
            )}})
          ) : (
            <div className="line-height-normal my-5">
              <div className="fs-4">No Current Connections</div>
            </div>
          )}
        </div>
      </Card>
      {checkRole('save') && <Button
        type="primary"
        className="pull-right"
        onClick={handleSubmit}
        disabled={loading}
        loading={loading}
      >
        Save
      </Button>}
    </div>
  );
};

export default ConnectionTab;
