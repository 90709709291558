import { useEffect } from "react";
import TemplateService from "../services/services/TemplateService";
import { useGlobalTag } from "../Context/GlobalTagProvider";

const useFetchGlobalProp = (addAssociation = false) => {
  const { state, dispatch } = useGlobalTag();
  const { moduleName } = state;
  const { loading, error } = state;

  const fetchGlobalPropsData = async () => {
     
    dispatch({ type: "SET_LOADING" });
    try {


      const response = await TemplateService.getTemplateList();
      const newData = response.data.data; 

      setTimeout(() => {
          dispatch({
            type: "SET_ALL_TAGS",
            payload: newData,
          });
      }, 400);
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch({
        type: "SET_ERROR",
        payload: error.message || "Failed to fetch data.",
      });
    }
  };

  useEffect(() => {
    }, [dispatch ]);

  return { loading, error, fetchGlobalPropsData };
};

export default useFetchGlobalProp;
