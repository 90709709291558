import React from 'react'
import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { 
    Row, 
    Col,
    Select,
    message,
    Tooltip,
    Typography,
    Spin,
} from "antd";
import './LevelGroupingMaster.scss';
import LGContent from './LGContent';
import LGIndicators from './LGIndicators';
// import LGSamples from './LGSamples';
import ScenarioServices from "../../services/services/ScenarioServices";

const colors = {
    'active' : '#52c41a',
    'phaseIn' : '#ffc107',
    'phaseOut' : '#a44ca4',
    'endOfLife' : '#ff0000',
}


function LevelGroupingMaster(props) {
    const [lgData,setLgData] = useState({});
    const [loading,setLoading] = useState(false);

    useEffect(()=>{
        setLgData({});
        getDrawSwimlane();
        
    },[props]);

    const getDrawSwimlane = () =>{
        let obj = {};
        obj.scenarioId = Number(props?.pathData?.scenarioId);
        obj.landscapeType = "Application";
        obj.level1ColumnId = props?.pathData?.columns?.find(f=>f.columnName?.toLowerCase()==='business area')?.id || "";
        obj.level2ColumnId = props?.pathData?.columns?.find(f=>f.columnName?.toLowerCase()==='business capability')?.id || "";
        obj.appColumnId = props?.pathData?.columns?.find(f=>f.columnName?.toLowerCase()==='application')?.id || "";
        obj.appIdColumnId = props?.pathData?.columns?.find(f=>f.columnName?.toLowerCase()==='application id')?.id || "";
        
        setLoading(true);
        ScenarioServices.getDrawSwimlane(obj)
        .then((response) => {
            if(response.data.code===409){
                setLgData({});
                message.error(response.data.message);
            }else{
                setLgData(response?.data || {});
            }
            setLoading(false);
        })
        .catch((err) => {
            message.error(err.message);
            setLoading(false);
        });
    }

    return (
        <div className='text-left level-grouping-master'>
            <Spin className="loading_bx" size="small" spinning={loading}>
                <Row className='mb-2'>
                    <Col span={24}>
                        <Typography.Title level={5} className="text-start py-2">

                            <Tooltip title="Add New Level 1" className="pull-right ">
                                <div>
                                <Link to={props?.pathData?.from?.pathname || '/scenario-list'} className="pull-right btn btn-sm btn-outline-secondary text-secondary ml-2"><i className="fa fa-arrow-left" ></i> Back</Link>
                                </div>
                            </Tooltip>
                        </Typography.Title>
                    </Col>
                </Row>
                <Row className='bg-theme mx-0'>
                    <Col span={12} className='h4 px-3 py-1 mb-0'>
                        Level 1 Grouping&emsp;
                        <Select 
                            defaultValue={'Area'} 
                            options={[{ value: 'area', label: <span>Area</span> }]} 
                            style={{minWidth:'150px'}}
                        />
                    </Col>
                    <Col span={12} className='h4 px-3 py-1 mb-0'>
                        Level 2 Grouping&emsp;
                        <Select 
                            defaultValue={'business-capabilities'} 
                            options={[{ value: 'business-capabilities', label: <span>Business Capabilities</span> }]} 
                            style={{minWidth:'150px'}}
                        />
                    </Col>
                    
                </Row>
                <Row className='text-left'>
                    <Col span={24}>
                        <LGContent colors={colors} lgData={lgData}/>
                        <LGIndicators colors={colors}/>
                    </Col>
                </Row>
            </Spin>
        </div>
    )
}

export default LevelGroupingMaster
