import React from 'react';
import Settings from '../components/settinngs/Settings';

const SettingsPage = (props) => {
    return (
        <>
            <Settings/>
        </>
    )
}

export default SettingsPage