import { notification } from "antd";

export const notificationType = {
  success: "success",
  error: "error",
  info: "info",
  warning: "warning",
  open: "open",
  destroy: "destroy",
};
//job status messages object
export const jobStatusMessages = {
  started: {
    message: "The job has started",
    description: "The job has started and progress will update you after completing the job",
  },
  pending: {
    message: "The job is pending",
    description: "The job is awaiting further action",
  },
  inProgress: {
    message: "The job is currently in progress",
    description: "The job is ongoing and updates will follow",
  },
  completed: {
    message: "The job has been successfully completed",
    description: "The job is finished and no further action is required",
  },
  failed: {
    message: "The job has failed",
    description: "There was an error and the job did not complete",
  },
  cancelled: {
    message: "The job has been cancelled",
    description: "The job will not continue and has been stopped",
  },
  onHold: {
    message: "The job is currently on hold",
    description: "The job is paused and will resume later",
  },
  scheduled: {
    message: "The job has been scheduled",
    description: "The job is planned to start at a specified time",
  },
  retrying: {
    message: "The job is being retried",
    description: "The job is attempting to run again after a failure",
  },
  queued: {
    message: "The job is queued and awaiting processing",
    description: "The job is in line to be processed soon",
  },
};

export const placements = {
  top: "top",
  topLeft: "topLeft",
  topRight: "topRight",
  bottom: "bottom",
  bottomLeft: "bottomLeft",
  bottomRight: "bottomRight",
};

export const showNotification = (
  type,
  message,
  description,
  duration = 3,
  placement = placements.topRight,
  showProgress = false
) => {
  notification[type]({
    message,
    description,
    duration,
    placement,
    pauseOnHover: true,
    showProgress,
  });
};
