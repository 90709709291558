import React from 'react';
import plantUml from "./../../../assets/plantuml.png"

const IntegrationGrid = ({HeaderContnet}) => {
    return (
        <React.Fragment>
            {HeaderContnet}
            <div className='text-center'>
                <img src={plantUml} alt='Intigration View'/>
            </div>
        </React.Fragment>
    )
}

IntegrationGrid.defaultProps = {
    HeaderContnet:()=>{}
}

export default IntegrationGrid