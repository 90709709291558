import { DatePicker, Form, Input, InputNumber, Select } from 'antd'
import React, { useEffect, useState } from 'react'

const FormField = ({field,...props}) => {
    const [initialValue,setInitialValue] = useState();
    const [name,setName] = useState();

    useEffect(()=>{
        if(field?.type==="Multi select"){
            setInitialValue([]);
        }else if(field?.type==="Numeric"){
            setInitialValue(0);
        }

        if(props?.index){
            setName([props?.index,field?.name])
        }else{
            setName(field?.name)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[field])

    return <React.Fragment>
        <Form.Item 
            name={name} 
            className='m-0'
            rules={[{required:field?.isRequired,message: "",}]}
            initialValue={initialValue}
        >
            {
                ((()=>{
                    if(field?.type==="Free text"){
                        return <Input 
                            placeholder={field?.displayName} 
                        />
                    }else if(field?.type==="Single select"){
                        return <Select 
                            showSearch
                            allowClear
                            placeholder={field?.displayName} 
                            options={field?.propertyOptions?.map(item=>{
                                return {
                                    value:item?.id,
                                    label:item?.name,
                                    item
                                }
                            })}
                        />
                    }else if(field?.type==="Multi select"){
                        return <Select 
                            showSearch
                            allowClear
                            mode='multiple'
                            placeholder={field?.displayName} 
                            options={field?.propertyOptions?.map(item=>{
                                return {
                                    value:item?.id,
                                    label:item?.name,
                                    item
                                }
                            })}
                        />
                    }else if(field?.type==="Date"){
                        return <DatePicker 
                            placeholder={field?.displayName} 
                        />
                    }else if(field?.type==="Time"){
                        return <DatePicker 
                            placeholder={field?.displayName} 
                            picker={`time`}
                        />
                    }else if(field?.type==="Date time"){
                        return <DatePicker 
                            placeholder={field?.displayName} 
                            showTime
                        />
                    }else if(field?.type==="Numeric"){
                        return <InputNumber
                            placeholder={field?.displayName} 
                            style={{width:"100%"}}
                        />
                    }else if(field?.type==="Long text"){
                        return <Input.TextArea
                            rows={props?.rows || 1}
                            placeholder={field?.displayName} 
                        />
                    }else{
                        return <></>
                    }
                })())
            }
        </Form.Item>
    </React.Fragment>
}

FormField.defaultProps = {}

export default FormField