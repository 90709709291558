import React from 'react';
import FormField from '../../common/FormField';
import ModuleDropdown from './ModuleDropdown';
import AppsDropdown from './AppsDropdown';
import { getMenuPortfolio } from '../../../../utils/settingCommon';

export const columnsRelationPortfolio = [
    "dataobject",
    "business_processes",
    "informationobject",
]

const UpdateColumnData = ({columns,setColumns,portfolioData,form,state,...props}) => {
    
    let tempColumns = [];
    // Source App
    let sourceAppCol = { 
        title: "Source App", 
        dataIndex: Math.random(),
        fieldName: "sourceApp",  
        moduleName: "business_application",
        fieldType:"Custom Single select Module",
        width:'250px',
        filters:[],
        render: (text, record, index) =>{
            return <AppsDropdown title={"Source App"} name="sourceApp" index={record?.key} form={form}/>
        }
    }
    tempColumns.push(sourceAppCol);

    // Target App
    let targetAppCol = { 
        title: "Target App", 
        dataIndex: Math.random(),
        fieldName: "targetApp",
        moduleName: "business_application",
        fieldType:"Custom Single select Module",
        width:'250px',
        filters:[],
        render: (text, record, index) =>{
            return <AppsDropdown title={"Target App"} name="targetApp" index={record?.key} form={form}/>
        }
    }
    tempColumns.push(targetAppCol);

    // Portfolio Items
    portfolioData?.portfolioSections.sort((a, b) => a.position - b.position)?.forEach(col => {
        col?.properties.sort((a, b) => a.position - b.position)?.forEach(col1=>{
            let colData = { 
                title: col1?.displayName, 
                dataIndex: Math.random(),
                fieldName: col1?.name,
                fieldType: col1?.type,
                ellipsis:true ,
                data:col1,
                width:150,
                filters:[],
                render: (text, record, index) =>{
                    return <FormField 
                        field={col1} 
                        index={record?.key} 
                        form={form} 
                    />
                }
            }
            tempColumns.push(colData);
        })
    });

    // Tags
    if(state?.tags && state?.tags[portfolioData?.moduleName]){
        let colData = { 
            title: "Tags", 
            dataIndex: Math.random(),
            fieldName: "tags",
            fieldType:"Multi select",
            data: {
                name:"tags",
                displayName:"Tags",
                type:"Multi select",
                propertyOptions:state?.tags[portfolioData?.moduleName],
            },
            width:150,
            filters:[],
            render: (text, record, index) =>{
                return <FormField 
                    field={{
                        name:"tags",
                        displayName:"Tags",
                        type:"Multi select",
                        propertyOptions:state?.tags[portfolioData?.moduleName],
                    }} 
                    index={record?.key}
                    form={form} 
                />
            }
        }
        tempColumns.push(colData);
    }

    // Global Tags
    state?.globalProperties?.forEach((item)=>{
        let colData = { 
            title: item?.templatename, 
            dataIndex: Math.random(),
            fieldName: item?.templatepropname, 
            fieldType: item?.type, 
            width:150,
            filters:[],
            data: {
                name:item?.templatename,
                displayName:item?.templatepropname, 
                type:item?.type, 
                // eslint-disable-next-line no-useless-computed-key
                propertyOptions:item?.templateOption?.map(item=>({...item,["name"]:item?.option})),
            },
            render: (text, record, index) =>{
                return <FormField 
                    field={{
                        name:item?.templatepropname, 
                        displayName:item?.templatename,
                        type:"Multi select",
                        // eslint-disable-next-line no-useless-computed-key
                        propertyOptions:item?.templateOption?.map(item=>({...item,["name"]:item?.option})),
                    }} 
                    index={record?.key}
                    form={form} 
                />
            }
        }
        tempColumns.push(colData);
    })

    // Relationship Portfolio
    if(portfolioData?.portfolioConnected?.length){
        portfolioData?.portfolioConnected?.forEach(portfolio => {
            // let portfolioMenu = getMenuPortfolio({moduleName});
            if(portfolio?.moduleName!=="business_application"){  
                let colPortfolio = { 
                    title: portfolio?.name, 
                    dataIndex: Math.random(),
                    fieldName: portfolio?.moduleName,  
                    fieldType:"Custom Single select Module",
                    width:'280px',
                    filters:[],
                    render: (text, record, index) =>{
                        return <ModuleDropdown moduleName={portfolio?.moduleName} index={record?.key} form={form} characterLimit={15} mode={"multiple"}/>
                    }
                }
                tempColumns.push(colPortfolio);
            }
        });
    }

    setColumns(tempColumns);
    return false;
}

UpdateColumnData.defaultProps = {
    showTags:true,
    showGloabalTags:true,
    columns:[],
    setColumns:()=>{},
    form:()=>{},
    state:{},
}

export default UpdateColumnData
