import { Col, Divider, Form, Row, Select, Typography } from 'antd';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { getMenuPortfolio } from '../../../../utils/settingCommon';
import UpdateGlobalState from '../../common/UpdateGlobalState';
import PortfolioSettingServices from '../../../../services/services/PortfolioSettingsService';
import { removeEmptyArrObjOrval } from '../../../../utils/Common';
const ExtraFormFields = ({moduleName,form,formData}) => {
    const {state,updateLocale} = UpdateGlobalState();
    const [portfolioInfo,setportfolioInfo] = useState({});
    const [page,setPage] = useState(0);
    const [filter,setFilter] = useState({});

    useEffect(() => {
        let p = getMenuPortfolio({moduleName})
        setportfolioInfo(p);

        if(!state[p?.moduleName]){
            fetchFilteredData();
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [moduleName]);

    useEffect(() => {
        if(formData?.parent_data){
            updateLocale({key:moduleName,items:[formData?.parent_data]});
        }        
    }, [formData])

    const fetchFilteredData = async () => {
        try {
            if (moduleName) {
                let updatedFilter = {
                    moduleName: moduleName || "",
                    sortBy: "displayname,ASC",
                    ...filter,
                };
                
                updatedFilter = removeEmptyArrObjOrval(updatedFilter);
                const response = await PortfolioSettingServices?.getFilteredPortfolios( updatedFilter, page+1, 20);
                let responseItems = response?.data?.data;
                setPage(Number(response?.data?.currentPage));                
                if (responseItems?.length>0) {
                    let items = [...new Map(responseItems?.map(item =>[item["id"], item])).values()]    
                    updateLocale({key:moduleName,items});
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    var timeout = null;
    const onSearch = (value) => {
        setPage(0)
        setFilter({name:value})
        if (timeout) {  
            clearTimeout(timeout);
        }
        timeout = setTimeout(function() {
            fetchFilteredData()//this is your existing function
        }, 2000);
    }
    
    return (
        <React.Fragment>
            {
                ((()=>{
                    if(portfolioInfo?.view_type?.split(",").includes("tree_view")){
                        return <React.Fragment>
                            <Row gutter={[16, 24]} className='mb-2'>
                                <Col span={8} className="gutter-row">
                                    <Typography.Title level={5} >Parent</Typography.Title>
                                </Col>
                                <Col span={16} className="gutter-row">
                                    <Form.Item name="parent_id">
                                        <Select
                                            showSearch
                                            allowClear
                                            onSearch={onSearch}
                                            filterOption={(inputValue, currOption)=>{
                                                return `${currOption?.option?.displayname || ""}`?.toLowerCase().includes(inputValue?.toLowerCase())
                                            }}
                                            options={
                                                state[portfolioInfo?.moduleName]?.map(option=>{
                                                    return {
                                                        value: option?.id?.toString(),
                                                        label: option?.displayname,
                                                    }
                                                }).filter(f=>(Number(f.value)!==Number(formData?.id)))
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Divider />
                        </React.Fragment>
                    }
                    return <></>
                })())
            }
            
        </React.Fragment>
    )
}

// ExtraFormFields.defaultProps = {}

export default ExtraFormFields