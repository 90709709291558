import { useEffect } from "react";
import PortfolioTagServices from "../services/services/PortfolioTagService";
import { usePortfolioTag } from "../Context/portfolioTagProvider";
import { useSaveState } from "../Context/StateProviderData";

const useFetchPortfolioTag = (addAssociation = false) => {
  const { state, dispatch } = usePortfolioTag();
  const { moduleName } = state;
  const { loading, error } = state;
  const { setState:setTagState,state:portfolioState } = useSaveState();

  const fetchTagsData = async () => {
    if (!moduleName) {
      dispatch({
        type: "SET_ERROR",
        payload: "Module name is required for fetching portfolio tags.",
      });
      return;
    }
    dispatch({ type: "SET_LOADING" });
    try {
      const response = await PortfolioTagServices.getAllTags({
        moduleName,
        addAssociation,
      });
      const newData = response.data.data;
      setTimeout(() => {
          dispatch({
            type: "SET_ALL_TAGS",
            payload: newData,
          });
          
          if(newData?.length>0){
            if(!portfolioState?.tags){
              portfolioState['tags'] = {};
            }
            portfolioState['tags'][moduleName]=newData;
            setTagState(portfolioState);
          }
      }, 400);
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch({
        type: "SET_ERROR",
        payload: error.message || "Failed to fetch data.",
      });
    }
  };

  useEffect(() => {
    // fetchTagsData();
  }, [dispatch, moduleName, addAssociation]);

  return { loading, error, fetchTagsData };
};

export default useFetchPortfolioTag;
