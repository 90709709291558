import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Collapse,
  Checkbox,
  Select,
  Input,
  Button,
  DatePicker,
  Flex,
  Tooltip,
  message,
} from "antd";
import FilterIndicator from "./FilterIndicator";
import { normalizeString } from "../../../utils/linksCommon";
import {
  allowedTypes,
  constantFilterSearch,
  dateYearValidation,
  filterPortfolioData,
  isPascalCase,
  numberRangeMax,
  numberRangeMin,
  removeEmptyArrObjOrval,
  removeEmptyKeys,
  sortArray,
  transformText,
} from "../../../utils/Common";
import moment from "moment/moment";
import NumberRangeComponent from "./NumberRangeComponent";
import { DownOutlined, InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import { usePortfolioTag } from "../../../Context/portfolioTagProvider";
import FilterTag from "./FilterTag";
import FilterRelationship from "./FilterRelationship";
import SearchContent from "./SearchContent";
import useFetchGlobalProp from "../../../hooks/useFetchGlobalProp";
import { useGlobalTag } from "../../../Context/GlobalTagProvider";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import { getMenuPortfolio } from "../../../utils/settingCommon";
const { RangePicker } = DatePicker;

const { Option } = Select;
const { Panel } = Collapse;
let initialSortBy = [
  {value: "displayname",title: "Name",},
  {value: "id",title: "ID",},
];

const defaultlevelComponent = {
  label: "Level",
  id: "1",
  options: [
    {
      name: "L1",
      id: "L1",
      default: true,
    },
    {
      name: "L2",
      id: "L2",
      default: false,
    },
    {
      name: "L3",
      id: "L3",
      default: false,
    },
  ]
};
const FilterContent = React.memo(
  ({
    lifeCycleStagesColors,
    activeGridStyle = 1,
    portfolioData,
    setSelectedFilters,
    selectedFilters,
    sortValues = [],
    optionalLevel = false,
    setSwimLaneSelectedFilters,
    setFilterSubmit= () => false,
    filterSubmit=false,
    selectedRelationFilters,
    setSelectedRelationFilters,
    showSortBy,
    isRelationPopup = false,
    isSourceTargetModuleName="",
    tagsDataSourceTarget=[],
    isRelationModuleName=""
  }) => {
    const [openPanels, setOpenPanels] = useState([-1]);

    const [sortByList, setSortByList] = useState([]);
    const [sortByColumn, setSortByColumn] = useState("");
    const [sortByOrder, setSortByOrder] = useState("DESC");

    const [search, setSearch] = useState("");

    // const [minValue, setMinValue] = useState(numberRangeMin);
    // const [maxValue, setMaxValue] = useState(numberRangeMax);
    const [numberRanges, setNumberRanges] = useState({});
    const [minInputFocused, setMinInputFocused] = useState(false);
    const [maxInputFocused, setMaxInputFocused] = useState(false);

    // Tag fetching start
    // get all settings data
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
      usePortfolioSetting();
    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { state: stateGlobalTag, dispatch: dispatchGlobalTag } = useGlobalTag();
    const { error: errorGTag, fetchGlobalPropsData } = useFetchGlobalProp();
    
    const { loading: tagLoading, error: tagError } = stateTag;
    const [allTagsList, setAllTagsList] = useState([]);
    const [shouldReset, setShouldReset] = useState(false);
    const [resetRelation, setResetRelation] = useState(false);
    const [optionFields, setOptionFields] = useState([]);
    const [filteredPortfolioData, setFilteredPortfolioData] = useState([]);
    const [getSelectedFiltersPropInside, setGetSelectedFiltersPropInside] = useState([]);

    //global tag data
    const [gTOptionFields, setGTOptionFields] = useState([]);
    const [gTFiltered, setGTFiltered] = useState([]);
    const [levelComponent, setLevelComponent] = useState(defaultlevelComponent);

    const [propertyFilterActive, setPropertyFilterActive] = useState(false);

    const menuDetail= JSON.parse(sessionStorage.getItem("sessionMenuID"));
    const storeExitsModule = localStorage.getItem("storeExitsModule");
    if(menuDetail && storeExitsModule != menuDetail?.moduleName) {
      localStorage.removeItem("selectedFilters");
      localStorage.removeItem("selectedFiltersRelation");
    }

    const fetchGlobalProp = async () => {
      try {
        await fetchGlobalPropsData();
      } catch (error) {
        console.error("Error fetching Global Prop:", error);
        message.error("Something went wrong.");
      }
    };
    useEffect(() => {
      setAllTagsList(tagsDataSourceTarget?.data?.data ? tagsDataSourceTarget?.data?.data : stateTag.data);
      const checkModuleName = isRelationModuleName != '' ? isRelationModuleName.toLowerCase().replace(" ", '_') : stateTag.data[0]?.moduleName;

      if(sortValues.length < 1)
      {
        if((checkModuleName == 'dataobject' || checkModuleName == 'data_object' || checkModuleName == 'initiative' || checkModuleName == 'plant' || checkModuleName == 'organization' || checkModuleName == 'informationobject' || checkModuleName == 'information_object' || checkModuleName == 'integration' || checkModuleName == 'objective_okr' || checkModuleName == 'objective(okr)' || checkModuleName == 'technology') && isSourceTargetModuleName == '')
        {
          initialSortBy = [
            {value: "displayname",title: "Name",},
          ];
          setSortByList(initialSortBy);
        }
        else if((checkModuleName == 'business_capability' || checkModuleName == 'business_processes' || checkModuleName == 'business_process' || checkModuleName == 'apqc_process') && isSourceTargetModuleName == '')
        {
          initialSortBy = [
            {value: "level",title: "Level",},
            {value: "displayname",title: "Name",},
          ];
          setSortByList(initialSortBy);
        }
        else if(checkModuleName == 'business_application' || isSourceTargetModuleName == 'business_application' || checkModuleName == 'application'){
          initialSortBy = [
            {value: "displayname",title: "Name",},
            {value: "id",title: "ID",},
          ];
          setSortByList(initialSortBy);
        }
      }
    }, [stateTag?.data,tagsDataSourceTarget?.data,isRelationModuleName]);
    // Tag fetching end

    useEffect(() => {
      if (Object.keys(selectedFilters)?.length > 0) {
        const keyExists = "sortBy" in selectedFilters;
        if (!keyExists) {
          setSelectedFilters({
            ...selectedFilters,
            ["sortBy"]: sortValues?.length
              ? `${sortValues[0]?.value},ASC`
              : `${initialSortBy[0]?.value},ASC` || "id,ASC",
          });
        }
      }
    }, [selectedFilters]);

    useEffect(() => {
      if (sortValues?.length) {
        setSortByList(sortValues);
      } else {
        setSortByList(initialSortBy);
      }
    }, [sortValues]);

    

    useEffect(() => {
      if (Object.keys(selectedFilters)?.length === 0) {
        setOpenPanels([-1]);
        setSearch("");
      }
    }, [selectedFilters]);


    useEffect(() => {
      if (filterSubmit) {
        const timer = setTimeout(() => {
          setFilterSubmit(false);
          setShouldReset(true);
        }, 1000);
        return () => clearTimeout(timer);
      }
    }, [filterSubmit]);

    useEffect(() => {

      const filteredData = filterPortfolioData(portfolioData, allowedTypes);
      setFilteredPortfolioData(filteredData?.map((item)=>{return {...item,isGlobalTem:false}}));
      if (portfolioData && portfolioData?.length > 0) {
        const newOptionFields = portfolioData
          .filter((item) => item.type === "Free text" && item.name)
          .map((item) => ({
            id: item.id,
            displayName: item.displayName,
            name: item.name,
            label: item.displayName,
            value: item.name,
            portfolioId: item.portfolioId,
            sectionId: item.sectionId,
            isGlobalTem:false,
          }));
        setOptionFields(newOptionFields);
        const findConstant = newOptionFields?.find((item)=>item?.name === constantFilterSearch);

        // setSelectedFilters({
        //   ...selectedFilters,
        //   searchFields:[findConstant?.name],
        //   searchAllFields: [...newOptionFields?.map((item) => item?.name)],
        // });
      }
    }, [menuDetail?.moduleName, portfolioData]);

    //Global templates
    useEffect(() => {
      if (stateGlobalTag?.data && stateGlobalTag?.data?.length > 0) {
        const filteredGT = filterPortfolioData(
          stateGlobalTag?.data?.map((item) => {
            return {
              ...item,
              name: item?.templatepropname,
              isGlobalTem: true,
              displayName: item?.templatename,
              propertyOptions: item?.templateOption?.map((to) => {
                return { ...to, name: to?.option };
              }),
            };
          }),
          allowedTypes
        );

        if (filteredGT?.length > 0) {
          setGTFiltered(filteredGT)
        }

        const newOptionGTFields = stateGlobalTag?.data
          .filter((item) => item.type === "Free text" && item.templatepropname)
          .map((item) => ({
            id: item.id,
            displayName: item.templatename,
            name: item.templatepropname,
            label: item.templatename,
            value: item.templatepropname,
            isGlobalTem: true,
          }));
        if (newOptionGTFields?.length > 0) {
          setGTOptionFields(newOptionGTFields);
        }
      }else{
        if (!stateGlobalTag?.completed) {
          fetchGlobalProp();
        }
      }
    }, [stateGlobalTag?.data]);
    

    useEffect(()=>{
      getLevelComponent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[menuDetail?.moduleName,isRelationPopup,isSourceTargetModuleName,isRelationModuleName])
    
    const getLevelComponent = async () => {
      try {
        let m = {};        
        if(isRelationModuleName !==""){
          m = getMenuPortfolio({name:isRelationModuleName});
        }else if(isSourceTargetModuleName){
          m = getMenuPortfolio({moduleName:isSourceTargetModuleName});
        }else{
          m = getMenuPortfolio({routeModuleName:menuDetail?.moduleName});
        }
        
        if(optionalLevel){ 
          let res = await PortfolioSettingServices?.getFilteredLevels(m?.moduleName);
          let levels =res?.data?.data?.map(level=>{
            return {
              name: level,
              id: level,
              default: true,
            }
          });
          let tempLevelComponent = {...levelComponent,options:levels};
          setLevelComponent(tempLevelComponent);
        }
      } catch (error) {
        console.log("error",error);
      }
    }

    const handlePanelChange = useCallback((index) => {
      setOpenPanels((prevOpenPanels) => {
        if (prevOpenPanels.includes(index)) {
          // If panel is already open, close it
          return prevOpenPanels.filter((panelIndex) => panelIndex !== index);
        } else {
          // If panel is closed, open it
          return [...prevOpenPanels, index];
        }
      });
    }, []);

    const handleFilterChange = useCallback((name, value) => {
      const updatedFilters = {
        ...selectedFilters,
        [name]: value,
      };
      setSelectedFilters(updatedFilters);
    }, [selectedFilters, setSelectedFilters]);

    const handleSearch = useCallback(
      (value) => {
        setFilterSubmit(true);
      },
      [selectedFilters, setSelectedFilters]
    );

    // Search change
    const handleSearchChange = useCallback(
      (value) => {
        setSearch(value);
        setSelectedFilters({
          ...selectedFilters,
          name: value,
        });
        setFilterSubmit(false);
      },
      [selectedFilters, setSelectedFilters]
    );

    const handleClearAll = useCallback(
      (value) => {
        setSelectedFilters({
          ...selectedFilters,
          searchFields: [],
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    const handleSelectAll = useCallback(
      (value) => {
        setSelectedFilters({
          ...selectedFilters,
          searchFields: value,
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    const handleChangeSearchBy = useCallback(
      (value) => {
        setSelectedFilters({
          ...selectedFilters,
          searchFields: value,
        });
      },
      [selectedFilters, setSelectedFilters]
    );
    

    const handleDateChange = useCallback(
      (name, dates) => {
        setSelectedFilters({
          ...selectedFilters,
          [name]: dates,
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    const handleNumberRangeChange = useCallback((id, type, value) => {
      setNumberRanges((prevRanges) => ({
        ...prevRanges,
        [id]: {
          ...prevRanges[id],
          [type]: value,
        },
      }));
    }, []);

    const disabledDate = useCallback((current) => {
      // Allow dates from 50 years ago to today
      return (
        current &&
        (current < moment().subtract(dateYearValidation, "years") ||
          current > moment())
      );
    }, []);
    
    //Store Filter in localStorage and check old module
    useEffect(() => {
      const localStorageFilters = localStorage.getItem("selectedFilters");
      if (localStorageFilters && !isRelationPopup) {
        setSelectedFilters(JSON.parse(localStorageFilters));
        setTimeout(() => {
          setFilterSubmit(true);
        }, 800);
      }
    }, []); 

    useEffect(() => {
      if(!isRelationPopup)
      {
        if(menuDetail) localStorage.setItem("storeExitsModule", menuDetail?.moduleName);
        localStorage.setItem("selectedFilters", JSON.stringify(selectedFilters));
      }
    }, [selectedFilters]);

  
    //Collapse Open for Property Filter if selected filter exists
    //const contactedFilters = [...filteredPortfolioData,...gTFiltered];
    let activeCollapseProKey = [];
    if(!isRelationPopup)
    {
      const getSelectedFiltersProp = filteredPortfolioData && filteredPortfolioData?.length > 0 && filteredPortfolioData?.flatMap((item) => 
        selectedFilters[item?.name] !== undefined ? selectedFilters[item?.name] : ''
      ).filter(item => item !== '');
      activeCollapseProKey = getSelectedFiltersProp && getSelectedFiltersProp.length > 0 ? [0] : [];
    }

    //Collapse Open for Property Filter inside selected filter exists
    useEffect(() => {
      if (!isRelationPopup) {
        const filteredItems = filteredPortfolioData && filteredPortfolioData.length > 0
          ? filteredPortfolioData.flatMap((item, index) =>
              selectedFilters[item?.name] !== undefined ? index : ''
            ).filter(item => item !== '')
          : [];
          
        setGetSelectedFiltersPropInside(filteredItems);
        setPropertyFilterActive(setPropertyFilter(filteredPortfolioData, selectedFilters));
      }
    }, [isRelationPopup, filteredPortfolioData, selectedFilters]);
    
    useEffect(() => {
      if (!isRelationPopup && getSelectedFiltersPropInside.length > 0) {
        setOpenPanels(getSelectedFiltersPropInside);
      }
    }, [isRelationPopup, getSelectedFiltersPropInside]);

    const setPropertyFilter = (filterOptions, selectedItems) => {
      let checkSelectedFilter = [];
      const selectedValues = removeEmptyArrObjOrval(removeEmptyKeys(selectedItems));
      filterOptions?.map((item,index) => {
        const { name } = item;
        const selected = selectedValues[name];
        // const findItemIndex = getSelectedFiltersPropInside?.find((fi)=>fi === index);
        if (selected /* || findItemIndex */) {
          checkSelectedFilter.push({ [name]: selected ? selected :  selectedItems[name],index});
        }
      });
      return checkSelectedFilter.length > 0;
    };

    // Number range
    const onMinMaxChange = useCallback(
      (name, value) => {
        setSelectedFilters({
          ...selectedFilters,
          [name]: {
            isRange: true,
            value,
          },
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    useEffect(() => {
      setSearch(selectedFilters["name"]);
    }, [selectedFilters["name"]]);

    const minInputProps = useCallback(
      useMemo(
        () => ({
          onFocus: () => setMinInputFocused(true),
          onBlur: () => setMinInputFocused(false),
        }),
        [setMinInputFocused]
      ),
      [selectedFilters, setSelectedFilters]
    );

    const maxInputProps = useCallback(
      useMemo(
        () => ({
          onFocus: () => setMaxInputFocused(true),
          onBlur: () => setMaxInputFocused(false),
        }),
        [setMaxInputFocused]
      ),
      [selectedFilters, setSelectedFilters]
    );

    const handleFilterTagChange = useCallback(
      (value, tagType) => {
        setSelectedFilters({
          ...selectedFilters,
          [tagType]: value,
        });
      },
      [selectedFilters, setSelectedFilters]
    );

    // Number range
    const FilterMenuItems = React.memo(({ item, index, isFirst, isLast }) => {
      return (
        <div key={index} className="my-2">
          <Collapse
            // defaultActiveKey={[`${index}`]}
            activeKey={openPanels}
            onChange={() => handlePanelChange(index)}
            expandIconPosition="end"
            // className="life-cycle-stages-filter"
            expandIcon={({ isActive }) =>
              isActive ? (
                <DownOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
              ) : (
                <RightOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
              )
            }
            ghost
          >
            {/* <Panel header={normalizeString(item?.name)} key={index}> */}
            <Panel header={ item?.displayName } key={index}>
              {/* <div key={index} className="items-start p-4"> */}
              <div key={index} className="items-start p-1">
                {item?.type === "Date time" || item?.type === "Date" ? (
                  <RangePicker
                    style={{ width: `100%` }}
                    onChange={(values) => {
                      handleDateChange(
                        item?.name,
                        values
                      );
                    }}
                    name={item?.name}
                    value={
                      selectedFilters &&
                      selectedFilters[
                        item?.name
                      ]?.length
                        ? selectedFilters[
                          item?.name
                          ]
                        : []
                    }
                    disabledDate={disabledDate}
                  />
                ) : item?.type === "Numeric" ? (
                  <>
                    <NumberRangeComponent
                      item={item}
                      onMinMaxChange={onMinMaxChange}
                      // minValue={minValue}
                      minValue={
                        numberRanges[item.id]?.minValue || numberRangeMin
                      }
                      // setMinValue={setMinValue}
                      setMinValue={(value) =>
                        handleNumberRangeChange(item.id, "minValue", value)
                      }
                      // maxValue={maxValue}
                      maxValue={
                        numberRanges[item.id]?.maxValue || numberRangeMax
                      }
                      // setMaxValue={setMaxValue}
                      setMaxValue={(value) =>
                        handleNumberRangeChange(item.id, "maxValue", value)
                      }
                      numberRangeMax={numberRangeMax}
                      numberRangeMin={numberRangeMin}
                      key={`${index}-range`}
                      minInputProps={minInputProps}
                      maxInputProps={maxInputProps}
                      minInputFocused={minInputFocused}
                      maxInputFocused={maxInputFocused}
                    />
                  </>
                ) : (
                  <Checkbox.Group
                    style={{ width: "100%" }}
                    key={`check-box-group-${index}`}
                    name={item?.name}
                    value={
                      selectedFilters &&
                      selectedFilters[
                        item?.name
                      ]
                    }
                    onChange={(values) => {
                      handleFilterChange(item?.name, values, true);
                    }}
                  >
                    {item?.propertyOptions
                      .sort((a, b) =>
                        normalizeString(a?.name).localeCompare(
                          normalizeString(b?.name)
                        )
                      )
                      .map((d, i) => (
                        <label
                          className="w-100 d-flex align-items-center"
                          key={d?.id}
                        >
                          <Checkbox value={d?.id} key={Math.random()}>
                            {/* {normalizeString(d?.name)} */}
                            {d?.name}
                          </Checkbox>
                        </label>
                      ))}
                  </Checkbox.Group>
                )}
              </div>
            </Panel>
          </Collapse>
        </div>
      );
    });

    const LevelList = React.memo(() => {
      //Collapse Open for Level Filter if selected filter exists
      let activeCollapseLevelKey = [];
      if(!isRelationPopup)
      {
        activeCollapseLevelKey = selectedFilters['Level'] && selectedFilters['Level'].length > 0 ? [0] : [];
      }
      
      if(levelComponent?.options?.length===0){
        return <React.Fragment></React.Fragment>
      }

      return (
        <>
          <Collapse
            defaultActiveKey={activeCollapseLevelKey}
            expandIconPosition="end"
            className="life-cycle-stages-filter"
            expandIcon={({ isActive }) =>
              isActive ? (
                <DownOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
              ) : (
                <RightOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
              )
            }
          >
            <Panel header={levelComponent?.label}>
              <div key={`level-${levelComponent?.id}`} className="items-center p-2">
                <Checkbox.Group
                  style={{ width: "100%" }}
                  key={`check-box-group-level`}
                  name={levelComponent?.label}
                  value={selectedFilters && selectedFilters["Level"]}
                  onChange={(values) => {
                    setSelectedFilters({
                      ...selectedFilters,
                      ["Level"]: values,
                    });
                  }}
                  defaultValue={selectedFilters && selectedFilters["Level"]}
                >
                  {levelComponent?.options
                    .sort((a, b) =>
                      normalizeString(a?.name).localeCompare(
                        normalizeString(b?.name)
                      )
                    )
                    .map((d, i) => (
                      <label
                        className="w-100 d-flex align-items-center"
                        key={d?.id}
                      >
                        <Checkbox value={d?.id} defaultChecked={d?.default}>
                          {normalizeString(d?.name)}
                        </Checkbox>
                      </label>
                    ))}
                </Checkbox.Group>
              </div>
            </Panel>
          </Collapse>
        </>
      );
    });

    const getSortValue = (sortValue) => {
      let sortedValue = "";
      if (sortValue) {
        sortedValue = sortValue?.split(",")[0];
      } else {
        if (sortValues?.length > 0) {
          sortedValue = sortValues[0]?.value;
        } else {
          sortedValue = sortByList[0]?.value;
        }
      }
      return sortedValue;
    };
    const allTags = [...allTagsList,...gTFiltered];

    return (
      <div className="filter_containerbx">
        {activeGridStyle === 1 && (
          <>
            <div
              className={`flex items-center justify-between mb-2 gap-2 align-items-center ${
                showSortBy ? "" : "d-none"
              }`}
            >
              <div className="w-1/5">
                <label className="fw-medium mb-0 text-nowrap">Sort By: </label>
              </div>
              <div className="w-4/5">
                <Flex gap={4}>
                  <Select
                    style={{ width: "100%" }}
                    defaultValue={getSortValue(selectedFilters["sortBy"])}
                    value={getSortValue(selectedFilters["sortBy"])}
                    popupMatchSelectWidth={false}
                    onChange={(value) => {
                      setSelectedFilters({
                        ...selectedFilters,
                        ["sortBy"]: value + "," + "ASC",
                      });
                      setSortByColumn(value);
                      setSortByOrder("DESC");
                    }}
                  >
                    {sortByList &&
                      sortByList?.length &&
                      sortByList?.map((item) => (
                        <Option
                          value={item?.value}
                          title={item?.title}
                          key={Math.random()}
                        >
                          {item?.title}
                        </Option>
                      ))}
                  </Select>
                  <Button
                    style={{ width: "80px" }}
                    onClick={() => {
                      let value = sortByOrder;
                      const sortBy = sortByColumn
                        ? sortByColumn
                        : getSortValue(selectedFilters["sortBy"]);
                      setSortByOrder(value === "ASC" ? "DESC" : "ASC");
                      if (value && sortBy) {
                        setSelectedFilters({
                          ...selectedFilters,
                          ["sortBy"]: sortBy + "," + value,
                        });
                      }
                      setTimeout(() => {
                        setFilterSubmit(true);
                      }, 800);
                    }}
                  >
                    {sortByOrder}
                  </Button>
                </Flex>
              </div>
            </div>
            {/* Search */}
            <SearchContent
              handleChangeSearchBy={handleChangeSearchBy}
              handleSearch={handleSearch}
              handleSearchChange={handleSearchChange}
              search={search}
              options={sortArray([...optionFields,...gTOptionFields], "displayName")}
              handleClearAll={handleClearAll}
              handleSelectAll={handleSelectAll}
              selectedItems={selectedFilters?.searchFields || []}
              activeCollapseProKey={activeCollapseProKey}
              isCollapsable={true}
            />

            <FilterRelationship
              selectedFilters={selectedFilters}
              portfolioData={portfolioData}
              setSelectedFilters={setSelectedFilters}
              setSwimLaneSelectedFilters={setSwimLaneSelectedFilters}
              resetRelation={resetRelation}
              setResetRelation={setResetRelation}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
              isRelationPopup={isRelationPopup}
              isSourceTargetModuleName={isSourceTargetModuleName}
            />
            {optionalLevel && <LevelList />}
            <Collapse
              defaultActiveKey={activeCollapseProKey}
              activeKey={propertyFilterActive ? [0] : []}
              onChange={(val)=>{setPropertyFilterActive(!propertyFilterActive)}}
              expandIconPosition="end"
              className="life-cycle-stages-filter"
              expandIcon={({ isActive }) =>
                isActive ? (
                  <DownOutlined
                    style={{ color: "#2A609D", fontSize: "14px" }}
                  />
                ) : (
                  <RightOutlined
                    style={{ color: "#2A609D", fontSize: "14px" }}
                  />
                )
              }
            >
              <Panel header={"Property Filter"}>
                <div className="items-center p-2">
                  {portfolioData?.length &&
                  filteredPortfolioData?.length > 0 ? (
                    filteredPortfolioData.map((data, index) => (
                      <FilterMenuItems item={data} index={index} key={index} />
                    ))
                  ) : (
                    <>No items found.</>
                  )}
                </div>
              </Panel>
            </Collapse>

            {allTags && allTags?.length > 0 && (
              <FilterTag
                tagList={allTagsList}
                gTFiltered={gTFiltered}
                selectedFilters={selectedFilters}
                onTagChange={handleFilterTagChange}
                isRelationPopup={isRelationPopup}
              />
            )}
          </>
        )}
        {[2, 3].includes(activeGridStyle) && (
          <FilterIndicator lifeCycleStagesColors={lifeCycleStagesColors} />
        )}
        <Flex
          gap={8}
          justify="space-between"
          align="center"
          className="filterbtn-bx py-4"
        >
          <Button
            type="default"
            onClick={() => {
              if (!isRelationPopup) {
                localStorage.removeItem("selectedFilters");
                localStorage.removeItem("selectedFiltersRelation");
              }
              setOpenPanels([-1]);
              setSortByList(initialSortBy);
              setSortByColumn("");
              setSortByOrder("DESC");
              setNumberRanges({});
              setSelectedFilters({});
              setSelectedRelationFilters({});
              //setFilterSubmit(true);
              setResetRelation(true);
              setTimeout(() => {
                setFilterSubmit(true);
              }, 800);
            }}
          >
            Reset
          </Button>
          <Button
            type="default"
            disabled={filterSubmit}
            onClick={() => {
              setFilterSubmit(true);
            }}
          >
            Apply
          </Button>
        </Flex>
      </div>
    );
  }
);

FilterContent.defaultProps = {
  setSwimLaneSelectedFilters:()=>{},
  showSortBy:true,
}

export default FilterContent;
