import { Button, Flex, Form, Select } from 'antd'
import React, { useEffect, useState } from 'react'
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { removeEmptyArrObjOrval } from '../../../utils/Common';
import TextWithTooltip from '../../../utils/TextWithTooltip';
import PortfolioSettingServices from '../../../services/services/PortfolioSettingsService';

const GenerateReport = ({...props}) => {
    const [loading,setLoading] = useState(false);
    const [portfolioData,setPortfolioData] = useState(null);
    const [filter,setFilter] = useState({});
    const [page,setPage] = useState(0);
    const [listOptions,setListOptions] = useState([]);

    useEffect(()=>{
        setPortfolioData(getMenuPortfolio({moduleName:"business_capability"}))
    },[])

    useEffect(()=>{
        if(portfolioData){
            fetchFilteredData();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[portfolioData])

    const fetchFilteredData = async () => {
        try {
            if (portfolioData?.moduleName && !loading) {
                setLoading(true);
                let updatedFilter = {
                    moduleName: portfolioData?.moduleName || "",
                    sortBy: "displayname,ASC",
                    ...filter,
                };
                updatedFilter = removeEmptyArrObjOrval(updatedFilter);
                const response = await PortfolioSettingServices?.getFilteredPortfolios( updatedFilter, page+1, 20);
                let items = [...new Map([...listOptions,...response?.data?.data].map(item =>[item["id"], item])).values()]
                if (response?.data?.data?.length>0) {
                    setListOptions(items);
                    setPage(Number(response?.data?.currentPage))
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally{
            setLoading(false);
        }
    };

    const onClear = () => {
        setPage(0)
        setFilter({})
    }

    var timeout = null;
    const onSearch = (value) => {
        setPage(0)
        setFilter({
            name:value
        })
        if (timeout) {  
            clearTimeout(timeout);
        }
        timeout = setTimeout(function() {
            fetchFilteredData()//this is your existing function
        }, 2000);
    }

    const onFinish = (values) => {

    }

    return (
        <React.Fragment>
            <Form
                layout="inline"
                onFinish={onFinish}
            >
                <Flex justify='flex-start'>
                    <Form.Item name="id" rules={[{required:true,message: 'Please select!'}]}>
                        <Select
                            showSearch
                            allowClear
                            mode={props?.mode}
                            onClear={onClear}
                            placeholder={portfolioData?.name}
                            filterOption={(inputValue, currOption)=>{
                                return `${currOption?.option?.displayname || ""} ${currOption?.option?.referenceid || ""}`?.toLowerCase().includes(inputValue?.toLowerCase())
                            }}
                            onSearch={onSearch}
                            onChange={(value)=>{ 
                                // updateFormData(value);
                            }}
                            className="w-100"
                            options={
                                listOptions?.map(option=>{
                                    return {
                                        value: Number(option?.id),
                                        label: <TextWithTooltip text={`${option?.displayname}`} characterLimit={props?.characterLimit} />,
                                        option,
                                    }
                                })
                            }
                        />
                    </Form.Item>
                    <Button htmlType="submit" type='primary'>Generate Report</Button>
                </Flex>
            </Form>
        </React.Fragment>
    )
}

export default GenerateReport
