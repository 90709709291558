import React,{useState,useEffect} from 'react'; 
import CreateComment from './CreateComment';
import { Divider } from 'antd';
import CommentList from './CommentList';
const TabComments = ({ setActiveTabKey,...props }) => {
    const [refreshData,setRefreshData] = useState(false)
    return <>
        <CreateComment {...props} setActiveTabKey={setActiveTabKey} refreshData={refreshData} setRefreshData={setRefreshData} />
        <Divider className="mt-3 bg-[#e3e3e3]"/>
        <CommentList {...props} setActiveTabKey={setActiveTabKey} refreshData={refreshData}/>
    </>;
}

export default TabComments;