import React, { useEffect, useRef, useState } from 'react'
import "./Integration.scss";
import { IntegrationHeader2 } from './IntegrationHeader';
import { Button, Col, Divider, Flex, Form, Input, Row, Select, Tooltip } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { ApplicationModal, default_data_lists, default_technology_lists } from './IntegrationCommon';
import { ApplicationTypeModal, IntigrationModalCheckbox } from './IntegrationAddComponents';
import TextWithTooltip from '../../utils/TextWithTooltip';
import DynamicForm from '../dynamicForm/DynamicForm';
import { getMenuPortfolio } from '../../utils/settingCommon';

const IntegrationAdd = ({moduleName}) => {
    const divHeightRef = useRef(null)
    const [form] = Form.useForm();

    const [formItems, setFormItems] = useState({});
    const [formData, setFormData] = useState({});

    const [height, setHeight] = useState(0)
    const [openModal, setOpenModal] = useState("");
    const [selectedSourceApp, setSelectedSourceApp] = useState([]);
    const [selectedTargetApp, setSelectedTargetApp] = useState([]);
    const [selectedData, setSelectedData] = useState([]);
    const [selectedTechnology, setSelectedTechnology] = useState([]);
    const [selectedApplicationType,setSelectedApplicationType] = useState(null);

    const SaveButton = ({moduleName}) => {
        return <>
            <Tooltip title="Save">
                <Button 
                    className='mr-2 px-4'
                    type='primary'
                    onClick={() => { 

                    }}
                    
                > Save </Button>
            </Tooltip>
        </>
    }

    const onFinish = (formData) => {

    }

    const ApplicationListButton = ({item}) => {
        let temp = [];
        if(openModal==="sourceApp"){ temp = selectedSourceApp; }
        if(openModal==="targetApp"){ temp = selectedTargetApp; }
        return <>
            {
                ((()=>{
                    if(temp?.find(f=>f.id===item?.id)){
                        return <>
                            <Tooltip title="Added">
                                <Button 
                                    className='mr-2 px-4'
                                    type='primary'
                                    ghost
                                    disabled
                                > Added </Button>
                            </Tooltip>
                        </>
                    }else{
                        return <>
                            <Tooltip title="Select">
                                <Button 
                                    className='mr-2'
                                    type='primary'
                                    icon={<PlusOutlined/>}
                                    onClick={()=>{
                                        setSelectedApplicationType(item);
                                    }}
                                />
                            </Tooltip>
                        </>
                    }
                })())
            }
            
        </>
    }

    const selectApplicationForm1 = (value) => {
        let temp = [];;
        if(openModal==="sourceApp"){
            value.title = value?.DisplayName;
            setSelectedSourceApp([value]);
        }else if(openModal==="targetApp"){
            value.title = value?.DisplayName;
            setSelectedTargetApp([...selectedTargetApp,value]);
        }else if(openModal==="data"){
            temp = value?.map(item=>{
                item.title= item?.label;
                return item;
            });
            setSelectedData(temp);
        }else if(openModal==="technology"){
            temp = value?.map(item=>{
                item.title= item?.label;
                return item;
            });
            setSelectedTechnology(temp);
        }
    }

    useEffect(() => {
        setHeight(divHeightRef?.current?.clientHeight || 0)
    },[divHeightRef])

    const setOpenAppliactionModal = (check) => {
        setOpenModal(check?"":"");
    }

    const ListSelectedItems = ({lists,keyName,span}) =>{
        return lists?.map(item=>{
                return <Col key={Math.random()} span={span || 24}>
                    <div className='form-control'>
                        <Flex gap={8} justify='space-between'>
                            <div><TextWithTooltip text={item?.title} characterLimit={20}/></div>
                            <div><DeleteOutlined className="cursor-pointer ml-2 text-danger" onClick={() => { removeSelectedItem(item,keyName) }} style={{ fontSize: "20px" }} /></div>
                        </Flex>
                    </div>
                </Col>
            })
    }

    const removeSelectedItem = (item,keyName) => {
        let temp = [];
        if(keyName==="sourceApp"){
            temp = selectedSourceApp?.filter(f=>(f?.id?.toString()!==item?.id?.toString()));
            setSelectedSourceApp(temp);
            setOpenModal("sourceApp");
        }else if(keyName==="targetApp"){
            temp = selectedTargetApp?.filter(f=>(f?.id?.toString()!==item?.id?.toString()));
            setSelectedTargetApp(temp);
        }else if(keyName==="data"){
            temp = selectedData?.filter(f=>(f?.value?.toString()!==item?.value?.toString()));
            setSelectedData(temp);
        }else if(keyName==="technology"){
            temp = selectedTechnology?.filter(f=>(f?.value?.toString()!==item?.value?.toString()));
            setSelectedTechnology(temp);
        }
    }

    const create_and_Updatd = () => {
        // form.validateFields()
        //     .then(async (values) => {
        //         let obj = {
        //             ...values,
        //         };

        //         const oldValues = updateValuesForChangeLog(
        //             list,
        //             formItems?.portfolioSections,
        //         );

        //         const newValues = updateValuesForChangeLog(
        //             obj,
        //             formItems?.portfolioSections,
        //         );

        //         let id = null;

        //         if (state.isEdit) {
        //             let obj = {
        //                 // NameInSourceSystem: "",
        //                 // DisplayName: values?.name,
        //                 // Description: values?.description,
        //                 // LevelID: values?.LevelId,
        //                 ...values,
        //             };
        //             obj.id = modelId;
        //             try {
        //                 let result;
        //                 result = await portfolioServices.update(obj);

        //                 if (result?.data?.code == "200") {
        //                     console.log("result?.data", result?.data);

        //                     //form.resetFields();
        //                     message.success(result?.data?.msg);

        //                     //Add Change Log
        //                     await UpdateChangeLog(
        //                         obj.id,
        //                         oldValues,
        //                         newValues,
        //                         "Update",
        //                         moduleName,
        //                     );

        //                     await fetchData({ moduleName, modelId });

        //                     await getData();
        //                 } else if(result?.data?.id){
        //                     //form.resetFields();
        //                     message.success("Updated Successfully");

        //                     //Add Change Log
        //                     await UpdateChangeLog(
        //                         obj.id,
        //                         oldValues,
        //                         newValues,
        //                         "Update",
        //                         moduleName,
        //                     );

        //                     await fetchData({ moduleName, modelId });

        //                     await getData();
        //                 }else {
        //                     message.error(result?.data?.msg);
        //                 }
        //             } catch (error) {
        //                 message.error(error?.message);
        //             }
        //         } else {
        //             try {
        //                 let result;
        //                 result = await portfolioServices.create(obj);
        //                 if (result.data?.code == "200") {
        //                     //console.log("Level 1 Data", result.data?.data.id);

        //                     //Add Change Log
        //                     await UpdateChangeLog(
        //                         result.data?.data.id,
        //                         oldValues,
        //                         newValues,
        //                         "Add",
        //                         moduleName,
        //                     );
        //                     await fetchData(moduleName, modelId);
        //                     form.resetFields();

        //                     await getData();

        //                     message.success(result?.data?.msg);

        //                     //console.log("ID BC Basic Info ", result?.data);
        //                     const newDataID = result.data?.data.id || null;
        //                     updateBussinessModuleId(newDataID);
        //                    setTimeout(() => {
        //                         navigate(updateCreateItemLink({basePath,id:result?.data?.id}));
        //                     }, 1000);
        //                 } else if(result?.data?.id){
        //                     message.success("Added Successfully");
        //                     navigate(updateCreateItemLink({basePath,id:result?.data?.id}));
        //                 }else if(result?.data?.error){
        //                     message.error(result?.data?.error);
        //                 }else {
        //                     message.error(result?.data?.msg);
        //                 }
        //             } catch (error) {
        //                 console.log("Result.....", error);
        //                 message.error(error?.response?.data?.error || error?.message);
        //             }
        //         }
        //     })
        //     .catch((info) => {
        //         console.log("Validate Failed:", info);
        //     });
    };

    return (
        <div className='integration-page'>
            <IntegrationHeader2 moduleName={moduleName} Buttons={<SaveButton/>}/>
            <DynamicForm
                data={formItems?.portfolioSections || []}
                form={form}
                formData={formData}
                labelCol={8}
                wrapperCol={16}
                createAndUpdate={create_and_Updatd}
                level={0}
                levelID={0}
                parentLevel={0}
            />
            {/* <Form
                form={form}
                onFinish={onFinish}
                initialValues={{ username: "" }}
                labelCol={{span:8}}
                wrapperCol={{span:16}}
                className='p-2'
            >
                <Flex gap={8}>
                    <div className='w-100 p-lg-3' ref={divHeightRef}>
                        <Form.Item name="username" label="Name" className='mb-2'>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="username 1" label="Description" className='mb-3'>
                            <Input.TextArea rows={3}/>
                        </Form.Item>
                        <Form.Item name="username 2" label="Type" className='mb-2' initialValue={"Api"}>
                            <Select
                                options={[{value:"Api",label:"Api"}]}
                            />
                        </Form.Item>
                    </div>
                    <Divider type="vertical" style={{ height: height,border:"0.8px solid #ddd" }}/>
                    <div className='w-100 p-lg-3'>
                        <Form.Item name="username 3" label="URL/Endpoint" className='mb-2'>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="username 4" label="Version" className='mb-2'>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="username 5" label="Frequency" className='mb-2'>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="username 6" label="Life Cycle State" className='mb-2' initialValue={""}>
                            <Select/>
                        </Form.Item>
                    </div>
                </Flex>
                <Divider style={{ border:"0.8px solid #ddd",}}  className='mb-3 mt-0'/>
                <Flex gap={8} className='mb-3'>
                    <div className='w-100 p-lg-3'>
                        <fieldset className="fieldset">
                            <label className="fieldset-label">Source App</label>
                            <div className='m-3'>
                                <Row gutter={[16,16]}>
                                    <ListSelectedItems lists={selectedSourceApp} keyName={'sourceApp'}/>
                                    {
                                        selectedSourceApp?.length===0 && 
                                        <div className='text-center'>
                                            <Tooltip title="Source App">
                                                <Button 
                                                    type='primary' 
                                                    shape='circle' 
                                                    icon={<PlusOutlined/>} 
                                                    onClick={()=>{
                                                        setOpenModal('sourceApp');
                                                    }}
                                                    ghost
                                                />
                                            </Tooltip>
                                        </div>
                                    }
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                    <div className='w-100 p-lg-3'>
                        <fieldset className="fieldset">
                            <label className="fieldset-label">Target App</label>
                            <div className='m-3'>
                                <Row gutter={[16,16]}>
                                    <ListSelectedItems lists={selectedTargetApp} keyName={'targetApp'} span={12}/>
                                    <Col span={(selectedTargetApp?.length+1)%12===0?24:12} className='text-center'>
                                        <Tooltip title="Target App">
                                            <Button 
                                                type='primary' 
                                                shape='circle' 
                                                icon={<PlusOutlined/>} 
                                                onClick={()=>{
                                                    setOpenModal('targetApp');
                                                }}
                                                ghost/>
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                </Flex>
                <Flex gap={8} className='mb-3'>
                    <div className='w-100 p-lg-3'>
                        <fieldset className="fieldset">
                            <label className="fieldset-label">Data</label>
                            <div className='m-3'>
                                <Row gutter={[16,16]}>
                                    <ListSelectedItems lists={selectedData} keyName={'data'} span={12}/>
                                    <Col span={(selectedTargetApp?.length+1)%12===0?24:12} className='text-center'>
                                        <Tooltip title="Data">
                                            <Button 
                                                type='primary' 
                                                shape='circle' 
                                                icon={<PlusOutlined/>}
                                                onClick={()=>{
                                                    setOpenModal('data');
                                                }} 
                                                ghost
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                    <div className='w-100 p-lg-3'>
                        <fieldset className="fieldset">
                            <label className="fieldset-label">Technology</label>
                            <div className='m-3'>
                                <Row gutter={[16,16]}>
                                    <ListSelectedItems lists={selectedTechnology} keyName={'technology'}/>
                                    <Col span={(selectedTargetApp?.length+1)%12===0?24:12} className='text-center'>
                                        <Tooltip title="Technology">
                                            <Button 
                                                type='primary' 
                                                shape='circle' 
                                                icon={<PlusOutlined/>}
                                                onClick={()=>{
                                                    setOpenModal('technology');
                                                }} 
                                                ghost
                                            />
                                        </Tooltip>
                                    </Col>
                                </Row>
                            </div>
                        </fieldset>
                    </div>
                </Flex>
                <ApplicationModal
                    open={openModal==="sourceApp"?true:false}
                    setOpen={setOpenAppliactionModal}
                    Buttons={ApplicationListButton}
                />
                <ApplicationModal
                    open={openModal==="targetApp"?true:false}
                    setOpen={setOpenAppliactionModal}
                    Buttons={ApplicationListButton}
                />
                <ApplicationTypeModal
                    open={selectedApplicationType}
                    setOpen={()=>{
                        setSelectedApplicationType(null)
                    }}
                    setSelectedItem={(data)=>{
                        selectApplicationForm1(data)
                        setSelectedApplicationType(null)
                    }}
                />
                {openModal==="data" && 
                    <IntigrationModalCheckbox
                        title={`Data`}
                        open={openModal==="data"?true:false}
                        setOpen={setOpenAppliactionModal}
                        setSelected={selectApplicationForm1}
                        lists={ default_data_lists?.map(item=>{
                            if(selectedData?.find(f=>f?.value?.toString()!==item?.value?.toString())){
                                item.checked = false;
                            }
                            return item;
                        })}
                    />
                }
                {openModal==="technology" && 
                    <IntigrationModalCheckbox
                        title={`Technology`}
                        open={openModal==="technology"?true:false}
                        setOpen={setOpenAppliactionModal}
                        setSelected={selectApplicationForm1}
                        lists={default_technology_lists?.map(item=>{
                            if(selectedTechnology?.find(f=>f?.value?.toString()!==item?.value?.toString())){
                                item.checked = false;
                            }
                            return item;
                        })}
                    />
                }
            </Form> */}
        </div>
    )
}

IntegrationAdd.defaultProps = {}

export default IntegrationAdd

