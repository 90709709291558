import React from 'react';
import BusinessCapabilityMaster from '../components/businessCapabilityMaster/BusinessCapabilityMaster';

function BusinessCapabilityMasterPage(props) {
  return (
    <BusinessCapabilityMaster/>
  )
}

export default BusinessCapabilityMasterPage;
