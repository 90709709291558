import React, { useEffect, useRef, useState } from 'react';
import { Card, Typography, Button, Tooltip } from 'antd';
import { DeleteOutlined, EditOutlined, ExclamationCircleFilled, PlusOutlined } from '@ant-design/icons';
import BCMLevel2, { getNewLevelId } from './BCMLevel2';
import { useNavigate } from "react-router";
import {  useParams } from "react-router-dom";
const baseStyle = {
    justifyContent: 'center',
    margin:'10px',
}

const BCMLevel1 = ({basePath,levelIndex,...props}) => {
    const [hovered, setHovered] = useState(false);
    const [dropdown, setDropdown] = useState(false);
    const dropdownRef = useRef(null);
    const { moduleName:moduleNamePath } = useParams();
    
    const navigate = useNavigate();
    let parentName;

    useEffect(() => {
        function handleClickOutside(event) {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setDropdown(false);
            }
        }
 
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
         
    }, [dropdownRef]);

    return <>
        <Card
            size="small"
            style={{ ...baseStyle, backgroundColor: props?.backgroundColor}}
        >
            <div className='d-flex align-items-center justify-between'>
                <Typography.Title
                    level={5}

                    className={props?.backgroundColor ? 'text-white title-level-1 cursor-pointer' : 'title-level-1 cursor-pointer'}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                    onClick={() => { navigate('/business-capability-master/'+moduleNamePath+'/'+props?.data?.id ,{state:{from:basePath,defaultActiveKey:2}}  )}}
                > 
                    {props?.data?.levelid} {props?.data?.displayname}
                </Typography.Title>
                <div className='level-btn-1'>
                    <div className="dropdown">
                        <button className="btn btn-primary dropdown-toggle p-0" type="button" onClick={() => {
                            setDropdown(!dropdown);
                        }}>
                            <i className="fa fa-ellipsis-v edit" />
                        </button>
                        { 
                            dropdown && (
                                <ul className="dropdown-menu" ref={dropdownRef}>
                                    <li><Button onClick={() => { props?.setItem(props?.data, { type: 'delete' }); props?.setModalData({ level: 1, ParentID: props?.data?.id, DisplayName:props?.data?.DisplayName }); }} className='btn-icon'><i className="fa fa-trash-o delete" /></Button></li>
                                    {/* <li><Button onClick={() => { props?.setItem(props?.data, { type: 'edit' }); props?.setModalData({ level: 1, ParentID: props?.data?.id }); }} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li> */}
                                    {/* <li><Button onClick={() => { navigate('/business-capability-master', { state: { type: 'edit',Level:1, ParentID: props?.data?.id , ParentName:props?.data?.DisplayName, underLevel:props?.underLevel } })}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li>
                                        */}
                                    <li><Button onClick={() => { navigate('/business-capability-master/'+moduleNamePath+'/'+props?.data?.id  )}} className='btn-icon'><i className="fa fa-pencil edit" /></Button></li>
                                        
                                </ul>
                            )
                        }
                    </div>
                </div>
            </div>

            {
                props?.data?.child?.map((item, index) => {
                    return <div  key={item.levelid}>
                        <BCMLevel2 {...props} defaultActiveKey={index === 0 ? 1 : 0} index={index} data={item}  underLevel={props?.data.displayname} underLevelID={props?.data.levelid} level={levelIndex+1}/>
                        </div>
                })
            }
            <br />
            <br />
            <Tooltip title="Level 2">
                {/* <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} onClick={() => { props?.openModal(!props?.visibleModal); props?.setModalData({ level: 2, ParentID: props?.data?.id }); }}></Button> */}
                 

                <Button shape="circle" className='btn-level-2' icon={<PlusOutlined />} onClick={() => {
                    navigate('/business-capability-master/'+ moduleNamePath+"/add", { state: {Level:levelIndex+1,ParentID: props?.data?.id, ParentName: props?.data?.displayname, underLevel: props?.data?.displayname, levelID: props?.data?.levelid ,newLevelId : getNewLevelId(props?.data?.levelid,levelIndex+1,props.data) ,from:basePath,defaultActiveKey:2} })
                } }></Button>
            </Tooltip>
        </Card>
    </>;
}

BCMLevel1.defaultProps={
    levelIndex:1,
}
export default BCMLevel1;