import './Home.scss';
import { Col, Row, Divider, Collapse } from 'antd';
import { useState, useEffect, useRef   } from 'react'; 
import HomeCard from './HomeCard';
import HomeQuickLink from './HomeQuickLink';
import HomeQuickStart from './HomeQuickStart';
import { PageTitleHeading } from '../../utils/Common';
import LoadingBar from 'react-top-loading-bar'
import VerticalBarChart from '../chart/VerticalBarChart';

const HomeLayout = () => {
    const ref = useRef(null)
    
    const [condition, setCondition] = useState(true);
    const [list, setList] = useState([]);
    
    useEffect(() => {
        getRecentViewed_Portfolio();
    },[list]);
 
    const getRecentViewed_Portfolio=()=>{ 
        if (ref.current) {
            ref.current.continuousStart();
          }
        if (condition)
        {        
        let recentPortfolio = localStorage.getItem("recent_viewed_portfolio"); 
        let recentPortfolio_json= JSON.parse(recentPortfolio)?.reverse().slice(0, 12);;
         
            setCondition(false);
            setList(recentPortfolio_json);  
        }
        if (ref.current) {
            ref.current.complete();
        }
    }

    const CollapseItems = [
        {
          key: '1',
          label: 'Portfolio Summary',
          children: <VerticalBarChart />,
        }
    ];

    localStorage.removeItem("selectedFilters");
    localStorage.removeItem("selectedFiltersRelation");

    return (
    <>
            <LoadingBar color='#1f1f1f' ref={ref} />
            <div className='row home-layout container_bx' /*style={{height:window?.innerHeight+100}}*/>
                <div className='col-md-9 text-left'>
                    <PageTitleHeading text={`Recently Viewed`}/>
                    <Row gutter={[20,16]} className='justify-content-start'>
                        {   
                            list?.map((item) => {
                                return( 
                                    <Col span={8} key={Math.random()}>
                                        <HomeCard {...{ ModelName: item.ModelName ,ModelID:item.ModelID, Module: item.Module,color:item.color, URL:item.URL }}/>
                                    </Col>
                                );
                            })
                        }
                    </Row>
                    <br></br><br></br>
                    <Row gutter={[20,16]} className='justify-content-start'>
                        <Col span={24} key={Math.random()}>
                            <Collapse items={CollapseItems} defaultActiveKey={['1']}></Collapse>
                        </Col>
                    </Row>
                </div>
                <div className='col-md-3 border-left'>
                    <Row className="justify-content-start" style={{ marginLeft:'10px'}}>
                        <Col span={24}>
                            <h3 className='mt-4 w-100'>{}</h3> 
                            <HomeQuickStart/>
                            <Divider type="horizontal" style={{ width: "100%" }}/>
                            <HomeQuickLink/>
                        </Col> 
                    </Row>
                </div>
            </div>
    </>
 );
};
export default HomeLayout;

{/* <div className="home-layout" style={{ marginTop:'20px',marginBottom:'20px'}}>
                    <Row className="justify-content-start" style={{ marginLeft:'10px'}}>
                        <Col>
                            <h2>Recently Viewed</h2> 
                        </Col> 
                    </Row>
                    <Row className="justify-content-start">
                        <Col span={16}>
                            <Row  className="justify-content-start">
                                {   
                                     list?.map((item, index) => {
                                        console.log("object item", item);

                                      return( 
                                      <Col span={8}>
                                            <HomeCard props={{ ModelName: item.ModelName ,ModelID:item.ModelID, Module: item.Module,color:item.color , URL:item.URL }}>  
                                            </HomeCard>
                                        </Col>
                                      );
                                    })
                                }
                                  
                            </Row>

                        </Col>
                        <Col span={2}  >

                            <Divider type="vertical" style={{ height: "100%" }}/>

                        </Col>

                        <Col span={6}>
                            <Row  className="justify-content-start"> 
                                    <Col >anchor
                                         <HomeQuickStart></HomeQuickStart>
                                    </Col>

                                    <Divider />

                                    <Col >
                                        
                                        <HomeQuickLink></HomeQuickLink>
                                    </Col>

                            </Row>
                        </Col> 
                    </Row>


                     
                 
                {/* <Row gutter={24} justify={`center`} align={`middle`}>
                    <Col>
                        <Link to={'/city-map'} className="btn btn-lg btn-theme m-2 px-5" title="Generate City Map">Generate City Map</Link>
                        <Link to={'/start-new-scenario'} className="btn btn-lg btn-theme m-2 px-5" title="Create Scenario">Create Scenario</Link>
                        <Link to={'/scenario-list'} className="btn btn-lg btn-theme m-2 px-5" title="View Scenario">View Scenario</Link>
                        <Link to={'/compare-scenario'} className="btn btn-lg btn-theme m-2 px-5" title="Compare Scenario">Compare Scenario</Link>
                    </Col>
                </Row> */}
            //</div> */}