import { useState, useEffect } from "react";
import UserService from "../services/UserService";

const useUserData = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userDataString = localStorage.getItem("userData");
    if (userDataString) {
      setUserData(JSON.parse(userDataString));
    } else {
      setUserData(null);
    }
  }, []);

  return userData;
};

export default useUserData;


export const checkRole = (type) =>{
  switch (type) {
    case 'add': return UserService?.hasRole(['Admin', 'Editor']);
    case 'save': return UserService?.hasRole(['Admin', 'Editor']);
    case 'delete': return UserService?.hasRole(['Admin', 'Editor']);
    case 'update': return UserService?.hasRole(['Admin', 'Editor']);
    case 'import': return UserService?.hasRole(['Admin', 'Editor']);
    case 'export': return UserService?.hasRole(['Admin', 'Editor']);
    case 'checkbox': return UserService?.hasRole(['Admin', 'Editor']);
    default: return false;
  }
}