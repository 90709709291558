import { Route, Routes } from "react-router";
import NoMatch from "../components/NoMatch";
import RolesRoute from "../components/RolesRoute";
import CompareScenarioList from "../components/scenario/CompareScenarioList";
import EditScenario from "../components/scenario/EditScenario";
import CityMapPage from "../pages/CityMapPage";
import CompareScenarioPage from "../pages/CompareScenarioPage";
import DataImportPage from "../pages/DataImportPage";
import HomePage from "../pages/HomePage";
import NewScenarioPage from "../pages/NewScenarioPage";
import ScenarioListPage from "../pages/ScenarioListPage";
import PrivateRoutes from "../components/PrivateRoutes";
import BusinessCapabilityMasterPage from "../pages/BusinessCapabilityMasterPage";
import LevelGroupingMasterPage from "../pages/LevelGroupingMasterPage";
import PlantUmlViewLayout from "../pages/PlantUmlViewLayout";
 
import PortfolioSingleEntryPage from "../pages/PortfolioSingleEntryPage";
import ApplicationListPage from "../pages/ApplicationListPage";
import BusinessCapabilityEntryPage from "../pages/BusinessCapabilityEntryPage";
import SettingsPage from "../pages/SettingsPage";
import ScenarioListV2 from "../components/scenario/ScenarioListV2";
import LayoutPage from "../pages/LayoutPage";
import DiagramPage from "../pages/DiagramPage";
import CompareScenarioPageV2 from "../pages/CompareScenarioPageV2";
import CompareScenarioViewerPageV2 from "../pages/CompareScenarioViewerPageV2";
import ListScenarioPage from "../pages/ListScenarioPage";
import BusinessProcessPage from "../pages/BusinessProcessPage";
import BusinessProcessEntryPage from "../pages/BusinessProcessEntryPage";
import SurveysPage from "../pages/SurveysPage";
import SurveysAddPage from "../pages/SurveysAddPage";
import NewScenarioAddPage from "../pages/NewScenarioAddPage";
import IntegrationPage from "../pages/IntegrationPage";
import AdminLayout from "../components/layouts/AdminLayout";
import PortfolioPage from "../pages/PortfolioPage";
import ReportsPage from "../pages/ReportsPage";
import NotFound from "../components/NotFound";
import ScenarioPlanning from "../components/scenario/scenarioPlanning/ScenarioPlanning";
import BusinessCapabiltyLandscapeReports from "../components/reports/BusinessCapabiltyLandscape";
import DataCompleteness from "../components/reports/DataCompleteness";
import BusinessCapabiltySummaryReports from "../components/reports/BusinessCapabiltySummaryReports";
 
const AuthenticatedRoutes = () => (
  <>
    <AdminLayout>
      <Routes>
        <Route path="/" element={<PrivateRoutes />}>
          <Route path="/" element={<HomePage />} />
        </Route>
        {/* <Route path="/" element={
                    <RolesRoute roles={['Viewer', 'Admin']} >
                        <ScenarioListPage />
                    </RolesRoute>
                }>
                </Route> */}
               
                <Route path="/city-map" element={
                    <RolesRoute roles={['Viewer', 'Admin']} >
                        <CityMapPage />
                    </RolesRoute>                
                } />
               
                <Route path="/scenario-list" element={
                    <RolesRoute roles={['Viewer', 'Admin']} >
                        <ScenarioListPage />
                    </RolesRoute>                    
                } />
                <Route path="/list-scenario" element={
                    <RolesRoute roles={['Viewer', 'Admin', 'Editor']} >
                        <ListScenarioPage />
                    </RolesRoute>                    
                } />
                <Route path="/start-new-scenario/:id" element={
                    <RolesRoute roles={['Viewer', 'Admin', 'Editor']} >
                        <NewScenarioPage />
                    </RolesRoute>
                } />
                <Route path="/add-new-scenario" element={
                    <RolesRoute roles={['Viewer', 'Admin']} >
                        <NewScenarioAddPage />
                    </RolesRoute>
                } />
                <Route path="/start-new-scenario" element={
                    <RolesRoute roles={['Viewer', 'Admin']} >
                        <NewScenarioPage />
                    </RolesRoute>
                } />
                <Route path="/edit-scenario/:id" element={
                    <RolesRoute roles={['Viewer', 'Admin']} >
                        <EditScenario />
                    </RolesRoute>
                } />
               
                {/* <Route path="/scenario-list-v2/:id" element={
                    <RolesRoute roles={['Viewer', 'Admin', 'Editor']} >
                        <LayoutPage component={<ScenarioListV2 />}/>
                    </RolesRoute>
                } /> */}
 
                <Route path="/compare-scenario-list" element={
                    <RolesRoute roles={['Viewer', 'Admin']} >
                        <CompareScenarioList />
                    </RolesRoute>
                } />
                <Route path="/compare-scenario" element={
                    <RolesRoute roles={['Viewer', 'Admin']} >
                        <CompareScenarioPage />
                    </RolesRoute>
                } />
                <Route path="/compare-scenario-v2" element={
                    <RolesRoute roles={['Viewer', 'Admin', 'Editor']} >
                        <CompareScenarioPageV2 />
                    </RolesRoute>
                } />
                <Route path="compare-scenario-v2/compare" element={
                    <RolesRoute roles={['Viewer', 'Admin', 'Editor']} >
                        <CompareScenarioViewerPageV2 />
                    </RolesRoute>
                } />
                <Route path="/data-import" element={
                    <RolesRoute roles={['Admin']} >
                        <DataImportPage />
                    </RolesRoute>
                } />
                <Route path="/business-capability-master" element={
                    <RolesRoute roles={['Admin']} >
                        <BusinessCapabilityMasterPage />
                    </RolesRoute>
                } />
 
        <Route
          path="/scenario-list-v2/:id"
          element={
            <RolesRoute roles={["Viewer", "Admin"]}>
              <LayoutPage component={<ScenarioPlanning />} />
              {/* <LayoutPage component={<ScenarioListV2 />} /> */}
            </RolesRoute>
          }
        />
 
        <Route
          path="/compare-scenario-list"
          element={
            <RolesRoute roles={["Viewer", "Admin"]}>
              <CompareScenarioList />
            </RolesRoute>
          }
        />
        <Route
          path="/compare-scenario"
          element={
            <RolesRoute roles={["Viewer", "Admin"]}>
              <CompareScenarioPage />
            </RolesRoute>
          }
        />
        <Route
          path="/compare-scenario-v2"
          element={
            <RolesRoute roles={["Viewer", "Admin"]}>
              <CompareScenarioPageV2 />
            </RolesRoute>
          }
        />
        <Route
          path="compare-scenario-v2/compare"
          element={
            <RolesRoute roles={["Viewer", "Admin"]}>
              <CompareScenarioViewerPageV2 />
            </RolesRoute>
          }
        />
        <Route
          path="/data-import"
          element={
            <RolesRoute roles={["Admin"]}>
              <DataImportPage />
            </RolesRoute>
          }
        />
        <Route
          path="/business-capability-master"
          element={
            <RolesRoute roles={["Admin"]}>
              <BusinessCapabilityMasterPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/business-capability-master/:moduleName"
          element={
            <RolesRoute roles={["Admin"]}>
              <BusinessCapabilityMasterPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/level-grouping-master"
          element={
            <RolesRoute roles={["Admin"]}>
              <LevelGroupingMasterPage />
            </RolesRoute>
          }
        />
        <Route
          path="/level-grouping-master"
          element={
            <RolesRoute roles={["Admin"]}>
              <LevelGroupingMasterPage />
            </RolesRoute>
          }
        />
        <Route
          path="/plant-uml-view"
          element={
            <RolesRoute roles={["Admin"]}>
              <PlantUmlViewLayout />
            </RolesRoute>
          }
        />
 
        <Route
          path="/portfolio-single-entry"
          element={
            <RolesRoute roles={["Admin"]}>
              <PortfolioSingleEntryPage />
            </RolesRoute>
          }
        />
        <Route
          path="/portfolio-single-entry/:moduleName"
          element={
            <RolesRoute roles={["Admin"]}>
              <PortfolioSingleEntryPage />
            </RolesRoute>
          }
        />
        <Route
          path="/portfolio-single-entry/:id"
          element={
            <RolesRoute roles={["Admin"]}>
              <PortfolioSingleEntryPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/application-list/:moduleName/:id"
          element={
            <RolesRoute roles={["Admin"]}>
              <PortfolioSingleEntryPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/application-list"
          element={
            <RolesRoute roles={["Admin"]}>
              <ApplicationListPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/application-list/:moduleName"
          element={
            <RolesRoute roles={["Admin"]}>
              <ApplicationListPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/Business-Capability-EntryPage"
          element={
            <RolesRoute roles={["Admin"]}>
              <BusinessCapabilityEntryPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/Business-Capability-EntryPage/:moduleName"
          element={
            <RolesRoute roles={["Admin"]}>
              <BusinessCapabilityEntryPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/Business-Capability-EntryPage/:id"
          element={
            <RolesRoute roles={["Admin"]}>
              <BusinessCapabilityEntryPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/Business-Capability-EntryPage/:id/:moduleName"
          element={
            <RolesRoute roles={["Admin"]}>
              <BusinessCapabilityEntryPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/Business-Capability-master/:moduleName/:id"
          element={
            <RolesRoute roles={["Admin"]}>
              <BusinessCapabilityEntryPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/others-settings"
          element={
            <RolesRoute roles={["Admin"]}>
              <SettingsPage />
            </RolesRoute>
          }
        />

        <Route
          path="/diagram-editor"
          element={
            <RolesRoute roles={["Admin", 'Editor']}>
              <DiagramPage />
            </RolesRoute>
          }
        />
 
        <Route path="/diagram-editor/:diagramId"
          element={
              <RolesRoute roles={['Admin', 'Editor']} >
                  <DiagramPage />
              </RolesRoute>
          }
        />
 
 
                <Route path="/business-process/:moduleName/:id"
                element={
                    <RolesRoute roles={['Viewer', 'Admin', 'Editor']} >
                        <BusinessProcessEntryPage />
                    </RolesRoute>
                } />
 
        <Route
          path="/business-process/:moduleName"
          element={
            <RolesRoute roles={["Admin"]}>
              <BusinessProcessPage />
            </RolesRoute>
          }
        />

        <Route
          path="/portfolio-integration/:moduleName"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <IntegrationPage page="index" />
            </RolesRoute>
          }
        />
        <Route
          path="/portfolio-integration/:moduleName/:id"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <IntegrationPage page="update" />
            </RolesRoute>
          }
        />
        <Route
          path="/portfolio/:moduleName"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <PortfolioPage page="index" />
            </RolesRoute>
          }
        />
        <Route
          path="/portfolio/:moduleName/:id"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <PortfolioPage page="update" />
            </RolesRoute>
          }
        />

        <Route
          path="/portfolio/:moduleName/:scenarioId/:id"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <PortfolioPage page="update" />
            </RolesRoute>
          }
        />

        <Route
          path="/reports/application-landscape"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <ReportsPage
                heading={"Application Landscape"}
                moduleName={"BusinessApplication"}
                showColorIndicator={true}
              />
            </RolesRoute>
          }
        />
        <Route
          path="/reports/integration-landscape"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <ReportsPage
                heading={"Integration Landscape"}
                moduleName={"Integration"}
                isIntegrationLandscape={true}
              />
            </RolesRoute>
          }
        />
        <Route
          path="/reports/technology-landscape"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <ReportsPage
                heading={"Technology Landscape"}
                moduleName={"Technology"}
                showColorIndicator={true}
              />
            </RolesRoute>
          }
        />
        <Route
          path="/reports/business-capabilty-landscape"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <BusinessCapabiltyLandscapeReports
                heading={"Integration Landscape"}
                moduleName={"BusinessCapability"}
                isIntegrationLandscape={true}
              />
            </RolesRoute>
          }
        />
        <Route
          path="/reports/business-capabilty-summary-report"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <BusinessCapabiltySummaryReports
                heading={"Business Capability Summary Report"}
                moduleName={"BusinessCapability"}
                isIntegrationLandscape={true}
              />
            </RolesRoute>
          }
        />
        <Route
          path="/reports/data-completeness-report"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <DataCompleteness
                heading={"Data Completeness Report"}
                // moduleName={"Integration"}
                // isIntegrationLandscape={true}
              />
            </RolesRoute>
          }
        />
        {/* <Route
          path="/fishbone"
          element={
            <RolesRoute roles={["Viewer", "Admin", "Editor"]}>
              <FishboneChart />
            </RolesRoute>
          }
        /> */}
        <Route path="*" element={<NotFound />} />
        {/* </Routes> */}
        {/* </AdminLayout> */}
 
        <Route
          path="/others-surveys"
          element={
            <RolesRoute roles={["Admin"]}>
              <SurveysPage />
            </RolesRoute>
          }
        />
        <Route
          path="/others-surveys/add"
          element={
            <RolesRoute roles={["Admin"]}>
              <SurveysAddPage />
            </RolesRoute>
          }
        />
 
        <Route
          path="/portfolio-integration/:moduleName"
          element={
            <RolesRoute roles={["Admin"]}>
              <IntegrationPage page="index" />
            </RolesRoute>
          }
        />
        <Route
          path="/portfolio-integration/:moduleName/:id"
          element={
            <RolesRoute roles={["Admin"]}>
              <IntegrationPage page="update" />
            </RolesRoute>
          }
        />
        <Route
          path="/portfolio/:moduleName"
          element={
            <RolesRoute roles={["Admin"]}>
              <PortfolioPage page="index" />
            </RolesRoute>
          }
        />
        <Route
          path="/portfolio/:moduleName/:id"
          element={
            <RolesRoute roles={["Admin"]}>
              <PortfolioPage page="update" />
            </RolesRoute>
          }
        />
 
        <Route
          path="/portfolio/:moduleName/:scenarioId/:id"
          element={
            <RolesRoute roles={["Admin"]}>
              <PortfolioPage page="update" />
            </RolesRoute>
          }
        />
 
        <Route
          path="/application-landscape"
          element={
            <RolesRoute roles={["Admin"]}>
              <ReportsPage
                heading={"Application Landscape"}
                moduleName={"BusinessApplication"}
                showColorIndicator={true}
              />
            </RolesRoute>
          }
        />
        <Route
          path="/integration-landscape"
          element={
            <RolesRoute roles={["Admin"]}>
              <ReportsPage
                heading={"Integration Landscape"}
                moduleName={"Integration"}
                isIntegrationLandscape={true}
              />
            </RolesRoute>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </AdminLayout>
  </>
);
 
export default AuthenticatedRoutes;