import { DrawIoEmbed } from 'react-drawio';
import './Diagram.scss';
import { useEffect, useState } from 'react';
import { Spin, message } from 'antd';
import diagramServices from '../../services/services/diagramServices';
import { PageTitleHeading } from '../../utils/Common';
import { useLocation, useNavigate } from 'react-router';
import { GoBack } from '../../utils/GoBackHistory';

const DrawioLayout = ({diagramId}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [loading,setLoading] = useState(false);
  const [updateDiagram,setUpdateDiagram] = useState(false);
  const [diagramData,setDiagramData] = useState({});
  const [xml,setXml] = useState("");
  const [svg,setSvg] = useState("");
  const [previousPath,setPreviousPath] = useState("");
  const [moduleData,setModuleData] = useState({}); 
  const [shown,setshown] = useState(true);

  useEffect(() => {
    if (!loading) {
        const timeout = setTimeout(() => {
            setshown(false);
            window.scrollTo(0, 0);
        }, 500);

        // Clean up the timeout
        return () => clearTimeout(timeout);
    }
}, [loading]);


  useEffect(() => {
    if(diagramId){
      getDiagram();
      if(location?.state?.previousPath){
        sessionStorage.setItem("drawioPreviousPath", location?.state?.previousPath || "");
        sessionStorage.setItem("drawioPreviousModuleData",JSON.stringify( location?.state) || {});
        setPreviousPath(location?.state?.previousPath);
        setModuleData(location?.state)
      }else{
        setPreviousPath(sessionStorage.getItem("drawioPreviousPath"));
        setModuleData(JSON.parse(sessionStorage.getItem("drawioPreviousModuleData")))
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[diagramId])

  useEffect(() => {
    if(updateDiagram){
      let obj = {
        "id": diagramId,
        "name": diagramData?.name || "",
        "description": diagramData?.name || "",
        "moduleName": diagramData?.moduleName || "",
        "moduleID":diagramData?.moduleID || "",
        "xml": xml,
        "svg": svg,
      };
      saveDigramData(obj)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[updateDiagram])

  const getDiagram = async () => {
    try {
      setLoading(true);
      const response = await diagramServices.getDiagram(diagramId);
      setDiagramData(response?.data || {})
      setTimeout(() => {
        setLoading(false);     
           
    }, 2000);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  const saveDigramData =async (obj) => {
    try {
      setLoading(true);
      const response = await diagramServices.updatediagram(obj);
      const newData = response?.data || null;
      // Handle success response
      message.success("Updated successfully");
      setTimeout(() => {
          setLoading(false);
          setUpdateDiagram(false)
          getDiagram();
      }, 1000);
    } catch (error) {
        console.log("catcheEror",error)
        setLoading(false);
        setUpdateDiagram(false)
    }
  }

  const getTitleData = () =>{
    let mName= "";
    if(diagramData?.moduleName==="BusinessApplication"){
      mName = "Application";
    }else{
      mName = diagramData?.moduleName?.replace(/([a-z])([A-Z])/g, '$1 $2');
    }


    return <div>
        <div className='mt-3 mb-2 h2'>{mName}: {moduleData?.titleName}</div>
        <div className='h4'>Diagram: {diagramData?.name}</div>
    </div>
  }

 

  
  return (
    <>
      {shown && <div style={{position:'absolute',zIndex:1, height:'130vh',width:window?.innerWidth-300,backgroundColor:'white'}}></div>}
      <Spin className="loading_bx" size="small" spinning={loading}>
        { 
          diagramData?.id && 
          <div className='d-flex justify-content-between align-items-center'>
            {getTitleData()}
            <div className="mt-3 mb-0">
              <GoBack path={previousPath || -1} state={{activeTabKey:'tab_diagram'}} />
            </div>
          </div>
        }
        <DrawIoEmbed 
          className="mt-0"
          xml={diagramData?.xml || ""}
          urlParameters={{
            ui: 'kennedy',
            spin: true,
            libraries: true,
            saveAndExit: false,
            noSaveBtn: false,
            noExitBtn: true,
          }}
          onSave={ (data) => { setSvg(data?.xml || null);} } 
          onExport={(data) => {
            console.log(data);
            setXml(data?.xml || null); 
            setUpdateDiagram(true)
          }}
        />
      </Spin>
      </>
  );
}

export default DrawioLayout;