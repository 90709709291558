import React from 'react';

const LayoutPage = ({component,...props}) => {
    return (
        <>
                {component}
        </>
    )
}

export default LayoutPage