import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import UserService from "../../../services/UserService";
const HeaderMenusItems = () => {
	// const userRole = UserService?.hasRole(['app-manager']);
	const userRole = 'admin';
	
	const getRoleName = () => {
		if(UserService.hasRole(['Admin'])){
			return "Admin";
		}else if(UserService.hasRole(['Editor'])){
			return "Editor";
		}else if(UserService.hasRole(['Viewer'])){
			return "Viewer";
		}else{
			return "Viewer";
		}
	}
	
	const headerMenus = [
		{
			'icon': <UserOutlined />,
			'label': "Hello, "+ UserService.getUserFullName().split(' ')[0] + `(${getRoleName()})`,
			'key': '#',
			'navigate': 'false',
		},
		{
			'icon': <LogoutOutlined />,
			'label': `Logout`,
			'key': 'logout',
			'navigate': 'false',
		}
	]
	return (headerMenus);
}

export default HeaderMenusItems;
