import React from 'react'; 
import DrawioLayout from "../components/diagram/drawio";
import { useParams } from 'react-router';

function DiagramPage() {
    const props=useParams();

    return (
        <>
            <DrawioLayout {...props}/>
        </>
    );
}

export default DiagramPage