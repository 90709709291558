import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button, Flex } from 'antd';
import React, { useEffect, useState } from 'react';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
const FullScereenContent = ({children,open=false,setOpen,...props}) => {
    const [fullscreen,setFullscreen] = useState(false);
    const handle = useFullScreenHandle();

    useEffect(()=>{
        if(fullscreen){
            handle.enter();
            setOpen(fullscreen)
        }else{
            handle.exit();
            setOpen(fullscreen)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[fullscreen])

    return (
        <React.Fragment>
            <FullScreen handle={handle} className='p-2'>
                <Flex justify='space-between'>
                    <div>{props?.title || ""}</div>
                    <Button className='pull-right' onClick={()=>{ setFullscreen(!fullscreen);}} icon={fullscreen?<FullscreenExitOutlined />:<FullscreenOutlined/>}/>
                </Flex>
               {children}
            </FullScreen>
        </React.Fragment>
    )
}

// FullScereenContent.defaultProps = {
//     title:'',
//     open:false,
// }

export default FullScereenContent