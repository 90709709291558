import "./Integration.scss";
import { Button, Flex, Tabs } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useEffect } from "react";
import TabDiagrams from "../../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams";
import TabChangeLogAlerts from "../../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts";
import TabLinks from "../../portfoliosingleentry/portfolioTabs/tablinks/TabLinks";
import TabConnections from "../../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections";
import TabComments from "../../portfoliosingleentry/portfolioTabs/tabcomments/TabComments";
import TabBasicInformation from "./tabs/TabBasicInformation";
import {
  PageTitleHeading,
  getRecentlyView_ScenarioName,
} from "../../../utils/Common";
import { GoBack } from "../../../utils/GoBackHistory";
import { UnorderedListOutlined } from "@ant-design/icons";
import gridSvg from "./../../../assets/Two columns layout.svg";

const IntegrationEntry = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [activeTabKey, setActiveTabKey] = useState("tab_information");
  const [integrationData, setIntegrationData] = useState({});
  const [TabBasicInformationForm, setTabBasicInformationForm] = useState();
  const [portfolioId, setPortfolioId] = useState(null);
  const { id = null, moduleName, scenarioId } = useParams();
  const [scenarioName, setScenarioName] = useState();

  if (!location?.state) {
    location.state =
      JSON.parse(sessionStorage.getItem("edit-portfolio-" + moduleName)) ||
      null;
  } else {
    sessionStorage.setItem(
      "edit-portfolio-" + moduleName,
      JSON.stringify(location?.state)
    );
  }

  const handleTabChange = (key) => {
    console.log("key", key);
    setActiveTabKey(key);
  };

  useEffect(() => {
    getScenarioName();
  }, [scenarioId]);

  const getScenarioName = async () => {
    const scenario = await getRecentlyView_ScenarioName(scenarioId);
    if (scenario?.length) {
      setScenarioName(scenario[0].scenarioName);
    }
  };

  useEffect(() => {
    if (location?.state?.activeTabKey) {
      handleTabChange(location?.state?.activeTabKey);
    }

    if (id?.toLowerCase() === "add") {
      setPortfolioId(null);
    } else {
      setPortfolioId(id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const tabsProps = {
    itemData: integrationData,
    setActiveTabKey,
    handleTabChange,
    // toggleTabDisabled,
    // tabDisabled,
    id: id?.toLowerCase() === "add" ? null : id,
    moduleName: moduleName,
  };

  const IntegrationTabs = [
    {
      label: `Information`,
      key: "tab_information",
      children: (
        <TabBasicInformation
          {...tabsProps}
          setPortfolioData={setIntegrationData}
          basePath={location?.pathname}
          setTabBasicInformationForm={setTabBasicInformationForm}
          locationData={location?.state}
        />
      ),
    },
    {
      label: `Relations`,
      key: "tab_connections",
      disabled: portfolioId ? false : true,
      children: activeTabKey === "tab_connections" && (
        <TabConnections {...tabsProps} />
      ),
    },
    {
      label: `External Links`,
      key: "tab_links",
      disabled: portfolioId ? false : true,
      children: <TabLinks {...tabsProps} />,
    },
    {
      label: `Diagram`,
      key: "tab_diagram",
      disabled: portfolioId ? false : true,
      children: (
        <TabDiagrams {...tabsProps} titleName={integrationData?.DisplayName} />
      ),
    },
    {
      label: `Comments`,
      key: "tab_comments",
      disabled: portfolioId ? false : true,
      children: <TabComments {...tabsProps} />,
    },
    {
      label: `Change log/ Alerts`,
      key: "tab_changelogAlert",
      disabled: portfolioId ? false : true,
      children: <TabChangeLogAlerts {...tabsProps} />,
    },
  ];

  const openPage = (active) => {
    navigate(location?.state?.from, { state: { defaultActiveKey: active } });
  };

  return (
    <div className=" mb-5 text-left">
      <div className="d-flex justify-content-between">
        <div>
          <PageTitleHeading
            text={(() => {
              if (integrationData?.displayname) {
                return (
                  <span>
                    Integration: {integrationData?.LevelID}{" "}
                    {integrationData?.displayname}
                  </span>
                );
              }
              return <span>Add New Integration</span>;
            })()}
          />
        </div>

        {scenarioId && (
          <div
            style={{
              background: "#ffaa02",
              paddingTop: 1,
              paddingLeft: 5,
              paddingRight: 5,
              margin: 3,
              borderRadius: 5,
              height: 25,
            }}
          >
            {scenarioName ? "CURRENT WORKSPACE: " + scenarioName : ""}
          </div>
        )}

        <div className="my-3">
          <Flex gap={8}>
            {/* <Button 
                            onClick={()=>{ openPage(1) }}
                            icon={<UnorderedListOutlined />}
                            block
                            style={{width:'30px'}}
                        /> */}
            {/* <Button 
                            onClick={()=>{ openPage(2) }}
                            icon={<img src={gridSvg} alt="Grid" width={20}/>}
                            block
                            style={{width:'30px'}}
                        /> */}
            {false && (
              <Button
                type="primary"
                className="px-4"
                onClick={() => {
                  TabBasicInformationForm.submit();
                }}
              >
                Save
              </Button>
            )}
            <GoBack path={location?.state?.from} state={location?.state} back />
          </Flex>
        </div>
      </div>
      <Tabs
        type="card"
        className="business-capability-entry"
        activeKey={activeTabKey}
        onChange={handleTabChange}
        items={IntegrationTabs}
      />
    </div>
  );
};

export default IntegrationEntry;
