import { Divider } from "antd";
import React from "react";
import { PaperClipOutlined } from '@ant-design/icons';

const dummyData = [
  // {
  //   name: "Marvin Mckinney",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment: "Lorem ipsum dolor sit amet consectetur. Urna non elit egestas a amet aenean integer ante.",
  //   files : [
  //     "https://www.imagetotext.io/web_assets/frontend/img/icon/right-plan-img.svg",
  //     "https://www.imagetotext.io/web_assets/frontend/img/emoji1.svg",
  //   ],
  //   replies:[
  //     {
  //       name: "Dev John",
  //       updatedAt: "03 Apr,2024, 14:30:25 PM",
  //       comment: "Lorem ipsum dolor sit amet consectetur. Urna non elit egestas a amet aenean integer ante.",
  //       files : [
  //         "https://www.imagetotext.io/web_assets/frontend/img/icon/right-plan-img.svg",
  //         "https://www.imagetotext.io/web_assets/frontend/img/emoji1.svg",
  //       ],
  //       replies : [],
  //     },
  //   ]
  // },
  // {
  //   name: "Jacob Johns",
  //   updatedAt: "03 Apr,2024, 14:30:25 PM",
  //   comment: "Lorem ipsum dolor sit amet consectetur. Urna non elit egestas a amet aenean integer ante.",
  //   files : [],
  //   replies:[],
  // },
  // {
  //   name: "Jerome bell",
  //   updatedAt: "02 Apr,2024, 14:30:25 PM",
  //   comment: "Lorem ipsum dolor sit amet consectetur. Urna non elit egestas a amet aenean integer ante.",
  //   files : [],
  //   replies:[],
  // },
  // {
  //   name: "Albert Flores",
  //   updatedAt: "28 March,2024, 14:30:25 PM",
  //   comment: "Lorem ipsum dolor sit amet consectetur. Urna non elit egestas a amet aenean integer ante.",
  //   files : [],
  //   replies:[],
  // },
];

const CommentList = ({ setActiveTabKey }) => {
  const getFileNameFromUrl = (url) =>{
    return url.split("/").pop();
  }

  const CommentListDom = (lists) => {
    if(lists?.length>0){

      return lists?.map((item,index)=>{
        return (
          <React.Fragment key={Math.random()}>
            <li className="card card-body border-0 bg-efefef mb-2">
              <div><span className="h6">{item?.name || ""} </span>{item?.updatedAt || ""}</div>
              <div>{item?.comment || ""}</div>
              {
                item?.files?.map((file,index)=>{
                  return <React.Fragment key={Math.random()}>
                    <a href={file} target="_blank" rel="noreferrer" key={Math.random()}><PaperClipOutlined /> {getFileNameFromUrl(file)}</a>
                  </React.Fragment>
                })
              }
              <ul>
                {
                  CommentListDom(item?.replies)
                }
              </ul>
            </li>
          </React.Fragment>
        );
      })
    }else{
      return false;
    }
  };

  return (
    <div className="flex flex-col">
      <div className='h4 mb-0'>Previous Comments</div>
      <div className="max-h-[800px] overflow-y-auto">
        {(dummyData?.length>0 && CommentListDom(dummyData)) || <div className="mt-4">No items found.</div>}
      </div>
    </div>
  );
};

export default CommentList;
