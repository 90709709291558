import React, { useState } from 'react'
import BusinessProcessGrid from './BusinessProcessGrid';
import BusinessProcessHeader from './BusinessProcessHeader';
import BusinessProcessList from './BusinessProcessList';
import "./BusinessProcess.scss";
import { useLocation, useParams } from 'react-router';

const BusinessProcess = (props) => {
    const location = useLocation()
    const [activePage,setActivePage] = useState(1);
    const {moduleName} = useParams();
    const heading = "Business Process";
    const [filterOptions, setFilterOptions] = useState({});
    
    useState(()=>{
        setActivePage(location?.state?.defaultActiveKey || 1)
    },[])

    const HeaderContent = <BusinessProcessHeader 
        activePage={activePage}
        setActivePage={setActivePage}
        heading={heading}
        addBtnTooltip={`Level 1`}
        filterOptions={filterOptions}
    />;

    return (
        <div className='applicationlist-page'>
            { 
                activePage === 2 && 
                <BusinessProcessGrid 
                    HeaderContnet = {HeaderContent}
                    moduleName={moduleName}
                />
            }
            { 
                activePage === 1 && 
                <BusinessProcessList 
                    HeaderContnet = {HeaderContent}
                    moduleName={moduleName}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    

                />
            }
        </div>
    )
}

export default BusinessProcess