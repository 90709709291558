const GetColumnFilters = ({field,form,state})=>{
    let filters = [];
    let f1 = Object.values(form.getFieldsValue())?.map(item=>(item));
    if(field?.fieldType==="Free text"){    
        filters = f1?.map(item=>{
            return {
                text: item[field?.fieldName],
                value: item[field?.fieldName],
            }
        })?.sort((a, b) => {
            return a?.value?.localeCompare(b?.value, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        });
    }else if(field?.fieldType==="Single select"){
        filters = f1?.map(item=>{
            let op = field?.data?.propertyOptions.find(f=>f?.id?.toString()===item[field?.fieldName]?.toString());
            return {
                text: op?.name,
                value: item[field?.fieldName],
                id:item[field?.fieldName],
            }
        })?.sort((a, b) => {
            return a?.text?.localeCompare(b?.text, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        });
    }else if(field?.fieldType==="Multi select"){
        let f2 = [];
        f1?.forEach(element => {
            if(element[field?.fieldName]?.length>0){
                f2 = [...f2,...element[field?.fieldName]];
            }
        });
        f2 = [...new Set(f2)];
        filters = f2?.map(item=>{
            let op = field?.data?.propertyOptions.find(f=>f?.id?.toString()===item?.toString());
            return {
                text: op?.name,
                value: item,
                id:item,
            }
        })?.sort((a, b) => {
            return a?.text?.localeCompare(b?.text, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        });
    }else if(field?.fieldType==="Date"){
        filters = f1?.map(item=>{
            return {
                text: item[field?.fieldName],
                value: item[field?.fieldName],
            }
        })?.sort((a, b) => {
            return a?.value?.localeCompare(b?.value, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        });
    }else if(field?.fieldType==="Time"){
        filters = f1?.map(item=>{
            return {
                text: item[field?.fieldName],
                value: item[field?.fieldName],
            }
        })?.sort((a, b) => {
            return a?.value?.localeCompare(b?.value, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        });
    }else if(field?.fieldType==="Date time"){
        filters = f1?.map(item=>{
            return {
                text: item[field?.fieldName],
                value: item[field?.fieldName],
            }
        })?.sort((a, b) => {
            return a?.value?.localeCompare(b?.value, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        });
    }else if(field?.fieldType==="Numeric"){
        filters = f1?.map(item=>{
            return {
                text: item[field?.fieldName],
                value: item[field?.fieldName],
            }
        }).sort((a, b) => Number(a?.value) - Number(b?.value));
    }else if(field?.fieldType==="Long text"){
        filters = f1?.map(item=>{
            return {
                text: item[field?.fieldName],
                value: item[field?.fieldName],
            }
        })?.sort((a, b) => {
            return a?.value?.localeCompare(b?.value, undefined, {
                numeric: true,
                sensitivity: "base",
            });
        });
    }else if(field?.fieldType==="Custom Single select Module"){
        if((field.fieldName==="sourceApp" || field.fieldName==="targetApp") && state[field?.moduleName]?.length){
            filters = f1?.map(item=>{
                let op = state[field?.moduleName].find(f=>f?.id?.toString()===item[field?.fieldName]?.toString());
                return {
                    text: `${op?.displayname} (${op?.referenceid})`,
                    value: item[field?.fieldName],
                    id:item[field?.fieldName],
                }
            })?.sort((a, b) => {
                return a?.text?.localeCompare(b?.text, undefined, {
                    numeric: true,
                    sensitivity: "base",
                });
            });
        }else if(state[field?.fieldName]?.length>0){
            filters = f1?.map(item=>{
                let op = state[field?.fieldName].find(f=>f?.id?.toString()===item[field?.fieldName]?.toString());
                return {
                    text: `${op?.displayname}`,
                    value: item[field?.fieldName],
                    id:item[field?.fieldName],
                }
            })?.sort((a, b) => {
                return a?.text?.localeCompare(b?.text, undefined, {
                    numeric: true,
                    sensitivity: "base",
                });
            });
        }
        
    }
    filters = [...new Map(filters.filter(f=>(f?.value!=="" && f?.value!==null && f?.value!==undefined && f!==null && f!==undefined && f!=="")).map(item => [item["value"], item])).values()]
    return  filters;
}

GetColumnFilters.defaultProps = {
    showTags:true,
    showGloabalTags:true,
    columns:[],
    setColumns:()=>{},
    form:()=>{},
    state:{},
}

export default GetColumnFilters