import React, { useEffect, useRef, useState } from "react";
import useDynamicNavigate from "../../../../utils/navigateUtils";
import { getMenuPortfolio } from "../../../../utils/settingCommon";
import { removeEmptyArrObjOrval } from "../../../../utils/Common";
import PortfolioSettingServices from "../../../../services/services/PortfolioSettingsService";
import { Button, Col, Form, Row, Select, Space } from "antd";
import { CaretDownOutlined, InfoOutlined, PlusOutlined } from "@ant-design/icons";
import PortfolioListModal from "../../common/PortfolioListModal";
import { useSaveState } from "../../../../Context/StateProviderData";
import TextWithTooltip from "../../../../utils/TextWithTooltip";


const ModuleDropdown = ({index,moduleName,name,form,...props}) => {
    const { state, setState } = useSaveState();
    const formItemRef = useRef(null);
    const navigateTo = useDynamicNavigate();
    const [loading,setLoading] = useState(false);
    const [listOptions,setListOptions] = useState([]);
    const [portfolioData,setPortfolioData] = useState(null);
    const [filter,setFilter] = useState({});
    const [page,setPage] = useState(0);
    const [selectedApp,setSelectedApp] = useState(null);
    const [openModuleList,setOpenModuleList] = useState(false);
    const [formItemName,setFormItemName] = useState();
    const [formItemName2,setFormItemName2] = useState();
    
    useEffect(()=>{
        setPortfolioData(getMenuPortfolio({moduleName}))
        let values;
        if(index && name){
            let f1 = form?.getFieldValue(index);
            if(f1 && f1[name]){
                values = f1[moduleName];
            }
        }else if(index && moduleName){
            let f1 = form?.getFieldValue(index);
            if(f1 && f1[moduleName]){
                values = f1[moduleName];
            }
        }else if(name){
            values = form?.getFieldValue(name);
        }else{
            values = form?.getFieldValue(name);
        }
        setSelectedApp(values);

        if(index && name){
            setFormItemName([index,name])
            setFormItemName2(name);
        }else if(index && moduleName){
            setFormItemName([index,moduleName])
            setFormItemName2(moduleName);
        }else if(name){
            setFormItemName(name)
            setFormItemName2(name);
        }else{
            setFormItemName(moduleName)
            setFormItemName2(moduleName);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[]);

    useEffect(()=>{
        if(portfolioData && formItemName2){
            if(state[formItemName2]?.length>0){
                setListOptions(state[formItemName2]);
                setPage(1);
            }else{
                // fetchFilteredData({});
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[portfolioData,formItemName2])

    useEffect(()=>{
        if(state[formItemName2]?.length>0){
            setListOptions(state[formItemName2]);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[state])

    const fetchFilteredData = async () => {
        try {
            if (portfolioData?.moduleName && !loading) {
                setLoading(true);
                let updatedFilter = {
                    moduleName: portfolioData?.moduleName || "",
                    sortBy: "displayname,ASC",
                    ...filter,
                };
                updatedFilter = removeEmptyArrObjOrval(updatedFilter);
                const response = await PortfolioSettingServices?.getFilteredPortfolios( updatedFilter, page+1, 20);
                let items = [...new Map([...listOptions,...response?.data?.data].map(item =>[item["id"], item])).values()]
                if (response?.data?.data?.length>0) {
                    setListOptions(items);
                    setPage(Number(response?.data?.currentPage))
                    updateLocale({key:formItemName2,values:items})
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally{
            setLoading(false);
        }
    };

    var timeout = null;
    const onSearch = (value) => {
        setPage(0)
        setFilter({
            name:value
        })
        if (timeout) {  
            clearTimeout(timeout);
        }
        timeout = setTimeout(function() {
            fetchFilteredData()//this is your existing function
        }, 2000);
    }
    const onClear = () => {
        setPage(0)
        setFilter({})
        setFormValue(null)
    }

    const updateFormData = (value) =>{;
        setSelectedApp(value);
        setFormValue(value);
    }
    
    const Buttons = (item) => {
        let selected = false;

        if(props?.mode==="multiple"){
            selected = selectedApp?.includes(item?.item?.id);
        }else{
            selected = selectedApp===item?.item?.id;
        }
        if(selected){
            return <Button disabled>Added</Button>
        }else{
            return <React.Fragment>
                <Button 
                    type="primary" 
                    icon={<PlusOutlined/>} 
                    onClick={()=>{ 
                        if(props?.mode==="multiple"){
                            updateFormData([...selectedApp,item?.item?.id]);
                        }else{
                            updateFormData(item?.item?.id);
                        } 
                    }}
                ></Button>
            </React.Fragment>
        }
    }

    const setFormValue = (value) =>{
        let f1 = form?.getFieldsValue();

        if(index){
            form?.setFieldsValue({
                ...f1,
                [index]:{
                    ...f1[index],
                    [formItemName2]:value || "",
                }
            })
        }else{
            form?.setFieldsValue({
                ...f1,
                [formItemName2]:isNaN(value)?value:Number(value)
            })
        }
    }

    const updateLocale = ({key,values}) =>{
        if(state[key]?.length>0){
            let a1 = state[key]?.map(item=>{ return item?.id });
            let a2 = values?.map(item=>{ return item?.id });
            
            let filters = a2.filter(n => !a1.includes(n));
            if(filters?.length>0){
                setState({key,value:[...new Map([...state[key],...values].map(item =>[item["id"], item])).values()]})
            }
        }else{
            setState({key,value:values})
        }
    }

    return <React.Fragment>
        <Form.Item name={formItemName} className={props?.FormItemClassName} {...props?.FormItemProps}>
            <Space.Compact className="w-100">
                <Select
                    showSearch
                    allowClear
                    mode={props?.mode}
                    onClear={onClear}
                    value={selectedApp}
                    placeholder={portfolioData?.name}
                    filterOption={(inputValue, currOption)=>{
                        return `${currOption?.option?.displayname || ""} ${currOption?.option?.referenceid || ""}`?.toLowerCase().includes(inputValue?.toLowerCase())
                    }}
                    onSearch={onSearch}
                    ref={formItemRef}
                    onChange={(value)=>{ 
                        updateFormData(value);
                    }}
                    className="w-100"
                    options={
                        listOptions?.map(option=>{
                            return {
                                value: Number(option?.id),
                                label: <TextWithTooltip text={`${option?.displayname}`} characterLimit={props?.characterLimit} />,
                                option,
                            }
                        })
                    }
                />
                { props?.mode==="multiple" && (selectedApp?.length===1) && <Button icon={<InfoOutlined />} onClick={()=>{ navigateTo(portfolioData?.route+"/"+selectedApp[0],true) }} style={{width:35}}/>}
                <Button icon={<PlusOutlined/>} onClick={()=>{ setOpenModuleList(true) }} style={{width:35}}/>
            </Space.Compact>
        </Form.Item>
        {
            openModuleList && 
            <PortfolioListModal 
                open={openModuleList}
                setOpen={setOpenModuleList}
                moduleName={portfolioData?.routeModuleName}
                Buttons={Buttons}
            />
        }
    </React.Fragment>
}

export default ModuleDropdown;

ModuleDropdown.defaultProps = {
    FormItemProps:{},
    mode:"default",
    FormItemClassName:"mb-0",
    characterLimit:25,
    initialValue:"",
}