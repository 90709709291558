import React from "react";
import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
    Row,
    Col,
    message,
    Tooltip,
    Typography,
    Spin,
    Card,
    Button,
    Modal,
    Input,
    Flex,
    Select,
    Form,
} from "antd";
import "./PlantUmlView.scss";

import ScenarioServices from "../../services/services/ScenarioServices";
import PlantUmlEncoder from "plantuml-encoder";

const colors = {
    active: "#52c41a",
    phaseIn: "#ffc107",
    phaseOut: "#a44ca4",
    endOfLife: "#ff0000",
};
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

function PlantUmlView(props) {
    const [form] = Form.useForm();
    const [src, setSrc] = useState("");
    const [scenarioName, setScenarioName] = useState("");
    const [loading, setLoading] = useState(false);
    const [isEmbedDiagramOpen, setIsEmbedDiagramOpen] = useState(false);
    // const [scenarioTitle, setScenarioTitle] = useState("");
    const [windowDimensions] = useState(getWindowDimensions());
    const [embededParams,setEmbededParams] = useState({});
    const [iframeValue, setIframeValue] = useState(``);
    const [selectedFilter, setSelectedFilter] = useState({landscapeType:"Integration",});

    useEffect(() => {
        // setSrc(null);
        getDrawPlantUml();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props,selectedFilter]);

    useEffect(()=>{
        getIframeValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[embededParams])

    const getDrawPlantUml = () => {
        let obj = {};
        obj.scenarioId = Number(props?.pathData?.scenarioId);
        obj = {...obj,...selectedFilter}

        if(obj?.plant){
            setLoading(true);
            ScenarioServices.getDrawPlantUml(obj)
                .then((response) => {
                    // console.log(response?.data?.plantuml, "=====response");
                    // const encode = PlantUmlEncoder.encode(response.data);
                    //     const url = `https://plantuml.truerouta.com/svg/${encode}`;
                    //     setSrc(url);
                    // setScenarioName(response.data.data.name)
                    if (response?.data) {
                        const encode = PlantUmlEncoder.encode(
                            response.data.plantuml,
                        );
                        const url = `https://plantuml.truerouta.com/svg/${encode}`;
                        setSrc(url);
                        setScenarioName(response.data.name);
                    } else {
                        setSrc(``);
                        message.error("No data found...");
                    }
                    setLoading(false);
                })
                .catch((err) => {
                    message.error(err.message);
                    setLoading(false);
                });
        }else{
            setSrc("");
        }
    };

    const getIframeValue = () =>{
        let url = `${process.env.REACT_APP_API_URL}reporting/embedplantuml/${props?.pathData?.scenarioId}?`;
        if(Object?.values(embededParams)?.length>0){
            url+=Object.keys(embededParams)?.map(key=>{ if(embededParams[key]){ return key+"="+embededParams[key];  } return "" })?.filter(f=>(f!==null && f!==undefined && f!=="")).join("&")
        }

        setIframeValue(`<iframe src="${url}" avoidlogin="true" style="margin:0px;padding:0px;border:0px solid #666666;"></iframe>`)
    }

    return (
        <div className="text-left level-grouping-master">
            <Spin className="loading_bx" size="small" spinning={loading}>
                <Row className="mb-2">
                    <Col span={24}>
                        <Typography.Title level={5} className="text-start py-2">
                            {scenarioName || <>&nbsp;</>}
                            <div className="pull-right ">
                                <Button
                                    type="default"
                                    onClick={() => {
                                        setIsEmbedDiagramOpen(
                                            !isEmbedDiagramOpen,
                                        );
                                    }}
                                >
                                    Embed Diagram
                                </Button>
                                <Tooltip>
                                    <Link
                                        to={
                                            props?.pathData?.from?.pathname ||
                                            "/scenario-list"
                                        }
                                        className="pull-right btn btn-sm btn-outline-secondary text-secondary ml-2"
                                    >
                                        <i className="fa fa-arrow-left"></i>{" "}
                                        Back
                                    </Link>
                                </Tooltip>
                            </div>
                        </Typography.Title>
                    </Col>
                </Row>
                <Row>
                    <Col
                        xs={24}
                        md={24}
                        lg={24}
                        className="edit-scenario"
                        style={{ height: windowDimensions?.height - 150 }}
                    >
                        <Card 
                            className="table-responsive dataTable dt-avoid-y-scroll graph_container mt-2 text-start"
                            title={
                                <Form
                                    form={form}
                                    layout={'inline'}
                                    onFinish={(formData)=>{
                                        setSelectedFilter({...selectedFilter,...formData})
                                        setEmbededParams(formData)
                                    }}
                                >
                                    <Form.Item name={'plant'} rules={[{ required: true,message:"Plant is required" }]} className="my-1">
                                        <Select
                                            allowClear
                                            showSearch={true}
                                            options={props?.pathData?.dropdowns?.plant?.filter(f=>f.label!=="__n/a") || []}
                                            style={{width:'250px'}}
                                            placeholder="Plant"
                                            onChange={(val)=>{
                                                setEmbededParams(form.getFieldsValue())
                                            }}
                                        />
                                    </Form.Item>
                                    <Form.Item name={'business'} className="my-1">
                                        <Select
                                            allowClear
                                            showSearch={true}
                                            options={props?.pathData?.dropdowns?.business?.filter(f=>f.label!=="__n/a") || []}
                                            style={{width:'250px'}}
                                            placeholder="Business"
                                            onChange={(val)=>{
                                                setEmbededParams(form.getFieldsValue())
                                            }}
                                        />
                                    </Form.Item>
                                    <div className="my-1">
                                        <Button htmlType="submit">Submit</Button>
                                    </div>
                                </Form>
                            }
                        >
                            {src && 
                                <div className="planuml-div">
                                    <object alt={"planuml daigram"} data={src} ></object>
                                </div>
                            }
                        </Card>
                    </Col>
                </Row>
            </Spin>
            <Modal
                title="Embed Diagram"
                open={isEmbedDiagramOpen}
                onCancel={() =>
                    setIsEmbedDiagramOpen(!isEmbedDiagramOpen)
                }
                footer={false}
                destroyOnClose
            >
                <Input.TextArea
                    value={iframeValue}
                    onChange={(e) => {
                        setIframeValue(e.target.value);
                    }}
                    placeholder="Embed Diagram Iframe"
                    readOnly
                    autoSize={{
                        minRows: 3,
                        maxRows: 5,
                    }}
                />
                <Button
                    type="default"
                    className="mt-2"
                    onClick={() => {
                        navigator.clipboard.writeText(iframeValue);
                        message.success("Copied...");
                    }}
                >
                    Copy
                </Button>
            </Modal>
        </div>
    );
}

export default PlantUmlView;
