import "./DynamicFormFields.scss";
import { ConfigProvider, DatePicker, Form, Input, InputNumber, Select, TimePicker, Tooltip } from "antd";
import { isValidHttpUrl } from "../portfolio/common/PortfolioCommon";
import { useState } from "react";
import { PaperClipOutlined } from "@ant-design/icons";
import { useEffect } from "react";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import "dayjs/locale/en"; // Ensure you've imported the correct locale
import locale from "antd/es/locale/en_US"; // Import Ant Design locale

dayjs.extend(localizedFormat); // Extend with the localizedFormat plugin
dayjs.locale("en"); // Set Day.js locale



export const ContentForm = ({FormItemProps,Content=()=>{}}) =>{
    return <Form.Item {...FormItemProps}>
        <Content/>
    </Form.Item>
}
// ContentForm.defaultProps={
//     Content:()=>{}
// }

// ---------------------------------------------------------------------

export const DatePickerForm = ({FormItemProps,FormFieldProps,value,setValue,...props}) =>{
    const [dateValue, setDateValue] = useState(value ? dayjs(value) : null);

    useEffect(() => {
      setDateValue(value ? dayjs(value) : null);
    }, [value]);

    return (
      <Form.Item {...FormItemProps}>
        <ConfigProvider locale={locale}>
          <DatePicker
            {...FormFieldProps}
            value={dateValue}
            onChange={(date,dateString) => {
              setDateValue(date);
              setValue(FormItemProps?.name,date);
            }}
          />
        </ConfigProvider>
      </Form.Item>
    );
}

// ---------------------------------------------------------------------

export const InputForm = ({FormItemProps,FormFieldProps,link,linkKeys,updateKey,form}) =>{
    const [linkValue,setLinkValue] = useState(null);
    const [isLink,setIsLink] = useState(false);

    useEffect(()=>{
        let value = form.getFieldValue(FormItemProps.name);
        let isHref = isValidHttpUrl(value)?true:false;
        if(isHref){
            setLinkValue(value)
            setIsLink(true)
        }else if(link && updateKey){
            let urlData = getUrlData(link);
            console.log("urlData",urlData)
            if(Object.keys(urlData?.params).find(f=>linkKeys.includes(f)) || Object.keys(urlData?.params).find(f=>updateKey===f)){
                let params = urlData?.params || {};
                params[updateKey] = value;
                let link = urlData?.origin+(urlData?.pathname || "")+"?"+new URLSearchParams(params).toString();

                setLinkValue(link)
                setIsLink(true)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[form])
    
    const onBlur = (e)=>{
        setIsLink(false)
        let value = e?.target?.value || ""
        let isHref = isValidHttpUrl(value)?true:false;
        if(isHref){
            setLinkValue(value)
            setIsLink(true)
        }else if(link && updateKey){
            let urlData = getUrlData(link);
            if(Object.keys(urlData?.params).find(f=>linkKeys.includes(f)) || Object.keys(urlData?.params).find(f=>updateKey===f)){
                let params = urlData?.params || {};
                params[updateKey] = value;
                let link = urlData?.origin+(urlData?.pathname || "")+"?"+new URLSearchParams(params).toString();

                setLinkValue(link)
                setIsLink(true)
            }
        }
    }

    return <Form.Item {...FormItemProps}>
        <Input 
            {...((isLink && linkValue)?{addonAfter:<Tooltip title={"Click here to open the link"}><a href={linkValue} target="_blank" rel="noreferrer" ><PaperClipOutlined /></a></Tooltip>}:{})} 
            onBlur={onBlur} 
            {...FormFieldProps}
            className={`${FormFieldProps?.className} cu-addonbg`}/>
    </Form.Item>
}
// InputForm.defaultProps={

// }
// ---------------------------------------------------------------------

export const InputNumberForm = ({FormItemProps,FormFieldProps}) =>{
    return <Form.Item {...FormItemProps}>
        <InputNumber {...FormFieldProps}/>
    </Form.Item>
}

// ---------------------------------------------------------------------
export const SelectForm = ({FormItemProps,FormFieldProps}) =>{
    return <Form.Item {...FormItemProps}>
        <Select 
            width={`100%`}
            {...FormFieldProps}
        />
    </Form.Item>
}

// ---------------------------------------------------------------------

export const TextAreaForm = ({FormItemProps,FormFieldProps}) =>{
    return <Form.Item {...FormItemProps}>
        <Input.TextArea
            {...FormFieldProps}
        />
    </Form.Item>
}

// ---------------------------------------------------------------------

export const TimePickerForm = ({
  FormItemProps,
  FormFieldProps,
  value,
  setValue,
  ...props
}) => {
  const [timeValue, setTimeValue] = useState(value ? dayjs(value) : null);

  useEffect(() => {
    setTimeValue(value ? dayjs(value) : null);
  }, [value]);
  return (
    <Form.Item {...FormItemProps}>
      <ConfigProvider locale={locale}>
        <TimePicker
          {...FormFieldProps}
          value={timeValue}
          onChange={(time) => {
            setTimeValue(time);
            setValue(FormItemProps?.name,time);
          }}
        />
      </ConfigProvider>
    </Form.Item>
  );
};

const getUrlData = (url)=>{
    let itemUrl = new URL(url);
    let urlParams = new URLSearchParams(itemUrl.search.substring(1));
    itemUrl.params = Object.fromEntries(urlParams);
    return itemUrl;
}