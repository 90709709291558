import React, { useEffect, useState } from 'react'
import FilterButtonToggle from '../../layouts/FilterButtonToggle'
import BusinessProcessFilterContent from './BusinessProcessFilterContent'
import PortfolioTagServices from "../../../services/services/PortfolioTagService";
import { Button, Flex } from 'antd'

const BusinessProcessListFilter = ({showFilter,setShowFilter,lifeCycleStagesColors,activeGridStyle,data,selectedFilters,setSelectedFilters,sortValues,optionalLevel,maxHeight, minHeight,filterSubmit,setFilterSubmit,selectedRelationFilters,setSelectedRelationFilters,portfolioData,isRelationPopup=false,isSourceTargetModuleName=''}) => {

  const [effectivePortfolioData, setEffectivePortfolioData] = useState([]);
  const [tagsDataSourceTarget, setTagsDataSourceTarget] = useState([]);

  useEffect(() => {
    if (data && data.length > 0) {
      setEffectivePortfolioData(data);
    } else if (portfolioData && portfolioData.length > 0) {
      setEffectivePortfolioData(portfolioData);
    } else {
      setEffectivePortfolioData([]);
    }
  }, [data, portfolioData]);

  let style = {maxHeight:maxHeight, minHeight}

  useEffect(() => {
    if(isSourceTargetModuleName != '')
    {
      const fetchTagsDataSourceTarget = async () => {
        try {
          const response = await PortfolioTagServices.getAllTags({
            moduleName: isSourceTargetModuleName,
            addAssociation: false,
          });
          setTagsDataSourceTarget(response);
        } catch (err) {
          console.error("Something went wrong");
        }
      };
      fetchTagsDataSourceTarget();
    }
  }, [isSourceTargetModuleName]);
  
  return (
    <div className='px-2 filter-ani'>
        <FilterButtonToggle
            showFilter={showFilter}
            setShowFilter={setShowFilter}
        />
        {
             (
            <React.Fragment>
              {/* <div className='overflow-y pr-3' style={style}> */}
              {/* <div className={`overflow-y pr-2  ${showFilter?'':'d-none'}`} style={style}> */}
              <div className={`${showFilter?'':'d-none'}`}>
                {effectivePortfolioData?.length > 0 && <BusinessProcessFilterContent
                  lifeCycleStagesColors={lifeCycleStagesColors}
                  activeGridStyle={activeGridStyle}
                  portfolioData={effectivePortfolioData}
                  selectedFilters={selectedFilters}
                  setSelectedFilters={setSelectedFilters}
                  sortValues={sortValues}
                  optionalLevel={optionalLevel}
                  //Filter relations
                  filterSubmit={filterSubmit}
                  setFilterSubmit={setFilterSubmit}
                  selectedRelationFilters={selectedRelationFilters}
                  setSelectedRelationFilters={setSelectedRelationFilters}
                  isRelationPopup={isRelationPopup}
                  isSourceTargetModuleName={isSourceTargetModuleName}
                  tagsDataSourceTarget={tagsDataSourceTarget}
                />}
              </div>
            </React.Fragment>
           )
        }
    </div>
  )
}

BusinessProcessListFilter.defaultProps = {
  maxHeight:(window?.innerHeight-200),
  minHeight:(window?.innerHeight-0)
}

export default BusinessProcessListFilter