import "./../components/portfolio/application/BusinessApplication.scss";
import React, { useEffect, useState } from 'react'
import BusinessApplicationGrid from '../components/portfolio/application/BusinessApplicationGrid'
import BusinessProcessHeader from "../components/businessProcess/BusinessProcessHeader";
import { elementToSVG, inlineResources } from 'dom-to-svg'
import { Button, Input, message, Modal, Tooltip } from "antd";
import { DownloadOutlined, NodeExpandOutlined } from "@ant-design/icons";
import { PageTitleHeading } from "../utils/Common";

const ReportsPage = ({heading,moduleName,...props}) => {
    const [showFullContent,setShowFullContent] = useState(false);
    const [showEmbedDiagramModal,setShowEmbedDiagramModal] = useState(false);
    const [reportFilter,setReportFilter] = useState({});
    const [iframeValue, setIframeValue] = useState(``);

    const exportContentImg =async () => {
        try {
            setShowFullContent(true)
            let svgString;
            await new Promise(resolve => setTimeout(resolve, 500));
            if(moduleName==="Integration"){
                const svgUrl = document.querySelector('.integration-landscape object').data;
                var xmlHttp = null;
    
                xmlHttp = new XMLHttpRequest();
                xmlHttp.open( "GET", svgUrl, false );
                xmlHttp.send( null );
                svgString = xmlHttp.responseText;
            }else if(moduleName==="BusinessApplication"){
                const svgDocument = elementToSVG(document.querySelector('.integration-landscape'))

                // Inline external resources (fonts, images, etc) as data: URIs
                await inlineResources(svgDocument.documentElement)

                // Get SVG string
                svgString = new XMLSerializer().serializeToString(svgDocument);
            }else if(moduleName==="Technology"){
                const svgDocument = elementToSVG(document.querySelector('.integration-landscape'))

                // Inline external resources (fonts, images, etc) as data: URIs
                await inlineResources(svgDocument.documentElement)

                // Get SVG string
                svgString = new XMLSerializer().serializeToString(svgDocument);
            }

            var svgBlob = new Blob([svgString], {type:"image/svg+xml;charset=utf-8"});
            var svgUrl = URL.createObjectURL(svgBlob);
            var downloadLink = document.createElement("a");
            downloadLink.href = svgUrl;
            downloadLink.download = heading+".svg";
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            setShowFullContent(false)
        } catch (error) {
            setShowFullContent(false)
        } finally{
            setShowFullContent(false)
        }
    }

    useEffect(()=>{
        getIframeValue();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    const getIframeValue = () =>{
        let url = `${process.env.REACT_APP_API_URL}portfoliosetting/embed/IntegrationLandscapeView?search=`;
        if(!reportFilter?.isIntegrationLandscape){
            url = `${window?.location?.origin}/iframe-application-landscape?search=`;
        }
        
        url+=encodeURI(JSON.stringify(reportFilter));
        // console.log("url",url);
        
        // url+=JSON_to_URLEncoded(reportFilter);
        setIframeValue(`<iframe src="${url}" avoidlogin="true" style="margin:0px;padding:0px;border:0px solid #666666;"></iframe>`)
    }

    const Buttons = <React.Fragment>
        <Tooltip title={`Export Image`}><Button icon={<DownloadOutlined />} onClick={()=>{ exportContentImg(); }}></Button></Tooltip>
        <Tooltip title={`Embed Diagram`}><Button icon={<NodeExpandOutlined />} onClick={()=>{ setShowEmbedDiagramModal(!showEmbedDiagramModal); }}></Button></Tooltip>
        &nbsp;
    </React.Fragment>

    
    
    return (
        <React.Fragment>
            <div className='business-application-page'>
                {moduleName && <BusinessApplicationGrid
                    HeaderContent={
                        <BusinessProcessHeader 
                            activePage={1}
                            setActivePage={()=>{}}
                            heading={heading}
                            matrix={false}
                            grid={false}
                            list={false}
                            addBtn={false}
                            bulkDelete={false}
                            bulk_delete={false}
                            showActionBtn={false}
                            Buttons = {Buttons}
                        />
                    }
                    heading={heading}
                    showFullContent={showFullContent}
                    moduleName={moduleName}
                    activePage={1}
                    setReportFilter={setReportFilter}
                    reportFilter={reportFilter}
                    {...props}
                />}
            </div>
            <Modal
                open={showEmbedDiagramModal}
                onCancel={()=>{ setShowEmbedDiagramModal(!showEmbedDiagramModal); }}
                footer={false}
                afterOpenChange={(v)=>{ if(v){ getIframeValue(); } }}
            >
                <PageTitleHeading text={`Embed Diagram`} className={`mb-2`}/>
                <hr/>
                <Input.TextArea
                    value={iframeValue}
                    onChange={(e) => {
                        setIframeValue(e.target.value);
                    }}
                    placeholder="Embed Diagram Iframe"
                    readOnly
                    autoSize={{
                        minRows: 3,
                        maxRows: 5,
                    }}
                />
                <Button
                    type="default"
                    className="mt-2"
                    onClick={() => {
                        navigator.clipboard.writeText(iframeValue);
                        message.success("Copied...");
                    }}
                >
                    Copy
                </Button>
            </Modal>
        </React.Fragment>
    )
}

ReportsPage.defaultProps = {
    showColorIndicator:false
}

export default ReportsPage

export const JSON_to_URLEncoded = (element,key,list)=>{
    list = list || [];
    if(typeof(element)=='object'){
      for (var idx in element)
        JSON_to_URLEncoded(element[idx],key?key+'['+idx+']':idx,list);
    } else {
      list.push(key+'='+encodeURIComponent(element));
    }
    return list.join('&');
}