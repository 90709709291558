import React, { useEffect, useState } from "react";
import { Badge, Button, Tooltip, Flex } from "antd";
import {
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router";
import { getDynamicRoutesLink } from "../../utils/portfolioRelationshipCommon";
import { PortfolioItemDetails } from "../portfolio/common/PortfolioCommon";
import { Spin } from "antd";
import "./ApplicationList.scss";

const BusinessApplicationListCards = ({
  handleAddRelationship,
  handleRemoveRelationship,
  dataLoading,
  cardModuleName,
  moduleName,
  freeText,
  addRemoveScenaioPortfolio,
  ...props
}) => {
  const navigate = useNavigate();
  const levelText = (l) => {
    if (l === "L1") {
      return "Level 1";
    }
    if (l === "L2") {
      return "Level 2";
    }
    if (l === "L3") {
      return "Level 3";
    }
  };

  const handleAddOrRemove = async (item) => {
    if (props.addedData.includes(item.id)) {
      // If item is already added, make API call to remove it
      await handleRemoveRelationship({
        //id: item?.relationShipId || item.id ,
        id: item.id,
        moduleName: item?.moduleName,
      });

      removeItemFromServer(item.id);
    } else {
      // If item is not added, make API call to add it
      await handleAddRelationship({
        id: item?.id,
        moduleName: item?.moduleName,
      });
      addItemToServer(item.id);
    }
  };

  const addItemToServer = (itemId) => {
    props.setAddedData([...props.addedData, itemId]);
  };

  const removeItemFromServer = (itemId) => {
    const temp = props.addedData.filter(
      (f) => f.toString() !== itemId.toString()
    );
    console.log("temp", temp);
    props.setAddedData(temp);
  };

  const getCardDescription = (item) => {
    let obj = { ...item };
    //obj.BadgeData = item.ReferenceID;
    obj.BadgeData = item.referenceid;
    //console.log(cardModuleName,"====cardModuleNamecardModuleName")
    if (
      cardModuleName === "BusinessCapability" ||
      cardModuleName === "business_capability"
    ) {
      //obj.BadgeData = item.LevelID;
      obj.BadgeData = item.levelid;
    } else if (
      cardModuleName === "BusinessProcess" ||
      cardModuleName === "business_processes"
    ) {
      //obj.BadgeData = item.LevelID;
      obj.BadgeData = item.levelid;
    }

    return { ...obj };
  };
  const getCard = (item) => {
    // const {BadgeData,DisplayName,Description} = getCardDescription(item);
    const { BadgeData, displayname, description } = getCardDescription(item);
    return (
      <div key={item?.id} className="w-100">
        <table width={`100%`} className="">
          <tbody>
            <tr>
              <td width={`95%`}>
                <PortfolioItemDetails
                  moduleName={moduleName}
                  item={item}
                  // basePath={basePath}
                  defaultActiveKey={1}
                  badgeValue={BadgeData}
                />
                {/* <div 
                  className="my-1 portfolio-item cursor-pointer"
                  onClick={()=>{  
                    let newLink= getDynamicRoutesLink({type:moduleName,id:item?.id});
                    navigate(newLink);
                  }}
                >
                  {BadgeData && <div>
                    <Badge count={BadgeData?.toString()} className="fw-small  badge_id" overflowCount={9999999999}/>
                  </div>}
                  <div className="des-bx">
                    <h4 className="text-primary">{displayname}</h4>
                    <p>{description}</p>{" "}
                  </div>
                </div> */}
              </td>
              <td>
                {props?.showButtons?.view && (
                  <EyeOutlined
                    className="cursor-pointer ml-2"
                    onClick={() => {
                      navigate("/business-capability-master/" + item?.id);
                    }}
                    style={{ fontSize: "20px" }}
                  />
                )}
                {props?.showButtons?.edit && (
                  <EditOutlined
                    className="cursor-pointer ml-2 text-blue-500"
                    onClick={() => {
                      navigate("/business-capability-master/" + item?.id);
                    }}
                    style={{ fontSize: "20px" }}
                  />
                )}
                {props?.showButtons?.delete && (
                  <DeleteOutlined
                    className="cursor-pointer ml-2 text-danger"
                    onClick={() => {
                      props?.setItem(item, { type: "delete" });
                      props?.setModalData({
                        level: 1,
                        ParentID: item?.id,
                        DisplayName: item?.displayname,
                      });
                    }}
                    style={{ fontSize: "20px" }}
                  />
                )}
                {props?.addRemove && (
                  <Button
                    type={`primary`}
                    onClick={() => handleAddOrRemove(item)}
                    {...(() => {
                      if (props?.addedData?.includes(item.id)) {
                        return { disabled: true };
                      } else {
                        return { disabled: dataLoading };
                      }
                    })()}
                    icon={
                      props?.addedData?.includes(item.id) ? (
                        ""
                      ) : (
                        <Tooltip title={`Select`}>
                          <PlusOutlined />
                        </Tooltip>
                      )
                    } //<Tooltip title={`Remove`}><DeleteOutlined /></Tooltip>
                  >
                    {props?.addRemove &&
                      props?.addedData?.includes(item.id) &&
                      `Added`}
                  </Button>
                )}
                {addRemoveScenaioPortfolio && (
                  <>
                    <div className="app-buttons">
                      {!props?.removedData?.includes(item.id) ? (
                        <Button
                          loading={props?.loadingButtonState}
                          style={{ width: 90 }}
                          type={`primary`}
                          onClick={() => handleAddOrRemove(item)}
                          {...(() => {
                            if (props?.addedData?.includes(item.id)) {
                              return { disabled: true };
                            } else {
                              return { disabled: dataLoading };
                            }
                          })()}
                          icon={
                            props?.addedData?.includes(item.id) ? (
                              "Imported"
                            ) : (
                              <Tooltip title={`Import`}>Import</Tooltip>
                            )
                          } //<Tooltip title={`Remove`}><DeleteOutlined /></Tooltip>
                        >
                          {props?.addRemove &&
                            props?.addedData?.includes(item.id) &&
                            `Imported`}
                        </Button>
                      ) : (
                        ""
                      )}

                      {!props?.addedData?.includes(item.id) ? (
                        <Button
                          loading={props?.loadingButtonState}
                          type={`primary`}
                          style={{ width: 90 }}
                          onClick={async () => {
                            props.setRemovedData([
                              ...props.removedData,
                              item.id,
                            ]);
                            await handleRemoveRelationship({
                              id: item.id,
                              moduleName: item?.moduleName,
                            });
                          }}
                          {...(() => {
                            if (props?.removedData?.includes(item.id)) {
                              return { disabled: true };
                            } else {
                              return { disabled: dataLoading };
                            }
                          })()}
                          icon={
                            props?.removedData?.includes(item.id) ? (
                              "Removed"
                            ) : (
                              <Tooltip title={`Remove`}>
                                {/* <DeleteOutlined /> */}
                                Remove
                              </Tooltip>
                            )
                          } //<Tooltip title={`Remove`}><DeleteOutlined /></Tooltip>
                        >
                          {props?.addRemove &&
                            props?.removedData?.includes(item.id) &&
                            `Removed`}
                        </Button>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                )}
              </td>
            </tr>
          </tbody>
        </table>
        <hr className="my-2" />
        {/* <div className="w-100">
          <div className=" d-flex justify-content-between">
            <div>
              <div>
                {item?.LevelID}{" "}
                <span className="h6 text-primary">{item?.DisplayName}</span>
              </div>
              <div>
                <small className="fs-small">{item?.Description}</small>{" "}
              </div>
            </div>
            {
              <div className="text-right">
                {props?.showButtons?.view && (
                  <EyeOutlined
                    className="cursor-pointer ml-2"
                    onClick={() => {
                      navigate("/business-capability-master/" + item?.id);
                    }}
                    style={{ fontSize: "20px" }}
                  />
                )}
                {props?.showButtons?.edit && (
                  <EditOutlined
                    className="cursor-pointer ml-2 text-blue-500"
                    onClick={() => {
                      navigate("/business-capability-master/" + item?.id);
                    }}
                    style={{ fontSize: "20px" }}
                  />
                )}
                {props?.showButtons?.delete && (
                  <DeleteOutlined
                    className="cursor-pointer ml-2 text-danger"
                    onClick={() => {
                      props?.setItem(item, { type: "delete" });
                      props?.setModalData({
                        level: 1,
                        ParentID: item?.id,
                        DisplayName: item?.DisplayName,
                      });
                    }}
                    style={{ fontSize: "20px" }}
                  />
                )}
              </div>
            }
          </div>
          <hr />
        </div>

        {props?.addRemove &&  !props?.addedData?.includes(item.id) && ( 
          <Button
            type={`primary`}
            onClick={() => handleAddOrRemove(item)}
            disabled={dataLoading}>
            {props?.addedData?.includes(item.id) ? "Remove" : "Add"}
          </Button>
        ) }

        {props?.addRemove &&   props?.addedData?.includes(item.id) && ( 
          <div style={{ margin:'7px', height:'30px', padding:'3px', border:'solid 1px blue', borderRadius:'10px'}} >
            Added
          </div>
        ) } */}
      </div>
    );
  };

  const sortBCMData = (lists) => {
    return lists?.sort((a, b) => {
      return a.levelid.localeCompare(b.levelid, undefined, {
        numeric: true,
        sensitivity: "base",
      });
    });
  };

  return (
    <>
      <div>
        {props?.lists.length === 0 && (
          <Spin
            size="small"
            spinning={
              freeText === "Data Not Found" || freeText === "No items found."
                ? false
                : true
            }
          >
            <div style={{ fontSize: 16, fontWeight: 600 }}>{freeText}</div>
          </Spin>
        )}
        {props?.lists?.map((element, i) => {
          let children = [element];

          element?.child?.forEach((element2) => {
            children.push(element2);
            element2?.child?.forEach((element3) => {
              children.push(element3);
              element3?.child?.forEach((element4) => {
                children.push(element4);
              });
            });
          });

          if (props.filterText.length > 0) {
            if (props.filterBy === "Name") {
              children = children.filter((item) =>
                item?.DisplayName.includes(props.filterText)
              );
            } else if (props.filterBy === "Level") {
              children = children.filter((item) =>
                item?.Level.includes(props.filterText)
              );
            } else if (props.filterBy === "Description") {
              children = children.filter((item) =>
                item?.DisplayName.includes(props.filterText)
              );
            }
          }

          return sortBCMData(children)?.map((item) => {
            if (
              props.filterLevel.includes(item?.Level) ||
              props.filterLevel.length === 0
            ) {
              return getCard(item);
            } else {
              return <div key={item?.id}></div>;
            }
          });
        })}
      </div>
    </>
  );
};

export default BusinessApplicationListCards;
