import { Layout, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import Tree from 'react-d3-tree';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import TextWithTooltip from '../../../utils/TextWithTooltip';
import useDynamicNavigate from '../../../utils/navigateUtils';

const PlantTree = ({HeaderContnet,...props}) => {
    const navigateTo = useDynamicNavigate();
    const nodeSize = { x: 300, y: 150 };
    const [translate, containerRef] = useCenteredTree();
    const [portfolio,setPortfolio] = useState(null);
    const [orgChartData,setOrgChartData] = useState({});
    const [spinning,setSpinning] = useState(false);

    useEffect(()=>{
        let p = getMenuPortfolio({routeModuleName:"Plant"})
        setPortfolio(p);
        const orgChart = {
            name:"Organization",
            children:[],
        }
        setOrgChartData(orgChart);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    useEffect(()=>{
        if(portfolio){
            getPlantTree();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[portfolio])

    const getPlantTree = () => {
        setSpinning(true)
        portfolio?.services?.getPlantTree()?.then(response=>{
            if(response?.data?.data){
                let res = response?.data?.data?.categories;
                const orgChart = {
                    name:"Organization",
                    children:res,
                }
                setOrgChartData(orgChart);
            }
        }).then(err=>{
            console.log(err);
        }).finally(()=>{
            setSpinning(false)
        })
    }
    
    return (
        <React.Fragment>
            {HeaderContnet}
            <Spin spinning={spinning}>
                <Layout>
                    <Layout.Content className='border-right plant-tree-page'>
                        <div 
                            id="treeWrapper"
                            style={{ width: "100%", height: window?.innerHeight-150 }}
                            ref={containerRef}
                        >
                            <Tree 
                                data={orgChartData}
                                translate={translate}
                                renderCustomNodeElement={(e)=>{ 
                                    return renderForeignObjectNode({...e,portfolio,navigateTo}) 
                                }}
                                orientation="vertical"
                                pathFunc={`step`}
                                nodeSize={nodeSize}
                                zoomable={true}
                                initialDepth={1}
                                centeringTransitionDuration={800}
                            />
                        </div>
                    </Layout.Content>
                </Layout>
            </Spin>
        </React.Fragment>
    )
}

PlantTree.defaultProps = {
    HeaderContnet:()=>{},
}

export default PlantTree;

const renderForeignObjectNode = ({ nodeDatum, toggleNode, foreignObjectProps = {},portfolio,navigateTo,...props }) => {
    let style = {
        width:200,
        height:150,
        x:-75.5,
        y:-50,
    }

    if(style){
        style.x = -(style.width/2)
    }

    let typeName = "";
    if(portfolio){
        if(portfolio?.portfolioSections?.length > 0){
            portfolio?.portfolioSections.forEach(section => {
                if(section?.properties?.length>0){
                    section?.properties?.forEach(properties => {
                        if(properties?.name==="type"){
                            typeName = properties?.propertyOptions?.find(f=>(f?.id?.toString()===nodeDatum?.type?.toString()))?.name || "";
                        }
                    });
                }
            });
        }
    }

    return (
        <React.Fragment>
            <foreignObject 
                {...foreignObjectProps}
                {...style}
            >
                <div className='pt-3'  >
                    <div className="cu-fieldset" >
                        <fieldset className="fieldset" style={{borderColor:`${portfolio?.color==="#FFFFFF"?"#ddd":portfolio?.color}`}}>
                            {typeName && <label className="fieldset-label border" style={{background:portfolio?.color}}><TextWithTooltip text={typeName || ``} characterLimit={15}/></label>}
                            <div className="m-3" onClick={()=>{ navigateTo(`${portfolio?.route}/${nodeDatum.id || ""}`) }}>
                                {nodeDatum?.name}
                            </div>
                        </fieldset>
                    </div>
                    {
                        nodeDatum?.children?.length >0 && <div className='d-flex justify-content-center align-items-center'><div className='cu-tree-node' style={{background:(portfolio?.color || "#ddd")}} onClick={toggleNode}>{nodeDatum?.children?.length}</div></div>
                    }
                </div>
            </foreignObject>
        </React.Fragment>
    )
};
    

export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
    const [translate, setTranslate] = useState(defaultTranslate);
    const containerRef = useCallback((containerElem) => {
      if (containerElem !== null) {
        const { width, height } = containerElem.getBoundingClientRect();
        setTranslate({ x: width / 2, y: height / 2 });
      }
    }, []);
    return [translate, containerRef];
};