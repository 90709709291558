import { LockOutlined, UnlockOutlined } from '@ant-design/icons';
import React from 'react';
import { PageTitleHeading } from '../../utils/Common';

const rotate90 = {
    writingMode: 'vertical-rl',
    transform: 'rotate(180deg)',
    // margin:'50px -5px 10px',
}
const FilterButtonToggle = ({setShowFilter,showFilter,text,setSelectedFilters}) => {
    const handleMouseEnter = () => {
        setShowFilter(!showFilter);
      };

    return (
        <div className={`fs-3 w-100 cursor-pointer mt-3`} 
        onClick={()=>setShowFilter(!showFilter)}
        // onMouseEnter={()=>handleMouseEnter}
        // onMouseLeave={()=>handleMouseEnter}
        >
            <div className={showFilter?`row align-items-center filterbx`:``}>
                <div className={`${showFilter?`col p-0 d-flex justify-content-end `:``}`}>{showFilter?<UnlockOutlined style={{ fontSize: "18px", color: "#497aab" }}/>:<LockOutlined style={{ fontSize: "18px", color: "#497aab",}}/>}</div>
                <div className={`${showFilter?`col p-0 text-left`:`d-flex align-items-center animatefilter`}`} style={showFilter?{margin:'0px 0px 0px'}:rotate90}><PageTitleHeading text={text} size={3} className={``}/></div> 
            </div>
        </div>
    )
}

FilterButtonToggle.defaultProps = {
    text:"Filter",
}

export default FilterButtonToggle