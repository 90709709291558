import React, { useEffect, useRef, useState } from 'react';
import { getAllFields, LandscapeContent } from '../../portfolio/application/BusinessApplicationGrid';
import { Card } from 'antd';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { removeEmptyKeys } from '../../integration/IntegrationCommon';
import PortfolioSettingServices from '../../../services/services/PortfolioSettingsService';
import PlantUmlEncoder from 'plantuml-encoder';

const ScenarioIntegrationGridV2 = ({moduleName,isIntegrationLandscape,scenarioId,scenarioModuleName,...props}) => {
    const ref = useRef(null)
    const [loading,setLoading] = useState(true);
    const [records, setRecords] = useState([]);
    const [portfolioData, setPortfolioData] = useState(null);
    const [grouppingFilter, setGrouppingFilter] = useState({});
    const [src, setSrc] = useState("");
    
    useEffect(()=>{
        setPortfolioData(getMenuPortfolio({routeModuleName:moduleName}));
        setGrouppingFilter(props?.grouppingFilter);
        
    },[moduleName,props?.grouppingFilter]);

    useEffect(()=>{
        if(portfolioData){
            fetchFilteredData({});
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[moduleName,portfolioData,scenarioId]);

    const fetchFilteredData = async (filter) => {
        try {
            setLoading(true);
            setRecords([]);
            if (ref.current) {ref.current.continuousStart()}
            if(portfolioData?.moduleName && Object.values(grouppingFilter).length>0){
                const selectedProperties = [];
                // return;
                let  updatedFilter = {
                    moduleName: scenarioModuleName || portfolioData?.moduleName || "",
                    selectedProperties: selectedProperties,
                    ...grouppingFilter,
                    ...filter,
                    isIntegrationLandscape,
                    scenarioId:scenarioId?Number(scenarioId):"",
                };
                updatedFilter = removeEmptyKeys(updatedFilter);
                const response = await PortfolioSettingServices?.getSwimlaneViewData( updatedFilter );
                if (response?.data?.data[0]?.plantUML && isIntegrationLandscape) {
                    const encode = PlantUmlEncoder.encode(response?.data?.data[0]?.plantUML);
                    const plantUMLUrl = `https://plantuml.truerouta.com/svg/${encode}`;
                    setSrc(plantUMLUrl);
                }else{
                    const lists = response?.data?.data?.map(item=>{
                        item.moduleName = moduleName;
                        return item;
                    });
                    setSrc("");
                    if (lists?.length) {
                        setRecords(lists);
                    } else {
                        // //message.info("No records found.");
                        setRecords(response?.data?.data);
                    }
                }
            }
            if (ref.current && records.length ) {ref.current.complete()}
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setLoading(false); 
            if (ref.current) {ref.current.complete()}        
        }
    };
    return (
        <React.Fragment>
            <Card className='my-2 py-2'>
                <LandscapeContent
                    isIntegrationLandscape={isIntegrationLandscape}
                    loading={loading}
                    records={records}
                    src={src}
                />
            </Card>
        </React.Fragment>
    )
}

ScenarioIntegrationGridV2.defaultProps = {
    isIntegrationLandscape:true,
    moduleName:"Integration",
    scenarioId:0,
    grouppingFilter:{},
    scenarioModuleName:"",
}

export default ScenarioIntegrationGridV2