import React from 'react'
import { Input, Select, Form,Space,Checkbox,Radio } from "antd";

const FormFields = ({type,label,name,value,className,rules,options,...props}) => {

    const getField  = () =>{
        if(type?.toLowerCase()==='input'){
            return <Input
                placeholder={props?.placeholder || ""}
                onBlur={props?.onBlur}
            />
        }else if(type?.toLowerCase()==='textarea'){
            return <Input.TextArea
                rows={props?.rows || 1}
                columns={props?.columns || 1}
                placeholder={props?.placeholder || ""}
                onBlur={props?.onBlur}
            />
        }else if(type?.toLowerCase()==='select'){
            let o = {};
            if(props?.optionFilterProp){
                o.optionFilterProp = props?.optionFilterProp;
            }
            if(props?.filterOption){
                o.filterOption = props?.filterOption;
            }
            if(props?.filterSort){
                o.filterSort = props?.filterSort;
            }
            return <Select
                multiple={props?.multiple || false}
                showSearch = {props?.showSearch || true}
                allowClear = {props?.allowClear || true}
                style={{width:'100%'}}
                placeholder={props?.placeholder || "Select"}
                onChange={props?.onChange}
                options={options && options?.length > 0 ? options : []} // Check if options exists and has length
                notFoundContent="No Data Found" // Message to display when no options are available
                {...o}
            />
        }else if(type?.toLowerCase()==='radio'){
            return <Radio.Group>
                    {
                        options?.map((e, i) => {
                            return (
                                <Radio key={Math.random()} value={e.value}>{e.label}</Radio>
                            );
                        })
                    }
                </Radio.Group>
        }else if(type?.toLowerCase()==='checkbox'){
            return <Space direction={props?.direction || "horizontal"} className={className}>
                {
                    options?.map((e, i) => {

                        let o ={};
                        if(value?.includes(e?.value)){
                            o.checked=true;
                        }

                        return (
                            <Checkbox 
                                key={Math.random()}
                                value={e?.value} 
                                {...o}
                            >
                                {e.label}
                            </Checkbox>
                        );
                    })
                }
            </Space>
        }
        return '';
    }

    
    
    return (
        <>
            <Form.Item
                label={label}
                name={name}
                rules={rules}
                initialValue={props?.initialValue || null}
                className={props?.formClassName}
                {...props?.columns} //{labelCol:{ span: 24 },wrapperCol:{ span: 24 }}
            >
                {getField()}
            </Form.Item>
        </>
    )
}

export default FormFields