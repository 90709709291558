import './Integration.scss';
import { Flex, } from "antd";   
import React from "react";
import { useLocation, useParams } from "react-router";
import { PageTitleHeading } from '../../../utils/Common';
import { GoBack } from '../../../utils/GoBackHistory';
import TabBulkEntry from './tabs/TabBulkEntry';

const IntegrationBulkEntry = (props) => {
    const location = useLocation();
    const { moduleName } = useParams();

    if(!location?.state){
        location.state = JSON.parse(sessionStorage.getItem('edit-portfolio-'+moduleName)) || null;
    }else{
        sessionStorage.setItem('edit-portfolio-'+moduleName,JSON.stringify(location?.state))
    }

    return (
        <div className=' mb-5 text-left'>
            <Flex gap={8} justify='space-between' align='flex-end'>
                <div>
                    <PageTitleHeading text={
                            ((()=>{
                                if(location?.state?.bulkUpdateIds?.length>0){
                                    return <span>Integration - Bulk Update</span>;
                                }
                                return <span>Integration - Bulk Entry</span>;
                            })())
                        }
                    />
                </div>
                <div className='my-3'>
                    <Flex gap={8}>
                        <GoBack path={location?.state?.from} state={location?.state} back/>
                    </Flex>
                </div>
            </Flex>
            <TabBulkEntry moduleName={moduleName} bulkUpdateIds={location?.state?.bulkUpdateIds}/>
        </div>
    );
}

export default IntegrationBulkEntry;