import React from "react";
import FormField from "../../../components/portfolio/common/FormField";
import ModuleDropdown from "./ModuleDropdown";
import AppsDropdown from "./AppsDropdown";
import { getMenuPortfolio } from "../../../utils/settingCommon";
import { deepFindByName } from "../../../utils/Common";

export const columnsRelationPortfolio = [
  "dataobject",
  "business_processes",
  "informationobject",
];

const UpdateColumnData = ({
  columns,
  setColumns,
  portfolioData,
  form,
  state,
  freeColumnName,
  ...props
}) => {
  let tempColumns = [];

  //User Column Prefrence
  let prefColumns = [];

  let getSelecteddata = localStorage.getItem("Scenario_Itegration_Column");
  if (getSelecteddata) {
    prefColumns = JSON.parse(getSelecteddata);
    // prefColumns.fromIntegration
    //prefColumns.sourceApplication
  }

  // Source App
  let sourceAppCol = {
    title: "Source App",
    dataIndex: Math.random(),
    fieldName: "sourceApp",
    moduleName: "business_application",
    fieldType: "Custom Single select Module",
    width: "250px",
    filters: [],
    render: (text, record, index) => {
      return (
        <AppsDropdown
          title={"Source App"}
          name="sourceApp"
          index={record?.key}
          form={form}
          initialDropdowns={
            props?.relationShipDropdowns?.business_application || []
          }
          setDropdowns={(items) => {
            props?.setRelationShipDropdowns({
              ...props?.relationShipDropdowns,
              ["business_application"]: items,
            });
          }}
        />
      );
    },
  };
  tempColumns.push(sourceAppCol);

  // Target App
  let targetAppCol = {
    title: "Target App",
    dataIndex: Math.random(),
    fieldName: "targetApp",
    moduleName: "business_application",
    fieldType: "Custom Single select Module",
    width: "250px",
    filters: [],
    render: (text, record, index) => {
      return (
        <AppsDropdown
          title={"Target App"}
          name="targetApp"
          index={record?.key}
          form={form}
          initialDropdowns={
            props?.relationShipDropdowns?.business_application || []
          }
          setDropdowns={(items) => {
            props?.setRelationShipDropdowns({
              ...props?.relationShipDropdowns,
              ["business_application"]: items,
            });
          }}
        />
      );
    },
  };
  tempColumns.push(targetAppCol);

  const portfolioValues = deepFindByName(portfolioData, "integration", false);

  // Portfolio Items
  portfolioValues?.portfolioSections
    ?.sort((a, b) => a.position - b.position)
    ?.forEach((col) => {
      col?.properties
        .sort((a, b) => a.position - b.position)
        ?.forEach((col1) => {
          if (prefColumns?.fromIntegration?.includes(col1?.displayName)) {
            let colData = {
              title: col1?.displayName,
              dataIndex: Math.random(),
              fieldName: col1?.name,
              fieldType: col1?.type,
              ellipsis: true,
              data: col1,
              width: 150,
              filters: [],
              render: (text, record, index) => {
                return (
                  <FormField field={col1} index={record?.key} form={form} />
                );
              },
            };

            tempColumns.push(colData);
          }
        });
    });

  // Tags
  //if(state?.tags && state?.tags[portfolioData?.moduleName]){
  if (state?.tags && state?.tags["integration"]) {
    let colData = {
      title: "Tags",
      dataIndex: Math.random(),
      fieldName: "tags",
      fieldType: "Multi select",
      data: {
        name: "tags",
        displayName: "Tags",
        type: "Multi select",
        //propertyOptions:state?.tags[portfolioData?.moduleName],
        propertyOptions: state?.tags["integration"],
      },
      width: 150,
      filters: [],
      render: (text, record, index) => {
        return (
          <FormField
            field={{
              name: "tags",
              displayName: "Tags",
              type: "Multi select",
              //propertyOptions:state?.tags[portfolioData?.moduleName],
              propertyOptions: state?.tags["integration"],
            }}
            index={record?.key}
            form={form}
          />
        );
      },
    };
    tempColumns.push(colData);
  }

  // Global Tags
  state?.globalProperties?.forEach((item) => {
    let colData = {
      title: item?.templatename,
      dataIndex: Math.random(),
      fieldName: item?.templatepropname,
      fieldType: item?.type,
      width: 150,
      filters: [],
      data: {
        name: item?.templatename,
        displayName: item?.templatepropname,
        type: item?.type,
        // eslint-disable-next-line no-useless-computed-key
        propertyOptions: item?.templateOption?.map((item) => ({
          ...item,
          ["name"]: item?.option,
        })),
      },
      render: (text, record, index) => {
        return (
          <FormField
            field={{
              name: item?.templatepropname,
              displayName: item?.templatename,
              type: "Multi select",
              // eslint-disable-next-line no-useless-computed-key
              propertyOptions: item?.templateOption?.map((item) => ({
                ...item,
                ["name"]: item?.option,
              })),
            }}
            index={record?.key}
            form={form}
          />
        );
      },
    };
    tempColumns.push(colData);
  });

  // Relationship Portfolio
  if (columnsRelationPortfolio?.length) {
    columnsRelationPortfolio?.forEach((moduleName) => {
      let portfolioMenu = getMenuPortfolio({ moduleName });

      let colPortfolio = {
        title: portfolioMenu?.name,
        dataIndex: Math.random(),
        fieldName: portfolioMenu?.moduleName,
        fieldType: "Custom Multiple select Module",
        width: "280px",
        filters: [],
        render: (text, record, index) => {
          return (
            <ModuleDropdown
              moduleName={portfolioMenu?.moduleName}
              index={record?.key}
              form={form}
              characterLimit={15}
              mode={"multiple"}
              initialDropdowns={
                props?.relationShipDropdowns[portfolioMenu?.moduleName] || []
              }
              setDropdowns={(items) => {
                props?.setRelationShipDropdowns({
                  ...props?.relationShipDropdowns,
                  [moduleName]: items,
                });
              }}
            />
          );
        },
      };
      tempColumns.push(colPortfolio);
    });
  }

  //  freeColumnName
  freeColumnName?.map((item) => {
    if (prefColumns?.freeColumns?.includes(item.displayname)) {
      let colData = {
        title: item.displayname,
        dataIndex: Math.random(),
        fieldName: item.name,
        fieldType: "Free text",
        ellipsis: true,
        data: {
          name: item.name,
          displayName: item.displayname,
          type: "Free text",
        },
        width: 150,
        filters: [],
        render: (text, record, index) => {
          return (
            <FormField
              field={{
                name: item.name,
                displayName: item.displayname,
                type: "Free text",
              }}
              index={record?.key}
              form={form}
            />
          );
        },
      };

      tempColumns.push(colData);
    }
  });

  setColumns(tempColumns);

  return false;
};

UpdateColumnData.defaultProps = {
  showTags: true,
  showGloabalTags: true,
  columns: [],
  setColumns: () => {},
  form: () => {},
  state: {},
};

export default UpdateColumnData;
