import React, { useEffect,useState, useRef } from 'react'
import { useNavigate, useParams } from 'react-router';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import usePortfolioFetcher from '../../../hooks/usePortfolioFetcher';
import { usePortfolioSetting } from '../../../Context/portfolioSettingProvider';
import { Button, Layout, Modal, Pagination, Spin, message } from 'antd';
import { PageTitleHeading, debounceTimeOut, default_pageSize, default_pageSizeOptions, getSelectProperties, removeEmptyArrObjOrval, removeEmptyKeys } from '../../../utils/Common';
import PortfolioSettingServices from '../../../services/services/PortfolioSettingsService';
import useDebounce from '../../../helper/useDebounce';
import BusinessProcessListContent from '../../businessProcess/list/BusinessProcessListContent';
import BusinessProcessListFilter from '../../businessProcess/list/BusinessProcessListFilter';
import { PlusOutlined } from '@ant-design/icons';
 
export default function PortfolioCommon() {
    return (
        <div>
        
        </div>
    )
}

 
const ExpandableText = ({ text, maxLength }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  if (text?.length <= maxLength) {
    return <p>{text}</p>;
  }

  if (text?.length > maxLength) {
  
  return (
    <div>
      <p   >
        {isExpanded ? text : `${text?.substring(0, maxLength)}...`}
      </p>
      {/* <button onClick={toggleExpand} style={{ color: "blue", cursor: "pointer" }}>
        {isExpanded ? 'Show Less' : 'Show More'}
      </button> */}
    </div>
  );
}
};

export const PortfolioItemDetails = ({page,limit, item,moduleName,routeModuleName,basePath,defaultActiveKey,onClick,badgeValue,}) =>{
    const navigate = useNavigate();
    const param = useParams()
    const getModuleData = getMenuPortfolio({moduleName:moduleName,routeModuleName:routeModuleName});
    console.log("testing portfolio two state pageandlimit",page,limit);
    
    return <React.Fragment key={Math.random()}>
        <div 
            className="my-1 portfolio-item cursor-pointer"
            onClick={()=>{
                onClick(item);
                let newLink  = getModuleData?.route ? getModuleData?.route+'/'+item?.id : '/';
                if(param?.id){
                    window.open(newLink, '_blank');
                }else{
                    navigate(newLink,{ state: {from:basePath,defaultActiveKey:defaultActiveKey,page:page,limit:limit,...item}});
                }
                
            }}
        >
            {/* <div>{moduleName} ,{routeModuleName}</div> */}
            {
                badgeValue &&
                <div>
                    <div className="badge_id px-1" style={{backgroundColor:getModuleData?.color}}>{badgeValue?.toString() || ""}</div>
                </div>
            }
            <div className="des-bx">
                <h4 className="text-primary">{item?.displayname}</h4>
                {/* <p>{item?.description}</p> */} 
            </div>
        </div>
        <div className="des-bx">
            <ExpandableText text={item?.description} maxLength={250} />
        </div>

    </React.Fragment>
}

PortfolioItemDetails.defaultProps = {
    item:{},
    moduleName:'',
    onClick:()=>{},
    badgeValue:'',
    defaultActiveKey:1,
    basePath:'',
}

export const isValidHttpUrl = (string)=> {
    let url;
    try {
      url = new URL(string);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
}

export const showViewType = ({moduleName,viewType}) =>{
  let moduleData = getMenuPortfolio({moduleName});
  let viewTypes = moduleData?.view_type?.split(",");
  // console.log('testing view Type moduleData', moduleData);
  
  if(viewTypes?.includes(viewType)){
    return true;
  }else{
    return false;
  }
}