import React from 'react';
import PlantUmlView from '../components/plantUml/PlantUmlView';
import {useLocation} from 'react-router-dom';

function PlantUmlViewLayout(props) {
  const location = useLocation();
  const plantUmlState = localStorage.getItem("plantUmlState");
  if(location?.state){
    localStorage.setItem("plantUmlState", JSON.stringify(location.state));
  }
  return (
    <>
        <PlantUmlView pathData = {location.state || JSON.parse(plantUmlState)}/>
    </>
  )
}

export default PlantUmlViewLayout;
