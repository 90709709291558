import React, { useEffect, useState } from 'react'
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import {getMenuPortfolio} from '../../../utils/settingCommon'
import "./Integration.scss";
import DataObjectGrid from '../plant/PlantGrid';
import DataObjectList from '../plant/PlantList';
import { showViewType } from '../common/PortfolioCommon';

const DataObject = ({moduleName}) => {
    const [activePage,setActivePage] = useState(0);
    const [moduleData,setModuleData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({});   
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);
    const [deleteStart,setDeleteStart] = useState(false);

    useEffect(()=>{
        setActivePage(1)
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])
    
    const heading = moduleData?.name || moduleName;

    const HeaderContnet = <BusinessProcessHeader 
        activePage={activePage}
        setActivePage={setActivePage}
        heading={heading}
        addBtnTooltip={`Add New`}
        grid={false}
        list={false}
        filterOptions={filterOptions} 
        bulk_delete={bulkDeleteButton}
        setDeleteStart={setDeleteStart}
    />
    
    return (
        <div className='applicationlist-page'>
            { 
                activePage === 2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                <DataObjectGrid 
                    HeaderContnet = {HeaderContnet}
                    moduleName={moduleName}
                />
            }
            { 
                activePage === 1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                <DataObjectList
                    HeaderContnet = {HeaderContnet}
                    moduleName={moduleName}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    setBulkDeleteButton={setBulkDeleteButton}
                    deleteStart={deleteStart}
                    setDeleteStart={setDeleteStart}
                />
            }
        </div>
    )
}

export default DataObject