import { encodeQueryData } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL

// filterOrder
async function getList() {
    const url = `${API_BASE_URL}comment`;
    const response = await http.post(url);
    return response;
}


async function create(data) {
    const url = `${API_BASE_URL}comment`;
    let response = await http.post(url, data);
    return response;
}

// async function update(data) {
//     const id = data.id;
//     delete data.id;
//     const body = data;
//     const url = `${API_BASE_URL}dataobject/`+id;
    
//     let response = await http.put(url, body);
//     return response;
// }

// async function deleteItem(id) {
//     const url = `${API_BASE_URL}dataobject/`;
//     let response = await http.delete(url + id);
//     return response;
// }

// async function getListByID(id) {
//     const url = `${API_BASE_URL}dataobject/`;
//     let response = await http.get(url + id);
//     return response;
// }

const CommentServices = {
    getList,
    create,
    // update,
    // deleteItem,
    // getListByID
}


export default CommentServices;
