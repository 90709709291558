import { Link } from 'react-router-dom';
import DotSvg from './../../assets/Dot.svg';
const HomeQuickStart = ( {person}) => {
    // const encode = plantumlEncoder.encode(props.src);
  
    return ( 
    <>  
    <div className='quickGuid'> 
        <h3 className='my-3'>Quick Short Guide</h3>
        <ul className='quickGuid'>
            <li><Link className='quickGuid-img-link2 quickGuid' href='/#'><img src={DotSvg} alt={`Create Scenario`} style={{width:'20px'}}/>Creating Scenario</Link></li>
            <li><Link className='quickGuid-img-link2 quickGuid'href='/#'><img src={DotSvg} alt={`Create Diagram`} style={{width:'20px'}}/>Creating Diagram</Link></li>
            <li><Link className='quickGuid-img-link2 quickGuid'href='/#'><img src={DotSvg} alt={`Business Capability Mapping`} style={{width:'20px'}}/>Business Capability Mapping</Link></li>
            <li><Link className='quickGuid-img-link2 quickGuid' href='/other-settings'><img src={DotSvg} alt={`Manage Portfolio Properties`} style={{width:'20px'}}/>Manage Portfolio Properties</Link></li>
        </ul> 
    </div>
    </>
    );
};
export default HomeQuickStart;