import { useLocation, useParams } from 'react-router';
import '../../businessCapabilityEntry/BusinessCapabilityEntry.scss';
import { Tabs } from "antd";   
import { useEffect, useState } from 'react';
import TabBCBasicInformation from '../../businessCapabilityEntry/BCETabs/TabBCBasicInformation/TabBCBasicInformation';
import TabConnections from '../../businessCapabilityEntry/BCETabs/TabBCConnections/TabConnections';
import TabLinks from '../../portfoliosingleentry/portfolioTabs/tablinks/TabLinks';
import TabDiagrams from '../../portfoliosingleentry/portfolioTabs/tabdiagrams/TabDiagrams';
import TabComments from '../../portfoliosingleentry/portfolioTabs/tabcomments/TabComments';
import TabChangeLogAlerts from '../../portfoliosingleentry/portfolioTabs/tabchangelogalerts/TabChangeLogAlerts';
import { PageTitleHeading,getRecentlyView_ScenarioName } from '../../../utils/Common';
import { GoBack } from '../../../utils/GoBackHistory';
 
const BusinessCapabilityEntry = (props) => {
    const location = useLocation();
    if(!location?.state){
        location.state = JSON.parse(sessionStorage.getItem('editData-BC')) || null;
    }else{
        sessionStorage.setItem('editData-BC',JSON.stringify(location?.state))
    }

 
    //let moduleName=applicationsList.businessCapability;
   // let moduleName=stateMenu?.MenuID?.moduleName;
     
    const [activeTabKey, setActiveTabKey] = useState("tab_information");
    const [businessCapabilityData, setBusinessCapabilityData] = useState({});
    const [portfolioId,setPortfolioId] = useState(null);
    // const { tabDisabled, toggleTabDisabled } = useTabContext();
    const { id=null, moduleName,scenarioId } = useParams();
    const [scenarioName, setScenarioName] = useState();

    const handleTabChange = (key) => {
    //   console.log("key",key)
      setActiveTabKey(key);
    };
    
  useEffect(() => {
    getScenarioName();
  }, [scenarioId]);

  const getScenarioName = async () => {
    const scenario = await getRecentlyView_ScenarioName(scenarioId);
    if (scenario && scenario?.length) {
      setScenarioName(scenario[0].scenarioName);
    }
  }; 

    useEffect(()=>{     
        // console.log('testinggggg location', location);
           
        if(location?.state?.activeTabKey){
            handleTabChange(location?.state?.activeTabKey);
        }
        if(id?.toLowerCase()==="add"){
            setPortfolioId(null);
        }else{
            setPortfolioId(id);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[id])

    
    const tabsProps = {
        itemData:businessCapabilityData,
        setActiveTabKey,
        handleTabChange, 
        // toggleTabDisabled,
        // tabDisabled,
        id:portfolioId,
        moduleName,
    };

    const BusinessCapabilityTabs = [
        {
            label: `Information`,
            key: 'tab_information',
            children: <TabBCBasicInformation {...tabsProps} setBusinessCapabilityData ={setBusinessCapabilityData }  defaultFormData={{sourceid:0,levelid:location?.state?.newLevelId}} locationData = {location?.state}/>,
        },
        {
            label: `Relations`,
            key: 'tab_connections',
            disabled:portfolioId?false:true,
            children: <TabConnections {...tabsProps}/>,
        },
        {
            label: `External Links`,
            key: 'tab_links',
            disabled:portfolioId?false:true,
            // children: <TabBCExternalLinks/>,
            children: <TabLinks {...tabsProps}/> ,
        },
        {
            label: `Diagram`,
            key: 'tab_diagram',
            disabled:portfolioId?false:true,
            children: <TabDiagrams {...tabsProps} titleName = {businessCapabilityData?.DisplayName}/>,
        },
        {
            label: `Comments`,
            key: 'tab_comments',
            disabled:portfolioId?false:true,
            children: <TabComments {...tabsProps}/>,
        },
        // {
        //     label: `Survey`,
        //     key: 'tab_survey',
        //     children: <TabBCBasicInformation {...tabsProps}/>,
        // }
        // ,
        {
            label: `Change log/ Alerts`,
            key: 'tab_changelogAlert',
            disabled:portfolioId?false:true,
            children: <TabChangeLogAlerts {...tabsProps}/>,
        }
    ];

    return (
        <div className=' mb-5 text-left'>
            {/* <div className='d-flex justify-content-between'>
                   */}
                    <div className="d-flex justify-content-between align-items-center">
                    <PageTitleHeading text={
                            ((()=>{
                                if(businessCapabilityData?.displayname){
                                    return <span>Business Capability: {businessCapabilityData?.levelid} {businessCapabilityData?.displayname}</span>;
                                }
                                return <span>Add New Business Capability</span>;
                            })())
                        }
                    />
                   
                    
                   {scenarioId && (<div style={{background:"#ffaa02", paddingTop:1, paddingLeft:5,paddingRight:5, margin:3, borderRadius:5, height:25}}>
                    { scenarioName?"CURRENT WORKSPACE: " +scenarioName:""}    
                    </div>)}    
                     
 
                <div className='my-3'>
                    <GoBack className={`ml-2 pull-right`} path={location?.state?.from} state={location?.state} back/>
                </div>
            </div>
            <Tabs
                type="card"
                className="business-capability-entry"
                activeKey={activeTabKey}
                onChange={handleTabChange}
                items={BusinessCapabilityTabs}
            />
        </div>
    );
}

// BusinessCapabilityEntry.defaultProps = {}

export default BusinessCapabilityEntry;