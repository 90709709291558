import React, { useState, useEffect } from "react";
import { getRecentlyView_ScenarioName } from "../utils/Common";

function ScenarioCurrentWorkspace(scenarioId) {
  const [scenarioName, setScenarioName] = useState();

  console.log("scenarioName............", scenarioId);

  useEffect(() => {
    getScenarioName();
  }, [scenarioId]);

  const getScenarioName = async () => { 
    const scenario = await getRecentlyView_ScenarioName(scenarioId);  
    if (scenario?.length) {
     
      if (scenario[0].scenarioName) {
        setScenarioName(scenario[0].scenarioName);
      }
    }
  };

  return (
    <div
      style={{
        background: "#ffaa02",
        paddingTop: 1,
        paddingLeft: 5,
        paddingRight: 5,
        margin: 3,
      }}
    >
      {scenarioName ? "CURRENT WORKSPACE: " + scenarioName : ""}
    </div>
  );
}

export default ScenarioCurrentWorkspace;
