import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router';
import BusinessCapabilityGrid from '../../businessCapabilityMaster/BusinessCapabilityGrid';
import BusinessCapabilityList from '../../businessCapabilityMaster/BusinessCapabilityList';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { showViewType } from '../common/PortfolioCommon';

const BusinessCapability = ({moduleName}) => {
    const location = useLocation()
    const mainDivRef = useRef(null);
    const [activeGridStyle, setActiveGridStyle] = useState(1);
    const [moduleData,setModuleData] = useState(null);  
    const [deleteStart,setDeleteStart] = useState(false);

    useState(()=>{
        setActiveGridStyle(location?.state?.defaultActiveKey || 1);
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    },[moduleName])

    return <React.Fragment>
        <div className='mb-0' ref={mainDivRef}>
            {activeGridStyle===2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                <BusinessCapabilityGrid 
                    activeGridStyle={activeGridStyle}
                    setActiveGridStyle={setActiveGridStyle }
                    width={mainDivRef?.current?.clientWidth}
                    basePath={location?.pathname}
                />
            }
            {activeGridStyle===1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                <BusinessCapabilityList 
                    activeGridStyle={activeGridStyle}
                    setActiveGridStyle={setActiveGridStyle }
                    basePath={location?.pathname}
                    showButtons={{
                        view:false,
                        edit:false,
                        delete:true,
                    }}                    
                    //setBulkDeleteButton={setBulkDeleteButton}
                    deleteStart={deleteStart}
                    setDeleteStart={setDeleteStart}
                />
            }
        </div>
    </React.Fragment>;
}

BusinessCapability.defautProps = {

}

export default BusinessCapability
