import { Button, Input,Upload ,  } from "antd";
import { PaperClipOutlined } from '@ant-design/icons';
import React from "react";
import { checkRole } from "../../../../helper/useUserData";
const { TextArea } = Input;


const CreateComment = ({ setActiveTabKey }) => {

  // const uploadFiles = {
  //   action: 'https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload',
  //   onChange({ file, fileList }) {
  //     if (file.status !== 'uploading') {
  //       console.log(file, fileList);
  //     }
  //   },
  //   defaultFileList: [
  //     {
  //       uid: '1',
  //       name: 'xxx.png',
  //       status: 'uploading',
  //       url: 'http://www.baidu.com/xxx.png',
  //       percent: 33,
  //     },
  //     {
  //       uid: '2',
  //       name: 'yyy.png',
  //       status: 'done',
  //       url: 'http://www.baidu.com/yyy.png',
  //     },
  //     {
  //       uid: '3',
  //       name: 'zzz.png',
  //       status: 'error',
  //       response: 'Server Error 500',
  //       // custom error message to show
  //       url: 'http://www.baidu.com/zzz.png',
  //     },
  //   ],
  // };

  return (
    <div className="flex flex-col items-start gap-4 p-2">
      {/* <div className="text-sm font-bold">Comments</div> */}
      <div className="txtdes">
        <h4>Comments</h4>
        <p>(this screen shows comments and replies left by users against the current object current object)</p>
      </div>

      <div className="w-full">
        <TextArea
          placeholder="Add Comments"
          className="w-full h-3/4 border border-2 rounded-lg shadow-md"
          autoSize={{ minRows: 6, maxRows: 100 }}
        />
      </div>
      <div className="flex justify-end gap-4">
        {checkRole('save') && <Button
          type="default"
          className="px-4 text-base font-normal h-auto w-36"
        >
          Attach File
        </Button>}
        {checkRole('save') && <Button
          type="primary"
          className="px-4 text-base font-normal h-auto w-36"
        >
          Post
        </Button>}
      </div>
    </div>
  );
};

export default CreateComment;
