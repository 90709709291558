import React, { useCallback, useEffect, useState } from 'react';
import {Select, Spin} from 'antd';
import { EyeFilled } from "@ant-design/icons";
import PortfolioRelationshipServices from '../../services/services/PortfolioRelationshipService';

const ApplicationCapabilityGrid = ({showFilter,selectedFilters,lifeCycleStagesColors,...props}) => {
    const [relationshipFilter,setRelationshipFilter] = useState(null);
    const [rowsData,setRowsData] = useState(null); // Business Process
    const [columnsData,setColumnsData] = useState(null); // Business Capability
    const [loading,setLoading] = useState(false); // Business Capability

    useEffect(()=>{
        setRelationshipFilter(selectedFilters?.relationshipFilter || {});
        setColumnsData(selectedFilters?.relationshipFilter?.business_capability || []);
    },[selectedFilters?.relationshipFilter]);


    
    const fetchRowData = useCallback(async () => {
        setLoading(true);
        let response = await getRowContentData(relationshipFilter?.business_processes)
        setRowsData(response);
        setLoading(false);
    }, [relationshipFilter?.business_processes])
    
    useEffect(() => {
        fetchRowData()
    }, [fetchRowData])


    const getRowsContent = (row) =>{
        let rowColumns = row?.relationships?.relations?.find(f=>f['moduleName']==="BusinessCapability")?.tags || [];
        return <React.Fragment key={Math.random()}>
            <tr>
                <td className='td-heading'>
                    <span className='rotate-90'>
                        {row?.item?.displayname }
                    </span>
                </td>
                <td>
                    <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(showFilter?750:470)}}>
                        {getColumnsContent(rowColumns)}
                    </div>
                </td>
            </tr>
        </React.Fragment>
    }
    const getColumnsContent = (columns) =>{
        return columns?.map(column=>{
            if(columnsData.find(f=>f.id===column?.id)){
                return <React.Fragment key={Math.random()}>
                    <div className='card' style={{background:lifeCycleStagesColors['active']}}>
                        <div className='card-header text-center bg-white'>
                            <span className='fw-bold'>{column?.displayname}</span>
                        </div>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold'>Newcast</div>
                                    <div>(App -751)</div>
                                </div>
                                <div>
                                    <EyeFilled style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            }
            return <React.Fragment key={Math.random()}></React.Fragment>
        })
    }
    return (
        <Spin className='loading_bx' spinning={loading}>
            <div className='main-wrapper'>
                <div className='bg-aaaaaa6b card card-body p-2 border-0'>
                    <div className='d-flex justify-content-end border-right'>
                        <div className='d-flex align-items-center'>
                            <div>Level 1 Groupping &emsp;</div>
                            <Select style={{width:'200px'}} defaultValue="Business Process">
                                <Select.Option value={`Business Process`}>Business Process</Select.Option>
                            </Select>
                        </div>
                        &emsp;
                        <div className='d-flex align-items-center'>
                            <div>Level 2 Groupping &emsp;</div>
                            <Select style={{width:'200px'}} defaultValue="Business Capabilities">
                                <Select.Option value={`Business Capabilities`}>Business Capabilities</Select.Option>
                            </Select>
                        </div>
                    </div>
                </div>
                <div>
                    <table width={`100%`} className='acg-table'>
                        <tbody>
                            {
                                rowsData?.map(row=>{
                                    return getRowsContent(row)
                                })
                            }
                            {/* {tempDataComponent({showFilter,lifeCycleStagesColors})} */}
                        </tbody>
                    </table>
                </div>
            </div>
        </Spin>
    )
}

export default ApplicationCapabilityGrid

const getRowContentData =async (rows) =>{
    let items = [];
    if(rows?.length>0){
        items = await Promise.all(rows?.map(async (row)=>{
            let relationships = await PortfolioRelationshipServices.getAllNode({nodeId: row?.id ,nodeTag: row?.moduleName})

            row.relationships = relationships?.data?.data;
            return row;
        }));
    }
    return items;
}

const tempDataComponent =({showFilter,lifeCycleStagesColors})=>{
    return <React.Fragment>
        <tr>
            <td className='td-heading'>
                <span className='rotate-90'>
                    Consolidation & Spare Parts
                </span>
            </td>
            <td>
                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(showFilter?750:470)}}>
                    <div className='card' style={{background:lifeCycleStagesColors['active']}}>
                        <div className='card-header text-center bg-white'>
                            <span className='fw-bold'>Consolidator</span>
                        </div>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold'>Newcast</div>
                                    <div>(App -751)</div>
                                </div>
                                <div>
                                    <EyeFilled style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card' style={{background:lifeCycleStagesColors['phaseIn']}}>
                        <div className='card-header text-center bg-white'>
                            <span className='fw-bold'>Spare Part Management</span>
                        </div>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold'>Newcast</div>
                                    <div>(App-573)</div>
                                </div>
                                <div>
                                    <EyeFilled style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card' style={{background:lifeCycleStagesColors['phaseOut']}}>
                        <div className='card-header text-center bg-white'>
                            <span className='fw-bold'>Spare Parts</span>
                        </div>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold'>Newcast</div>
                                    <div>(App-573)</div>
                                </div>
                                <div>
                                    <EyeFilled style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td className='td-heading'>
                <span className='rotate-90'>
                    Maintaince
                </span>
            </td>
            <td>
                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(showFilter?750:470)}}>
                    <div className='card' style={{background:lifeCycleStagesColors['endOfLife']}}>
                        <div className='card-header text-center bg-white'>
                            <span className='fw-bold'>3D Printing</span>
                        </div>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold'>HP3D</div>
                                    <div>(App-4188)</div>
                                </div>
                                <div>
                                    <EyeFilled style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card' style={{background:lifeCycleStagesColors['color6']}}>
                        <div className='card-header text-center bg-white'>
                            <span className='fw-bold'>Vibration Monitoring</span>
                        </div>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold'>ISVM</div>
                                    <div>(App-4190)</div>
                                </div>
                                <div>
                                    <EyeFilled style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='card' style={{background:lifeCycleStagesColors['color7']}}>
                        <div className='card-header text-center bg-white'>
                            <span className='fw-bold'>Consolidator</span>
                        </div>
                        <div className='card-body'>
                            <div className='d-flex justify-content-between align-items-center'>
                                <div>
                                    <div className='fw-bold'>Newcast</div>
                                    <div>(App-751)</div>
                                </div>
                                <div>
                                    <EyeFilled style={{ fontSize: "20px" }} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </td>
        </tr>
        <tr>
            <td className='td-heading'>
                <span className='rotate-90'>
                    Finance
                </span>
            </td>
            <td>
                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(showFilter?750:470)}}></div>
            </td>
        </tr>
        <tr>
            <td className='td-heading'>
                <span className='rotate-90'>
                    People Experience
                </span>
            </td>
            <td>
                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(showFilter?750:470)}}></div>
            </td>
        </tr>
        <tr>
            <td className='td-heading'>
                <span className='rotate-90'>
                    Order Planning Fulfillment & Scheduling Experience
                </span>
            </td>
            <td>
                <div className='d-flex overflow-y swimlane_listbx' style={{width:window?.innerWidth-(showFilter?750:470)}}></div>
            </td>
        </tr>
    </React.Fragment>
}