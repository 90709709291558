import React, { useEffect, useRef } from 'react'; 
import { useState } from 'react';
import { Tooltip, Button, Carousel, Flex, Modal, message } from 'antd';
import { UnorderedListOutlined ,PlusOutlined,LeftCircleOutlined,RightCircleOutlined, ExclamationCircleFilled} from '@ant-design/icons';
import BCMSamples from './BCMSamples';
import BCMLevel1 from './BCMLevel1';
import { useNavigate } from "react-router";
import ActionsBtn from '../applicationlist/ActionsBtn';
import { PageTitleHeading } from '../../utils/Common';
import {  useParams } from "react-router-dom";
import gridSvg from "./../../assets/Two columns layout.svg";
import LoadingBar from 'react-top-loading-bar';
import businessCapabilityServices from "../../services/services/BusinessCapabilityService";
import { UpdateChangeLog } from '../../utils/ChangeLogs';
import { useMenu } from '../../Context/MenuProvider';
import BusinessProcessHeader from '../businessProcess/BusinessProcessHeader';

const baseStyleRow = {
    overflowX: 'auto',
    minHeight: window?.innerHeight-250,
    maxWidth:window?.innerWidth-250,
};

const BusinessCapabilityGrid = ({basePath,...props}) => {
    const ref = useRef(null)
    const navigate = useNavigate();
    const [openImportModal, setOpenImportModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);
    const [showFilter, setshowFilter] = useState(false);
    const [filterLevel,setFilterLevel] = useState([]);
    const [filterText,setFilterText] = useState('');
    const [filterBy,setFilterBy] = useState('displayName');
    const [orderList, setorderList] = useState("label,ASC")
    const [lists, setLists] = useState([]);

    const [filter_By, setfilterBy] = useState("")
    const [filter_ByText, setfilter_ByText] = useState("")
    const [filter_levels, setfilter_levels] = useState("")
    const [filterOrder, setfilterOrder] = useState("label,ASC")
    const [filterChanges, setFilterChanges] = useState(false);
    const [field_OldData, setfield_OldData] = useState({displayName:  "",LevelId: "",description:  ""});
    const { moduleName:moduleNamePath } = useParams();
    const { stateMenu, dispatchMenu } = useMenu();
    const [filterOptions, setFilterOptions] = useState({});

    useEffect(()=>{
        console.log('testing get list data', lists);
        
    },[lists])

    const sortBCMData = (lists) => {
        return lists?.sort((a, b) => {
            return a.levelid.localeCompare(b.levelid, undefined, {numeric: true, sensitivity: 'base'});
        })
    }
    
    const SampleNextArrow = (props) => {
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style}}
          onClick={onClick}
          >
            <RightCircleOutlined/>
          </div>
      );
    }
    
    const SamplePrevArrow = (props) =>{
      const { className, style, onClick } = props;
      return (
        <div
          className={className}
          style={{ ...style}}
          onClick={onClick}
        >
            <LeftCircleOutlined />
        </div>
      );
    }
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        draggable:true,
        arrows:true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    useEffect(() => {
        getBusinessCapabilityList(filter_By?filter_By:"",filter_ByText?filter_ByText:"",filter_levels,filterOrder);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter_ByText,filter_levels,filterOrder]);

    const getBusinessCapabilityList = async (filter_By, filter_ByText,filter_levels,filterOrder) => {  
        if (ref.current) {
            ref.current.continuousStart();
        }
        setLists([])
        const list = await businessCapabilityServices.getList(filter_By,filter_ByText,filter_levels,filterOrder);
        const resultData = list?.data?.data
        setFilterChanges(false)
        setLists(resultData)
        if (ref.current) {
            ref.current.complete();
        }
    }

    const setItem = (item, type) => {
        if (type.type === 'edit') {
        } else {
            showConfirm(item)
        }
    }

    const showConfirm = (item) => {
        let id = item?.id
        Modal.confirm({
            title: 'Are you sure want to delete?',
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    let result;
                    result = await businessCapabilityServices.deleteBusinessCapability(id);
                    if (result) {

                        let obj = {
                            id:localStorage.getItem('id'),
                            NameInSourceSystem: "",
                            DisplayName: localStorage.getItem('DisplayName'),
                            Description:"",
                            LevelID:""
                        } 

                        let field_OldData = {
                            DisplayName: item?.DisplayName || "",
                            Description:item?.Description || "",
                            LevelID:item?.LevelID || "",
                        }

                        await UpdateChangeLog(obj.id,field_OldData,obj, "Delete", stateMenu?.MenuID?.moduleName)

                        await getBusinessCapabilityList();
                        message.success(result?.data?.msg);
                        // setId(null)
                    } else {
                        message.error(result?.data?.msg)
                    }
                } catch (error) {
                    message.error(error?.message)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <React.Fragment>
            <LoadingBar color='#1f1f1f' ref={ref} />
            <div className='d-flex justify-content-between'>
                {/* <div className='w-100 towerview_bx' style={baseStyleRow} > */}
                <div className='w-100 towerview_bx container-fluid'>
                    <BusinessProcessHeader 
                        activePage={props.activeGridStyle}
                        setActivePage={props.setActiveGridStyle}
                        heading={'Business Capability'}
                        addBtnTooltip={`Level 1`}
                        lists={lists}
                    />
                    <div className='text-left business-capability-master'>
                        <div style={{ margin: 24 }} className="slider-container">
                            <Carousel {...settings} >
                            {
                                sortBCMData(lists).map((item, index) => {
                                    let color = BCMSamples?.BCM_COLORS[parseInt(index % BCMSamples?.BCM_COLORS.length)];
                                    return <div key={index} className='align-items-start text-center'><BCMLevel1 index={index} backgroundColor={color} setItem={setItem} openModal={null} visibleModal={props.visibleModal} setModalData={()=>{}} data={item}  basePath={basePath}/></div>
                                })
                            }
                            </Carousel>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default BusinessCapabilityGrid;