// src/components/ColorPickerPopover.js
import React, { useState } from "react";
import { ColorPicker } from "antd";

const ColorPickerPopover = ({ color, onChange, key }) => {
  const handleChange = (color, hex) => {
    onChange(hex);
  };

  return (
    <ColorPicker
      key={key}
      defaultValue="#FFFFFF"
      value={color}
      size="medium"
      defaultFormat="hex"
      onChange={handleChange}
      disabledAlpha
      showText
    />
  );
};

export default ColorPickerPopover;
