import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getBpmnRecords(id,modulename) {
  const url = `${API_BASE_URL}bpmn/getAllRecords/${modulename}/${id}`;
  let response = await http.get(url);
  return response;
}

async function getBpmnStepType() {
  const url = `${API_BASE_URL}bpmn/steptype`;
  let response = await http.get(url);
  return response;
}

async function getBpmnById(modulename,id) {
  const url = `${API_BASE_URL}bpmn/${modulename}/${id}`;
  let response = await http.get(url);
  return response;
}

async function deleteBpmnById(id) {
  const url = `${API_BASE_URL}bpmn/${id}`;
  let response = await http.delete(url);
  return response;
}

async function saveBpmnRecords(formData) {
  const url = `${API_BASE_URL}bpmn`;
  let response = await http.post(url, formData);
  return response;
}

async function getPreviewDiagram(formData) {
  const url = `${API_BASE_URL}bpmn/getPreview`;
  let response = await http.post(url, formData);
  return response;
}

async function saveXMLData(id, xmlData) {
  const url = `${API_BASE_URL}bpmn/postXml/${id}`;
  try {
    let response = await http.post(url, xmlData);
    return response;
  } catch (error) {
    console.error('Error saving XML data:', error);
    throw error;
  }
} 

async function SaveImportDiagram(formData) {
  const url = `${API_BASE_URL}bpmn/importDiagram`;
  let response = await http.post(url, formData,{ headers: { "content-type": "multipart/form-data" },});
  return response;
}

const BpmnServices = {
    getBpmnRecords,
    saveBpmnRecords,
    getPreviewDiagram,
    getBpmnStepType,
    getBpmnById,
    deleteBpmnById,
    saveXMLData,
    SaveImportDiagram
};

export default BpmnServices;
