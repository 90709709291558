import React from "react";
import { Input, Modal, Form, message, Spin } from "antd";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { UpdateChangeLog } from "../../../../utils/ChangeLogs";
import { usePlant } from "../../../../Context/plant.js";
import { useApplicationlink } from "../../../../Context/linkProvider";
import DynamicForm from "../../../dynamicForm/DynamicForm";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import {
  addIsTextToProperties,
  deepFindByName,
  isCamelCase,
  isPascalCase,
  removeEmptyArrObjOrval,
  transformText,
  SavePortfolioItemsCheckModified
} from "../../../../utils/Common";
import { getMenuPortfolio } from "../../../../utils/settingCommon";
import { useMenu } from "../../../../Context/MenuProvider";
import { SaveRecentlyView_Portfolio } from "../../../../utils/ResentViewedPortfolio";
import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider";
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";
import { getDynamicRoutesLink } from "../../../../utils/portfolioRelationshipCommon";
import { usePortfolioTag } from "../../../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../../../hooks/useFetchPortfolioTag";
import { staticSettingPortfolioList } from "../../../../utils/settingCommon";

import { useGlobalTag } from "../../../../Context/GlobalTagProvider";
import useFetchGlobalProp from "../../../../hooks/useFetchGlobalProp";
import ExtraFormFields from "./ExtraFormFields.jsx";
import ScenarionServices from "../../../../services/services/ScenarioServices";
  

const TabBasicInformation = ({
  setActiveTabKey,
  toggleTabDisabled,
  id: modelId,
  setPortfolioData,
  basePath,
  ...props
}) => {
  const navigate = useNavigate();
  const { loading, fetchPortfolio } = usePortfolioFetcher();
  const { stateMenu, dispatchMenu } = useMenu();
  const [form] = Form.useForm();
  const location = useLocation();
  const leveldata = location.state || null;
  const [spinning, setSpinning] = useState(false);
  const [list, setList] = useState([]);
  const [portfolioServices, setPortfolioServices] = useState(null);
  const [formData, setFormData] = useState({});
  // const [parentList, setParentlist] = useState({});
  // const [field_OldData, setfield_OldData] = useState({ displayName: "", LevelId: "", description: "", });
  const [level, setLevel] = useState();
  const [parentLevel, setParentLevel] = useState();
  const [levelID, setLevelID] = useState();
  const { state, dispatch } = usePlant();
  const { state: PortfolioSettingState, dispatch: PortfolioSettingDispatch } = usePortfolioSetting();
  const { state: stateChangeLog, dispatch: dispatchChangeLog } = useApplicationChangeLogs();
  const [formItems, setFormItems] = useState({});
  //let { moduleName } = useParams();
  const [allTagsList, setAllTagsList] = useState([]);
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;
  const { error, fetchTagsData } = useFetchPortfolioTag();

  const { state: businessLinkState, dispatch: businessLinkDispatch } = useApplicationlink();

  ///////////////////////////////////////////////////////////
  const [globalTagsList, setGlobalTagsList] = useState([]);
  const { state: stateGlobalTag, dispatch: dispatchGlobalTag } = useGlobalTag();
  const { loading: GlobalTagLoading, error: GlobaltagError } = stateGlobalTag;
  const { error: errorGTag, fetchGlobalPropsData } = useFetchGlobalProp(); 

  let  { id=null, moduleName, scenarioId } = useParams();

	useEffect(() => {
		fetchData(moduleName, modelId);
	}, [moduleName, modelId]);
	
  const fetchData = async (moduleName, modelId ) => {
    try {
      const response = await ChangeLogsServices.getChangeLogsList({
        moduleName,
        modelId,
      });

      const newData = response.data.data;
      await dispatchChangeLog({ type: "SET_LINK", payload: newData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const formatPropertyName = (name) => {
    // Convert name to lower case and remove leading/trailing spaces
    let formattedName = name.toLowerCase().trim();
    // Replace hyphens and underscores with spaces
    formattedName = formattedName.replace(/[_-]/g, " ");
    // Convert camelCase to Camel Case
    formattedName = formattedName.replace(/([a-z])([A-Z])/g, "$1 $2");
    // Capitalize the first letter of each word
    formattedName = formattedName.replace(/\b\w/g, (c) => c.toUpperCase());
    return formattedName;
  };

  const checkTextCase = (name) => {
    if (isPascalCase(name) || isCamelCase(name)) {
      return name; // If already in PascalCase or camelCase, return unchanged
    } else {
      return transformText(name, "camel"); // Convert to camelCase otherwise
    }
  };

  const updateBussinessModuleId = async (id) => {
    await businessLinkDispatch({
      type: "SET_BUSINESSAPPLICATIONID",
      payload: id,
    });
    await businessLinkDispatch({
      type: "SET_BUSINESS_APPLICATION_MODEL",
      payload: moduleName,
    });
    await dispatch({ type: "SET_IS_EDIT", payload: true });
  };

  //////////////////////////////////////////////////////////////////////////
  //Fetch tags items
  const fetchGlobalProp = async () => {
    try {
      await fetchGlobalPropsData();
    } catch (error) {
      console.error("Error fetching Global Prop:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchGlobalProp();
  }, []);

  useEffect(() => {
    setGlobalTagsList(stateGlobalTag.data);
  }, [stateGlobalTag?.data,moduleName, modelId]);

  //////////////////////////////////////////////////////////////////////////

  //Fetch tags items
  const fetchAllPortfolioTags = async () => {
    try {
      const aa = await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  // Tag useEffect start
  useEffect(() => {
    if (stateTag?.moduleName) {
      fetchAllPortfolioTags();
    }
  }, [stateTag?.moduleName,moduleName, modelId]);

  useEffect(() => {
    setAllTagsList(stateTag?.data);
  }, [stateTag?.data.length,moduleName, modelId]);

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(PortfolioSettingDispatch, 1, 100);
      setPortfolioServices(
        getMenuPortfolio({
          routeModuleName: moduleName,
          getServicesByRouteModule: true,
        })
      );
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchAllPortfolio();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //     if (PortfolioSettingState?.data?.length) {
  //         const portfolioValues = deepFindByName(
  //             PortfolioSettingState?.data,
  //             getMenuPortfolio({routeModuleName:moduleName,getNameByRouteModule:true}),
  //             false,
  //         );

  //         setFormItems(addIsTextToProperties(portfolioValues, "Level"));
  //     }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [PortfolioSettingState?.data]);

  useEffect(() => {
    if (PortfolioSettingState?.data?.length) {
      // const portfolioValues = deepFindByName(
      //   PortfolioSettingState?.data,
      //   staticSettingPortfolioList?.businessCapability,
      //   false
      // );

      const portfolioValues = PortfolioSettingState?.data.find(
        (item) => transformText(item.moduleName, "pascal") === moduleName
      );

      if (portfolioValues?.moduleName) {
        dispatchTag({ type: "EMPTY_TAGS" });
        dispatchTag({
          type: "SET_MODULE_NAME",
          payload: portfolioValues?.moduleName,
        });
      }

      setFormItems(addIsTextToProperties(portfolioValues, "Level"));
    }
  }, [PortfolioSettingState?.data,moduleName, modelId]);

  useEffect(() => {
    if (modelId != null) {
      updateBussinessModuleId(modelId);
    } else {
      dispatch({ type: "SET_IS_EDIT", payload: false });

      //Cheking Level Data to Populate the Parent Detail
      if (leveldata != null) {
        console.log("Level Data", leveldata);
        if (leveldata?.Level == "1") {
          setLevel("Level 1");
          setParentLevel("");
          setLevelID("");
        } else if (leveldata?.Level == "2") {
          setLevel("Level 2");
          setParentLevel(leveldata.underLevel);
          setLevelID(leveldata.levelID);
        } else if (leveldata?.Level == "3") {
          setLevel("Level 3");
          setParentLevel(leveldata?.underLevel);
          setLevelID(leveldata?.levelID);
        }
      } else {
        setLevel("Level 1");
        setParentLevel("");
        setLevelID("");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, state.isEdit,moduleName]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // console.log("formData.....", formData);
  }, [formData]);

  useEffect(() => {
    if (modelId && state.isEdit && portfolioServices) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modelId, state.isEdit, portfolioServices,moduleName]);

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const create_and_Updatd = () => {
    form
      .validateFields()
      .then(async (values) => {
        let obj = {
          ...values,
          tags: values?.tags?.map((item) => item?.id),
        };

        const {displayname} = obj
        if(!displayname){
          return message.error(`Display Name is Required`);
        }

        const oldValues = updateValuesForChangeLog(
          list,
          formItems?.portfolioSections
        );

        const newValues = updateValuesForChangeLog(
          obj,
          formItems?.portfolioSections
        );

        let id = null;

        if (state.isEdit) {
          let obj = {
            // NameInSourceSystem: "",
            // DisplayName: values?.name,
            // Description: values?.description,
            // LevelID: values?.LevelId,
            ...values,
            tags: values?.tags?.map((item) => item?.id),
          };
          obj.id = modelId;
          try {
            let result;
            result = await portfolioServices.update(obj);
            if (result?.data?.code == "200") {
              console.log("result?.data", result?.data);

              //form.resetFields();
              message.success(result?.data?.msg);

              //Add Change Log
              await UpdateChangeLog(
                obj.id,
                oldValues,
                newValues,
                "Update",
                moduleName,
                formItems?.portfolioSections
              );

              await fetchData({ moduleName, modelId });

              await getData();
            } else if (result?.data?.id) {
              //form.resetFields();
              message.success("Updated Successfully");
              //Add Change Log
              await UpdateChangeLog(
                modelId,
                oldValues,
                newValues,
                "Update",
                moduleName,
                formItems?.portfolioSections
              );

              await fetchData({ moduleName, modelId });

              await getData();

              //Update Data modification for scenario portfolio Item
              if (scenarioId) { 
                const list_Edit = await portfolioServices?.getListByID(modelId);

                if (list_Edit.data) {
                  const portfolioValues = PortfolioSettingState?.data.find(
                    (item) =>
                      transformText(item.moduleName, "pascal") === moduleName
                  );
                  if (portfolioValues?.moduleName) {
                    // await SavePortfolioItemsCheckModified({
                    //   moduleName: portfolioValues?.moduleName,
                    //   sourceItemId: parseInt(modelId, 10),
                    //   targetItemId: parseInt(list_Edit?.data?.sourceItemId),
                    // });
                    const objdata = {
                      source_id: parseInt(modelId, 10), 
                      modulename: portfolioValues?.moduleName,
                      tab_name: "Information",
                    };
                    await ScenarionServices.checkscenariomodifyrecord(objdata);
                  }

                  setTimeout(() => {
                    navigate(`/scenario-list-v2/${scenarioId}`);
                  }, 1000);
                }
              } ///

            } else {
              message.error(result?.data?.msg);
            }
          } catch (error) {
            message.error(error?.message);
          }
        } else {
          try {
            let result;
            if (Object.values(removeEmptyArrObjOrval(values))?.length === 0) {
              return message.error("Nothing to Submit");
            }
            // if(props?.locationData?.scenarioId){
            //   obj.scenarioId = props?.locationData?.scenarioId;
            // }

            if (scenarioId)
              {
                obj.scenarioId = scenarioId; 
                obj.sourceItemType="New";
              }

              
            result = await portfolioServices?.create(obj);

            //if (result.data?.code == "201") {
            if (result.status == "201") {
              //console.log("Level 1 Data", result.data?.data.id);

              //Add Change Log
              await UpdateChangeLog(
                result.data?.id,
                oldValues,
                newValues,
                "Add",
                moduleName,
                formItems?.portfolioSections
              );
              modelId=result.data?.id

              await fetchData(moduleName, modelId);
              //form.resetFields();
            
              await getData();

              message.success("Created Successfully");
              //message.success(result?.data?.msg);

              if (scenarioId) { 
                setTimeout(() => {
                  navigate(
                    `/scenario-list-v2/${scenarioId}`
                  );
                }, 1000);
    
              } else {
              //console.log("ID BC Basic Info ", result?.data);
              const newDataID = result.data?.id || null;
              updateBussinessModuleId(newDataID);
              setTimeout(() => {
                navigate(
                  updateCreateItemLink({ basePath, id: result?.data?.id })
                );
              }, 1000);
            }


            } else if (result?.data?.id) {
              message.success("Created Successfully");
              navigate(
                updateCreateItemLink({ basePath, id: result?.data?.id })
              );
            } else if (result?.data?.error) {
              message.error(result?.data?.error);
            } else {
              message.error(result?.data?.msg);
            }
          } catch (error) {
            console.log("Result.....", error);
            message.error(error?.response?.data?.error || error?.message);
          }
        }
      })
      .catch((info) => {
        console.log("Validate Failed:", info);
      });
  };

  const updateValuesForChangeLog = (data, propss) => {
    let oldList = {};
    Object.keys(data).map((dataItem) => {
      propss.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties
            // ?.sort((a, b) =>
            //     formatPropertyName(a.name).localeCompare(
            //         formatPropertyName(b.name),
            //     ),
            // )
            ?.map((property, index) => {
              //if (dataItem === checkTextCase(property.name)) {
                if (dataItem === property.name) {
                if (property?.type === "Single select") {
                  property?.propertyOptions.map((propvalue) => {
                    if (data[dataItem] == null) {
                      oldList[dataItem] = "";
                    } else if (propvalue?.id === data[dataItem]) {
                      oldList[dataItem] = propvalue?.name;
                    }
                  });
                } else if (property?.type === "Multi select") {
                  let lst = data[dataItem];
                  if (lst?.length) {
                    let propValue = "";
                    for (let i = 0; i < lst?.length; i++) {
                      property?.propertyOptions.map((propvalue) => {
                        if (propvalue?.id === lst[i]) {
                          if (propValue == "") {
                            propValue = propvalue?.name;
                          } else {
                            propValue = propValue + ", " + propvalue?.name;
                          }
                        }
                      });
                    }
                    oldList[dataItem] = propValue;
                  } else {
                    oldList[dataItem] = "";
                  }
                } else {
                  oldList[dataItem] = data[dataItem];
                }
              }
            });
      });
    });
    return oldList;
  };

  const getData = async () => {
    const list1 = (await portfolioServices?.getListByID(modelId)) || {};

    setList(list1?.data);

    if (list1?.data?.Level === "L1") {
      setLevel("Level 1");
    } else if (list1?.data?.Level === "L2") {
      setLevel("Level 2");
    } else if (list1?.data?.Level === "L3") {
      setLevel("Level 3");
    }

    let listParent;
    if (list1?.data?.ParentID != null && list1?.data?.ParentID != 0) {
      console.log("list1?.data.ParentID", list1);
      listParent = await portfolioServices.getListByID(list1?.data.ParentID);

      // setParentlist(listParent?.data);

      setParentLevel(listParent?.data.DisplayName);
      setLevelID(listParent?.data.LevelID);
    } else {
      setLevel("Level 1");
      setParentLevel("");
    }

    setFormData(list1?.data);
    setPortfolioData(list1?.data);

    form.setFieldsValue({
      name: list1?.data.DisplayName,
      LevelId: list1?.data.LevelID,
      description: list1?.data.Description,
    });

    if (stateMenu.MenuID !== undefined) {
      const portfolioData = PortfolioSettingState?.data.find(
        (item) => transformText(item.moduleName, "pascal") === moduleName
      );

      SaveRecentlyView_Portfolio(
        //stateMenu.MenuID.moduleName,
        portfolioData.name,
        window.location.href,
        list1?.data.displayname,
        list1?.data.id,
        // stateMenu.MenuID.color
        portfolioData.color
      );
    }
  };

  return (
    <Spin spinning={spinning}>
      <DynamicForm
        data={formItems?.portfolioSections || []}
        form={form}
        formData={formData}
        labelCol={8}
        wrapperCol={16}
        createAndUpdate={create_and_Updatd}
        level={level}
        levelID={levelID}
        parentLevel={parentLevel}
        showSaveButton={true}
        tagOptions={allTagsList}
        tagLoading={tagLoading}
        moduleName={formItems?.moduleName || null}
        GlobalTagOptions={globalTagsList}
        ExtraFormFields2 ={<ExtraFormFields formData={formData} form={form} moduleName={formItems?.moduleName || null}/>}
      />
      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          className="mt-5"
        >
          <Form.Item
            label="Additional Information Caption"
            name="AdditionalInfoCaption"
            rules={[
              {
                required: true,
                message: "Please input the additional information caption",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="AdditionalInfoValue"
            label="Additional Information Value"
            rules={[
              {
                required: true,
                message: "Please input the additional information caption",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </Spin>
  );
};

export default TabBasicInformation;

export const updateCreateItemLink = ({ basePath, id }) => {
  return getDynamicRoutesLink({
    path: basePath?.replace("/add", ""),
    id,
  });
};
