import React, { useState, useRef } from 'react'; 
import "./Surveys.scss"
import { Button, Flex, Layout, Spin, Tooltip } from 'antd';
import { PageTitleHeading } from '../../utils/Common';
import { PlusOutlined } from '@ant-design/icons';
import SurveyLeftCard from './SurveyLeftCard';
import SurveyContentCard from './SurveyContentCard';
import SurveyRightCard from './SurveyRightCard';
import { useNavigate } from 'react-router';
import LoadingBar from 'react-top-loading-bar'

const Surveys = () => {
    const ref = useRef(null)
    const navigation = useNavigate();
    const [loading,setLoading] = useState(false);



    return <><LoadingBar color='#1f1f1f' ref={ref} /><div className='surveys-page'>
        <Spin className="loading_bx" size="small" spinning={loading}>
            <Flex gap={'small'} justify='space-between' align="center">
                <PageTitleHeading text={`Survey`}/>
                <Flex gap={8}>
                    <div>
                        <Tooltip title={`Add Survey `}>
                            <Button icon={<PlusOutlined/>} onClick={()=>{ navigation(`/others-surveys/add`);  }}/>
                        </Tooltip>
                    </div>
                </Flex>
            </Flex>
            <Layout>
                <Layout.Sider width="25%" theme='light' className='border p-3 bg-light-1'>
                    <SurveyLeftCard/>
                </Layout.Sider>
                <Layout.Content  className='border p-3'>
                    <SurveyContentCard/>
                </Layout.Content>
                <Layout.Sider width="25%" theme='light' className='border p-3'>
                    <SurveyRightCard/>
                </Layout.Sider>
            </Layout>
        </Spin>
    </div>
    </>
}

Surveys.defaultProps = {};
// #endregion

export default Surveys;