import { useEffect, useRef, useState } from "react";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import { debounceTimeOut, deepFindByName, default_pageSize, default_pageSizeOptions, getSelectProperties, removeEmptyArrObjOrval, removeEmptyKeys } from "../../../utils/Common";
import { getMenuPortfolio, staticSettingPortfolioList, staticSettingPortfolioList2 } from "../../../utils/settingCommon";
import { Layout, Modal, Pagination, Spin, message, Checkbox } from "antd";
import BusinessProcessButtonsV1 from "../../businessProcess/list/BusinessProcessButtons";
import useDebounce from "../../../helper/useDebounce";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { UpdateChangeLog } from "../../../utils/ChangeLogs";
import LoadingBar from "react-top-loading-bar";
import BusinessProcessListContent from "../../businessProcess/list/BusinessProcessListContent";
import BusinessProcessListFilter from "../../businessProcess/list/BusinessProcessListFilter";
import { useLocation } from "react-router";
import { usePortfolioTag } from '../../../Context/portfolioTagProvider';
import useFetchPortfolioTag from '../../../hooks/useFetchPortfolioTag';
import { SaveRecentlyView_Portfolio, RemoveRecentViewed } from "../../../utils/ResentViewedPortfolio";
import UpdateGlobalState from "../common/UpdateGlobalState";

const lifeCycleStagesColors = {
    active: "#FFB381",
    phaseIn: "#FFF280",
    phaseOut: "#FC819E",
    endOfLife: "#B2B377",
    color5: "#B5C0D0",
    color6: "#BFEF82",
    color7: "#90DDF9",
};

const sortByValue = [
    //{ value: "id", title: "ID" },
    { value: "displayname", title: "Name" },
];
const PlantList = ({HeaderContnet,moduleName,deleteStart,setDeleteStart,setFilterOptions=()=>{},setBulkDeleteButton,...props}) => {
    const ref = useRef(null)
    const location = useLocation();
    const {updateLocale} = UpdateGlobalState();
    const [showFilter, setShowFilter] = useState(true);
    const [portfolioData, setPortfolioData] = useState([]);
    const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } = usePortfolioSetting();
    const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
    const [selectedFilters, setSelectedFilters] = useState({});
    const [lists,setLists] = useState([]);
    const [loading,setLoading] = useState(true);
    const [filterChanges, setFilterChanges] = useState(false);
    const [portfolioServices, setPortfolioServices] = useState(null);
    const [totalRecords, setTotalRecords] = useState(0);
    const [page, setPage] = useState(1);
    const [limit, setLimit] = useState(default_pageSize);
    const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
    const { loading: tagLoading, error: tagError } = stateTag;
    const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();

    // Submit filter state it should work only apply is clicked
    const [filterSubmit, setFilterSubmit] = useState(false);
    const [selectedRelationFilters, setSelectedRelationFilters] = useState({});
    const [bulkUpdateIds, setBulkUpdateIds] = useState([]);

    useEffect(()=>{
        console.log("testing portfolio two state location",location);
        if(location?.state){
            console.log('testing portfolio one', "page",location?.state?.page, "limit",location?.state?.limit);
            setPage(location?.state?.page)
            setLimit(location?.state?.limit)
            }

    },[location?.state])

    const getPageNumber =()=>{
        let paze ;
        console.log("testing location?.state?.page",location?.state?.page );
        
        if(location?.state?.page){
            paze=location?.state?.page
        }else{
            paze=page
        }
        return paze
    }

    const getPageLimit =()=>{
        let limitz ;
        console.log("testing location?.state?.page",location?.state?.page );
        
        if(location?.state?.limit){
            limitz=location?.state?.limit
        }else{
            limitz=limit
        }
        return limitz
    }
    

    useEffect(()=>{        
        if(bulkUpdateIds.length>0){
            setBulkDeleteButton(true)
        }else{
            setBulkDeleteButton(false)
        }
    },[bulkUpdateIds])
    
    useEffect(()=>{
        setPortfolioServices(getMenuPortfolio({routeModuleName:moduleName,getServicesByRouteModule:true}))
    },[moduleName]);

    useEffect(() => {
        if (portfolioSettingState?.data?.length) {
            //const staticModel = moduleName === "Initiative" ? staticSettingPortfolioList?.initiative : staticSettingPortfolioList?.organization;
            //console.log('testing comming module name', moduleName);
            const modelval = staticSettingPortfolioList2(moduleName)
            //console.log('testing modulename', modelval, moduleName);
            const portfolioValues = deepFindByName( portfolioSettingState?.data, modelval, false );
            //console.log('testing portfolioValues?.moduleName',portfolioValues?.moduleName);
            if (portfolioValues?.portfolioSections?.length) {
                if (portfolioValues?.moduleName) {
                    dispatchTag({ type: "EMPTY_TAGS" });
                    dispatchTag({
                      type: "SET_MODULE_NAME",
                      payload: portfolioValues?.moduleName,
                    });
                  }
                setPortfolioData(getSelectProperties(portfolioValues?.portfolioSections, true));
            } else {
                setPortfolioData([]);
            }
        } else {
            fetchAllPortfolio();
        }
         
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState?.data,moduleName]);

    const fetchAllPortfolio = async () => {
        try {
          const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
          if (response) {
            // message.success("Portfolio fetched successfully.");
          } else {
            message.error("Portfolio not found.");
          }
        } catch (error) {
          console.error("Error fetching portfolio:", error);
          message.error("Something went wrong.");
        }
    };

    const Buttons = (item) => {

        return <BusinessProcessButtonsV1 
            {...item} 
            setItemDelete={showConfirmDelete}
            moduleName={moduleName}
            showButtons={{
                view:false,
                edit:false,
                delete:true,
            }}
        />
    }

    useEffect(() => {
        setLoading(true);
        const pageNumber = getPageNumber()
        const pageLimit = getPageLimit()
        fetchFilteredData(selectedFilters,pageNumber,pageLimit);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [portfolioSettingState]);

    // Filter and debounce
    const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
    useEffect(() => {
        if (filterSubmit) {
            fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter),page,limit);
        }
        //Export with filters
        setFilterOptions({...removeEmptyArrObjOrval(debouncedFilter),page:page,limit:limit});
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filterSubmit,debouncedFilter,page,limit]);

            //Fetch tags items
  const fetchAllPortfolioTags = async () => {
    try {
      await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (stateTag?.moduleName) {
      fetchAllPortfolioTags();
    }
  }, [stateTag?.moduleName]);

    const fetchFilteredData = async (filter,pageNumber,pageLimit) => {
        try {
            setLoading(true);
            if (ref.current) {ref.current.continuousStart()}
            if (portfolioSettingState?.data?.length) {
                filter  = removeEmptyKeys(filter);
                const portfolio = await deepFindByName(
                    portfolioSettingState?.data,
                    getMenuPortfolio({routeModuleName:moduleName,getNameByRouteModule:true}),
                    false
                );
                
                const updatedFilter = {
                    moduleName: portfolio?.moduleName,
                    ...filter,
                    //sortBy:'displayname,ASC'
                };
                //const aa = await getPageNumber()
                //console.log("testing location?.state?.page pagenumber",updatedFilter, pageNumber,limit);
                const response = await PortfolioSettingServices?.getFilteredPortfolios(updatedFilter,pageNumber,pageLimit);
                //console.log("testing location?.state?.page response", response);
                
                if (response?.data?.data?.length) {
                    setTimeout(() => {
                        setLoading(false);
                        setLists(response?.data?.data);
                        updateLocale({key:portfolio?.moduleName,items:response?.data?.data})
                        setTotalRecords(response?.data?.totalCount || 0);
                    }, 500);
                } else {
                    //message.info("No records found.");
                    setTimeout(() => {
                        setLists(response?.data?.data);
                        setTotalRecords(response?.data?.totalCount || 0);
                        setFilterChanges(true)
                    }, 500);
                }
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        } finally {
            setFilterSubmit(false);
            setLoading(false);
            if (ref.current) {ref.current.complete()}
        }
    };

    const showConfirmDelete = (item,type) => {
        const id = item?.id || 0;
        Modal.confirm({
            title: 'Are you sure want to delete?',
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    let result;
                    result = await portfolioServices.deleteItem(id);
                    
                    if (result) {
                        let obj = {
                            id:id,
                            NameInSourceSystem: item?.NameInSourceSystem || "",
                            DisplayName: item?.DisplayName || "",
                            Description:item?.Description || "",
                            LevelID:item?.LevelID || "",
                        } 

                        let field_OldData = {
                            DisplayName: item?.DisplayName || "",
                            Description:item?.Description || "",
                            LevelID:item?.LevelID || "",
                        }

                        //Remove From Home Screen 
                        RemoveRecentViewed( {modelName:"",  modelID:id});

                        await UpdateChangeLog(obj.id,field_OldData,obj, "Delete", moduleName)

                        const filterdIds = bulkUpdateIds.filter((value)=>value !== id)
                        setBulkUpdateIds(filterdIds)
                        
                        await fetchFilteredData(selectedFilters,page,limit);
                        message.success(result?.data?.msg || "Deleted Successfully");
                    } else {
                        message.error(result?.data?.msg)
                    }
                } catch (error) {
                    message.error(error?.message)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    useEffect(()=>{
        if(deleteStart === true && bulkUpdateIds.length>0){
            Modal.confirm({
                title: 'Are you sure want to delete?',
                icon: <ExclamationCircleFilled />,
                async onOk() {
                    try {
                        let result;
                        result =
                            await PortfolioSettingServices.delete_bulk_Portfolio_ids(
                                {
                                    data:{
                                        id:bulkUpdateIds,
                                        moduleName:moduleName
                                    }
                                }
                            );
                        //console.log('testing results', result);
                            
                        if (result) {
                            // let obj = {
                            //     id: localStorage.getItem("id"),
                            //     NameInSourceSystem: "",
                            //     DisplayName: localStorage.getItem("DisplayName"),
                            //     Description: "",
                            //     LevelID: "",
                            // };
    
                            // let field_OldData = {
                            //     DisplayName: item?.displayname || "",
                            //     Description: item?.description || "",
                            //     LevelID: item?.levelid || "",
                            // };
    
                            // await UpdateChangeLog(
                            //     obj.id,
                            //     field_OldData,
                            //     obj,
                            //     "Delete",
                            //     stateMenu?.MenuID?.moduleName,
                            // );
    
                        //Remove From Home Screen 
                        //RemoveRecentViewed( {modelName:"",  modelID:id});
                              
                        //     setLists([]);
                                
                                setDeleteStart(false)
                                setBulkUpdateIds([])
                                fetchFilteredData(selectedFilters,page,limit);
                        //     setTotalRecords(0);
                        //     message.success(result?.data?.msg);
                                message.success("Deleted Successfully");
                            // setId(null)
                        } else {
                            message.error(result?.data?.msg);
                        }
                    } catch (error) {
                        console.log(error);
                        
                        message.error(error?.message);
                    }
                },
                onCancel() {
                    console.log("Cancel");
                    setDeleteStart(false)
                },
            });
        }
    },[deleteStart === true])

    
    const onCheckboxChange = (e) =>{
        let {value,checked} = e?.target;
        let ary = [...bulkUpdateIds];
        if(checked){
            ary.push(value);
        }else{
            ary = ary.filter(f=>f?.toString()!== value?.toString())
        }
        setBulkUpdateIds(ary);
    }

    
    return (
        <>
            <LoadingBar color='#1f1f1f' ref={ref} />
            <Layout className="applicationlist-page">
                <Layout.Content className='left_pnlbx'>
                    <Spin className="loading_bx" size="small" spinning={loading}>
                        <div className='container-fluid'>
                            {HeaderContnet}
                            <div className='overflow-y listview_bx' style={{maxHeight:window?.innerHeight-200}}>
                            <Checkbox.Group className="w-100">   
                                <BusinessProcessListContent
                                    lists = {lists}
                                    ButtonComponents={Buttons}
                                    loading={loading}
                                    moduleName={moduleName}
                                    basePath={location?.pathname}
                                    isListCheckbox={true}
                                    onCheckboxChange={onCheckboxChange}
                                    page={page}
                                    limit={limit}
                                />
                            </Checkbox.Group>     
                            </div>
                            {totalRecords>0 &&
                            <div style={{display:'flex', flexDirection:'row', justifyContent:'space-between', alignItems:'center'}}>
                                <span className="mt-2" style={{fontSize:14}}> {bulkUpdateIds?.length} Selected {`${bulkUpdateIds?.length>1 ? "Items": "Item"}`} </span>
                                <Pagination
                                    className="mt-2 text-right"
                                    total={totalRecords}
                                    showTotal={(total) =>
                                        `Total ${total} Items`
                                    }
                                    defaultPageSize={limit || default_pageSize}
                                    defaultCurrent={page}
                                    pageSizeOptions={default_pageSizeOptions}
                                    showSizeChanger
                                    onChange={(p, ps)=>{
                                        setFilterSubmit(true);
                                        setLimit(ps)
                                        setPage(p);
                                    }}
                                />
                                </div>
                            }
                        </div>
                    </Spin>
                </Layout.Content>
                {/* <Layout.Sider theme='light'  width={(!showFilter)?50:240} style={{height:"100vh"}}> */}
                <Layout.Sider theme='light'  width={(!showFilter)?50:240} className="right_pnlbx">
                    <BusinessProcessListFilter
                        lifeCycleStagesColors={lifeCycleStagesColors}
                        showFilter={showFilter}
                        setShowFilter={setShowFilter}
                        portfolioData={portfolioData}
                        selectedFilters={selectedFilters}
                        // setSelectedFilters={setSelectedFilters}
                        setSelectedFilters={(value)=>{setSelectedFilters(value); }}
                        sortValues={sortByValue}
                        optionalLevel={false}
                        //Filter relations
                        filterSubmit={filterSubmit}
                        setFilterSubmit={(value)=>{setFilterSubmit(value)}}
                        selectedRelationFilters={selectedRelationFilters}
                        setSelectedRelationFilters={setSelectedRelationFilters}
                    />
                </Layout.Sider>
            </Layout>
        </>
    )
}

PlantList.defaultProps = {
    setFilterOptions:()=>{},
};

export default PlantList;