import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  Divider,
  Drawer,
  Flex,
  Modal,
  Row,
  Space,
  Tooltip,
  message,
} from "antd";
import { PageTitleHeading } from "../../../utils/Common";
import { FullscreenOutlined } from "@ant-design/icons";
import parse from "html-react-parser";

function ViewDifferencesInDiagram({
  diagramList,
  diagramZoom,
  setDiagramZoom,
}) {
  const [diagramMaster, setDiagramMaster] = useState([]);
  const [diagramScenario, setDiagramScenario] = useState([]);

  useEffect(() => {
    let masterDia = [];
    let newDia = [];
    diagramList?.map((item) => {
      if (item?.master_value) {
        masterDia.push(item);
      }

      if (item?.new_value) {
        newDia.push(item);
      }
    });
    setDiagramMaster(masterDia);
    setDiagramScenario(newDia);
  }, [diagramList]);

  return (
    <>
      {/* <Button
        onClick={() => {
          setDiagramZoom(true);
        }}
        icon={<FullscreenOutlined />}
        className="pull-right"
      /> */}
      <React.Fragment>
        <div className="card w-100 border-0">
          <Row className="justify-content-start diagram-viewdifferent">
            <Col span={12} className="diagram-left">
              {diagramMaster?.map((item) => {
                return (
                  <>
                    <h2>Master Diagram - {item.label_name}</h2>
                    {/* <div className="card-header d-flex justify-content-between align-items-center bg-none border-0 py-0 border-bottom"> */}
                    {/* <PageTitleHeading text={item.label_name} size={4} /> */}
                    {/* <h2>{item.label_name}</h2> */}
                    {/* </div> */}
                    <div className="card-body">
                      <div className="card" style={{ padding: 15 }}>
                        {(() => {
                          if (item?.diagram_type === "embed_diagram") {
                            //return <h5>IFrame</h5>;
                            return (
                              item?.master_value && (
                                <div className="embed-responsive embed-responsive-16by9">
                                  {item?.master_value &&
                                    parse(item?.master_value)}
                                </div>
                              )
                            );
                          } else {
                            //return(<h5>SVG</h5>);
                            return (
                              item?.master_value && (
                                <object
                                  data={item?.master_value}
                                  alt={item?.label_name}
                                  className="img-fluid"
                                  type="image/svg+xml"
                                  style={{ maxHeight: window?.innerHeight / 2 }}
                                ></object>
                              )
                            );
                          }
                        })()}
                      </div>
                    </div>
                  </>
                );
              })}
            </Col>
            <Col span={12} className="diagram-right">
              {/* Scenario */}
              {diagramScenario?.map((item) => {
                return (
                  <>
                    <h2>Scenario Diagram - {item.label_name}</h2>
                    {/* <div className="card-header d-flex justify-content-between align-items-center bg-none border-0 py-0 border-bottom"> */}
                    {/* <PageTitleHeading text={item.label_name} size={4} /> */}
                    {/* <h2>{item.label_name}</h2> */}
                    {/* </div> */}
                    <div className="card-body">
                      <div className="card" style={{ padding: 15 }}>
                        {(() => {
                          if (item?.diagram_type === "embed_diagram") {
                            // return <h5>IFrame</h5>;
                            return (
                              item?.new_value && (
                                <div className="embed-responsive embed-responsive-16by9">
                                  {item?.new_value && parse(item?.new_value)}
                                </div>
                              )
                            );
                          } else {
                            //return(<h5>SVG</h5>);
                            return (
                              item?.new_value && (
                                <object
                                  data={item?.new_value}
                                  alt={item?.label_name}
                                  className="img-fluid"
                                  type="image/svg+xml"
                                  style={{ maxHeight: window?.innerHeight / 2 }}
                                ></object>
                              )
                            );
                          }
                        })()}
                      </div>
                    </div>
                  </>
                );
              })}
            </Col>
          </Row>
        </div>
      </React.Fragment>

      <Drawer
        //title={selectdiagramData?.name || ""}
        placement="top"
        width={window?.innerWidth}
        height={window?.innerHeight}
        onClose={() => {
          setDiagramZoom(false);
        }}
        open={diagramZoom}
        extra={
          <Space>
            {/* <Button onClick={()=>{setImageScreen(false)}} icon={<FullscreenExitOutlined />}/> */}
          </Space>
        }
      >
        <div className="difference-viewer">
          <div className="card-header">
          <h2 onClick={() => {}}>Diagram</h2>
          </div>
          <div className="card w-100 border-0">          
            <Row className="justify-content-start diagram-viewdifferent">
              <Col span={12} className="diagram-left">
                {diagramMaster?.map((item) => {
                  return (
                    <>
                     <h2>Master Diagram - {item.label_name}</h2>
                      {/* <div className="card-header d-flex justify-content-between align-items-center bg-none border-0 py-0 border-bottom"> */}
                        {/* <PageTitleHeading text={item.label_name} size={4} /> */}
                        {/* <h2>{item.label_name}</h2> */}
                      {/* </div> */}
                      <div className="card-body">
                        <div className="card" style={{ padding: 15 }}>
                          {(() => {
                            if (item?.diagram_type === "embed_diagram") {
                              return <h5>IFrame</h5>;
                              // return (
                              //   <div className="embed-responsive embed-responsive-16by9">
                              //     {selectdiagramData?.embed_diagram &&
                              //       parse(selectdiagramData?.embed_diagram)}
                              //   </div>
                              // );
                            } else {
                              //return(<h5>SVG</h5>);
                              return (
                                item?.master_value && (
                                  <object
                                    data={item?.master_value}
                                    alt={item?.label_name}
                                    className="img-fluid"
                                    type="image/svg+xml"
                                    style={{ maxHeight: window?.innerHeight / 2 }}
                                  ></object>
                                )
                              );
                            }
                          })()}
                        </div>
                      </div>
                    </>
                  );
                })}
              </Col>
              <Col span={12} className="diagram-right">
                {/* Scenario */}
                {diagramScenario?.map((item) => {
                  return (
                    <>  
                    <h2>Scenario Diagram - {item.label_name}</h2>
                      {/* <div className="card-header d-flex justify-content-between align-items-center bg-none border-0 py-0 border-bottom"> */}
                        {/* <PageTitleHeading text={item.label_name} size={4} /> */}
                        {/* <h2>{item.label_name}</h2> */}
                      {/* </div> */}
                      <div className="card-body">
                        <div className="card" style={{ padding: 15 }}>
                          {(() => {
                            if (item?.diagram_type === "embed_diagram") {
                              return <h5>IFrame</h5>;
                              // return (
                              //   <div className="embed-responsive embed-responsive-16by9">
                              //     {selectdiagramData?.embed_diagram &&
                              //       parse(selectdiagramData?.embed_diagram)}
                              //   </div>
                              // );
                            } else {
                              //return(<h5>SVG</h5>);
                              return (
                                item?.new_value && (
                                  <object
                                    data={item?.new_value}
                                    alt={item?.label_name}
                                    className="img-fluid"
                                    type="image/svg+xml"
                                    style={{ maxHeight: window?.innerHeight / 2 }}
                                  ></object>
                                )
                              );
                            }
                          })()}
                        </div>
                      </div>
                    </>
                  );
                })}
              </Col>
            </Row>
          </div>
        </div>
      </Drawer>
    </>
  );
}

export default ViewDifferencesInDiagram;
