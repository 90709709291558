import React, { useEffect, useState } from "react";
import {
  Card,
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Select,
  Collapse,
  message,
  Spin,
  Tooltip,
  Switch,
} from "antd";
import {
  EditTwoTone,
  DeleteTwoTone,
  PlusOutlined,
  ExclamationCircleFilled,
  SortAscendingOutlined,
  SortDescendingOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons";
import { normalizeString } from "../../utils/linksCommon";
import {
  changeFirstLetter,
  portfolioTypes,
  restrictedTexts,
} from "../../utils/Common";
import PortfolioSectionServices from "../../services/services/PortfolioSectionService";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import PortfolioPropertyServices from "../../services/services/PortfolioPropertyService";
import { getDynamicMessage } from "../../utils/businessApplication";
import { useDebounce } from "use-debounce";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import ColorPickerPopover from "./components/ColorPickerPopover";
import { checkRole } from "../../helper/useUserData";
const { confirm } = Modal;
const { Option } = Select;
const { Panel } = Collapse;
const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

const formItemLayoutWithOutLabel = {
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 24,
    },
  },
};

const ApplicationTab = ({
  selectedMenuText,
  selectedMenu,
  item,
  fetchAllPortfolio,
  setUpdatedMenu,
  activeTab,
}) => {
  const { state, dispatch } = usePortfolioSetting();
  const [formSection] = Form.useForm();
  const [formProperty] = Form.useForm();
  const [formSectionOpen, setFormSectionOpen] = useState(false);
  const [formNewPropertyOpen, setFormNewPropertyOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [sectionsData, setSectionsData] = useState([]);
  const [sectionOptions, setSectionOptions] = useState([]);
  const [defaultOption, setDefaultOption] = useState("");
  const [isEditSection, setIsEditSection] = useState(false);
  const [isEditProperty, setIsEditProperty] = useState(false);
  const [loadingSection, setLoadingSection] = useState(false);
  const [loadingProperty, setLoadingProperty] = useState(false);
  const [showSelectFields, setShowSelectFields] = useState(false);
  const [activePanelz, setactivePanelz] = useState([]);
  const [collapsedSections, setCollapsedSections] = useState({});
  const [portfolioTypeOptions, setPortfolioTypeOptions] = useState(
    portfolioTypes?.map((val) => {
      return { value: val, lable: normalizeString(val) };
    })
  );
  const [callParentApi, setCallParentApi] = useState(false);
  const [initialPropertyOptions, setInitialPropertyOptions] = useState([]);
  const [removedPropertyOptions, setRemovedPropertyOptions] = useState([]);

  const updateMenuItems = () => {
    setUpdatedMenu({ id: selectedMenu, name: selectedMenuText });
  };

  const fetchSectionOption = (data) => {
    let options = [];
    if (data && data?.length) {
      data?.map((itemOption) => {
        const option = {
          value: itemOption?.id,
          label: normalizeString(itemOption?.name),
          portfolioId: itemOption?.portfolioId,
        };
        options.push(option);
      });
    }
    setSectionOptions(options);
  };

  useEffect(() => {
    updateMenuItems();
    setSectionsData(item?.portfolioSections || []);
    fetchSectionOption(item?.portfolioSections || []);
  }, [item]);

  useEffect(() => {
    setactivePanelz([]);
  }, [selectedMenu, activeTab]);

  useEffect(() => {
    const activeWindow = localStorage.getItem("activePanelz");
    const splitValues = activeWindow ? activeWindow.split(",") : [];
    if (activeWindow) {
      setactivePanelz(splitValues);
    }

    const handleUnload = () => {
      localStorage.removeItem("activePanelz");
      // You can perform any cleanup actions here, like clearing local storage, or sending analytics data
    };
    // Add the event listener for beforeunload
    // window.addEventListener('beforeunload', handleUnload);

    return () => {
      console.log("Component is unmounted");
      // Remove the event listener when the component unmounts
      window.addEventListener("beforeunload", handleUnload);
    };
  }, []);

  useEffect(() => {
    if (callParentApi) {
      fetchAllPortfolio();
      setCallParentApi(!callParentApi);
    }
  }, [callParentApi]);

  const handleSectionSubmit = async (formData) => {
    let keyz = activePanelz;
    try {
      setLoading(true);
      setLoadingSection(true);
      const newSection = { ...formData, portfolioId: selectedMenu };
      let response;
      if (isEditSection) {
        response = await PortfolioSectionServices.update(
          formData?.id,
          newSection
        );
        setIsEditSection(!isEditSection);
        //message.success(`Section ${formData?.name} Updated Successfully.`);
        message.success(`Updated Successfully.`);
      } else {
        response = await PortfolioSectionServices.create(newSection);
        //message.success(`Section ${formData?.name} Created Successfully.`);
        message.success(`Created Successfully.`);
      }
      console.log("response-section", response?.data);
      setFormSectionOpen(!formSectionOpen);
      setTimeout(() => {
        formSection.resetFields();
        setCallParentApi(!callParentApi);

        setTimeout(() => {
          document.querySelector(`.application-tab-section-content-${formData?.id}`).scrollIntoView({
            behavior: 'instant',
          });
        }, 1000);
      }, 1000);
    } catch (error) {
      message.error(error?.message);
    } finally {
      setLoadingSection(false);
      setactivePanelz(keyz);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const setFormNewPropertySubmit = async (formData) => {
    try {
      const newSection = {
        ...formData,
        // name: await changeFirstLetter(formData?.name, true),
        portfolioId: selectedMenu,
        propertyOptions:
          formData?.propertyOptions && removedPropertyOptions
            ? [...formData?.propertyOptions, ...removedPropertyOptions]
            : [],
      };
      setLoading(true);
      setLoadingProperty(true);
      if (isEditProperty) {
        await PortfolioPropertyServices.update(formData?.id, newSection);
        setIsEditProperty(!isEditProperty);
        //message.success(`Property ${formData?.name} Updated Successfully.`);
        message.success(`Updated Successfully.`);
        setFormNewPropertyOpen(false);
      } else {
        await PortfolioPropertyServices.create(newSection);
        //message.success(`Property ${formData?.name} Created Successfully.`);
        message.success(`Created Successfully.`);
        setFormNewPropertyOpen(true);
      }
      
      setTimeout(() => {
        setRemovedPropertyOptions([]);
        formProperty.resetFields(['name', 'isRequired', 'type']);
        setShowSelectFields(false)
        setCallParentApi(!callParentApi);

        setTimeout(() => {
          document.querySelector(`.application-tab-property-content-${formData?.id}`).scrollIntoView({
            behavior: 'instant',
          });
        }, 1000);
      }, 1000);
    } catch (error) {
      // message.error(
      //   getDynamicMessage(error?.response?.data?.error) || error?.message);

      message.error(error?.response?.data?.error);
    } finally {
      setLoadingProperty(false);
      setTimeout(() => {
        setLoading(false);
      }, 1500);
    }
  };

  const EditSection = (item) => {
    setFormSectionOpen(true); // Open the edit section modal
    setIsEditSection(true);
    formSection.setFieldsValue({
      id: item?.id,
      name: item?.name,
    });
    // Prevent the collapse by setting the collapse state to true
    // when opening the edit section modal
    setCollapsedSections((sections) => ({ ...sections, [item.id]: false }));
  };

  const EditProperty = async (item) => {
    setShowSelectFields(
      item?.type === "Single select" || item?.type === "Multi select"
    );
    setFormNewPropertyOpen(true); // Open the edit property modal
    setIsEditProperty(true);
    formProperty.setFieldsValue({
      id: item?.id,
      sectionId: item?.sectionId,
      type: item?.type,
      //name: await changeFirstLetter(item?.name, false),
      name: item?.displayName,
      propertyOptions: item?.propertyOptions,
      isRequired: item?.isRequired,
    });
    // Prevent the collapse by setting the collapse state to true
    // when opening the edit property modal
    setCollapsedSections((sections) => ({
      ...sections,
      [item.sectionId]: false,
    }));
  };

  const showConfirm = (item, Issection = true) => {
    confirm({
      title: `Are you sure want to delete "${item?.displayName || item.name }" ${
        Issection ? "section" : "property"
      } once deleted it can't be recovered?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          let responsePortfolio;
          if (Issection) {
            responsePortfolio =
              await PortfolioSectionServices.deletePortfolioSection(item?.id, {
                isForce: 0,
              });
          } else {
            responsePortfolio =
              await PortfolioPropertyServices.deletePortfolioProperty(
                item?.id,
                { isForce: 0 }
              );
          }

          //Added by Awadhesh
          if (Issection) {
            message.success("Deleted Successfully");
          } else {
            message.success("Deleted Successfully");
          }

          await setTimeout(() => {
            setCallParentApi(!callParentApi);
          }, 1000);
        } catch (error) {
          message.error(error?.response?.data?.error || error?.message);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const showConfirmPropertyOption = ({ item, remove, name }) => {
    confirm({
      title: `Are you sure want to delete "${item?.name}" once deleted it can't be recovered?`,
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          setRemovedPropertyOptions((prev) => [...prev, item]);
          remove(name, item);
        } catch (error) {
          console.error("error", error);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const EditDeleteButtons = ({ item, isProperty = false }) => {
    const disable = isProperty && item?.isRestricted;
    const iconColor = isProperty && item?.isRestricted ? "#A9A9A9" : "#207dff";

    return (
      <div>
        <Tooltip
          title={
            isProperty && item?.isRestricted
              ? `Property ${item?.name} is restricted your not able to modified.`
              : ""
          }
          trigger="hover"
        >
          <Button
            icon={
              <EditTwoTone
                twoToneColor={iconColor}
                className={`cursor-thumb`}
                onClick={() => {
                  isProperty ? EditProperty(item) : EditSection(item);
                }}
              />
            }
            type="text"
            disabled={disable}
            className="border-none"
          />
        </Tooltip>
        <Tooltip
          title={
            isProperty && item?.isRestricted
              ? `Property ${item?.name} is restricted your not able to delete`
              : ""
          }
          trigger="hover"
        >
          <Button
            icon={
              <DeleteTwoTone
                className={`cursor-thumb`}
                twoToneColor={iconColor}
                onClick={() => {
                  isProperty ? showConfirm(item, false) : showConfirm(item);
                }}
              />
            }
            type="text"
            disabled={disable}
            className="border-none"
          />
        </Tooltip>
      </div>
    );
  };

  const PropertyCard = ({ item }) => {
    return (
      <div className={`card card-body p-2 mb-2 setting_carditem application-tab-property-content-${item?.id}`} key={item?.id}>
        <div className="d-flex justify-content-between">
          {/* <div>{normalizeString(item?.displayName)}</div> */}
          <div>{item?.displayName}</div>

          <div className="d-flex justify-content-end">
            {/* <EditTwoTone
              className={`cursor-thumb`}
              onClick={() => {
                EditProperty(item);
              }}
            /> */}
            &emsp;
            {/* <DeleteTwoTone
              className={`cursor-thumb`}
              onClick={() => {
                showConfirm(item, false);
              }}
            /> */}
            <EditDeleteButtons item={item} isProperty={true} />
          </div>
        </div>
      </div>
    );
  };

  const SectionCard = ({ item, id, isFirst, index }) => {
    const [sortProperties, setSortProperties] = useState("ASC");
    const [searchText, setSearchText] = useState("");
    const [currentTabItem, setcurrentTabItem] = useState(null);
    const [newCurrentTabItem, setNewCurrentTabItem] = useDebounce(
      currentTabItem,
      200
    );

    useEffect(() => {
      if (item?.properties) {
        // Sort the array by updatedAt property in descending order
        // const sortedItems = item.properties.sort((a, b) => {
        //   return new Date(b.updatedAt) - new Date(a.updatedAt);
        // });

        // Update the state with the sorted array
        setcurrentTabItem(item.properties);
      }
    }, [item]);

    const searchByTextOrder = (value) => {
      // console.log("searchText>>>>>>>>>>>>>>>>>>>>>>.", value);
      if (value === undefined || value === null || value === "") {
        // If no value is provided, reset to the original list
        setcurrentTabItem(item.properties); // Assuming you have an originalTabItem to reset to
      } else {
        // Filter items by name including the value
        const filteredItems = currentTabItem.filter((item) =>
          item.displayName?.toLowerCase().includes(value?.toLowerCase())
        );
        setcurrentTabItem(filteredItems);
      }
    };

    const aceOrder = () => {
      setcurrentTabItem(
        [...currentTabItem]?.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
      );
    };

    const deceOrder = () => {
      setcurrentTabItem(
        [...currentTabItem]?.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        })
      );
    };

    return (
      <div key={id} className={`application-tab-section-content-${id} my-2`}>
        <Collapse
          activeKey={activePanelz}
          onChange={(activeKeys) => {
            setCollapsedSections(
              activeKeys.reduce((acc, key) => ({ ...acc, [key]: false }), {})
            );
            setactivePanelz(activeKeys);
            localStorage.setItem("activePanelz", activeKeys);
            console.log("activePanelz", activeKeys);
          }}
        >
          <Panel
            header={
              <div onClick={(e) => e.stopPropagation()}>
                {sortProperties === "DESC" ? (
                  <SortAscendingOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      setSortProperties("ASC");
                      aceOrder();
                    }}
                  />
                ) : (
                  <SortDescendingOutlined
                    className="cursor-pointer"
                    onClick={() => {
                      setSortProperties("DESC");
                      deceOrder();
                    }}
                  />
                )}
                &nbsp;{normalizeString(item?.name)}
              </div>
            }
            key={id}
            extra={
              <div
                className="d-flex justify-content-end"
                onClick={(e) => e.stopPropagation()}
              >
                {/* <EditTwoTone
                  className={`cursor-thumb`}
                  onClick={() => {
                    EditSection(item);
                  }}
                />
                &emsp;
                <DeleteTwoTone
                  className={`cursor-thumb`}
                  onClick={() => {
                    showConfirm(item);
                  }}
                /> */}
                <EditDeleteButtons item={item} isProperty={false} />
                &emsp;
                <Input.Search
                  style={{
                    width: "150px",
                    marginLeft: "20px",
                  }}
                  placeholder="Search"
                  onChange={(e) => {
                    if(e.target.value.length === 0){
                      searchByTextOrder(e.target.value);
                    }
                  }}
                  onSearch={(value) => {
                    searchByTextOrder(value);
                    setSearchText(value);
                  }}
                  onPressEnter={(e) => {
                    e.stopPropagation()
                    console.log("tagetvalue",e.target.value)
                    searchByTextOrder(e.target.value);
                  }}
                ></Input.Search>
              </div>
            }
          >
            <div
              className={`overflow-y p-2 mb-2`}
              style={{ height: window.innerHeight / 3 }}
            >
              {/* {(item?.properties &&
                item?.properties?.length &&
                item?.properties
                  ?.sort((a, b) => {
                    if (sortProperties === "ASC") {
                      return a?.localeCompare(b, undefined, {
                        numeric: true,
                        sensitivity: "base",
                      });
                    } else {
                      return b?.localeCompare(a, undefined, {
                        numeric: true,
                        sensitivity: "base",
                      });
                    }
                  })
                  ?.filter((f) => f?.indexOf(searchText) !== -1)
                  ?.map((val, index) => (
                    <PropertyCard item={val} key={index} />
                  ))) || (
                <div className="text-base font-bold mt-4">No items found.</div>
              )} */}
              <Droppable
                key={id}
                droppableId={`${id}`}
                index={index}
                type="child"
              >
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {(currentTabItem &&
                      currentTabItem?.length &&
                      currentTabItem
                        ?.sort((a, b) => a.position - b.position)
                        ?.map((val, index) => (
                          <Draggable
                            key={`${val?.id}`}
                            draggableId={`${val?.id}`}
                            index={index}
                          >
                            {(provided) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <PropertyCard item={val} key={index} />
                              </div>
                            )}
                          </Draggable>
                        ))) || (
                      <div className="text-base font-bold mt-4">
                        No items found.
                      </div>
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </div>
          </Panel>
        </Collapse>
      </div>
    );
  };

  // Custom validation rule to check if the input value matches the restricted text
  const validateRestrictedText = (_, value) => {
    if (restrictedTexts.includes(value?.toLowerCase())) {
      return Promise.reject(`This ${value} is not allowed`);
    }
    return Promise.resolve();
  };

  const PropertyOption = ({
    field,
    fields,
    index,
    remove,
    propertyOptionsItem,
    isEditProperty,
  }) => {
    const { key, name } = field;

    // Custom validation rule to check if the name starts with a number or special character
    const validateName = (_, value) => {
      if (!/^[A-Za-z]/.test(value)) {
        return Promise.reject("Name should start with a letter");
      }
      return Promise.resolve();
    };

    const handleRemove = () => {
      const hasId = propertyOptionsItem?.id;
      if (!hasId) {
        if (fields.length > 1) {
          remove(name);
        } else {
          message.error("At least one option is required");
        }
      } else {
        // Update form data with action "delete"
        const newFormData = {
          ...propertyOptionsItem,
          action: "delete",
        };
        showConfirmPropertyOption({ item: newFormData, remove, name });
      }
    };

    const portFolioColor = item?.color;

    const handleColorChange = (color) => {
      formProperty.setFieldsValue({
        propertyOptions: formProperty
          .getFieldValue("propertyOptions")
          .map((option, i) =>
            i === index ? { ...option, color: color } : option
          ),
      });
    };

    return (
      <Form.Item
        {...formItemLayoutWithOutLabel}
        required={false}
        key={`${key}-${index}`}
      >
        <div className="flex gap-2">
          <div className="flex w-1/4">
            <Form.Item
              {...field}
              name={[name, "color"]}
              validateTrigger={["onChange", "onBlur"]}
              rules={[{ required: false, message: "Please pick a color!" }]}
              initialValue={
                formProperty?.getFieldValue()?.propertyOptions?.[index]
                  ?.color ||
                portFolioColor ||
                "#FFFFFF"
              }
              noStyle
              key={`${key}-color-${index}`}
            >
              <ColorPickerPopover
                color={
                  formProperty?.getFieldValue()?.propertyOptions?.[index]
                    ?.color ||
                  portFolioColor ||
                  "#FFFFFF"
                }
                key={index}
                onChange={handleColorChange}
              />
            </Form.Item>
          </div>
          <div className="flex w-3/4">
            <Form.Item
              {...field}
              name={[name, "name"]}
              validateTrigger={["onChange", "onBlur"]}
              rules={[
                { required: true, message: "Please enter!" },
                // { validator: validateName },
              ]}
              noStyle
              key={`${key}-name-${index}`}
              initialValue=""
            >
              <Input
                style={{ width: "90%", marginRight: "10px" }}
              />
            </Form.Item>
            {fields.length > 1 && (
              <MinusCircleOutlined
                className="dynamic-delete-button"
                onClick={handleRemove}
              />
            )}
            <Form.Item
              {...field}
              name={[name, "portfolioId"]}
              initialValue={selectedMenu}
              style={{ display: "none" }}
              key={`${key}-action-${index}`}
            >
              <Input />
            </Form.Item>
            {isEditProperty && propertyOptionsItem?.id ? (
              <Form.Item
                {...field}
                name={[name, "action"]}
                initialValue="update"
                style={{ display: "none" }}
                key={`${key}-action-${index}`}
              >
                <Input />
              </Form.Item>
            ) : isEditProperty ? (
              <Form.Item
                {...field}
                name={[name, "action"]}
                initialValue="add"
                style={{ display: "none" }}
                key={`${key}-action-${index}`}
              >
                <Input />
              </Form.Item>
            ) : (
              <></>
            )}
          </div>
        </div>
      </Form.Item>
    );
  };

  const PropertiesOptionsList = ({ propertyOptionsValues, isEditProperty }) => {
    const handleAdd = (add) => {
      add();
    };

    return (
      <Form.List
        name="propertyOptions"
        rules={[
          {
            validator: async (_, propertyOptions) => {
              if (!propertyOptions || propertyOptions.length < 1) {
                return Promise.reject(new Error("At least 1 field required"));
              }
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <>
            <Form.Item>
              <label className="fw-500">Options</label>
              <Button
                type="link"
                onClick={() => handleAdd(add)}
                className="pull-right border-0"
                icon={<PlusOutlined />}
              >
                Add
              </Button>
              <Form.ErrorList errors={errors} />
            </Form.Item>
            <div className="overflow-y" style={{maxHeight:150}}>
              {fields.map((field, index) => (
                <PropertyOption
                  key={`${field.key}-${index}`}
                  field={field}
                  fields={fields}
                  index={index}
                  remove={remove}
                  propertyOptionsItem={propertyOptionsValues[index]}
                  isEditProperty={isEditProperty}
                />
              ))}
            </div>
          </>
        )}
      </Form.List>
    );
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = [...list]; // Create a shallow copy of the list
    const [removed] = result.splice(startIndex, 1);
    const result2 = result.toSpliced(endIndex, 0, removed);
    return result2;
  };

  const handleReorderSectionSubmit = async (list, startIndex, endIndex) => {
    const result = [...list]; // Create a shallow copy of the list
    const [removed] = result.splice(startIndex, 1);
    const result2 = result.toSpliced(endIndex, 0, removed);
    result2.map((sec, index) => {
      sec.position = index + 1;
      delete sec.properties;
      delete sec.createdAt;
      delete sec.isDeleted;
      delete sec.updatedAt;
    });
    // let formdata = removed;
    let response = null;
    setLoadingSection(true);
    response = await PortfolioSettingServices.updateSectionOrdering(result2);
    // message.success(`Section ${removed?.name} Updated Successfully.`);
    message.success(`Updated Successfully.`);
    const res = await PortfolioSettingServices.getList({ page: 1, limit: 100 });
    const newData = res.data.data;
    await dispatch({ type: "SET_PORTFOLIO_SETTINGS", payload: newData });
  };
  const handleReorderPropertiesSubmit = async (list, startIndex, endIndex) => {
    const result = [...list]; // Create a shallow copy of the list
    const [removed] = result.splice(startIndex, 1);
    const result2 = result.toSpliced(endIndex, 0, removed);
    result2.map((prop, index) => {
      prop.position = index + 1;
      delete prop.createdAt;
      delete prop.isDeleted;
      delete prop.updatedAt;
    });
    // let formdata = removed;
    let response = null;
    setLoadingSection(true);
    response = await PortfolioSettingServices.updatePropertiesOrdering(result2);
    // message.success(`Section ${removed?.name} Updated Successfully.`);
    message.success(`Updated Successfully.`);
    const res = await PortfolioSettingServices.getList({ page: 1, limit: 100 });
    const newData = res.data.data;
    await dispatch({ type: "SET_PORTFOLIO_SETTINGS", payload: newData });
  };

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return;
    }
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;
    const sourceDroppableId = result.source.droppableId;
    const destinationDroppableId = result.destination.droppableId;

    if (
      sourceDroppableId === destinationDroppableId &&
      sourceDroppableId != "parents"
    ) {
      // Reordering items within the same list
      const list = sectionsData.find((p) => p.id == sourceDroppableId);
      const reorderedList2 = await reorder(
        list?.properties,
        sourceIndex,
        destinationIndex
      );
      const reorderedItems = await sectionsData.map((p) =>
        p.id == sourceDroppableId ? { ...p, properties: reorderedList2 } : p
      );
      // setSectionsData(reorderedItems);
      handleReorderPropertiesSubmit(
        list?.properties,
        sourceIndex,
        destinationIndex
      );
    } else {
      // Moving items between parent lists
      const reorderedItems = await reorder(
        sectionsData,
        result.source.index,
        result.destination.index
      );
      setSectionsData(reorderedItems);
      handleReorderSectionSubmit(
        sectionsData,
        result.source.index,
        result.destination.index
      );
    }
  };

  return (
    <Spin key={selectedMenu} spinning={loading}>
      {/* <div className="h5 mb-0">{selectedMenuText} Properties</div> */}
      <div>
        (Here you can create properties that will appear in {selectedMenuText}{" "}
        creation screen)
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="parents" type="parent">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {(sectionsData &&
                sectionsData?.length &&
                sectionsData
                  ?.sort((a, b) => a.position - b.position)
                  ?.map((value, index) => (
                    <Draggable
                      key={`${value?.id}`}
                      draggableId={`${value?.id}`}
                      index={index}
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <SectionCard
                            item={value}
                            id={value?.id}
                            isLast={index === sectionsData?.length - 1}
                            isFirst={index === 0}
                            index={index}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))) || (
                <div className="text-base font-bold mt-4">No items found.</div>
              )}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div className="flex justify-between items-center">
        {checkRole('save') && <Button
          type="primary"
          icon={<PlusOutlined />}
          className="pull-left"
          onClick={() => {
            setFormSectionOpen(!formSectionOpen);
          }}
        >
          New Section
        </Button>}
        {checkRole('save') && <Button
          type="primary"
          icon={<PlusOutlined />}
          disabled={item?.portfolioSections?.length === 0}
          onClick={() => {
            console.log("item?.id", item);
            formProperty.setFieldsValue({
              sectionId: item?.portfolioSections[0]?.id,
              type: portfolioTypes[0],
            });
            setDefaultOption(normalizeString(item?.name) || "");
            setFormNewPropertyOpen(!formSectionOpen);
          }}
          className="my-2"
        >
          {isEditProperty ? "Update Property" : "New Property"}
        </Button>}
      </div>

      <Modal
        closable
        title={`${isEditSection ? "Update Section" : "Add Section"}`}
        destroyOnClose
        open={formSectionOpen}
        onCancel={() => {
          setIsEditSection(false);
          setFormSectionOpen(!formSectionOpen);
          formSection.resetFields();
        }}
        footer={false}
        requiredMark={false}
        maskClosable={false}
      >
        <Form
          form={formSection}
          onFinish={handleSectionSubmit}
          autoComplete="off"
        >
          <div className="card card-body">
            {isEditSection && (
              <Form.Item
                {...formItemLayout}
                className="my-3"
                label="ID"
                name="id"
                style={{ display: "none" }}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item
              {...formItemLayout}
              className="my-3"
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <Row className="mt-3">
            <Col span={24}>
              {checkRole('save') && <Button
                type={`primary`}
                className={`w-100`}
                htmlType="submit"
                disabled={formSection.submitting || loadingSection}
              >
                {formSection.submitting || loadingSection ? (
                  <Spin spinning={loadingSection} />
                ) : (
                  "Save"
                )}
              </Button>}
            </Col>
          </Row>
        </Form>
      </Modal>

      <Modal
        closable
        title={`${isEditProperty ? "Update Property" : "Add New Property"}`}
        destroyOnClose
        open={formNewPropertyOpen}
        onCancel={() => {
          setRemovedPropertyOptions([]);
          setIsEditProperty(false);
          setFormNewPropertyOpen(!formNewPropertyOpen);
          setShowSelectFields(false);
          formProperty.resetFields();
        }}
        footer={false}
        maskClosable={false}
      >
        <Form
          form={formProperty}
          onFinish={setFormNewPropertySubmit}
          initialValues={{
            propertyOptions: isEditProperty ? initialPropertyOptions : [],
            isRequired: false,
          }}
          autoComplete="off"
          requiredMark={false}
        >
          <div className="card card-body">
            {isEditProperty && (
              <Form.Item
                {...formItemLayout}
                className="my-3"
                label="ID"
                name="id"
                style={{ display: "none" }}
              >
                <Input />
              </Form.Item>
            )}

            <Form.Item
              {...formItemLayout}
              className="my-1"
              label="Section"
              name="sectionId"
              rules={[{ required: true, message: "Please Select!" }]}
              initialValue={sectionOptions[0]?.value || ""}
            >
              <Select>
                {sectionOptions?.map((option) => (
                  <Option key={option.value} value={option.value}>
                    {option.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              className="my-1"
              label="Name"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Please input your name!",
                },
                { validator: validateRestrictedText },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              className="my-1"
              label="Is Required"
              name="isRequired"
            >
              <Switch
                onChange={(value) => {
                  formProperty.setFieldsValue({
                    isRequired: value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              className="my-1"
              label="Type"
              name="type"
              rules={[{ required: true, message: "Please select!" }]}
              initialValue={portfolioTypeOptions[0]?.value || ""}
            >
              <Select
                options={portfolioTypeOptions}
                // Need to update the condition in feature
                disabled={isEditProperty}
                onChange={(value) => {
                  setShowSelectFields(
                    value === "Single select" || value === "Multi select"
                  );
                }}
              />
            </Form.Item>
            {showSelectFields === true && (
              <>
                <PropertiesOptionsList
                  propertyOptionsValues={
                    formProperty?.getFieldValue()?.propertyOptions || []
                  }
                  isEditProperty={isEditProperty}
                />
              </>
            )}
          </div>
          <Row className="mt-3">
            <Col span={24}>
              {checkRole('save') && 
                <Button
                  type={`primary`}
                  className={`w-100`}
                  htmlType="submit"
                  disabled={formProperty.submitting || loadingProperty}
                >
                  {formProperty.submitting || loadingProperty ? (<Spin spinning={loadingProperty} />) : ("Save")}
                </Button>
              }
            </Col>
          </Row>
        </Form>
      </Modal>
    </Spin>
  );
};

export default ApplicationTab;
