import http from "./httpService";
const API_BASE_URL = process.env.REACT_APP_API_URL;

async function getScenariosItemsByScenarioId(id) {
  const url = `${API_BASE_URL}scenarioV2/getItems/`;
  let response = await http.get(url + id);
  return response;
}

// Create scenario items
async function createScenarioItem(data) {
  const url = `${API_BASE_URL}scenarioV2/createScenarioItemsV2`;
  let response = await http.post(url, data);
  return response;
}

async function updateScenarioItem(id, data) {
  const url = `${API_BASE_URL}scenarioV2/updateScenarioItemsV2/${id}`;
  let response = await http.put(url, data);
  return response;
}

async function deleteScenarioItem(id) {
  const url = `${API_BASE_URL}scenarioV2/deleteScenarioItemsV2/`;
  let response = await http.delete(url + id);
  return response;
}


async function createScenarioIntegrationItems(obj) {
  const url = `${API_BASE_URL}scenarioV2/createScenarioIntegrationItems`;
  let response = await http.post(url, obj);
  return response;
}


async function getScenariosItemsByScenarioIdV3(id) {
  const url = `${API_BASE_URL}scenarioV2/getItemsV3/`;
  let response = await http.get(url + id);
  return response;
}


async function deletescenarioportfoliobyidv3(obj) {
  const url = `${API_BASE_URL}scenarioV2/deletescenarioportfoliobyidv3`;
  let response = await http.post(url, obj);
  return response;
}
 

const ScenarioItemsServices = {
  getScenariosItemsByScenarioId,
  createScenarioItem,
  updateScenarioItem,
  deleteScenarioItem,
  createScenarioIntegrationItems,
  getScenariosItemsByScenarioIdV3,
  deletescenarioportfoliobyidv3
};

export default ScenarioItemsServices;
