import React from "react";
import { Form,Input, InputNumber, Button } from "antd";

const CustomLevelComponent = ({ name = "" }) => { 
  // console.log('testing name >>>>>', name);
  
  const pattern1 = /^\d+(\.\d+)*$/;

  const validatePattern = (_, value) => {
    if (!value || value.length === 0) {
      return Promise.reject(new Error("Level Id Required"));
    }
    
    if (value[0] === "0") {
      return Promise.reject(new Error("Level Id Can't Start With 0"));
    }
    
    if (pattern1.test(value)) {
      return Promise.resolve();
    } else {
      return Promise.reject(new Error("Pattern Not matched"));
    }
  };

  return (
    <Form.Item name={name} rules={[{ validator: validatePattern }]}>
      <Input
        formatter={(value) => (value === "" ? "" : `${value}`)}
        placeholder="Enter pattern e.g., 1.1.1, 11.1.1, 11.2.10"
      />
    </Form.Item>
  );
};

export default CustomLevelComponent;
