import { Button, Card, Col, Form, Input, Modal, Row, Spin, Typography, Popover, Select, message } from "antd";
import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router";
import AdminLayout from "../layouts/AdminLayout";
import $ from "jquery";
import JSZip from "jszip";
import pdfmake from "pdfmake";
import DataTable from "datatables.net-dt";
import Editor from "@datatables.net/editor-dt";
import "datatables.net-bs5";
import "datatables.net-buttons-dt";
import "datatables.net-buttons/js/buttons.colVis.mjs";
import "datatables.net-buttons/js/buttons.html5.mjs";
import "datatables.net-colreorder-dt";
import "datatables.net-fixedheader-dt";
import "datatables.net-responsive-dt";
import "datatables.net-searchpanes-dt";
import "datatables.net-select-dt";
// import "datatables.net-buttons-bs5";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import CompareScenarioServices from "../../services/services/CompareScenarioServices";
import ScenarioServices from "../../services/services/ScenarioServices";
import { Menu, Item, Separator, Submenu, useContextMenu } from 'react-contexify';

import select2 from "select2";
import axios from "axios";
import UserService from "../../services/UserService";
const { Title } = Typography;
window.JSZip = JSZip;
window.pdfmake = pdfmake;

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const CompareScenarioList = (props, state) => {
  let navigate = useNavigate();
  const [windowDimensions] = useState(getWindowDimensions());
  const [loading, setLoading] = useState(false);
  const [pageTitle, setPageTitle] = useState("Compare Scenarios");
  const [scenarioData, setScenarioData] = useState([]);
  const [scenarioColumns, setScenarioColumns] = useState([]);
  const [openNewColModal, setOpenNewColModal] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [scenarioColumnsHeaders, setScenarioColumnsHeaders] = useState([]);
  const [defaultSelectValue, setDefaultSelectValue] = useState([]);
  const [options, setOptions] = useState([]);
  const [selectSubmitData, setSelectSubmitData] = useState([]);
  const [modalSelectOpen, setModalSelectOpen] = useState(false);
  const [scenarioColumnsResponse, setScenarioColumnsResponse] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [modalTextareaOpen, setModalTextareaOpen] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');
  const [commentValue, setCommentValue] = useState('');
  const [modalCommentOpen, setModalCommentOpen] = useState(false);
  const [scenarioDataTable, setScenarioDataTable] = useState();


  const MENU_ID = 'react-bla-bla';

  const location = useLocation();
  const tableRef = useRef();
  const { Option } = Select;
  const { TextArea } = Input;

  const API_BASE_URL = CompareScenarioServices.API_BASE_URL;
  var CLIPBOARD;
  var CSeditor;
  var CSTable;
  const [newColForm] = Form.useForm();

  const { show, hideAll } = useContextMenu({
    id: MENU_ID,
  });

  useEffect(() => {
    loadData()
  }, []);

  const loadData = async () => {
    await getScenarioColumns();
    await getScenarioLandscape();
  }


  const replacePlaceholder = () => {
    $('.dtsp-search').each(function (key, val) {
      let newPlaceholder = $(this).attr('placeholder').replace(/<\/?[^>]+(>|$)/g, "");
      $(this).attr('placeholder', newPlaceholder)
    })
  }
  const getColumnIndexByKey = (columnHeaders, key) => {
    for (let i = 0; i < columnHeaders.length; i++) {
      if (columnHeaders[i].data === key) {
        return i;
      }
    }
    return -1; // Column not found
  }

  useEffect(() => {
    if (scenarioData?.length) {
      const colResize_options = {
        isEnabled: true,
        hoverClass: "dt-colresizable-hover",
        hasBoundCheck: true,
        minBoundClass: "dt-colresizable-bound-min",
        maxBoundClass: "dt-colresizable-bound-max",
        isResizable: function (column) {
          return true;
        },
        onResize: function (column) { },
        onResizeEnd: function (column, columns) { },
        getMinWidthOf: function ($thNode) { },
      };

      const colReorder_options = {
        realtime: false,
      };

      CSeditor = new $.fn.dataTable.Editor({
        ajax: {
          url: API_BASE_URL + 'scenario/updateCompareRowData',
          type: 'POST',
          data: function (d) {
            d.baseScenarioId = location?.state?.baseScenarioID;
            d.compareScenarioIds = location?.state?.compareScenarioIds;
            d.filterColumn = location?.state?.filterColumn;
          },
          headers: {
            "Authorization": `Bearer ${UserService?.getToken()}`,
            "X-XSS-Protection": `1; mode=block`,
            "X-Content-Type-Options": "nosniff",
            "Referrer-Policy": "same-origin",
            "X-Content-Security-Policy": "default-src 'self'; script-src 'self';",
          }
        },
        table: '#int-app-compare-table',
        idSrc: 'dt_row_id',
        fields: scenarioColumns,
        formOptions: {
          inline: {
            onBlur: "submit",
          },
        },
      });

      if (scenarioDataTable) {
        scenarioDataTable.destroy();
        setScenarioDataTable(null)
        document.querySelector('#int-app-compare-table').innerHTML = "";
      }

      CSTable = new DataTable(tableRef.current, {
        fixedHeader: true,
        scrollX: 300,
        scrollY: ((windowDimensions?.height/1.5)-100),
        scrollCollapse: true,
        colReorder: colReorder_options,
        data: scenarioData,
        processing: true,
        language: {
          loadingRecords: "&nbsp;",
          processing: "Loading...",
        },
        select: true,
        buttons: [
          "colvis",
          "pageLength",
          {
            extend: "excel",
            text: '<i class="fa fa-download"></i> Excel',
            title: "Landscape Data",
          }
        ],
        // columns: scenarioColumnsHeaders,
        columns: scenarioColumnsHeaders?.map((element, index) => {
          element.render = function (data, type, row) {
            if (
              type === "display" &&
              data != null &&
              typeof data === "string"
            ) {
              data = data.replace(/<(?:.|\\n)*?>/gm, "");
              data = data.replaceAll('"', "");
              const dataTitle = data;
              data = data.split(",").join(",<br/>")
              if (data.length > 100 && data[element.data] == 'Business Capability') {
                return `<span class='show-ellipsis' data-title='${dataTitle}'>${data.substr(
                  0,
                  100
                )} ...</span><span class='no-show'>${data.substr(100)}</span>`;
              } else if (data.length > 50) {
                return `<span class='show-ellipsis' data-title='${dataTitle}'>${data.substr(
                  0,
                  50
                )} ...</span><span class='no-show'>${data.substr(50)}</span>`;
              } else {
                return data || "";
              }
            } else {
              return data || "";
            }
          }

          return element;
        }),
        destroy: true,
        pageLength: 100,
        lengthMenu: [
          [10, 25, 50, 100, 500, -1],
          ["10", "25", "50", "100", "500", "Show all"],
        ],
        aaSorting: [],
        searchPanes: {
          layout: 'columns-4',
          orderable: false,
        },
        dom: "PBfrtip",
        // columnDefs: [
        //   {
        //     visible: true,
        //     targets: "_all",
        //     render: function (data, type, row) {
        //       if (
        //         type === "display" &&
        //         data != null &&
        //         typeof data === "string"
        //       ) {
        //         data = data.replace(/<(?:.|\\n)*?>/gm, "");
        //         data = data.replaceAll('"', "");
        //         const dataTitle = data;
        //         data = data.split(",").join(",<br/>")
        //         if (data.length > 100 && data[element.data] == 'Business Capability') {
        //           console.log(data[element.data],"====data[element.data]")
        //           return `<span class='show-ellipsis' data-title='${dataTitle}'>${data.substr(
        //             0,
        //             100
        //           )} ...</span><span class='no-show'>${data.substr(100)}</span>`;
        //         } else if (data.length > 25) {
        //           console.log(data,"====data")
        //           return `<span class='show-ellipsis' data-title='${dataTitle}'>${data.substr(
        //             0,
        //             25
        //           )} ...</span><span class='no-show'>${data.substr(25)}</span>`;
        //         } else {
        //           return data || "";
        //         }
        //       } else {
        //         return data || "";
        //       }
        //     },
        //   },
        // ],
        select: {
          style: "multi",
          selector: "td:first-child",
        },
        initComplete: function (settings, json) {
          var api = this.api();
          replacePlaceholder();
          if (settings?.aoData?.length) {
          }

          $('#int-app-compare-table').on('dblclick', 'tbody td', async function () {
            let container = $("div.comment-content");
            container.remove();
            message.destroy()
            if (UserService.hasRole(['Admin', 'Editor'])) {
              setDefaultSelectValue([]);
              setModalSelectOpen(false);
              setModalTextareaOpen(false);
              setInputValue('');
              let columnIndex = $(this).index();

              var rowIndex = $(this).closest('tr').index();
              let row = CSTable.row(this).data();
              let scenarioId;
              if (row && row?.dt_row_id) {
                scenarioId = row.dt_row_id.split("_")[2];
              }
              let columnId = null;
              let availableColumnId = null;
              const column = scenarioColumns[columnIndex - 1];
              if (scenarioColumnsResponse?.length != 0) {
                for (let item of scenarioColumnsResponse) {
                  if (item?.columnName == column?.label) {
                    columnId = item.id;
                    availableColumnId = item.availableColumnId;
                    break
                  }
                }
              }
              let value = $(this).text();
              let obj = {
                scenarioId: scenarioId,
                rowIndex: rowIndex,
                columnId: columnId,
                availableColumnId: availableColumnId,
                column: column,
                row: row,
              }
              setSelectSubmitData(obj)
              if (column?.type == "select") {
                const dropdownVal = await ScenarioServices.getScenarioDropdownValue({
                  "columnId": availableColumnId,
                })
                if (dropdownVal.data) {
                  setOptions(dropdownVal.data)
                } else {
                  setOptions(column?.ipOpts)
                }
                let trimmedValues;
                if (value.includes(',')) {
                  trimmedValues = value.split(',').map(item => item.trim());
                  setDefaultSelectValue(trimmedValues)
                } else {
                  trimmedValues = [value];
                  setDefaultSelectValue(trimmedValues)
                }
                setModalSelectOpen(true)

              } else {
                setTextareaValue(value);
                setModalTextareaOpen(true)
              }
            } else {
              message.error("You are not authorised to update the data!")
            }


          })

          $('#int-app-compare-table').on('contextmenu', 'tbody td', function (event) {
            message.destroy()
            if (UserService.hasRole(['Admin', 'Editor'])) {
              setSelectSubmitData({})
              var columnIndex = $(this).index();
              let row = CSTable.row(this).data()
              if (row) {
                let scenarioId = row.dt_row_id.split("_")[2];
                let rowId = row.dt_row_id.split("_")[1];
                let columnId = null;
                let availableColumnId = null;
                const column = scenarioColumns[columnIndex - 1];
                if (scenarioColumnsResponse?.length != 0) {
                  for (let item of scenarioColumnsResponse) {
                    if (item?.columnName == column?.label) {
                      columnId = item.id;
                      availableColumnId = item.availableColumnId;
                      break
                    }
                  }
                }
                setSelectSubmitData({
                  rowId: rowId, columnId: availableColumnId, scenarioId: scenarioId
                })
                show({
                  event,
                  props: {
                    key: 'value'
                  }
                })
              }
            } else {
              message.error("You are not authorised to apply comment!")
            }
          })

          $('.dataTables_scrollHead').css({
            'overflow-x': 'scroll'
          }).on('scroll', function (e) {
            var scrollBody = $(this).parent().find('.dataTables_scrollBody').get(0);
            scrollBody.scrollLeft = this.scrollLeft;
            $(scrollBody).trigger('scroll');
          });
        },
        createdRow: function (row, data, dataIndex) {
          if (data['column_base']) {
            $(row).css('background-color', '#d8e5f1');
            $(row).css('font-weight', '600');
          }
          for (let key in data) {
            if (key.includes('_comment') && data[key] && data[key].length > 0) {
              const columnIndex = getColumnIndexByKey(scenarioColumnsHeaders, key.replace('_comment', ''));
              if (columnIndex !== -1) {
                const cell = $(row).find('td:eq(' + columnIndex + ')');
                cell.addClass('comment-added');

                const commentData = data[key];
                const commentContent = commentData.map(item => {
                  const formattedDate = new Date(item.createdAt).toLocaleDateString('en-GB', {
                    day: '2-digit',
                    month: '2-digit',
                    year: 'numeric'
                  });

                  return `
                            <strong>User:</strong> ${item.userEmail ? item.userEmail : '-'}<br>
                            <strong>Comment:</strong> ${item.comment}<br>
                            <strong>Date:</strong> ${formattedDate}<br>
                            <hr>
                          `;
                }).join('');
                // Add Bootstrap popover
                cell.attr('data-toggle', 'popover');
                cell.attr('data-html', 'true'); // Enable HTML content
                cell.attr('data-content', commentContent);
                cell.attr('data-placement', "bottom");
              }
            }
            if (key.includes('_difference') && data[key]) {
              const columnIndex = getColumnIndexByKey(scenarioColumnsHeaders, key.replace('_difference', ''));

              if (columnIndex !== -1) {
                const cell = $(row).find('td:eq(' + columnIndex + ')');
                cell.css('color', '#ea0505');
                cell.css('font-style', 'italic')
              }
            }
          }
        },
      });
      CSTable.on('dblclick', 'tbody td:not(:first-child)', function (e) {
        let selectElement = $(this).find("select");

        if (selectElement) {
          (selectElement).select2()
        }
      });
      setScenarioDataTable(CSTable);
    }
    setLoading(false);
  }, [scenarioData]);

  $(document).on("click", "tbody td.comment-added", function (e) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    let container = $("div.comment-content");
    if (container.length) {
      container.remove();
    } else {
      container.remove();
      let comments = $(this).attr('data-content');
      if (comments) {
        let content = `<div class="comment-content">${comments}</div>`;
        $(this).append(content);
      }
    }
  })

  $(document).on("click", function (e) {
    let container = $("div.comment-content");
    if (container.length && !$(e.target).closest('.comment-added').length) {
      container.remove();
    }
  });

  function sortByMandatory(a, b) {
    if (a.is_mandatory === 1 && b.is_mandatory === 0) {
      return -1; // a should come before b
    } else if (a.is_mandatory === 0 && b.is_mandatory === 1) {
      return 1; // b should come before a
    } else {
      return 0; // maintain the existing order
    }
  }


  const getScenarioColumns = async () => {
    setLoading(true);
    let obj = {};
    obj.scenarioId = location?.state?.baseScenarioID;
    await ScenarioServices.getScenarioColumns(obj)
      .then(async (response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          responseVal = responseVal.sort(sortByMandatory);
          setScenarioColumnsResponse(responseVal)
          let colHeader = [];
          let scenarioCol = [];
          colHeader.push({
            data: null,
            title: "Select",
            defaultContent: "",
            className: "select-checkbox text-center",
            orderable: false,
          });
          responseVal.map((e) => {
            let column = e.columnName.toLowerCase().trim().replace(/[^a-zA-Z0-9]/g, "_").replace("__", "_");
            if (e['type'] == "dropdown") {
              scenarioCol.push({
                visible: e.is_mandatory == 1,
                label: e.columnName,
                name: 'column_' + column,
                "type": "select",
                "def": e['options'][0],
                ipOpts: e['options'],
              });
            } else {
              scenarioCol.push({
                visible: e.is_mandatory == 1,
                label: e.columnName,
                name: 'column_' + column,
                type: 'textarea',
              });
            }

            if (e?.is_mandatory) {
              colHeader.push({
                visible: e.is_mandatory == 1,
                label: `<div>${e.columnName}<span class="a_datatbl_trash d-none"><i class="fa fa-times datatbl_trash" ></i></span></div>`,
                title: `<div>${e.columnName}<span class="a_datatbl_trash d-none"><i class="fa fa-times datatbl_trash" ></i></span></div>`,
                data: "column_" + column,
                editField: "column_" + column
              });
            } else {
              colHeader.push({
                visible: e.is_mandatory == 1,
                label: `<div class="delete-col">${e.columnName}<span class="a_datatbl_trash" data-column-id=${e.id} data-scenario-id=${e.scenarioID}"><i class="fa fa-times datatbl_trash" ></i></span></div>`,
                title: `<div class="delete-col">${e.columnName}<span class="a_datatbl_trash" data-column-id=${e.id} data-scenario-id=${e.scenarioID}" ><i class="fa fa-times datatbl_trash" ></i></span> </div>`,
                data: "column_" + column,
                editField: "column_" + column
              });
            }
          });

          setScenarioColumnsHeaders(colHeader);
          setScenarioColumns(scenarioCol);
        }
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const getScenarioLandscape = async () => {
    let obj = {};
    obj.baseScenarioId = location?.state?.baseScenarioID;
    obj.compareScenarioIds = location?.state?.compareScenarioIds;
    obj.filterColumn = location?.state?.filterColumn;
    setLoading(true);
    CompareScenarioServices.getCSLandscape(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data?.DATA;
          setScenarioData(responseVal);
        } else if (response?.data?.code === 404) {
          setLoading(false);
          message.error(response?.data?.message)
          navigate('/compare-scenario')
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const onNewColSubmit = (formData) => {
    setConfirmLoading(true);
    let obj = {};
    // obj.scenarioId = props?.id;
    // obj.columnName = formData?.columnName;
    // ScenarioServices.checkUniqueScenarioColumns(obj)
    //   .then((response) => {
    //     if (response?.data?.code === 404) {
    //       ScenarioServices.putScenarioColumns(obj).then(async (response) => {
    //         // setNewColAdded(true)
    //         await getScenarioColumns();
    //         await getScenarioLandscape();
    //       });
    //     }
    //     setConfirmLoading(false);
    //     setOpenNewColModal(false);
    //   })
    //   .catch((err) => {
    //     message.error(err.message);
    //     setConfirmLoading(false);
    //   });
  };

  const submitSelect = async () => {
    try {
      let commaSep = defaultSelectValue.join(',')
      let obj = {
        rowNo: selectSubmitData.row.id,
        scenarioId: selectSubmitData.scenarioId,
        columnId: selectSubmitData.columnId,
        availableColumnId: selectSubmitData.availableColumnId,
        filterColumn: commaSep,
        isInput: false,
        inputValue: ''
      }

      if (inputValue) {
        obj.isInput = true;
        obj.inputValue = inputValue;
      } else {
        obj.isInput = false;
        obj.inputValue = '';
      }
      if (modalSelectOpen) {
        const response = await ScenarioServices.updateSelectData(obj);
        if (response && response.data.status) {
          setInputValue('')
          message.success("Updated Successfully!")
          setModalSelectOpen(false)
          setModalTextareaOpen(false)
          await getScenarioColumns();
          await getScenarioLandscape();

        } else {
          message.error(response.data.message)
        }
      } else {

      }

    } catch (error) {

    }
  }

  const handleChange = (value) => {
    setDefaultSelectValue(value)
  };

  const submitTextarea = async () => {
    try {
      let obj = {
        rowNo: selectSubmitData.row.id,
        scenarioId: selectSubmitData.scenarioId,
        columnId: selectSubmitData.columnId,
        availableColumnId: selectSubmitData.availableColumnId,
        filterColumn: textareaValue,
        isInput: false,
        inputValue: ''
      }
      const response = await ScenarioServices.updateSelectData(obj);
      if (response && response.data.status) {
        message.success("Updated Successfully!")
        setInputValue('')
        setModalSelectOpen(false)
        setModalTextareaOpen(false)
        await getScenarioColumns();
        await getScenarioLandscape();
      } else {
        message.error(response.data.message)
      }

    } catch (error) {

    }
  }

  const handleItemClick = ({ id, event, props }) => {
    setCommentValue('');
    setModalCommentOpen(true);

  }

  const submitComment = async () => {
    try {
      let obj = {
        columnId: selectSubmitData.columnId,
        comment: commentValue,
        rowId: selectSubmitData.rowId,
        scenarioId: selectSubmitData.scenarioId,
        userEmail: localStorage.getItem('email')
      }
      const response = await ScenarioServices.singleScenarioComment(obj);
      if (response) {
        message.success("Created Successfully!")
        setCommentValue('')
        setModalCommentOpen(false)
        await getScenarioColumns();
        await getScenarioLandscape();
      } else {
        message.error(response.data.message)
      }

    } catch (error) {

    }
  }

  return (
    <>
      <Spin className="loading_bx" size="small" spinning={loading}>
        {/* Scenario List Filter */}
        <Title level={5} className="text-start py-2">
          {pageTitle}
          <Link to={'/compare-scenario'} className="pull-right btn btn-sm btn-outline-secondary text-secondary" title="Back"><i className="fa fa-arrow-left" ></i> Back</Link>
        </Title>
        {/* Scenario List Table */}
        <Row>
          <Col xs={24} md={24} lg={24} className="edit-scenario" style={{height:windowDimensions?.height-150}}>
            <Card className="table-responsive dt-avoid-y-scroll graph_container mt-2 text-start">
              <table
                id="int-app-compare-table"
                ref={tableRef}
                className="table nowrap pt-1 compare-scenario-list table-bordered display"
                style={{ width: "100%" }}
              ></table>
            </Card>
          </Col>
        </Row>
      </Spin>

      <Modal
        title={"Add New Column"}
        open={openNewColModal}
        footer={false}
        destroyOnClose
        closable={true}
        maskClosable={false}
        onCancel={() => setOpenNewColModal(false)}
      >
        <Form
          form={newColForm}
          onFinish={onNewColSubmit}
          requiredMark={false}
          initialValues={{}}
        >
          <Form.Item
            label={
              <>
                <span>Name</span>
                <span className="text-danger">*</span>
              </>
            }
            name="columnName"
            rules={[{ required: true, message: "Please enter Column name" }]}
            className="my-4 text-start"
          >
            <Input placeholder="" autoComplete="off" />
          </Form.Item>
          <Form.Item>
            <Button
              className="ms-auto d-block"
              htmlType="submit"
              disabled={confirmLoading}
            >
              {confirmLoading ? "Please Wait" : "Save"}
            </Button>
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        title="Update Content"
        style={{ top: 20 }}
        open={modalSelectOpen}
        maskClosable={false}
        onOk={() => submitSelect()}
        onCancel={() => setModalSelectOpen(false)}
      >
        <Select
          mode="multiple"
          placeholder="Select items"
          onChange={handleChange}
          style={{ width: '100%' }}
          value={defaultSelectValue}
        >
          {options && options.map((item, index) => (
            <Option key={index} value={item.value}>{item.label}</Option>
          ))}
        </Select>
        <span>Or Add New</span>
        <Input value={inputValue} onChange={(e) => setInputValue(e.target.value)}
        />
      </Modal>

      <Modal
        title="Update Content"
        style={{ top: 20 }}
        open={modalTextareaOpen}
        maskClosable={false}
        onOk={() => submitTextarea()}
        onCancel={() => setModalTextareaOpen(false)}
      >
        <TextArea value={textareaValue} onChange={(e) => setTextareaValue(e.target.value)}
        />
      </Modal>

      <Menu id={MENU_ID}>
        <Item id="copy" onClick={handleItemClick}>Comment</Item>
      </Menu>

      <Modal
        title="Comment"
        style={{ top: 20 }}
        open={modalCommentOpen}
        maskClosable={false}
        onOk={() => submitComment()}
        onCancel={() => setModalCommentOpen(false)}
      >
        <TextArea value={commentValue} onChange={(e) => setCommentValue(e.target.value)}
        />
      </Modal>

    </>
  );
}

export default CompareScenarioList;