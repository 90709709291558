import React, { useEffect, useRef, useState } from "react";
import { PageTitleHeading } from "../../utils/Common";
import {
  Button,
  Card,
  Flex,
  Input,
  Modal,
  Select,
  Spin,
  Table,
  Tooltip,
  message,
} from "antd";
import ScenarioServices from "../../services/services/ScenarioServices";
import TextWithTooltip from "./../../utils/TextWithTooltip";
import { useNavigate } from "react-router";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { getScenarioType } from "../../utils/ScenarioCommon";
import LoadingBar from "react-top-loading-bar";
import {
  SaveRecentlyView_Portfolio,
  RemoveRecentViewed,
} from "../../utils/ResentViewedPortfolio";
import { checkRole } from "../../helper/useUserData";
import { SaveRecentlyView_Scenario } from "../../utils/Common";

const ListScenario = (props) => {
  const ref = useRef(null);
  //let openedScenarios = sessionStorage.getItem("recentlyOpenScenarios");
  let openedScenarios = localStorage.getItem("recentlyOpenScenarios");
  openedScenarios = JSON.parse(openedScenarios);

  const recentlyOpenScenariosRef = useRef(null);

  const navigation = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCurrentSize, setCurrentPageSize] = useState(20);
  const [totalRecords, setTotalRecords] = useState(0);
  const [scenarios, setScenarios] = useState([]);
  const [recentlyOpenScenarios, setRecentlyOpenScenarios] = useState(
    openedScenarios || []
  );
  const [scenarioType, setScenarioType] = useState([]);
  const [emptyText, setemptyText] = useState("Fetching Data");
  const [searchData, setSearchData] = useState({
    intiativeId: "",
    visibility: "",
    scenarioTypeID: "",
    search: "",
    page: 1,
    limit: pageCurrentSize,
    isScenarioType: true,
    isUser: true,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getScenarioType(loading, setLoading, setScenarioType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getAllScenarios({ ...searchData, page: currentPage });
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [searchData]);

  const getAllScenarios = async (filter) => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    setLoading(true);
    let obj = {
      ...filter,
    };
  
    try {
      let response = await ScenarioServices?.getAllScenariosV2(obj);
      const finalResponse = response.data.data;

      console.log("object finalResponse",finalResponse);

      if (finalResponse === undefined || null || finalResponse.length === 0) {
        setemptyText("Data Not Found");
        setScenarios([]);
        setTotalRecords(0);

      } else if (finalResponse.length) {
        setScenarios(response?.data?.data);
        setTotalRecords(response?.data?.totalCount);
      }
      setLoading(false);
    } catch (error) {
      console.log("error", error);
      setLoading(false);
    }
    if (ref.current) {
      ref.current.complete();
    }
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record, index) => {
        return <TextWithTooltip text={text} characterLimit={50} />;
      },
    },
    // {
    //   title: "Type",
    //   dataIndex: "scenarioTypeID",
    //   className: "text-center",
    //   width: 250,
    //   render: (text, record, index) => {
    //     if (record?.scenarioType?.name) {
    //       return record?.scenarioType?.name;
    //     }
    //     return "";
    //   },
    // },
    {
        title: "Initiative",
        //className: "text-center",
        dataIndex: "intiativeId",
        render: (text, record, index) => {
          return <TextWithTooltip text={record?.initiative?.displayname } characterLimit={50} />;
        },
      },

    {
      title: "Status",
      className: "text-center",
      dataIndex: "approvalstatus",
      width: 150,
      render: (text, record, index) => {
        return <TextWithTooltip text={text} characterLimit={50} />;
      },
    },
    {
      title: "Created By",
      dataIndex: "createdBy",
      className: "text-center",
      width: 150,
      render: (text, record, index) => {
        if (record?.user?.name) {
          return record?.user?.name;
        }
        return "";
      },
    },
    {
        title: "Updated On",
        dataIndex: "updatedAt",
        className: "text-center",
        width: 150,
        render: (text, record, index) => {
          if (record?.updatedAt) {
            //return record?.updatedAt;
            return new Date( record?.updatedAt).toLocaleDateString('en-GB', {
                day: '2-digit',
                month: '2-digit',
                year: 'numeric',
                hour:'2-digit',
                minute: '2-digit',
                second: '2-digit',
            })
          }
          return "";
        },
      },
    {
      title: "Actions",
      dataIndex: "id",
      className: "text-center",
      width: 150,
      render: (text, record, index) => {
        return (
          <Flex gap={0} justify="center">
            <Tooltip title="View scenario item list">
              <Button
                icon={<i className="fa fa-eye text-primary"></i>}
                type="text"
                onClick={() => {
                  SaveRecentlyView_Scenario(text, record?.name);

                  localStorage.setItem("scenarioName", record?.name);
                  setRecentOpenScenario(record);
                  navigation("/scenario-list-v2/" + text);
                }}
              />
            </Tooltip>
            {checkRole("update") && (
              <Tooltip title="Edit scenario">
                <Button
                  icon={<i className="fa fa-pencil text-primary"></i>}
                  type="text"
                  onClick={() => {
                    navigation("/start-new-scenario/" + record?.id);
                  }}
                />
              </Tooltip>
            )}
            {checkRole("delete") && (
              <Tooltip title="Delete scenario">
                <Button
                  icon={<i className="fa fa-trash-o text-primary"></i>}
                  type="text"
                  onClick={async () => {
                    await deleteScenario(record);
                  }}
                />
              </Tooltip>
            )}
          </Flex>
        );
      },
    },
  ];

  const deleteScenario = async (item) => {
    let obj = {};
    obj.scenarioId = item?.id;
    obj.isForce = 0;
    Modal.confirm({
      title: (
        <div className="font-normal">
          <p style={{ fontWeight: 600, fontSize: 16 }}>
            Are you sure want to delete?
          </p>
        </div>
      ),
      icon: <ExclamationCircleFilled />,
      async onOk() {
        try {
          let result;
          result = await ScenarioServices.deleteScenariosV2(item?.id);
          if (result) {
            if (result?.data?.code === 200) {
              message.success(
                result?.data?.message ||
                  result?.data?.msg ||
                  "Deleted Successfully"
              );

              //item?.id

              let updatedScenarios = recentlyOpenScenarios.filter(
                (scenario) => scenario.id !== item?.id
              );
              updatedScenarios = JSON.stringify(updatedScenarios);
              //sessionStorage.setItem("recentlyOpenScenarios", updatedScenarios);

              localStorage.setItem("recentlyOpenScenarios", updatedScenarios);

              //let openedScenarios = sessionStorage.getItem("recentlyOpenScenarios");
              let openedScenarios = localStorage.getItem(
                "recentlyOpenScenarios"
              );

              openedScenarios = JSON.parse(openedScenarios);
              setRecentlyOpenScenarios(openedScenarios);

              //Remove From Home Screen
              RemoveRecentViewed({ modelName: "Scenario", modelID: item?.id });

              //getAllScenarios(1,pageCurrentSize,"");
              await getAllScenarios(searchData);
            } else {
              message.error(result?.data?.message || result?.data?.msg);
            }
          } else {
            message.error(result?.data?.msg);
          }
        } catch (error) {
          message.error(error?.message);
        }
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  const setRecentOpenScenario = (scenario) => {
    if (recentlyOpenScenarios?.length === 10) {
      recentlyOpenScenarios.shift();
    }
    let tempScenarios = recentlyOpenScenarios.filter(
      (f) => f?.id?.toString() !== scenario?.id?.toString()
    );
    tempScenarios.push(scenario);
    tempScenarios = JSON.stringify(tempScenarios);
    //sessionStorage.setItem("recentlyOpenScenarios", tempScenarios);
    localStorage.setItem("recentlyOpenScenarios", tempScenarios);
  };

  const recentlyOpenScenariosTags = () => {
    let scenarios = [...recentlyOpenScenarios].reverse();
    return scenarios.splice(0, 6)?.map((scenario) => {
      return (
        <Tooltip key={Math.random()} title={scenario?.name || ""}>
          <Button
            type="primary"
            ghost
            onClick={() => {
              localStorage.setItem("scenarioName", scenario?.name);
              SaveRecentlyView_Scenario(scenario?.id, scenario?.name);
              setRecentOpenScenario(scenario);
              navigation(`/scenario-list-v2/${scenario?.id}`);
            }}
            style={{ width: "32%" }}
          >
            <Flex gap={8} justify="space-between">
              <div className="w-100 text-center">
                <TextWithTooltip
                  text={scenario?.name || ""}
                  characterLimit="25"
                  tooltip={false}
                  title={false}
                />
              </div>
              <div>
                <i className="fa fa-eye text-primary"></i>
              </div>
            </Flex>
          </Button>
        </Tooltip>
      );
    });
  };

  const onRecallScenarios = (name, value) => {
    setSearchData({ ...searchData, [name]: value });
  };

  const searchDataa = (values) => {
   
    // const results = scenarios.filter((value) =>
    //   value.name.toLowerCase().includes(values.toLowerCase())
    // );
    setSearchData({ ...searchData, page: currentPage , search:values});
    
   // getAllScenarios({ ...searchData, page: currentPage , search:values});
 
    // if (scenarios.length > 0 && !results.length) {
    //   setemptyText("Data Not Found");
    // }
    // setScenarios(results);
  };

  return (
    <>
      <LoadingBar color="#1f1f1f" ref={ref} />
      <Spin className="loading_bx" size="small" spinning={loading}>
        <div className="list-scenario-page">
          <div className="d-flex justify-content-between">
            <PageTitleHeading text={`Scenario List`} />
          </div>
          <div>
            <Flex gap={8} vertical className="scenario_listbx">
              {recentlyOpenScenarios?.length > 0 && (
                <Card
                  title={`Recently Open`}
                  bordered={false}
                  size="small"
                  ref={recentlyOpenScenariosRef}
                >
                  <Flex gap={8} className="flex-wrap scenario_recentitem">
                    {recentlyOpenScenariosTags()}
                  </Flex>
                </Card>
              )}
            </Flex>
            {/* <hr className='my-2'/> */}
            <Card
              className="scenario_viewitem"
              bordered={false}
              size="small"
              extra={
                <Flex gap={8} align="center" justify="right">
                  <div className="fw-medium">Filter: </div>
                  <Select
                    allowClear
                    options={scenarioType}
                    placeholder="Scenario Type"
                    onChange={(value) => {
                      onRecallScenarios("scenarioTypeID", value || "");
                    }}
                    style={{ width: 150 }}
                  />
                  <Input.Search
                    placeholder="Search here..."
                    onSearch={(value) => {
                      searchDataa(value);
                    }}
                    onChange={(e) => {
                      if (!e?.target?.value) {
                        onRecallScenarios("search", "");
                      }
                    }}
                    style={{ width: 240 }}
                  />
                </Flex>
              }
            >
              <Table
                rowKey={"id"}
                columns={columns}
                bordered={true}
                size={`small`}
                dataSource={scenarios}
                pagination={{
                  defaultPageSize: pageCurrentSize,
                  pageSize: pageCurrentSize,
                  showSizeChanger: true,
                  total: totalRecords,
                  pageSizeOptions: [5, 10, 20, 50, 100, 200, 500, 1000],
                  onChange: (page, pageSize) => {
                    if (page !== currentPage) {
                      onRecallScenarios("page", page);
                    }
                    if (pageSize !== pageCurrentSize) {
                      onRecallScenarios("limit", pageSize);
                    }

                    const current = pageSize > totalRecords;
                    if (current) {
                      onRecallScenarios("page", current ? 1 : page);
                    }
                    setCurrentPage(page);
                    setCurrentPageSize(pageSize);
                  },
                }}
                scroll={{
                  x: window?.innerWidth - 450,
                  y:
                    window?.innerHeight -
                    300 -
                    (recentlyOpenScenariosRef?.current?.offsetHeight || 0),
                }}
                locale={{
                  emptyText: emptyText,
                }}
              />
            </Card>
          </div>
        </div>
      </Spin>
    </>
  );
};

export default ListScenario;
