import React, { useEffect, useState } from "react";
import Sidebar from "./Sidebar";
import FilterContent from "./FilterContent";
import FilterButtonToggle from "../../layouts/FilterButtonToggle";

const setActiveGridText = (activeGridStyle) => {
  if (activeGridStyle === 1) {
    return "Filter";
  } else if (activeGridStyle === 2 || activeGridStyle === 3) {
    return "Indicator";
  } else {
    return "Filter";
  }
};

const FilterRightSideBar = ({
  activeGridStyle,
  lifeCycleStagesColors,
  //showFilter,
  //setShowFilter,
  portfolioData,
  selectedFilters,
  setSelectedFilters,
  sortValues,
  optionalLevel = null,
  allowSlider = false,
  SidebarHeight,  
  setFilterSubmit = () => false,
  filterSubmit=false,
  selectedRelationFilters,
  setSelectedRelationFilters,
  isRelationPopup = false,
  isRelationModuleName = "",
  ...props
}) => {
  const [data, setData] = useState([]);
  const [showFilter, setShowFilter] = useState(true);

  useEffect(() => {
    setData(portfolioData || []);
  }, [portfolioData]);

  useEffect(() => {
    props.setShowFilter(showFilter);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showFilter]);

  return (
    <>
    {/* <div className="z-10 m-2 bg-white h-full"> */}
    <div className="right_pnlbx">
      {!allowSlider ? (
        <Sidebar showFilter={showFilter} SidebarHeight={SidebarHeight}>
          <FilterButtonToggle
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setSelectedFilters={setSelectedFilters}
          />
          <div style={{ display: showFilter ? "block" : "none" }}>
            <FilterContent
            
              lifeCycleStagesColors={lifeCycleStagesColors}
              activeGridStyle={activeGridStyle}
              portfolioData={data}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              sortValues={sortValues}
              optionalLevel={optionalLevel}
              setSwimLaneSelectedFilters={props?.setSwimLaneSelectedFilters}
              setFilterSubmit={setFilterSubmit}
              filterSubmit={filterSubmit}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
              showSortBy={props?.showSortBy}
              isRelationPopup={isRelationPopup}
              isRelationModuleName={isRelationModuleName}
            />
          </div>
        </Sidebar>
      ) : (
        <div>
          <FilterButtonToggle
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            setSelectedFilters={setSelectedFilters}
          />
          <div style={{ display: showFilter ? "block" : "none" }}>
            <FilterContent
              lifeCycleStagesColors={lifeCycleStagesColors}
              activeGridStyle={activeGridStyle}
              portfolioData={data}
              selectedFilters={selectedFilters}
              setSelectedFilters={setSelectedFilters}
              sortValues={sortValues}
              optionalLevel={optionalLevel}
              setSwimLaneSelectedFilters={props?.setSwimLaneSelectedFilters}
              setFilterSubmit={setFilterSubmit}
              filterSubmit={filterSubmit}
              selectedRelationFilters={selectedRelationFilters}
              setSelectedRelationFilters={setSelectedRelationFilters}
              showSortBy={props?.showSortBy}
              isRelationPopup={props?.isRelationPopup}
              isRelationModuleName={isRelationModuleName}
            />
          </div>
        </div>
      )}
    </div>
    </>
  );
};

FilterRightSideBar.defaultProps = {
  setSwimLaneSelectedFilters:()=>{},
  showSortBy:true,
  setShowFilter:()=>{}
}

export default FilterRightSideBar;
