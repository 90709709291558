import { DownOutlined, InfoCircleOutlined, RightOutlined } from "@ant-design/icons";
import { Checkbox, Collapse, Select, Tooltip} from "antd";
import React from "react";

const { Option } = Select;
const { Panel } = Collapse;

const FilterTag = ({ tagList, gTFiltered, selectedFilters, onTagChange, isRelationPopup=false }) => {

  //Collapse Open for Tags Filter if selected filter exists
  let activeCollapseAllTagsKey = [];
  let activeCollapseTagsKey = [];
  let activeCollapseGTagsKey = [];
  if(!isRelationPopup)
  {
      activeCollapseAllTagsKey = (selectedFilters['tags'] && selectedFilters['tags']?.length > 0) || (selectedFilters[gTFiltered[0]?.templatepropname || 'global_tag_1'] && selectedFilters[gTFiltered[0]?.templatepropname || 'global_tag_1']?.length > 0)  ? [0] : [];

      activeCollapseTagsKey = selectedFilters['tags'] && selectedFilters['tags']?.length > 0 ? [0] : [];
      activeCollapseGTagsKey = selectedFilters[gTFiltered[0]?.templatepropname || 'global_tag_1'] && selectedFilters[gTFiltered[0]?.templatepropname || 'global_tag_1']?.length > 0 ? [0] : [];
  }
 
  return (
    <div className="my-1">
      <Collapse
        defaultActiveKey={activeCollapseAllTagsKey}
        // activeKey={0}
        expandIconPosition="end"
        className="life-cycle-stages-filter"
        expandIcon={({ isActive }) =>
          isActive ? (
            <DownOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
          ) : (
            <RightOutlined style={{ color: "#2A609D", fontSize: "14px" }} />
          )
        }
      >
        <Panel header={`Tags`}>
          <div className="items-center p-2">
            <div className="my-2">
              <Collapse
                defaultActiveKey={activeCollapseTagsKey}
                expandIconPosition="end"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <DownOutlined
                      style={{ color: "#2A609D", fontSize: "14px" }}
                    />
                  ) : (
                    <RightOutlined
                      style={{ color: "#2A609D", fontSize: "14px" }}
                    />
                  )
                }
              >
                <Panel header={`Portfolio Tag`}>
                  <div className="items-start p-1">
                    <Checkbox.Group
                      style={{ width: "100%" }}
                      key={`check-box-group`}
                      name={"tags"}
                      value={selectedFilters && selectedFilters["tags"]}
                      onChange={(values) => {
                        onTagChange(values, "tags");
                      }}
                    >
                      {tagList.length > 0 ? (
                        tagList?.map((d, i) => (
                          <label
                            className="w-100 d-flex align-items-center"
                            key={i}
                          >
                            <Checkbox value={d?.id}>{d?.name}</Checkbox>
                          </label>
                        ))
                      ) : (
                        <div>No items found</div>
                      )}
                    </Checkbox.Group>
                  </div>
                </Panel>
              </Collapse>
            </div>

            <div className="my-2">
              <Collapse
                defaultActiveKey={activeCollapseGTagsKey}
                expandIconPosition="end"
                expandIcon={({ isActive }) =>
                  isActive ? (
                    <DownOutlined
                      style={{ color: "#2A609D", fontSize: "14px" }}
                    />
                  ) : (
                    <RightOutlined
                      style={{ color: "#2A609D", fontSize: "14px" }}
                    />
                  )
                }
              >
                <Panel
                  header={
                    <div className="flex gap-2">
                      <span>Global Tag</span>{" "}
                      <Tooltip title="This is a global property">
                        <InfoCircleOutlined
                          style={{ fontSize: "14px", color: "#2a609d" }}
                        />
                      </Tooltip>
                    </div>
                  }
                >
                  <div className="items-start p-1">
                    {gTFiltered && gTFiltered?.length > 0 && gTFiltered?.map((item, index) => {
                      return (
                        <Checkbox.Group
                          style={{ width: "100%" }}
                          key={`check-box-group-${index}`}
                          name={item?.templatename}
                          value={
                            selectedFilters &&
                            selectedFilters[item?.templatepropname]
                          }
                          onChange={(values) => {
                            onTagChange(values, item?.templatepropname);
                          }}
                        >
                          {item?.propertyOptions?.map((d, i) => (
                            <label
                              className="w-100 d-flex align-items-center"
                              key={i}
                            >
                              <Checkbox value={d?.id}>
                                {d?.name}
                                {item?.displayName && (
                                  <Tooltip title={item?.displayName}>
                                    <span className="m-1 text-sm">
                                      (
                                      {item?.displayName?.length > 4
                                        ? `${item?.displayName.slice(0, 4)}...`
                                        : item?.displayName}
                                      )
                                    </span>
                                  </Tooltip>
                                )}
                              </Checkbox>
                            </label>
                          ))}
                        </Checkbox.Group>
                      );
                    })}
                    {/* <Checkbox.Group
                        style={{ width: "100%" }}
                        key={`check-box-group`}
                        name={"global_tag_1"}
                        value={selectedFilters && selectedFilters["global_tag_1"]}
                        onChange={(values) => {
                          onTagChange(values, 'global_tag_1');
                        }}
                      >
                        {gTFiltered?.map((d, i) => (
                          <label className="w-100 d-flex align-items-center" key={i}>
                            <Checkbox value={d?.id}>{d?.name}</Checkbox>
                          </label>
                        ))}
                      </Checkbox.Group> */}
                  </div>
                </Panel>
              </Collapse>
            </div>
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default FilterTag;
