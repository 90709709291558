import { DeleteOutlined, EditOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import {
  Button,
  Col,
  Divider,
  Input,
  Row,
  Space,
  Table,
  Typography,
  message,Modal,
  Flex
} from "antd";
import React, { useState,useEffect } from "react";
import CustomModal from "../../../custom/CustomModal";
import { applicationsList } from "../../../../utils/businessApplication";
import linkServices from "../../../../services/services/LinkService";
import { useApplicationlink } from "../../../../Context/linkProvider";
import EditSvg from "../../../../assets/Edit.svg";
import DeleteSvg from "../../../../assets/Remove.svg";
import linkTypeServices from '../../../../services/services/LinkTypeService';
import { useParams } from "react-router";
import { snakeToPascal } from "../../../../utils/Common";
import ScenarionServices from '../../../../services/services/ScenarioServices';
import PortfolioSettingServices from '../../../../services/services/PortfolioSettingsService';


const { confirm } = Modal;

const LinkList = ({ data, businessApplicationId,moduleName }) => {
  const { state, dispatch } = useApplicationlink();
  const [visibleModal, setVisibleModal] = useState(false);
  const [formModalData, setFormModalData] = useState({
    name: "",
    URL: "",
    moduleID: businessApplicationId || null,
    moduleName: moduleName,
  });
  const [isEditing, setIsEditing] = useState(false); // State to track editing mode
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);
  let { id : modelIDScenario,  scenarioId } = useParams();
  const [portfolioSetting, setPortfolioSetting] = useState([]);

  
  useEffect( () => {
     getLinkTypeList();
     getPortfolioData();
 }, []);

  const handleUpdate = async (values, setSubmitting) => {
    try {
      // Handle form submission
      setLoading(true);
      const response = await linkServices.update(values);
      const newData = response?.data || null;
      console.log("API Response: submit", response.data);

      // Handle success response
      message.success("Updated Successfully");

      

      await dispatch({ type: "UPDATE_LINK", payload: newData });
      setTimeout(() => {
        setSubmitting(false);
        // navigate("/application-list");
      }, 1000);
    } catch (error) {
      // Handle error
      message.error("Failed to submit form. Please try again.");
      //console.error("API Error:", error);
      setSubmitting(false);
    } finally {
      setLoading(false);
      setVisibleModal(false);
    }
  };

  const handleEdit = (item) => {
    const { URL, name, moduleID, moduleName, status, id ,linkTypeID,Description} = item;
    setVisibleModal(true);
    setFormModalData({ URL, name, moduleID, moduleName, status, id ,linkTypeID,Description});
    setIsEditing(true);
  
  };

  const getLinkTypeList=async ()=>{
 
    const list = await linkTypeServices.getList();
    const selectListData=[];

    list?.data?.data.map((item)=>{
      selectListData.push({value: item.id, label:item.linkTypeName})
    })
     
    setList(selectListData);
     
  }


  const getPortfolioData = async () => {
    try {
      const response = await PortfolioSettingServices.getList({
        page: 1,
        limit: 1000,
      });

      const newData = response.data.data;
      setPortfolioSetting(newData);
    } catch (error) {
      message.error("Something went wrong");
    } finally {
    }
  };
   
  const showConfirm = (item) => {
         
    confirm({
      title: 'Are you sure want to delete?',
      icon: <ExclamationCircleFilled />,
        async onOk() {
            try {
                
                console.log("object Item", item);
                let result = await linkServices.deletelink(item.id);
                if (result) {
                   // getLinkTypeList();
                   message.success("Deleted Successfully")


                   if (scenarioId) {
                    const modulenameScenario = portfolioSetting?.find(
                      (item) => snakeToPascal(item?.moduleName) === moduleName
                    );
          
                    const objdata = {
                      source_id: modelIDScenario,
                      modulename: modulenameScenario?.moduleName,
                      tab_name: "Links",
                    };
          
                    const obj = await ScenarionServices.checkscenariomodifyrecord(
                      objdata
                    ); 
                  }
                     
                   await dispatch({ type: "DELETE_LINK", payload: item }); 
                } else {
                 message.error(result?.data?.msg)
                }
                // setLinkTypesOpen(false)
            } catch (error) {
                message.error(error?.message)
            }
        },
        onCancel() {
            console.log('Cancel');
        },
    });
};

  

  const handleCancel = () => {
    setVisibleModal(false);
    setIsEditing(false);
  };

  return (
    <>
      <div className="txtdes">
        {/* <div className="fs-4">External Links</div> */}
        <p>(this screen shows clickable hyperlinks to external URL)</p>
      </div>
      <Divider className="mt-0" />
      <table className="table table-sm table-borderless externallink_tbl" width={`100%`}>
        <tbody>
          {data?.length > 0 &&
            data?.map((item, index) => {
              return (
                <tr key={index}>
                  <td width={`30%`}>{item?.linkType?.linkTypeName}</td>
                  {/* <td width={`60%`} className="externallink_list">
                     <a target="_blank" href={item.URL}>{item.URL}</a>
                  </td> */}
                  <td>
                    <Flex gap={8} align="center" justify="space-start">
                      <Button 
                        href={item?.URL} 
                        target="_blank" 
                        type="link" 
                        className="cu-text-primary"
                        title={item?.URL}
                      >
                        {item?.linkType?.linkTypeName}
                      </Button>
                      <Button
                        shape="circle"
                        icon={
                          <img
                            src={EditSvg}
                            alt={`Edit`}
                            // style={{ height: "16px" }}
                          />
                        }
                        onClick={() => handleEdit(item)}
                      />
                      <Button
                        shape="circle"
                        icon={
                          <img
                            src={DeleteSvg}
                            alt={`Delete`}
                            // style={{ height: "16px" }}
                          />
                        }
                        onClick={()=>{showConfirm(item)}}
                      />
                    </Flex>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>

      {/* <div>
        {(data?.length > 0 &&
          data?.map((item, index) => {
            return (
              <div key={index}>
                <Row gutter={[16, 24]} className="mb-2">
                  <Col className="gutter-row" span={6}>
                    {item.name}
                  </Col>
                  <Col className="gutter-row" span={4}>
                    <Input value={item.URL} disabled />
                  </Col>
                  <Col>
                    <div className="flex gap-2 items-center">
                      <Button
                        shape="circle"
                        className="bg-white text-blue"
                        onClick={() => handleEdit(item)}
                        icon={<EditOutlined />}
                      ></Button>

                      <Button
                        shape="circle"
                        className="bg-white text-blue"
                        onClick={() => {}}
                        icon={<DeleteOutlined />}
                      ></Button>
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })) || (
          <div className="flex items-center font-bold">No items found</div>
        )}
      </div> */}
      <CustomModal
        visible={visibleModal}
        onCancel={handleCancel}
        onOk={handleUpdate}
        okText="Save"
        cancelText="Cancel"
        closable={false}
        loading={loading}
        initialValues={formModalData}
        list={list}
      />
    </>
  );
};

export default LinkList;
