import ChangeLogsServices from "../services/services/ChangeLogsServices";
import { transformData } from "./portfolioRelationshipCommon";

export const getChangeLogs = async (moduleName) => {
  await ChangeLogsServices.getChangeLogsList(moduleName.ModuleName)
    .then((response) => {
      if (response?.data?.code === 200) {
        console.log(response?.data?.data);
        return response?.data?.data;
      }
    })
    .catch((err) => {
      return err;
    });
};

export const CreateChangeLogs = (data) => {
  ChangeLogsServices.createChangeLogs(data)
    .then((response) => {
      if (response?.data?.code === 200) {
        return response?.data?.data;
      }
    })
    .catch((err) => {
      return err;
    });
};

export const UpdateChangeLog = async (
  id,
  OldValue,
  NewValue,
  TransactionType,
  moduleName,
  FieldName
) => {
  let arr = [];

  const userID = localStorage.getItem("email");
  if (TransactionType === "Update") {
    const oldDataProperty = Object.keys(OldValue);
    const newDataProperty = Object.keys(NewValue);

    //  console.log("oldDataProperty",oldDataProperty);
    //  console.log("newDataProperty",newDataProperty);

    oldDataProperty.forEach((OldpropertyName) => {
      newDataProperty.forEach((NewpropertyName) => {
        if (OldpropertyName === NewpropertyName) {
          if (OldValue[OldpropertyName] != NewValue[NewpropertyName]) {
            //Find Field Name
            ///////////////
            let displayName = "", propertyName="";
            FieldName?.map((section, index) => {
              section?.properties?.length > 0 &&
                section?.properties
                  ?.sort((a, b) =>
                    //formatPropertyName(a.name).localeCompare(formatPropertyName(b.name))
                    a.name.localeCompare(b.name)
                  )
                  ?.map((property, index) => {
                    if (OldpropertyName === property.name) {
                      displayName = property.displayName;
                      propertyName= property.name;
                    }
                  });
            });

            //console.log(`${OldpropertyName}: ${data[OldpropertyName]}`);
            // console.log(
            //   `Old ${OldpropertyName}: ${OldValue[OldpropertyName]}        New ${NewpropertyName}: ${NewValue[NewpropertyName]}`
            // );

            let dataStore = {
              ModuleName: moduleName,
              DataField: "",
              DataID: id,
              OldValue: "",
              NewValue: "",
              TransactionType: TransactionType,
              UserID: userID,
            };

            //dataStore.DataField=OldpropertyName
            dataStore.DataField = displayName;
            dataStore.OldValue = OldValue[OldpropertyName];
            dataStore.NewValue = NewValue[NewpropertyName];

            if (propertyName!=="parentid")
            { 
                arr.push(dataStore);
            }

             
          }
        }
      });
    });
  } else if (TransactionType === "Add") {
    let dataStore = {
      ModuleName: moduleName,
      DataField: "",
      DataID: id,
      OldValue: "",
      NewValue: "",
      TransactionType: TransactionType,
      UserID: userID,
    };

    dataStore.DataField = "Newly Created";
    dataStore.OldValue = "";
    dataStore.NewValue = "";
    arr.push(dataStore);
  } else if (TransactionType === "Delete") {
    let dataStore = {
      ModuleName: moduleName,
      DataField: "",
      DataID: id,
      OldValue: "",
      NewValue: "",
      TransactionType: TransactionType,
      UserID: userID,
    };

    dataStore.DataField = "Delete";
    dataStore.OldValue = "";
    dataStore.NewValue = "";
    arr.push(dataStore);
  }

  console.log("arr....", arr);

  //await ChangeLogsServices.createChangeLogs([...new Map(arr.map((item) => [item["DataID"], item])).values(),])
  await ChangeLogsServices.createChangeLogs(arr)
    .then((response) => {
      if (response?.data?.code === 200) {
        return response?.data?.data;
      }
    })
    .catch((err) => {
      return err;
    });
};

export const Update_Application_ChangeLog = async (
  id,
  OldValue,
  NewValue,
  TransactionType,
  ModuleName,
  FieldName
) => {
  let arr = [];
  const userID = localStorage.getItem("email");

  if (TransactionType == "Update") {
    const oldDataProperty = Object.keys(OldValue);
    const newDataProperty = Object.keys(NewValue);

    oldDataProperty.forEach((OldpropertyName) => {
      newDataProperty.forEach((NewpropertyName) => {
        if (OldpropertyName === NewpropertyName) {
          if (OldValue[OldpropertyName] !== NewValue[NewpropertyName]) {
            //Find Field Name
            ///////////////
            let displayName = "";

            FieldName.map((section, index) => {
              section?.properties?.length > 0 &&
                section?.properties
                  ?.sort((a, b) =>
                    //formatPropertyName(a.name).localeCompare(formatPropertyName(b.name))
                    a.name.localeCompare(b.name)
                  )
                  ?.map((property, index) => {
                    if (OldpropertyName === property.name) {
                      displayName = property.displayName;
                    }
                  });
            });

            /////////////////////////

            //console.log(`${OldpropertyName}: ${data[OldpropertyName]}`);
            // console.log(`Old ${OldpropertyName}: ${OldValue[OldpropertyName]}        New ${NewpropertyName}: ${NewValue[NewpropertyName]}`);

            let dataStore = {
              ModuleName: ModuleName,
              DataField: "",
              DataID: id,
              OldValue: "",
              NewValue: "",
              TransactionType: TransactionType,
              UserID: userID,
            };

            dataStore.DataField = displayName;
            dataStore.OldValue = OldValue[OldpropertyName];
            dataStore.NewValue = NewValue[NewpropertyName];
            arr.push(dataStore);
          }
        }
      });
    });
  } else if (TransactionType == "Add") {
    let dataStore = {
      ModuleName: ModuleName,
      DataField: "",
      DataID: id,
      OldValue: "",
      NewValue: "",
      TransactionType: TransactionType,
      UserID: userID,
    };

    dataStore.DataField = "New Application Created";
    dataStore.OldValue = "";
    dataStore.NewValue = "";
    arr.push(dataStore);
  }

  console.log("Change log Data", arr);

  await ChangeLogsServices.createChangeLogs(arr)
    .then((response) => {
      if (response?.data?.code === 200) {
        console.log("Save Change Log", response?.data?.data);

        return response?.data?.data;
      }
    })
    .catch((err) => {
      return err;
    });
};
