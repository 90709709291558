import React, { useEffect, useState } from "react";
import { Card, Checkbox, Button, message, Input } from "antd";
import { normalizeString } from "../../utils/linksCommon";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";
import { useDebounce } from "use-debounce";
import { checkRole } from "../../helper/useUserData";

const CompletenessTab = ({
  selectedMenuText,
  items,
  selectedMenu,
  fetchAllPortfolio,
  menuItems,
  selectedPortfolioItem,
  checkedPropertyAndRelationItems,
}) => {
  const { state, dispatch } = usePortfolioSetting();
  const [loading, setLoading] = useState(false);
  const [sortConnections, setSortConnections] = useState("ASC");

  const [propertyItems, setPropertyItems] = useState([]);
  const [relationItems, setRelationItems] = useState([]);

  const [checkedProperties, setCheckedProperties] = useState([]);
  const [checkedRelations, setCheckedRelations] = useState([]);

  useEffect(() => {
    const filteredAndSortedItems =
      items
        ?.filter((item) => item.checked)
        ?.sort((a, b) => (a.position ?? 0) - (b.position ?? 0)) || [];
    setRelationItems(filteredAndSortedItems);
  }, [items]);

  useEffect(() => {
    if (checkedPropertyAndRelationItems) {
      setCheckedProperties(checkedPropertyAndRelationItems?.property);
      setCheckedRelations(checkedPropertyAndRelationItems?.relation);
    }
  }, [checkedPropertyAndRelationItems]);

  useEffect(() => {
    if (selectedPortfolioItem) {
      setPropertyItems(getAllProperties(selectedPortfolioItem));
    } else if (state) {
      const foundItem = state?.data?.find((item) => item?.id === selectedMenu);
      setPropertyItems(getAllProperties(foundItem));
    }
  }, [selectedPortfolioItem, state, selectedMenu]);

  const getAllProperties = (data) => {
    const sections = data?.portfolioSections || [];
    const properties = [];

    sections.forEach((section) => {
      if (section?.properties) {
        section.properties.forEach((property) => properties.push(property));
      }
    });

    return properties.sort((a, b) => (a.position ?? 0) - (b.position ?? 0));
  };

  const handleCheckboxChange = (value, setValue, checkedValue) => {
    const isChecked = checkedValue?.includes(value);
    if (isChecked) {
      setValue(checkedValue?.filter((item) => item !== value));
    } else {
      setValue([...checkedValue, value]);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const findPortfolio =
        state?.data?.find((item) => item?.id === selectedMenu) || {};

      if (!findPortfolio) {
        return message.error("Portfolio not found. Please try again.");
      }

      findPortfolio.importantPropertiesIds = checkedProperties;
      findPortfolio.importantRelationsIds = checkedRelations;
      const response = await PortfolioSettingServices.update(
        selectedMenu,
        findPortfolio
      );
      const newData = response?.data || null;
      message.success("Updated Successfully");
      await dispatch({ type: "UPDATE_PORTFOLIO_SETTING", payload: newData });
      fetchAllPortfolio();
    } catch (error) {
      message.error("Failed to update connection. Please try again.");
      console.error("API Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const ascOrder = () => {
    setPropertyItems(
      [...propertyItems]?.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    );

    setRelationItems(
      [...relationItems]?.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      })
    );
  };

  const descOrder = () => {
    setPropertyItems(
      [...propertyItems]?.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      })
    );

    setRelationItems(
      [...relationItems]?.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }
        return 0;
      })
    );
  };

  return (
    <div>
      <div className="text-sm font-bold">
        Data Completeness for {selectedMenuText} portfolio.
      </div>
      <Card
        title={
          <>
            {sortConnections === "ASC" ? (
              <SortAscendingOutlined
                className="cursor-pointer"
                onClick={() => {
                  setSortConnections("DESC");
                  descOrder();
                }}
              />
            ) : (
              <SortDescendingOutlined
                className="cursor-pointer"
                onClick={() => {
                  setSortConnections("ASC");
                  ascOrder();
                }}
              />
            )}
          </>
        }
        className={`application-tab-card cu-border my-2`}
        bodyStyle={{ padding: 5 }}
        extra={
          <div className="d-flex justify-content-end">
            <Input.Search
              style={{ width: "150px", marginLeft: "20px" }}
              placeholder="Search"
              onChange={(e) => {
                if (e.target.value.length === 0) {
                  // searchByTextOrder(e.target.value);
                }
              }}
              onSearch={(value) => {
                // searchByTextOrder(value);
              }}
              onPressEnter={(e) => {
                e.stopPropagation();
                // searchByTextOrder(e.target.value);
              }}
              disabled={true}
            ></Input.Search>
          </div>
        }
      >
        <div className="flex items-center justify-evenly gap-2">
          <div className={`w-1/2`} style={{ height: window.innerHeight / 2 }}>
            <div className="sticky top-0 z-10 bg-white">
              <ul className="list-disc font-bold text-sm py-2 ml-4">
                <li>Choose Properties which are important to be filled.</li>
              </ul>
            </div>
            <div
              className="overflow-y-auto p-2"
              style={{ height: "calc(100% - 2rem)" }}
            >
              {propertyItems && propertyItems.length ? (
                propertyItems.map((item, index) => {
                  return !item?.isDeleted ? (
                    <div className="card card-body p-2 mb-2" key={index}>
                      <div>
                        <Checkbox
                          checked={checkedProperties.includes(item.id)}
                          onChange={() =>
                            handleCheckboxChange(
                              item.id,
                              setCheckedProperties,
                              checkedProperties
                            )
                          }
                        >
                          {normalizeString(item.displayName)}
                        </Checkbox>
                      </div>
                    </div>
                  ) : null; // returning null instead of empty JSX fragment for cleaner code
                })
              ) : (
                <div className="line-height-normal my-5">
                  <div className="fs-4">No Current Properties.</div>
                </div>
              )}
            </div>
          </div>
          <div className={`w-1/2`} style={{ height: window.innerHeight / 2 }}>
            <div className="sticky top-0 z-10 bg-white">
              <ul className="list-disc font-bold text-sm py-2 ml-4">
                <li>Choose Relations which are important to be Linked.</li>
              </ul>
            </div>
            <div
              className="overflow-y-auto p-2"
              style={{ height: "calc(100% - 2rem)" }}
            >
              {relationItems && relationItems.length ? (
                relationItems.map((item, index) => {
                  return (
                    <div className="card card-body p-2 mb-2" key={index}>
                      <div>
                        <Checkbox
                          checked={checkedRelations.includes(item.id)}
                          onChange={() =>
                            handleCheckboxChange(
                              item.id,
                              setCheckedRelations,
                              checkedRelations
                            )
                          }
                        >
                          {normalizeString(item.name)}
                        </Checkbox>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="line-height-normal my-5">
                  <div className="fs-4">No Current Connections</div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Card>
      {checkRole("save") && (
        <Button
          type="primary"
          className="pull-right"
          onClick={handleSubmit}
          disabled={loading}
          loading={loading}
        >
          Save
        </Button>
      )}
    </div>
  );
};

export default CompletenessTab;
