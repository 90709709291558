import PropTypes from 'prop-types'
import UserService from "../services/UserService";
import NotAllowed from './NotAllowed';


const RolesRoute =({ roles, children }) => {

    return UserService.hasRole(roles) ? children : <NotAllowed/>
}

RolesRoute.propTypes = {
  roles: PropTypes.arrayOf(PropTypes.string).isRequired,
}

export default RolesRoute
