import React from "react";
import { Layout } from "antd";

const Sidebar = ({ showFilter, setShowFilter, children ,SidebarHeight}) => {
  return (
    <Layout.Sider
      theme={`light`}
      width={showFilter ? `240px` : `50px`}
      // className="border-left filter-ani"
      className="filter-ani"
      // style={{ overflow: "auto",height:'100vh', transition:"all 0.25s ease"}}
      style={{ overflow: "auto", transition:"all 0.25s ease"}}
      
    >
      {children}
    </Layout.Sider>
  );
};

Sidebar.defaultProps={
  SidebarHeight:window.innerHeight - 100
}
export default Sidebar;
