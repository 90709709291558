import AdminLayout from "../layouts/AdminLayout";
import { ExclamationCircleOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import "datatables.net-colreorder-dt";
import "datatables.net-bs5";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { useEffect, useRef, useState } from "react";
import ScenarioServices from "../../services/services/ScenarioServices";
import {
  Spin,
  Select,
  Row,
  Col,
  Typography,
  Card,
  Space,
  Tag,
  Modal,
  message,
} from "antd";
import "./Scenario.scss";
import { useNavigate } from "react-router";
import { getScenarioType, getScenarioSubType } from "../../utils/ScenarioCommon";
import UserService from "../../services/UserService";
import LoadingBar from 'react-top-loading-bar'
import { SaveRecentlyView_Portfolio, RemoveRecentViewed } from "../../utils/ResentViewedPortfolio";
import TextWithTooltip from "../../utils/TextWithTooltip";
      
const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

// const DataTable = DataTables(window, $);
const ScenarioList = (props) => {
  const ref = useRef(null)
  const [scenarioData, setScenarioData] = useState([]);
  const [userList, setUserList] = useState([]);
  const [scenarioType, setScenarioType] = useState([]);
  const [scenarioId, setScenarioId] = useState(null);
  const tableRef = useRef();
  const [scenarioSubType, setScenarioSubType] = useState([]);
  const [scenarioFilter, setScenarioFilter] = useState({
    typeId: "",
    subTypeId: "",
    visibility: "1",
    userEmail: ""
  });
  const [recentOpen, setRecentOpen] = useState([]);
  const [loading, setLoading] = useState(false);
  const [scenarioDataTable, setScenarioDataTable] = useState(null);
  const navigate = useNavigate();
  const [modalShareOpen, setModalShareOpen] = useState(false);

  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [scenarioName, setScenarioName] = useState(false);

  const [selectedValues, setSelectedValues] = useState([]);
  var scenarioTable;

  useEffect(() => {
    getScenarioType(loading, setLoading, setScenarioType)
    getScenarioSubType(loading, setLoading, setScenarioSubType);
    loadData()
    recentlyOpenedScenario("view");
  }, []);

  useEffect(() => {
    recentlyOpenedScenario("view");
    loadData();
  }, [scenarioFilter]);

  const loadData = async () => {
    await getScenarioList();
  }

  const confirmBox = (name, scenarioId) => {
    confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: `Are you sure want to delete ${name}?`,
      onOk() {
        let obj = {};
        obj.scenarioId = scenarioId;
        ScenarioServices.deleteScenario({ data: obj }).then((response) => {
          recentlyOpenedScenario("delete", scenarioId);
          getScenarioList();
        });
      },
      onCancel() { },
    });

  };

  const deleteScenario = () => {
    let obj = {};
    obj.scenarioId = scenarioId;
    ScenarioServices.deleteScenario({ data: obj }).then((response) => {
      recentlyOpenedScenario("delete", scenarioId);
      getScenarioList();
      setModalDeleteOpen(false);
    });
  }

  useEffect(() => {
    loadTale();
  }, [scenarioData]);

  const loadTale = async () => {
    await createTable()
  }

  const createTable = async () => {
    const colReorder_options = {
      realtime: false,
    };
    if (scenarioDataTable) {
      scenarioDataTable?.destroy();
      setScenarioDataTable(null)
      document.querySelector('#scenario-list-table').innerHTML = "";
    }
    let columns = [
      { title: "Name", data: "name",className:"ellipsis-text-300 ",'createdCell':  function (td, cellData, rowData, row, col) { $(td).attr('title', cellData);}},
      { title: "Type", data: "scenario_type_name" },
      { title: "Sub Type", data: "scenario_sub_type_name" },
      { title: "Created By", data: "created_by" },
      { title: "Action", data: "action", className: "text-center" },
    ]
    scenarioTable = new DataTable(tableRef.current, {
      colReorder: colReorder_options,
      data: scenarioData,
      processing: true,
      columns: columns,
      destroy: true,
      lengthMenu: [
        [10, 25, 50, 100, 500, -1],
        ["10", "25", "50", "100", "500", "All"],
      ],
      aaSorting: [],
    });
    setScenarioDataTable(scenarioTable);
  }

  const getScenarioList = async () => {
    if (ref.current) {
      ref.current.continuousStart();
    }
    setLoading(true);
    let obj = {};
    obj.scenarioTypeID = scenarioFilter?.typeId;
    obj.scenarioSubtypeID = scenarioFilter?.subTypeId;
    obj.visibility = scenarioFilter?.visibility ? scenarioFilter?.visibility : '1';
    if (!UserService.hasRole(['Admin']) && scenarioFilter?.visibility != '1') {
      obj.userEmail = localStorage.getItem('email');
    }
    setScenarioData([]);
    await ScenarioServices.getScenarioList(obj)
      .then((response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          for (let item of responseVal) {
            item.action = `<button type="button" class="btn btn-xs btn-primary go-to-build-scenario"  attr-scenarioId="${item.id}" attr-scenarioName="${item.name}"><i class="fa fa-eye"></i></button>`;
            if (UserService.hasRole(['Admin', 'Editor'])) {
              item.action += `&nbsp;&nbsp;<button type="button" class="btn btn-xs btn-danger delete-scenario" attr-scenarioId="${item.id}" attr-scenarioName="${item.name}"><i class="fa fa-trash-o"></i>
              </button>&nbsp;&nbsp;<button type="button" class="btn btn-xs share-scenario" style="color:#2a609d;border:none;" attr-scenarioId="${item.id}" attr-scenarioName="${item.name}" ><i class="fa fa-share"></i></button>`
            }
          }
          setScenarioData(responseVal);
          setScenarioDataTable(null)
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err.message);
        setLoading(false);
      });

      if (ref.current) {
        ref.current.complete();
      }
  };

  // set scenario type filter value
  const handelTypeChange = (e) => {
    if (e === undefined) {
      setScenarioFilter({ ...scenarioFilter, typeId: "" });
    } else {
      setScenarioFilter({ ...scenarioFilter, typeId: e });
    }
  };

  // set scenario sub type filter value
  const handelSubTypeChange = (e) => {
    if (e === undefined) {
      setScenarioFilter({ ...scenarioFilter, subTypeId: "" });
    } else {
      setScenarioFilter({ ...scenarioFilter, subTypeId: e });
    }
  };

  const handelVisibilityChange = (e) => {
    if (e === undefined) {
      setScenarioFilter({ ...scenarioFilter, visibility: "" });
    } else {
      setScenarioFilter({ ...scenarioFilter, visibility: e });
    }
  };

  const recentlyOpenedScenario = (action, scenarioId = "") => {
    let recentScenarios = localStorage.getItem("recent_scenarios");
    let recentScenariosArr = [];
    if (recentScenarios != null) {
      recentScenariosArr = JSON.parse(recentScenarios)?.reverse();
    }

    if (action === "view" && recentScenariosArr.length > 0) {
      // let recetOpenedBadges = [];
      // let loopI = 0;
      // for (let k = recentScenariosArr.length - 1; k >= 0; k--) {
      //   if (loopI < 3) {
      //     recetOpenedBadges.push({
      //       scenarioId: recentScenariosArr[k]?.scenarioId,
      //       scenarioName: recentScenariosArr[k]?.scenarioName,
      //     });
      //   }
      //   loopI++;
      // }
      let recetOpenedBadges = recentScenariosArr?.map(item => {
        return {
          scenarioId: item?.scenarioId,
          scenarioName: item?.scenarioName,
        }
      })
      setRecentOpen(recetOpenedBadges);
    } else if (action === "delete") {

      let getFindIndex = recentScenariosArr.findIndex(
        (p) => p.scenarioId == scenarioId
      );

      if (getFindIndex > -1) {
        recentScenariosArr.splice(getFindIndex, 1);
      }
      localStorage.removeItem("recent_scenarios");
      localStorage.setItem(
        "recent_scenarios",
        JSON.stringify(recentScenariosArr)
      );

      // let recetOpenedBadges = [];
      // let loopI = 0;
      // for (let k = recentScenariosArr.length - 1; k >= 0; k--) {
      //   if (loopI < 3) {
      //     recetOpenedBadges.push({
      //       scenarioId: recentScenariosArr[k]?.scenarioId,
      //       scenarioName: recentScenariosArr[k]?.scenarioName,
      //     });
      //   }
      //   loopI++;
      // }
      let recetOpenedBadges = recentScenariosArr?.map(item => {
        return {
          scenarioId: item?.scenarioId,
          scenarioName: item?.scenarioName,
        }
      })
      setRecentOpen(recetOpenedBadges);

       //Remove From Home Screen 
      RemoveRecentViewed( "Scenario",scenarioId);
      
    }
  };

  // open scenario
  $(document).on("click", ".go-to-build-scenario", function (e) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    let scenarioId = $(this).attr("attr-scenarioId");
    let recentScenarios = localStorage.getItem("recent_scenarios");
    let recentScenariosArr = [];
    if (recentScenarios != null) {
      recentScenariosArr = JSON.parse(recentScenarios);
    }
    let getFindIndex = recentScenariosArr.findIndex(
      (p) => p.scenarioId == scenarioId
    );

    if (getFindIndex > -1) {
      recentScenariosArr.splice(getFindIndex, 1);
      recentScenariosArr.push({
        scenarioId: scenarioId,
        scenarioName: $(this).attr("attr-scenarioName"),
      });
    } else if (getFindIndex == -1) {
      recentScenariosArr.push({
        scenarioId: scenarioId,
        scenarioName: $(this).attr("attr-scenarioName"),
      });
    }
    localStorage.setItem(
      "recent_scenarios",
      JSON.stringify(recentScenariosArr)
    );
    navigate(`/edit-scenario/${scenarioId}`);
  });

  // open scenario
  $(document).on("click", ".recent-opened-scenario-click", function (e) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    let scenarioId = $(this).attr("attr-scenarioId");
    // if (scenarioData?.find(item => item.id?.toString() === scenarioId?.toString())) {
    navigate(`/edit-scenario/${scenarioId}`);
    // } else {
    //   message.error("Scenario not found");
    // }
  });

  // delete scenario  
  $(document).on("click", ".delete-scenario", function (e) {
    // e.stopPropagation();
    // e.stopImmediatePropagation();
    let scenarioId = $(this).attr("attr-scenarioId");
    let scenarioName = $(this).attr("attr-scenarioname");
    setScenarioId(scenarioId)
    setScenarioName(scenarioName)
    // confirmBox(scenarioName, scenarioId);
    setModalDeleteOpen(true)

  });

  const userData = () => {
    ScenarioServices.userList()
      .then((response) => {
        if (response?.data?.code === 200) {
          let responseVal = response?.data?.data;
          responseVal = responseVal.filter(e => e.email != localStorage.getItem('email'))
          setUserList(responseVal);
        }
        setLoading(false);
      })
      .catch((err) => {
        // console.log(err.message);
        setLoading(false);
      });
  }

  useEffect(() => {
    userData();
  }, [])

  //  share scenario

  $(document).on("click", ".share-scenario", async function (e) {

    setSelectedValues([]);
    let scenarioId = $(this).attr("attr-scenarioId");
    setScenarioId(scenarioId);
    // e.stopPropagation();
    // e.stopImmediatePropagation();
    setModalShareOpen(true)

  });

  const handleChange = (value) => {
    setSelectedValues(value)
  };

  const submitShare = async () => {
    let email = localStorage.getItem('email');
    let obj = {
      "scenarioID": scenarioId,
      "emails": selectedValues,
      "sharedBy": email
    }
    if (selectedValues.length > 0) {
      await ScenarioServices.shareScenario(obj)
        .then((response) => {
          if (response?.data?.code === 200) {
            setModalShareOpen(false)
            message.success(response.data.message);
            setLoading(false);
          }
        })
        .catch((err) => {
          message.error(err.message);
          setLoading(false);
        });
    } else {
      message.error('Please select email');
    }

  }


  const closeHareModal = () => {
    setModalShareOpen(false)
  }
  return (
    <>
      <LoadingBar color='#1f1f1f' ref={ref} />
      <Spin className="loading_bx" size="small" spinning={loading}>
        {/* Scenario List Filter */}
        <Card className="bg-light mt-2">
          <Row className="d-flex align-items-center justify-content-start">
            <Col md={4} className="me-3">
              <Typography.Title level={5} className="text-start">
                Landscape Type:
              </Typography.Title>
              <Select
                // mode="multiple"
                allowClear
                onChange={handelTypeChange}
                defaultValue={[]}
                placeholder="Select"
                style={{ width: "100%" }}
                options={scenarioType}
                className="text-start"
              />
            </Col>

            <Col md={4} className="me-5">
              <Typography.Title level={5} className="text-start">
                Landscape Sub Type:
              </Typography.Title>
              <Select
                // mode="multiple"
                allowClear
                onChange={handelSubTypeChange}
                defaultValue={[]}
                placeholder="Select"
                style={{ width: "100%" }}
                options={scenarioSubType}
                className="text-start"
              />
            </Col>
            <Col md={4} className="me-5">
              <Typography.Title level={5} className="text-start">
                Visibility:
              </Typography.Title>
              <Select
                // mode="multiple"
                // allowClear
                onChange={handelVisibilityChange}
                placeholder="Select"
                style={{ width: "100%" }}
                initialValue=""
                options={[
                  // { value: '', label: "All" },
                  { value: '1', label: "Public" },
                  { value: '2', label: "Private" }
                ]}
                className="text-start"
                defaultValue="1"
              />
            </Col>
            <Col md={8} className="text-start">
              <Title level={4}>Recently Opened</Title>
              <Space>
                {recentOpen &&
                  // recentOpen.filter(a => scenarioData.find(b => b?.id?.toString() === a?.scenarioId?.toString()))?.map((item) => {
                  recentOpen?.map((item) => {
                    return (
                      <Tag
                        className="badge badge-theme recent-opened-scenario-click"
                        attr-scenarioId={item?.scenarioId}
                        title={item?.scenarioName}
                        key={Math.random()}
                      >
                        {item?.scenarioName}
                      </Tag>
                    );
                  }).splice(0, 3)}
              </Space>
            </Col>
          </Row>
        </Card>

        {/* Scenario List Table */}
        <Row>
          <Col xs={24} md={24} lg={24}>
            <Card
              title="Landscape List"
              className="table-responsive dataTable dt-avoid-y-scroll graph_container mt-2 text-start"
            >
              <table
                ref={tableRef}
                id="scenario-list-table"
                className="table display nowrap"
                style={{ width: "100%" }}
              ></table>
            </Card>
          </Col>
        </Row>
      </Spin>
      {modalShareOpen &&
        <Modal
          title="Select Users"
          style={{ top: 20 }}
          open={modalShareOpen}
          maskClosable={false}
          onOk={() => submitShare()}
          onCancel={() => closeHareModal()}
        >
          {/* <Select
            mode="multiple"
            style={{
              width: '100%',
            }}
            placeholder="Please select"
            defaultValue={[]}
            onChange={handleChange}
            options={userList}
          /> */}
          <Select
            mode="multiple"
            placeholder="Select items"
            onChange={handleChange}
            style={{ width: '100%' }}
            value={selectedValues}
          >
            {userList && userList.map(item => (
              <Option key={item.id} value={item.email}>{item.name + ' - ' + item.email}</Option>
            ))}
          </Select>
        </Modal>
      }

      {modalDeleteOpen &&
        <Modal
          // title="Confirm"
          style={{ backgroundClip:"padding-box",  pointerEvents:'auto',  width:"100px", height:0 }}
          open={modalDeleteOpen}
          //maskClosable={false}
          closable={false}
          onOk={() => deleteScenario()}
          onCancel={() => setModalDeleteOpen(false)}
        >
          <div style={{display:'flex', alignItems:'center',gap:10 }}>
          <ExclamationCircleFilled style={{color:'#faad14', fontSize:20}}/>
          <p style={{fontWeight:600, fontSize:16}}> Are you sure want to delete? </p>
          </div>
          
        </Modal>
      }
  </>  
  );
};
// {scenarioName}?
export default ScenarioList;
