import React, { useEffect, useState } from "react";
import { Typography, Row, Col, Select, Form, Divider, message } from "antd";
import CreatableSelect from "react-select/creatable"; 
import { useGlobalTag } from "../../../../Context/GlobalTagProvider";
const { Option } = Select;

const GlobalTags = ({ tagOptions, form, formData, templatename,templatePropName }) => {
  const { state: stateTag, dispatch: dispatchTag } = useGlobalTag();
  const [options, setOptions] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);

  useEffect(() => { 
    setOptions(
      tagOptions.map((option) => {
         
        return { ...option, value: option?.id, label: option?.option };
      })
    );
  }, [tagOptions]);
  
  const handleChange = (tags) => {
     
    let dddd={ ...formData, template:tags };
     
    //form.setFieldsValue({ ...formData, template:tags });
    form.setFieldValue(templatePropName,tags);
  }; 

   

  return (
    <>
      <Row gutter={[16, 24]} className="mb-2">
        <Col span={8} className="gutter-row">
          <Typography.Title level={5} className="mb-2">
            {templatename}
          </Typography.Title>
        </Col>
        <Col span={16} className="gutter-row">
          <Form.Item
            name={templatePropName}
            rules={[
              {
                required: false,
                message: `Tag is required!`,
              },
            ]}
            wrapperCol={{ span: 16 }}
            className="mb-2 text-start"
          >
            <Select
               mode="multiple"
              // value={selectedTags}
              options={options}
              onChange={handleChange} 
              placeholder="Select Global Properties"
              className="react-select-container"
              // classNamePrefix="react-select"
              // components={{ Option: CustomOption }}
            />
          </Form.Item>
        </Col>
      </Row>
      <Divider />
    </>
  );
};

export default GlobalTags;
