import { Button, Flex } from "antd";
import React, { useEffect, useState } from "react";
import DCBarCharts from "./DCBarCharts";
import { CloseOutlined } from "@ant-design/icons";
import CompletenessDataTable from "./CompletenessDataTable";

const DCViewDetails = ({
  portfolioData,
  data,
  dataRelations = [],
  handleGetDetail,
}) => {
  const [showDetails, setShowDetails] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
    total: 0,
  });

  const fetchDetailsData = async (page, pageSize) => {
    setDetailsLoading(true);
    try {
      const data = await handleGetDetail(portfolioData?.id, page, pageSize);
      setDetailsData(data);
    } catch (error) {
      console.error("Error fetching details data:", error);
    } finally {
      setDetailsLoading(false);
    }
  };

  useEffect(() => {
    if (showDetails) {
      fetchDetailsData(pagination.current, pagination.pageSize);
    }
  }, [showDetails, pagination.current, pagination.pageSize]);

  const handlePageChange = (newPagination, filters, sorter) => {
    const { current, pageSize } = newPagination;
    if (current !== pagination.current || pageSize !== pagination.pageSize) {
      setPagination((prev) => ({
        ...prev,
        current: current,
        total: Number(portfolioData?.totalRecords) || 0,
        pageSize
      }));
    }
  };
  const checkBoolean = (obj) =>
    Object.values(obj).some((value) => (Array.isArray(value) ? true : false));

  return (
    <React.Fragment>
      <Flex justify="space-between" align="flex-end">
        <Flex justify="space-start" className="gap-4">
          {(data?.length > 0 && (
            <DCBarCharts portfolioData={portfolioData} data={data} />
          )) || <div className="no-results-message">No Properties found!</div>}

          <>
            {(dataRelations?.length > 0 &&
              dataRelations?.map((item, index) => {
                const relationValue = { ...item };
                return (
                  <div key={index}>
                    <DCBarCharts
                      portfolioData={portfolioData}
                      data={[relationValue]}
                    />
                  </div>
                );
              })) || (
              <div className="no-results-message">No relations found!</div>
            )}
          </>
        </Flex>
        <div>
          <Button
            type="link"
            onClick={() => {
              setPagination((prev) => ({
                ...prev,
                current: 1,
                pageSize: 10,
                total: Number(portfolioData?.totalRecords) || 0,
              }));
              setShowDetails(!showDetails);
            }}
          >
            View Details
          </Button>
        </div>
      </Flex>
      {showDetails && (
        <div
          className={`table-responsive ${showDetails === true ? "" : "d-none"}`}
        >
          <CompletenessDataTable
            data={detailsData}
            width="95%"
            height={440}
            style={{ margin: "20px" }}
            paginationOptions={{
              ...pagination,
              showSizeChanger: false,
              pageSizeOptions: ["5", "10", "20", "50", "100", "200"], // Options for page size
            }}
            columnHeight={60} // Custom column height
            columnWidth={200} // Custom column width
            position="left" // Position for the first column
            rowHeight={20}
            rowKey="displayname"
            size="small"
            loading={detailsLoading}
            onChange={handlePageChange}
          />
        </div>
      )}
    </React.Fragment>
  );
};

DCViewDetails.defaultProps = {};

export default DCViewDetails;

const randomIntFromInterval = (min, max) => {
  // min and max included
  return parseInt(Math.floor(Math.random() * (max - min + 1) + min));
};
