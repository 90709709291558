import React, { useState, useEffect } from "react";
import { Table, Input, Button } from "antd";
import { CheckOutlined, CloseOutlined, SearchOutlined } from "@ant-design/icons";

const CompletenessDataTable = ({
  data,
  rowKey = "id",
  className,
  style,
  width,
  height,
  columnHeight = 60, // Default column height
  columnWidth = 150, // Default column width
  rowHeight = 40, // Default row height
  fontSize = 14, // Default font size
  position = "left", // Default fixed column position
  paginationOptions,
  ...tableProps
}) => {
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    if (data?.length > 0) {
      const generatedColumns = generateColumnsFromData(data);
      setColumns(generatedColumns);
    }
  }, [data]);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ marginBottom: 8, display: "block" }}
        />
        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => handleReset(clearFilters,confirm)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? "#517cad" : undefined }} />
    ),
    onFilter: (value, record) =>
      record[dataIndex]
        ? record[dataIndex]
            .toString()
            .toLowerCase()
            .includes(value.toLowerCase())
        : "",
    render: (text) =>
      searchedColumn === dataIndex ? <span>{text}</span> : text,
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters,confirm) => {
    clearFilters();
    confirm();
    setSearchText("");
    setSearchedColumn("");
};

  const generateColumnsFromData = (data) => {
    const firstRow = data[0];

    return Object.keys(firstRow)
      .filter((key) => key !== "id") // Exclude 'id' from the columns
      .map((key, index) => {
        const isBoolean = typeof firstRow[key] === "boolean";

        return {
          title: key.replace(/([A-Z])/g, " $1").trim(),
          dataIndex: key,
          key,
          sorter: (a, b) => (a[key] > b[key] ? 1 : -1),
          width: columnWidth, // Column width customization
          ...(isBoolean
            ? {
                filters: [
                  { text: "Complete", value: true },
                  { text: "Incomplete", value: false },
                ],
                onFilter: (value, record) => record[key] === value,
                render: (value) =>
                  value ? (
                    <CheckOutlined className="text-base text-green-700"/>
                  ) : (
                    <CloseOutlined className="text-base text-red-700" />
                  ),
              }
            : getColumnSearchProps(key)),
          ...(index === 0 && { fixed: position }), // Fix first column position
        };
      });
  };

  return (
    <Table
      columns={columns}
      dataSource={data}
      rowKey={rowKey}
      className={className}
      style={{
        ...style,
        width,
        height,
      }}
      scroll={{
        x: width || 1200,
        y: height || 300,
      }}
      pagination={paginationOptions} // Enable pagination
      onRow={() => ({
        // style: { height: rowHeight, fontSize: `${fontSize}px` }, // Apply custom row height and font size
      })}
      rowClassName={() => "text-sm"}
      {...tableProps}
    />
  );
};

export default CompletenessDataTable;
