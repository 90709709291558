import React, { useEffect, useState } from 'react'
import BusinessProcessHeader from '../../businessProcess/BusinessProcessHeader';
import PlantGrid from './PlantGrid';
import PlantList from './PlantList';
import "./Plant.scss";
import { useLocation } from 'react-router';
import PlantTree from './PlantTree';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { showViewType } from '../common/PortfolioCommon';

const Plant = ({moduleName}) => {
    const location = useLocation()
    const [activePage,setActivePage] = useState(0);
    const [moduleData,setModuleData] = useState(null);
    const [filterOptions, setFilterOptions] = useState({});   
    const [bulkDeleteButton,setBulkDeleteButton] = useState(false);
    const [deleteStart,setDeleteStart] = useState(false);

    useEffect(()=>{
        setActivePage(location?.state?.defaultActiveKey || 1);
        let module = getMenuPortfolio({routeModuleName:moduleName});
        setModuleData(module);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[location]);

    const HeaderContnet = <BusinessProcessHeader 
        activePage={activePage}
        setActivePage={setActivePage}
        heading={(moduleData?.name || moduleName)}
        addBtnTooltip={`Add New`}
        grid={false}
        list={true}
        tree={true}
        filterOptions={filterOptions}
        bulk_delete={bulkDeleteButton}
        setDeleteStart={setDeleteStart}
        //bulk_delete_id={bulk_delete_id}
    />
    console.log("filterOptions-2",filterOptions)
    return (
        <div className=''>
            { 
                activePage === 2 && showViewType({moduleName:moduleData?.moduleName,viewType:"tower_view"}) &&
                <PlantGrid 
                    HeaderContnet={HeaderContnet}
                    moduleName={moduleName}
                />
            }
            { 
                activePage === 1 && showViewType({moduleName:moduleData?.moduleName,viewType:"list_view"}) &&
                <PlantList 
                    HeaderContnet={HeaderContnet}
                    moduleName={moduleName}
                    filterOptions={filterOptions}
                    setFilterOptions={setFilterOptions}
                    setBulkDeleteButton={setBulkDeleteButton}
                    deleteStart={deleteStart}
                    setDeleteStart={setDeleteStart}

                />
            }
            { 
                activePage === 4 && showViewType({moduleName:moduleData?.moduleName,viewType:"tree_view"}) &&
                <PlantTree 
                    HeaderContnet={HeaderContnet}
                    moduleName={moduleName}
                />
            }
        </div>
    )
}

export default Plant