import React, { useEffect, useRef, useState } from 'react';

const escapeHTML = (string) => {
    if (typeof string !== 'string') return string;
    const chatMap = {
        '&': '&amp;',
        "'": '&#x27;',
        '`': '&#x60;',
        '"': '&quot;',
        '<': '&lt;',
        '>': '&gt;',
    };
    return string.replace(/[&'`"<>]/g, (match) => chatMap[match]);
};

const createMxGraphData = (xml, idx = new Date().getTime()) => ({
    editable: false,
    highlight: '#0000ff',
    nav: false,
    toolbar: null,
    edit: null,
    resize: true,
    lightbox: 'open',
    xml,
});

const createMxGraphHTML = (json) => (
    `<div class="mxgraph" id="mxGraphSvg" style="max-width:100%;border:1px solid transparent;" data-mxgraph="${escapeHTML(json)}"></div>`
);

const DiagramData = ({ dataGraphXML }) => {
    const [svgContent, setSvgContent] = useState('');
    const [svgContentUpd, setSvgContentUpd] = useState('');
    const svgContainerRef = useRef(null);
    const [isContentLoaded, setIsContentLoaded] = useState(false);

    useEffect(() => {
        const loadDiagram = async () => {
            try { 
                setIsContentLoaded(false);
                const mxGraphData = createMxGraphData(dataGraphXML);
                const json = JSON.stringify(mxGraphData);
                const content = createMxGraphHTML(json);
                setSvgContent(content);
                
                if (window.GraphViewer) {
                  setTimeout(() => {
                      window.GraphViewer.processElements();
                  }, 200);
                }
            } catch (err) {
                console.error('Error loading draw.io file:', err);
            }
        };

        loadDiagram();
    }, [dataGraphXML]);

    useEffect(() => {
        if (svgContainerRef.current) {
            setTimeout(() => {
                const mxGraphSvgElement = svgContainerRef.current.querySelector('#mxGraphSvg');
                if (mxGraphSvgElement) {
                    setSvgContentUpd(mxGraphSvgElement.innerHTML);
                    setTimeout(() => { setIsContentLoaded(true); }, 200);
                } else {
                    console.error('mxGraphSvg element not found');
                }
            }, 500);
        }
    }, [svgContent]);


    return (
        <>
            <div dangerouslySetInnerHTML={{ __html: svgContent }} ref={svgContainerRef}></div>
        </>
    );
};

export default DiagramData;
