import React, { useState } from "react";
import {
  Popover,
  Checkbox,
  Button,
  Flex,
  Divider,
  Tag,
  Tooltip,
  Card,
} from "antd";
import { CloseOutlined, DownOutlined } from "@ant-design/icons";
import { COLORS } from "../../../utils/Common";

const MultiSelectPopover = ({
  options,
  onSelect,
  handleSelectAll,
  handleClearAll,
  selectedItems,
  placement,
  title,
  tags,
  removeItem,
}) => {
  const handleSelectAllItems = () => {
    handleSelectAll(options.map((option) => option.value));
  };

  const handleClearAllItems = () => {
    handleClearAll([]);
  };

  const handleChange = (value) => {
    // setSelectedItems(value);
    onSelect(value);
  };

  const PopoverContent = () => {
    return (
      <>
        <React.Fragment>
          <div style={{ marginTop: 10 }}>
            <Flex
              gap={8}
              justify="space-between"
              align="center"
              className="mb-2"
            >
              <Button
                type="primary"
                onClick={handleSelectAllItems}
                size="small"
                disabled={options?.length === selectedItems?.length}
              >
                Select All
              </Button>
              <Button type="text" onClick={handleClearAllItems} size="small">
                Clear All
              </Button>
            </Flex>
          </div>
          <Divider className="m-2" />
          <Checkbox.Group onChange={handleChange} value={selectedItems}>
            <Flex
              gap={4}
              vertical
              className="overflow-y"
              style={{ maxHeight: 200 }}
            >
              {options.map((option) => (
                <Checkbox value={option.value}>{option.label}</Checkbox>
              ))}
            </Flex>
          </Checkbox.Group>
        </React.Fragment>
      </>
    );
  };

  const TagList = ({ selectedItems, removeItem }) => {
    // const getRandomColor = () =>
    //   COLORS[Math.floor(Math.random() * COLORS.length)];
    // return (
    //   <div>
    //     <Flex gap="4px 0" wrap>
    //       {selectedItems?.map((tag) => (
    //         <Tooltip key={tag} title={tag.label.length > 15 ? tag.label : null}>
    //           <Tag color={getRandomColor()}>
    //             {tag.length > 15 ? `${tag.slice(0, 15)}...` : tag}
    //           </Tag>
    //         </Tooltip>
    //       ))}
    //     </Flex>
    //   </div>
    // );
    return (
      <Flex gap="4px 0" wrap vertical>
        {selectedItems?.map((tag) => {
          return (
            <>
              <Tag
                key={tag}
                closable
                onClose={() => removeItem(tag)}
                closeIcon={<CloseOutlined className="text-[#2A609D] text-sm" />}
                className="text-sm flex items-center justify-between"
              >
                {tag.length > 18 ? (
                  <Tooltip title={tag} placement="top">
                    <span>{tag.substring(0, 18)}...</span>
                  </Tooltip>
                ) : (
                  <span>{tag}</span>
                )}
              </Tag>
            </>
          );
        })}
      </Flex>
    );
  };

  return (
    <div className="w-full z-10">
      <Popover
        content={<PopoverContent />}
        trigger="click"
        placement={placement}
        className="w-full"
      >
        {selectedItems?.length > 0 && tags ? (
          <Card
            className="cursor-pointer"
            title={title ? title : "Selected items"}
          >
            <Flex justify="space-between" align="flex-start" vertical>
              <TagList selectedItems={selectedItems} removeItem={removeItem} />
            </Flex>
          </Card>
        ) : selectedItems?.length > 0 && !tags ? (
          <Flex
            justify="space-between"
            align="center"
            className="cursor-pointer border border-gray-300 p-1 rounded-md"
          >
            <div>{selectedItems?.length + " Selected"}</div>
            <div>
              <DownOutlined />
            </div>
          </Flex>
        ) : (
          <Button
            className="bg-none"
            iconPosition={"end"}
            icon={<DownOutlined />}
          >
            {title ? title : "Select"}
          </Button>
        )}
      </Popover>
    </div>
  );
};

MultiSelectPopover.defaultProps = {
  options: [],
  placement: "bottomLeft",
  selectedItems: [],
  onSelect: () => {},
  handleSelectAll: () => {},
  handleClearAll: () => {},
  title: "Select",
  tags: false,
  removeItem: () => {},
};

export default MultiSelectPopover;
