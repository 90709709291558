import {
  Badge,
  Button,
  Card,
  Col,
  Row,
  Collapse,
  Tooltip,
  Flex,
  Modal,
  message,
} from "antd";
import React, { useEffect, useState, useRef } from "react";
import { useLocation, useNavigate, useParams } from "react-router";

import DeleteSvg from "../../assets/Remove.svg";
import EditSvg from "../../assets/Edit.svg";
import {
  convertToNormalText,
  dynamicRoutesLinks,
} from "../../utils/portfolioRelationshipCommon";
import useDynamicNavigate from "../../utils/navigateUtils";
import { clippingParents } from "@popperjs/core";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/fr"; // Import the desired locale
import { usePortfolioTag } from "../../Context/portfolioTagProvider";
import listSvg from "./../../assets/List.svg";
import gridSvg from "./../../assets/Two columns layout.svg";
import matrixSvg from "./../../assets/Matrix.svg";
import { PlusOutlined } from "@ant-design/icons";
import ScenarioListSelection from "./portfolio/ScenarioListSelection";
import IntegrationGrid from "./intergrationgrid/integrationgrid";
import ColumnSelector from "./intergrationgrid/ColumnSelector";
import IntegrationServices from "../../services/services/IntegrationServices";
import ScenarioIntegrationGrid from "./intergrationgrid/ScenarioIntegrationGrid";
import IntegrationGridImport from "./intergrationgrid/integrationGridImport";
import ScenarioIntegrationGridV2 from "./intergrationgrid/ScenarioIntegrationGridV2";
import { checkRole } from "../../helper/useUserData";

const { confirm } = Modal;

dayjs.locale("fr"); // Set the locale globally if needed
const { Panel } = Collapse;

const ScenarioListData = ({
  data,
  scenarioId,
  showConfirmDeleteScenarioItem,
  setCommentModel,
  setComment,
  setChangeTypeModal_Edit,
  setModuleName_ScenarioListData,
  setPortfolioName_ScenarioListData,
  setPortfolioItemID,
  setChangeTypeName,
  changeTypeData,
  setSelectedItemId,
  setRelationshipPortfolioId,
  setEditFlag,
  getAllScenariosItems,
  integrationGridData,
  handleBulkUpdate_new,
  ...props
}) => {
  const [scenarioItems, setScenarioItems] = useState([]);
  const navigateTo = useDynamicNavigate();
  const groupedData = (data) => {
    return data?.reduce((acc, obj) => {
      const { item_id: itemId, portfolioSettings } = obj;
      const name = portfolioSettings?.name;
      if (itemId && portfolioSettings?.portfolios) {
        acc[name] = acc[name] || [];
        acc[name].push(obj);
      }
      return acc;
    }, {});
  };

  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const divRef = useRef(null);
  const spanRef = useRef(null);
  const [wordsInLine, setWordsInLine] = useState(0);
  const [scenarioModal, setScenarioModal] = useState(false);
  const [chooseColumnModal, setChooseColumnModal] = useState(false);
  const [activePanel, setactivePanel] = useState([]);
  const [collapsedSections, setCollapsedSections] = useState({});
  const [activeIntegrationPanel, setActiveIntegrationPanel] = useState(1);
  const [selectedItemIDRows, setselectedItemIDRows] = useState([]);
  const [copyScenarioID, setCopyScenarioID] = useState();
  const [scenarioIntegrationImportModal, setScenarioIntegrationImportModal] =
    useState(false);

  //Filter
  const [showFilter, setShowFilter] = useState(false);
  const [filterData, setFilterData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [totalRecords, setTotalRecords] = useState(0);
  const scenarioName = localStorage.getItem("scenarioName");
  const [alreadySelectedRows, setAlreadySelectedRows] = useState([]);
  const [gridState, setGridState] = useState(true);

  const [freeColumnName, setFreeColumnName] = useState();

  useEffect(() => {}, [copyScenarioID]);

  useEffect(() => {
    if (data?.length > 0) {
      setScenarioItems(groupedData(data));
    }
  }, [data]);

  useEffect(() => {
    setactivePanel([]);

    fetchFreeColumn();

    localStorage.removeItem("activePanelScenario");

    const activeWindow = localStorage.getItem("activePanelScenario");
    const splitValues = activeWindow ? activeWindow.split(",") : [];
    if (activeWindow) {
      setactivePanel(splitValues);
    }

    const handleUnload = () => {
      localStorage.removeItem("activePanelScenario");
    };

    //Default Selected Column
    const selectedColumns = {
      freeColumns: [],
      fromIntegration: ["Integration Name", "Description"],
      sourceApplication: [],
      targetApplication: [],
    };
    let tempScenarios = JSON.stringify(selectedColumns);

    localStorage.setItem("Scenario_Itegration_Column", [tempScenarios]);

    //End  Default Selected Column

    return () => {
      window.addEventListener("beforeunload", handleUnload);
    };
  }, []);

  const fetchFreeColumn = async () => {
    try {
      const response = await IntegrationServices.get_columns();

      const newData = response.data.data;

      setFreeColumnName(newData);
    } catch (error) {
      console.error("Something went wrong");
    } finally {
    }
  };

  //   Group data of portfolios
  const groupAndExtractNames = (array) => {
    const result = {};

    array?.forEach(({ moduleName, mameinsourcesystem, displayname }) => {
      //if (moduleName==="BusinessCapability"){
      if (!result[moduleName]) {
        result[moduleName] = { list: [] };
      }

      // Push either NameInSourceSystem or DisplayName into the list array
      if (displayname) {
        result[moduleName].list.push(displayname);
      }
      //}
    });
    return result;
  };

  const WithChangeType = ({
    id = null,
    replaceWith = null,
    version = null,
    changeTypeId = null,
    replacedPortfolio = {},
    portfolios = {},
    portfolioName = {},
  }) => {
    const checkOperation = replaceWith
      ? 2
      : version
      ? 3
      : changeTypeId === 1
      ? 1
      : changeTypeId === 4
      ? 4
      : 5;
    return (
      <>
        <div className="fw-medium scenario_opt">
          {/* {checkOperation === 2
              ? "Replace with"
              : checkOperation === 3
              ? "Upgraded to"
              : checkOperation === 1
              ? "Retire"
              : "Retain"} */}
          {checkOperation === 2 ? (
            <span className="scenario-replacewith">Replace with</span>
          ) : checkOperation === 3 ? (
            <span className="scenario-upgradeto">Upgraded to</span>
          ) : checkOperation === 1 ? (
            <span className="scenario-retire">Retire</span>
          ) : checkOperation === 4 ? (
            <span className="scenario-retain">Retain</span>
          ) : checkOperation === 5 ? (
            <span></span>
          ) : (
            ""
          )}
        </div>

        <div>
          <div className="d-flex fs-6 align-items-center">
            {checkOperation === 2 ? (
              (replaceWith && replacedPortfolio && (
                <>
                  <div
                    className="h6 mb-0 cursor-pointer"
                    onClick={() => {
                      const payload = {
                        displayname: replacedPortfolio?.displayname || "",
                      };
                      onDynamicLinkClick(
                        replacedPortfolio?.id,
                        portfolioName,
                        payload
                      );
                    }}
                  >
                    {replacedPortfolio?.displayname}
                  </div>
                </>
              )) || <></>
            ) : checkOperation === 3 ? (
              (
                <>
                  <div
                    className="h6 mb-0 cursor-pointer"
                    onClick={() => {
                      const payload = {
                        displayname: portfolios?.displayname || "",
                      };
                      onDynamicLinkClick(
                        portfolios?.id,
                        portfolioName,
                        payload
                      );
                    }}
                  >
                    Version <span className="font-bold">{version}</span>
                  </div>
                </>
              ) || <></>
            ) : checkOperation === 1 ? (
              <>{/* <div className="h6 mb-0">Removed</div> */}</>
            ) : (
              <>{/* <div className="h6 mb-0">Retain</div> */}</>
            )}
            <span>&emsp;&emsp;</span>
            {/* <div className="h6 mb-0">
              <Button
                shape={`circle`}
                icon={<img src={DeleteSvg} alt={`TCP`} width={`20px`} />}
                onClick={async () => {
                  const { NameInSourceSystem: name, id: itemId } = portfolios;
                  await showConfirmDeleteScenarioItem({
                    id,
                    name,
                    itemId,
                    portfolioName,
                  });
                }}
              />
            </div> */}
          </div>
        </div>
      </>
    );
  };

  const ConnectedPortfoliosItems = ({ relationship }) => {
    const portfoliosItems = groupAndExtractNames(relationship);
    return (
      <>
        {Object.keys(portfoliosItems).map((name) => (
          <Row key={Math.random()}>
            <Col span={4}>
              <span className="fw-medium">{convertToNormalText(name)}</span> :
            </Col>
            <Col span={20}>
              <span>{portfoliosItems[name]?.list.join(",")}</span>
            </Col>
          </Row>
        ))}
      </>
    );
  };

  const onDynamicLinkClick = (id = null, moduleName = "", rest = {}) => {
    const redirectLink = dynamicRoutesLinks?.find(
      (item) => item?.type === moduleName
    );
    if (redirectLink?.link && redirectLink?.moduleName && id) {
      const newLink = redirectLink?.link + redirectLink?.moduleName + "/" + id;
      navigateTo(newLink, true);
    }
  };
  const ExpandableText = ({ text }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [isLongText, setIsLongText] = useState(false);
    const divRef = useRef(null);

    const toggleExpand = () => {
      setIsExpanded(!isExpanded);
    };

    useEffect(() => {
      // Split the text into words and check if it has more than 10 words
      const wordCount = text.split(" ").length;
      setIsLongText(wordCount >= 25);
    }, [text]);

    return (
      <div ref={divRef}>
        {isExpanded ? (
          <p>{text}</p>
        ) : (
          <p>
            {text.split("\n")[0]}{" "}
            {isLongText && (
              <span
                style={{ color: "blue", cursor: "pointer" }}
                onClick={toggleExpand}
              >
                Read more
              </span>
            )}
          </p>
        )}
        {isExpanded && isLongText && (
          <span
            style={{ color: "blue", cursor: "pointer" }}
            onClick={toggleExpand}
          >
            Show less
          </span>
        )}
      </div>
    );
  };

  return (
    <div className="scenariolistbx">
      {Object.keys(scenarioItems).map(
        (name) =>
          (name == "Integration" && (
            <Card
              key={name}
              title={
                <>
                  <Flex wrap gap="small" className="ml-2" align="center">
                    <div className="h4 mb-0">{name}</div>
                    <Button
                      icon={<img src={listSvg} alt="List" width={20} />}
                      style={{ width: "30px" }}
                      onClick={() => setActiveIntegrationPanel(1)}
                      {...(activeIntegrationPanel === 1
                        ? { type: "primary", ghost: true }
                        : { block: true })}
                    />
                    {/* <Button
                      icon={<img src={gridSvg} alt="Grid" width={20} />}
                      style={{ width: "30px" }}
                      onClick={() => {
                        setActiveIntegrationPanel(2);
                        setGridState(true);
                      }}
                      {...(activeIntegrationPanel === 2
                        ? { type: "primary", ghost: true }
                        : { block: true })}
                    /> */}

                    <Button
                      icon={<img src={matrixSvg} alt="Matrix" width={25} />}
                      style={{ width: "30px" }}
                      className="pb-1"
                      onClick={() => setActiveIntegrationPanel(3)}
                      {...(activeIntegrationPanel === 3
                        ? { type: "primary", ghost: true }
                        : { block: true })}
                    />

                    {checkRole('import') && <Button
                      type="primary"
                      onClick={() => {
                        setScenarioModal(true);
                      }}
                    >
                      Import from other Scenario
                    </Button>}
                    {checkRole('import') && <Button
                      type="primary"
                      onClick={() => {
                        setChooseColumnModal(true);
                      }}
                    >
                      Choose Columns
                    </Button>}
                  </Flex>
                </>
              }
              style={{
                background: `${scenarioItems[name][0]?.portfolioSettings?.color}`,
                marginBottom: "20px",
              }}
              headStyle={{
                backgroundColor: `${scenarioItems[name][0]?.portfolioSettings?.color}`,
                padding: "10px 10px",
              }}
            >
              {
                activeIntegrationPanel === 1 ? (
                  <ScenarioIntegrationGrid
                    ScenarioId={scenarioId}
                    moduleID={9} //passed fixed integration id
                    copyScenarioID={copyScenarioID}
                    freeColumnName={freeColumnName}
                    integrationGridData={integrationGridData}
                    handleBulkUpdate_new={handleBulkUpdate_new}
                  />
                ) : activeIntegrationPanel === 3 ? (
                  <ScenarioIntegrationGridV2
                    scenarioId={scenarioId}
                    scenarioModuleName={`scenarios_integrations`}
                    moduleName={"Integration"}
                    grouppingFilter={{
                      grouping_1: "BusinessProcesses",
                    }}
                  />
                ) : (
                  <></>
                )

                // ((()=>{
                //   switch (activeIntegrationPanel) {
                //     case 1:
                //       return <React.Fragment key={Math.random()}>
                //         <ScenarioIntegrationGrid
                //           ScenarioId={scenarioId}
                //           moduleID={9}
                //           copyScenarioID={copyScenarioID}
                //           freeColumnName={freeColumnName}
                //         />
                //       </React.Fragment>
                //     case 2:
                //       return <React.Fragment key={Math.random()}>
                //         <div className="overflow-y-auto">
                //           {/* <IntegrationGrid
                //             ScenarioId={scenarioId}
                //             moduleID={9}
                //             copyScenarioID={copyScenarioID}
                //             freeColumnName={freeColumnName}
                //           /> */}
                //         </div>
                //       </React.Fragment>
                //     case 3:
                //       return <React.Fragment key={Math.random()}>
                //         <ScenarioIntegrationGridV2
                //           scenarioId={scenarioId}
                //           scenarioModuleName={`scenarios_integrations`}
                //           moduleName={"Integration"}
                //           grouppingFilter={{
                //             grouping_1:"BusinessProcesses"
                //           }}
                //         />
                //       </React.Fragment>
                //     default:
                //       return <React.Fragment></React.Fragment>;
                //   }
                // })())
              }
            </Card>
          )) || (
            <Card
              key={name}
              title={<span className="h4">{name}</span>}
              style={{
                background: `${scenarioItems[name][0]?.portfolioSettings?.color}`,
                marginBottom: "20px",
              }}
              headStyle={{
                backgroundColor: `${scenarioItems[name][0]?.portfolioSettings?.color}`,
                padding: "10px 10px",
              }}
            >
              <React.Fragment key={Math.random()}>
                <div className="overflow-y-auto">
                  {scenarioItems[name]?.map((item, index) => {
                    const {
                      portfolioSettings,
                      id,
                      comment,
                      deadline,
                      replace_with: replaceWith,
                      version,
                      change_type_id: changeTypeId,
                    } = item;
                    const {
                      portfolios,
                      replacedPortfolio,
                      NameInSourceSystem,
                      moduleName,
                      relationship = [],
                    } = portfolioSettings;
                    return (
                      <Collapse
                        key={Math.random()}
                        expandIconPosition="start"
                        bordered={true}
                        className="settings-menus"
                        activeKey={activePanel}
                        onChange={(activeKeys) => {
                          setCollapsedSections(
                            activeKeys.reduce(
                              (acc, key) => ({ ...acc, [key]: false }),
                              {}
                            )
                          );
                          setactivePanel(activeKeys);
                          localStorage.setItem(
                            "activePanelScenario",
                            activeKeys
                          );
                          console.log("activePanelScenario", activeKeys);
                        }}
                      >
                        <Panel
                          key={id}
                          header={
                            <div className="card-header">
                              <div className="d-flex align-items-center">
                                <div>
                                  <h6
                                    className="h6 mb-0 cursor-pointer"
                                    onClick={() => {
                                      const payload = {
                                        displayname: portfolios?.displayname,
                                      };
                                      onDynamicLinkClick(
                                        item?.item_id,
                                        moduleName,
                                        payload
                                      );
                                    }}
                                  >
                                    {portfolios?.displayname}
                                  </h6>
                                </div>
                                <WithChangeType
                                  id={id}
                                  replaceWith={replaceWith}
                                  version={version}
                                  changeTypeId={changeTypeId}
                                  replacedPortfolio={replacedPortfolio}
                                  portfolios={portfolios}
                                  portfolioName={moduleName}
                                />
                              </div>
                              <div className="btnbx">
                                {checkRole('update') && <Tooltip title="Edit">
                                  <Button
                                    shape={`circle`}
                                    icon={
                                      <img
                                        src={EditSvg}
                                        alt={`TCP`}
                                        width={`20px`}
                                      />
                                    }
                                    onClick={async () => {
                                      setChangeTypeModal_Edit(true);
                                      setModuleName_ScenarioListData(
                                        moduleName
                                      );
                                      setPortfolioName_ScenarioListData(name);
                                      setSelectedItemId(item.item_id);
                                      setEditFlag(true);
                                      setRelationshipPortfolioId(
                                        item.portfolio_settings_id
                                      );

                                      let data = {};

                                      data["changeTypeId"] =
                                        item.change_type_id;
                                      data["comment"] = item.comment;

                                      data["version"] = item.version;
                                      data["replaceWith"] =
                                        item.portfolioSettings?.replacedPortfolio?.displayname;
                                      data["portfolios"] =
                                        item.portfolioSettings?.portfolios?.displayname;
                                      data["portfoliosID"] =
                                        item.portfolioSettings?.portfolios?.id;
                                      data["id"] = item.id;

                                      //data["deadline"]=item.deadline;

                                      if (item.deadline) {
                                        let formattedDate = moment(
                                          item.deadline
                                        ).format("YYYY-MM-DD");
                                        //let formattedDate = moment(item.deadline).format('DD-MM-YYYY');
                                        data["deadline"] = dayjs(formattedDate);
                                      }

                                      setPortfolioItemID(data);

                                      dispatchTag({
                                        type: "SET_MODEL_DATA",
                                        payload: data,
                                      });
                                      const changeTypeName =
                                        changeTypeData.find(
                                          (type) =>
                                            type.id == item.change_type_id
                                        );
                                      setChangeTypeName(changeTypeName.name);
                                    }}
                                  />
                                </Tooltip>}

                                {checkRole('delete') && <Tooltip title="Delete">
                                  <Button
                                    shape={`circle`}
                                    icon={
                                      <img
                                        src={DeleteSvg}
                                        alt={`TCP`}
                                        width={`20px`}
                                      />
                                    }
                                    onClick={async () => {
                                      const { displayname: name, id: itemId } =
                                        portfolios;
                                      await showConfirmDeleteScenarioItem({
                                        id,
                                        name,
                                        itemId,
                                        portfolioName: moduleName,
                                      });
                                    }}
                                  />
                                </Tooltip>}
                              </div>
                            </div>
                          }
                          style={{ width: "100%" }}
                          className="panel"
                        >
                          <div key={index} className="card mb-2">
                            {/* <div className="card-header">
                              <div className="d-flex align-items-center">
                                <div>
                                  <h6
                                    className="h6 mb-0 cursor-pointer"
                                    onClick={() => {
                                      const payload = {
                                        displayname: portfolios?.displayname,
                                      };
                                      onDynamicLinkClick(
                                        item?.item_id,
                                        moduleName,
                                        payload
                                      );
                                    }}
                                  >
                                    {portfolios?.displayname ||
                                      portfolios?.nameinsourcesystem}
                                  </h6>
                                </div>
                                <WithChangeType
                                  id={id}
                                  replaceWith={replaceWith}
                                  version={version}
                                  changeTypeId={changeTypeId}
                                  replacedPortfolio={replacedPortfolio}
                                  portfolios={portfolios}
                                  portfolioName={moduleName}
                                />
                              </div>
                            </div> */}
                            {/* {relationship?.length > 0 && (
                          <div className="flex  flex-col gap-2 card-header bg-white">
                            <ConnectedPortfoliosItems relationship={relationship} />  
                          </div>
                        )} */}

                            <div className="card-body">
                              <Row>
                                <Col span={3}>
                                  <span className="fw-medium">Deadline </span>:
                                </Col>
                                <Col span={21}>
                                  {deadline &&
                                    moment(deadline).format("YYYY-MM-DD")}
                                </Col>

                                <Col span={3}>
                                  <span className="fw-medium">Comment</span>:
                                </Col>
                                <Col span={21}>
                                  <ExpandableText text={comment} />
                                </Col>
                              </Row>
                              <div className="scenario_listbtn d-none">
                                <div className="btnbx">
                                  {/* <button className="btn btn-sm btn-dark mr-2">
                                    Impact Analysis
                                  </button>
                                  <button className="btn btn-sm btn-dark mr-2">
                                    Change Management
                                  </button> */}
                                </div>

                                {/* <div className="btnbx">
                                  <Tooltip title="Edit">
                                    <Button
                                      shape={`circle`}
                                      icon={
                                        <img
                                          src={EditSvg}
                                          alt={`TCP`}
                                          width={`20px`}
                                        />
                                      }
                                      onClick={async () => {
                                        setChangeTypeModal_Edit(true);
                                        setModuleName_ScenarioListData(
                                          moduleName
                                        );
                                        setPortfolioName_ScenarioListData(name);
                                        setSelectedItemId(item.item_id);
                                        setEditFlag(true);
                                        setRelationshipPortfolioId(
                                          item.portfolio_settings_id
                                        );

                                        let data = {};

                                        data["changeTypeId"] =
                                          item.change_type_id;
                                        data["comment"] = item.comment;

                                        data["version"] = item.version;
                                        data["replaceWith"] =
                                          item.portfolioSettings?.replacedPortfolio?.displayname;
                                        data["portfolios"] =
                                          item.portfolioSettings?.portfolios?.displayname;
                                        data["portfoliosID"] =
                                          item.portfolioSettings?.portfolios?.id;
                                        data["id"] = item.id;

                                        //data["deadline"]=item.deadline;

                                        let formattedDate = moment(
                                          item.deadline
                                        ).format("YYYY-MM-DD");
                                        //let formattedDate = moment(item.deadline).format('DD-MM-YYYY');
                                        data["deadline"] = dayjs(formattedDate);

                                        setPortfolioItemID(data);

                                        dispatchTag({
                                          type: "SET_MODEL_DATA",
                                          payload: data,
                                        });
                                        const changeTypeName =
                                          changeTypeData.find(
                                            (type) =>
                                              type.id == item.change_type_id
                                          );

                                        setChangeTypeName(changeTypeName.name);
                                      }}
                                    />
                                  </Tooltip>

                                  <Tooltip title="Delete">
                                    <Button
                                      shape={`circle`}
                                      icon={
                                        <img
                                          src={DeleteSvg}
                                          alt={`TCP`}
                                          width={`20px`}
                                        />
                                      }
                                      onClick={async () => {
                                        const {
                                          displayname: name,
                                          id: itemId,
                                        } = portfolios;
                                        await showConfirmDeleteScenarioItem({
                                          id,
                                          name,
                                          itemId,
                                          portfolioName: moduleName,
                                        });
                                      }}
                                    />
                                  </Tooltip>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </Panel>
                      </Collapse>
                    );
                  })}
                </div>
              </React.Fragment>
            </Card>
          )
      )}

      {/* ScenarioListSelection */}

      <Modal
        destroyOnClose
        title={false}
        header={false}
        open={scenarioModal}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setScenarioModal(false);
          getAllScenariosItems();
          // setShowFilter(false);
          // setFilterData([]);
          // setOpenPortfolioList(false);
          // setSelectedModuleItems({});
          // setSelectedFilters({});
        }}
        width={window.innerWidth / 1.3}
        className="cu-modal-p-0"
        closable
        centered
      >
        <div className="m-4 my-2">
          <ScenarioListSelection
            setselectedItemIDRows={setselectedItemIDRows}
            setShowFilter={setShowFilter}
            scenarioId={scenarioId}
            setScenarioModal={setScenarioModal}
            getAllScenariosItems={getAllScenariosItems}
            alreadySelectedRows={alreadySelectedRows}
            setAlreadySelectedRows={setAlreadySelectedRows}
            setCopyScenarioID={setCopyScenarioID}
            setActiveIntegrationPanel={setActiveIntegrationPanel}
            setScenarioIntegrationImportModal={
              setScenarioIntegrationImportModal
            }
          />
        </div>
      </Modal>

      <Modal
        destroyOnClose
        title={false}
        header={false}
        open={scenarioIntegrationImportModal}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setScenarioIntegrationImportModal(false);

          //getAllScenariosItems();
          // setShowFilter(false);
          // setFilterData([]);
          // setOpenPortfolioList(false);
          // setSelectedModuleItems({});
          // setSelectedFilters({});
        }}
        width={window.innerWidth / 1.3}
        className="cu-modal-p-0"
        closable
        centered
      >
        <div className="m-4 my-2">
          <IntegrationGridImport
            setScenarioIntegrationImportModal={
              setScenarioIntegrationImportModal
            }
            ScenarioId={scenarioId}
            moduleID={9} //passed fixed integration id
            copyScenarioID={copyScenarioID}
            freeColumnName={freeColumnName}
            setselectedItemIDRows={setselectedItemIDRows}
            setShowFilter={setShowFilter}
            scenarioId={scenarioId}
            setScenarioModal={setScenarioModal}
            getAllScenariosItems={getAllScenariosItems}
            alreadySelectedRows={alreadySelectedRows}
            setAlreadySelectedRows={setAlreadySelectedRows}
            setCopyScenarioID={setCopyScenarioID}
            setActiveIntegrationPanel={setActiveIntegrationPanel}
            handleBulkUpdate_new={handleBulkUpdate_new}
          />
        </div>
      </Modal>

      <Modal
        title={false}
        header={false}
        open={chooseColumnModal}
        footer={false}
        maskClosable={false}
        onCancel={() => {
          setChooseColumnModal(false);
          //getAllScenariosItems();
          // setShowFilter(false);
          // setFilterData([]);
          // setOpenPortfolioList(false);
          // setSelectedModuleItems({});
          // setSelectedFilters({});
          handleBulkUpdate_new();
        }}
        width={window.innerWidth / 1.3}
        className="cu-modal-p-0"
        closable
        centered
      >
        <div className="m-4 my-2 py-4">
          <h2> Choose Columns</h2>
          <ColumnSelector handleBulkUpdate_new={handleBulkUpdate_new} />
        </div>
      </Modal>
    </div>
  );
};

export default ScenarioListData;
