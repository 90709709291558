import { encodeQueryData } from "../../utils/Common";
import http from './httpService';
const API_BASE_URL = process.env.REACT_APP_API_URL

async function getAll({ limit = 20, page = 1 }) {
    const url = `${API_BASE_URL}integration?page=${page}&limit=${limit}`;
    let response = await http.get(url);
    return response;
  }

  
// filterOrder
async function getList(filterBy, filter_ByText, filter_levels,filterOrder) {
    const url = `${API_BASE_URL}integration/filter`;
    let filter;

    if (filterOrder === "Level,ASC"){
        filter = [["Level","ASC"]]
    }else if (filterOrder === "Level,DESC"){
        filter = [["Level","DESC"]]
    }else if (filterOrder === "name,ASC"){
        filter = [["DisplayName","ASC"]]
    }else if (filterOrder === "name,DESC"){
        filter = [["DisplayName","DESC"]]
    }else {
        console.log("wrong input");
    }

    const requestData = {
        [filterBy]: filter_ByText,
        "level": filter_levels,
        "sortBy":filter
    };

    const response = await http.post(url, requestData);
    return response;
}


async function create(data) {
    const url = `${API_BASE_URL}integration`;
    let response = await http.post(url, data);
    return response;
}

async function bulkCreate(data) {
    const url = `${API_BASE_URL}integration/bulk`;
    let response = await http.post(url, data);
    return response;
}

async function bulkUpdate(data) {
    const url = `${API_BASE_URL}integration/bulk`;
    let response = await http.patch(url, data);
    return response;
}

async function update(data) {
    const id = data.id;
    delete data.id;
    const body = data;
    const url = `${API_BASE_URL}integration/`+id;
    
    let response = await http.put(url, body);
    return response;
}

async function deleteItem(id) {
    const url = `${API_BASE_URL}integration/`;
    let response = await http.delete(url + id);
    return response;
}

async function getListByID(id) {
    const url = `${API_BASE_URL}integration/`;
    let response = await http.get(url + id);
    return response;
}

async function bulkGet(data) {
    const url = `${API_BASE_URL}integration/bulk_get`;
    let response = await http.post(url, data);
    return response;
}



async function add_newcolumn(obj) {
    const url = `${API_BASE_URL}integration/add_newcolumn`;
    let response = await http.post(url, obj);
    return response;
}

async function get_columns(obj) {
    const url = `${API_BASE_URL}integration/get_columns`;
    let response = await http.post(url, obj);
    return response;
}


const IntegrationServices = {
    getList,
    bulkGet,
    getListByID,
    getAll,
    create,
    bulkCreate,
    bulkUpdate,
    update,
    deleteItem,
    add_newcolumn,
    get_columns

}


export default IntegrationServices;
