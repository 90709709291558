import React, { useEffect, useState } from "react";
import { useSaveState } from "../../../Context/StateProviderData";
import { usePortfolioSetting } from "../../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../../hooks/usePortfolioFetcher";
import {
  PageTitleHeading,
  debounceTimeOut,
  default_pageSize,
  default_pageSizeOptions,
  getSelectProperties,
  removeEmptyArrObjOrval,
  removeEmptyKeys,
} from "../../../utils/Common";
import { getMenuPortfolio } from "../../../utils/settingCommon";
import useDebounce from "../../../helper/useDebounce";
import PortfolioSettingServices from "../../../services/services/PortfolioSettingsService";
import { Layout, Modal, Pagination, Spin, message } from "antd";
import BusinessProcessListContent from "../../businessProcess/list/BusinessProcessListContent";
import BusinessProcessListFilter from "../../businessProcess/list/BusinessProcessListFilter";

export const PortfolioListModal = ({
  open,
  setOpen,
  selectedApps,
  Buttons,
  moduleName: routeModuleName,
  title,
  ...props
}) => {
  const { state, setState } = useSaveState();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();
  const { fetchPortfolio } = usePortfolioFetcher();
  const [portfolioData, setPortfolioData] = useState({});
  const [portfolioProperties, setPortfolioProperties] = useState([]);

  const [showFilter, setShowFilter] = useState(false);

  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(default_pageSize);
  const [lists, setLists] = useState([]);

  const [selectedFilters, setSelectedFilters] = useState({});
  const [selectedRelationFilters, setSelectedRelationFilters] = useState({});
  const [loading, setLoading] = useState(true);
  const [filterChanges, setFilterChanges] = useState(false);

  // const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  // const { loading: tagLoading, error: tagError } = stateTag;
  // const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();

  useEffect(() => {
    if (portfolioSettingState?.data?.length > 0) {
      let md = getMenuPortfolio({ routeModuleName });
      setPortfolioData(md);
      setPortfolioProperties(getSelectProperties(md?.portfolioSections, true));
    } else {
      fetchAllPortfolio();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioSettingState]);

  const fetchAllPortfolio = async () => {
    try {
      await fetchPortfolio(portfolioSettingDispatch, 1, 100);
    } catch (error) {
      console.error("Error fetching portfolio:", error);
    }
  };

  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);
  useEffect(() => {
    if (Object.keys(portfolioData)?.length > 0) {
      fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedFilter, page, limit, portfolioData]);

  const fetchFilteredData = async (filter) => {
    try {
      setLoading(true);
      if (portfolioSettingState?.data?.length) {
        filter = removeEmptyKeys(filter);

        const updatedFilter = {
          moduleName: portfolioData?.moduleName,
          ...filter,
        };

        const response = await PortfolioSettingServices?.getFilteredPortfolios(
          updatedFilter,
          page,
          limit
        );
        if (response?.data?.data?.length) {
          setTimeout(() => {
            setLoading(false);
            setLists(response?.data?.data);
            props?.setItems(response?.data?.data);
            setTotalRecords(response?.data?.totalCount || 0);
          }, 500);
        } else {
          //message.info("No records found.");
          setTimeout(() => {
            setLists(response?.data?.data);
            setTotalRecords(response?.data?.totalCount || 0);
            setFilterChanges(true);
          }, 500);
        }

        if (response?.data?.data?.length > 0 && props?.isSetState) {
          if (state[portfolioData?.moduleName]?.length > 0) {
            let items = [
              ...new Map(
                [
                  ...state[portfolioData?.moduleName],
                  ...response?.data?.data,
                ].map((item) => [item["id"], item])
              ).values(),
            ];
            updateLocale({ key: portfolioData?.moduleName, values: items });
          } else {
            let items = [
              ...new Map(
                [...response?.data?.data].map((item) => [item["id"], item])
              ).values(),
            ];
            updateLocale({ key: portfolioData?.moduleName, values: items });
          }
        }
      }
    } catch (error) {
      setLists([]);
      setTotalRecords(0);
      setFilterChanges(true);
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  const updateLocale = ({ key, values }) => {
    if (state[key]?.length > 0) {
      let a1 = state[key]?.map((item) => {
        return item?.id;
      });
      let a2 = values?.map((item) => {
        return item?.id;
      });

      let filters = a2.filter((n) => !a1.includes(n));
      if (filters?.length > 0) {
        setState({
          key,
          value: [
            ...new Map(
              [...state[key], ...values].map((item) => [item["id"], item])
            ).values(),
          ],
        });
      }
    } else {
      setState({ key, value: values });
    }
  };

  return (
    <Modal
      // title={
      //     <div className="d-flex justify-content-between mb-2 modelpopup_bx">
      //         <div>
      //             <PageTitleHeading
      //                 text={title || portfolioData?.name + " List"}
      //                 className={`my-0`}
      //             />
      //         </div>
      //     </div>
      // }
      centered
      open={open}
      onOk={() => setOpen(!open)}
      onCancel={() => {
        setPage(1);
        setLimit(default_pageSize);
        setSelectedFilters({});
        setOpen(!open);
      }}
      width={props?.modalWidth}
      footer={false}
      closeIcon={true}
    >
      <Layout className="modelpopup_bx">
        <Layout.Content className="popup_leftpnlbx">
          <Spin className="loading_bx" size="small" spinning={loading}>
            <div className="container-fluid px-0">
              <div className="justify-content-between mb-2 popup_headerbx">
                <PageTitleHeading
                  text={title || portfolioData?.name + " List"}
                  className={`my-0`}
                />
              </div>
              {/* <div className='overflow-y pr-2 listview_bx' style={{maxHeight:props?.modalHeight}}> */}
              <div className="overflow-y pr-2 listview_bx">
                <BusinessProcessListContent
                  lists={lists}
                  ButtonComponents={Buttons}
                  loading={loading}
                  moduleName={portfolioData?.routeModuleName}
                  DisableItemClass={props?.DisableItemClass}
                  basePath={props?.moduleData?.route}
                />
              </div>
              {totalRecords > 10 && (
                <Pagination
                  className="mt-2 text-right"
                  total={totalRecords}
                  showTotal={(total) => `Total ${total} items`}
                  defaultPageSize={default_pageSize}
                  defaultCurrent={page}
                  pageSizeOptions={default_pageSizeOptions}
                  showSizeChanger
                  onChange={(p, ps) => {
                    setLimit(ps);
                    setPage(p);
                  }}
                />
              )}
            </div>
          </Spin>
        </Layout.Content>
        <Layout.Sider
          theme="light"
          width={!showFilter ? 50 : 240}
          className="popup_rightpnlbx"
        >
          <BusinessProcessListFilter
            activeGridStyle={1}
            selectedFilters={selectedFilters}
            data={portfolioProperties || []}
            setShowFilter={setShowFilter}
            showFilter={showFilter}
            setSelectedFilters={(value) => {
              setPage(1);
              setSelectedFilters(value);
            }}
            allowSlider={false}
            optionalLevel={("BusinessCapability" === routeModuleName || "BusinessProcesses" === routeModuleName)}
            maxHeight={props?.modalHeight}
            minHeight={props?.modalHeight - 300}
            selectedRelationFilters={selectedRelationFilters}
            setSelectedRelationFilters={setSelectedRelationFilters}
            isSourceTargetModuleName={portfolioData?.moduleName}
            isRelationPopup={true}
          />
        </Layout.Sider>
      </Layout>
    </Modal>
  );
};
PortfolioListModal.defaultProps = {
  modalWidth: window?.innerWidth - 100,
  modalHeight: window?.innerHeight - 300,
  Buttons: () => {},
  isSetState: true,
  setItems: () => {},
};

export default PortfolioListModal;
