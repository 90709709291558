import React from "react";
import { DeleteOutlined, CopyOutlined, CheckOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import { useApplicationlink } from "../../Context/linkProvider";
import { useMenu } from "../../Context/MenuProvider";
import Delayed from "../../utils/Delayed";
import { getDynamicRoutesLink } from "../../utils/portfolioRelationshipCommon";
import { Flex, Typography, Checkbox } from "antd";
import { PortfolioItemDetails } from "../portfolio/common/PortfolioCommon";
import { getMenuPortfolio } from "../../utils/settingCommon";
import { checkRole } from "../../helper/useUserData";

const ApplicationCapabilityList = ({ page,limit,data, moduleName, loading,basePath, ...props }) => {
  // console.log("data--->", moduleName);
  const Row = ({ item, isLast }) => {
    const [visible, setVisible] = React.useState(false);
    const navigate = useNavigate();
    const { state, dispatch } = useApplicationlink();
    const { stateMenu, dispatchMenu } = useMenu();

    const toggleVisibility = async () => {
      console.log("item-data", item);
      setVisible(!visible);
      await dispatch({ type: "EMPTY_LINK" });
      //Will use in feature
      // const query = new URLSearchParams({
      //   modelId: item?.id,
      //   modelName: applicationsList.businessApplication,
      // }).toString();
      await dispatch({ type: "SET_BUSINESSAPPLICATIONID", payload: item?.id });
      await dispatch({
        type: "SET_BUSINESS_APPLICATION_MODEL",
        //payload: applicationsList.businessApplication,
        payload: stateMenu?.MenuID?.moduleName,
      });
      // navigate(`/application-list/${moduleName}/${item?.id}`, { state: {} });
    };
     
    // console.log(item)
    return (
      <div className="portfolio-listbx">
        <table width={`100%`}>
          <tbody>
            <tr>
            {props?.isListCheckbox && checkRole('checkbox') && <td>
                                    <Checkbox value={item?.id} onChange={props?.onCheckboxChange}/>
                                </td>
                            }
              <td width={`95%`}>
                <PortfolioItemDetails 
                  routeModuleName={moduleName} 
                  item={item}  
                  defaultActiveKey={1} 
                  onClick={(item)=>{ toggleVisibility() }} 
                  badgeValue={ item?.referenceid}
                  page={page}
                  limit={limit}
                  basePath={basePath}
                />
              </td>
              <td width={`5%`} className="text-nowrap">
                <Flex gap={8}>
                  <Typography.Text 
                    copyable={{ 
                      text: window.location.origin+getMenuPortfolio({routeModuleName:moduleName,getRouteByRouteModule:true})+"/"+item?.id ,
                      icon:[<CopyOutlined style={{ fontSize: 20 }}/>,<CheckOutlined style={{ fontSize: 20 }}/>],
                      tooltips:['Copy Url','Copied'],
                    }}
                  />
                 {checkRole('delete') && <DeleteOutlined
                      className="cursor-pointer ml-2 text-danger"
                      onClick={() => {
                        props?.setItem(item, { type: "delete" });
                      }}
                      style={{ fontSize: "20px" }}
                    />   }             
                </Flex>
                {/* {visible ? (
                  <>
                    <DeleteOutlined
                      className="cursor-pointer ml-2 text-danger"
                      onClick={() => {}}
                      style={{ fontSize: "20px" }}
                    />
                  </>
                ) : (
                  <>
                    <DeleteOutlined
                      className="cursor-pointer ml-2 text-danger"
                      onClick={() => {
                        props?.setItem(item, { type: "delete" });
                      }}
                      style={{ fontSize: "20px" }}
                    />
                  </>
                )} */}
              </td>
            </tr>
          </tbody>
        </table>
        {/* <Divider className="my-2 bg-[#f7f7f7]" /> */}
        {/* {isLast && <Divider className="my-4 bg-[#f7f7f7]" />} */}
      </div>
    );
  };

  return (
    <div
      className="overflow-y pr-3 listview_bx"
      style={{ maxHeight: window?.innerHeight - 100, width:'100%' }}
    >
      {(data?.length > 0 &&
        data?.map((item, index) => (
          <Row key={index} item={item} isLast={index === data.length - 1} />
        ))) || (
        <Delayed waitBeforeShow={2000}>
          <div
            className={`text-base font-bold mt-4 ${loading ? "d-none" : ""}`}
          >
            No items found.
          </div>
        </Delayed>
      )}
    </div>
  );
};

export default ApplicationCapabilityList;
