import React from 'react';
import './fishbone.scss';
import Draggable from 'react-draggable';
import { getMenuPortfolio } from '../../../utils/settingCommon';
import { ArrowRightOutlined } from '@ant-design/icons';

let legends = [
    {color:"#D21C21",text:"Retire"},
    {color:"#1285FD",text:"Replace"},
    {color:"#4EC628",text:"Add"},
    {color:"#BB29B5",text:"Change Version"},
];

let lists = [
    {
        title_1:"Jan",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Feb",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Mar",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Apr",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Vcats -> One Engine",
                color:"#1285FD",
            },
            {
                title:"(App) Bartender",
                color:"#D21C21",
            },
        ],
    },
    {
        title_1:"May",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Jun",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Jul",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Profi -> V2",
                color:"#BB29B5",
            },
            {
                title:"(App) Pos -> TOCC",
                color:"#BB29B5",
            },
        ],
    },
    {
        title_1:"Aug",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Sep",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Anaqua",
                color:"#4EC628",
            },
            {
                title:"(App) Insider Log",
                color:"#4EC628",
            },
        ],
    },
    {
        title_1:"Oct",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Percipio",
                color:"#4EC628",
            },
            {
                title:"(App) Die",
                color:"#D21C21",
            },
        ],
    },
    {
        title_1:"Nov",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Dec",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
    {
        title_1:"Jan",
        title:"2025",
        color:"#BB29B5",
        children:[
            {
                title:"(App) Tidinfo -> UKG",
                color:"#1285FD",
            },
            {
                title:"(App) Fieldglass",
                color:"#D21C21",
            },
        ],
    },
    {
        title_1:"Feb",
        title:"2025",
        color:"#BB29B5",
        children:[],
    },
]

function FishboneChart({legends,lists,tbdData,...props}) {

    let fishboneChartStyle = {
        "--chart-height":props?.fishboneChartStyle?.height || `${window?.innerHeight/1.25}px`,
        "--chart-width":props?.fishboneChartStyle?.width || window?.innerWidth,
        ...props?.fishboneChartStyle,
    }

    let fishboneChartInnerStyle = {
        "--chart-height":props?.fishboneChartInnerStyle?.height || `${window?.innerHeight/1.25}px`,
        "--chart-width":props?.fishboneChartInnerStyle?.width || "100%",
        ...props?.fishboneChartInnerStyle,
    }

    let GetLinks = ({child}) =>{
        let getMenu = getMenuPortfolio({moduleName:child?.moduleName});

        return <React.Fragment>
            <a href={`${window?.location?.origin}${getMenu?.route}/${child?.title_id}`} rel="nofollow noreferrer" target="_blank" title={`${child?.name ? `(${child?.name})`:``} ${child?.title}`}>{ child?.name ? `${child?.name}`:`` }<br/> {child?.title}</a>
            {
                child?.title_1_id && <React.Fragment>
                    <i className='text-nowrap'>&emsp;{`->`}&emsp;</i>
                    <a href={`${window?.location?.origin}${getMenu?.route}/${child?.title_1_id}`} rel="nofollow noreferrer" target="_blank" title={`${child?.name ? `${child?.name}`:``} ${child?.title_1}`}>{ child?.name ? `(${child?.name})`:`` }<br/> {child?.title_1}</a>
                </React.Fragment>
            }
        </React.Fragment>
    }

    return (
        <React.Fragment>
            <div className='fishbone-chart' style={fishboneChartStyle}>
                {
                    legends?.length>0 && 
                    <div className='fishbone-chart-legend'>
                        <ul>
                            {
                                legends?.map(legend=>{
                                    let styles={
                                        "--ligend-color":legend?.color
                                    }
                                    return <li key={`leg-${Math.random()}`} style={styles}>{legend?.text}</li>
                                })
                            }
                        </ul>
                    </div>
                }
                <Draggable>
                    <div className="fishbone-chart-inner" style={fishboneChartInnerStyle}>
                        {   
                            tbdData?.length>0 && <div className='time-not-defined-data'>
                                <ol>
                                    <li>Timeline Not Defined</li>
                                    {
                                        tbdData?.map(child=>{
                                            return <li key={`list-child-${Math.random()}`} style={{"--dot-color": child?.sourceitemtypeColor}}>
                                                <span className='fishbone-link' style={{"--fishbonelink-color":child?.color}}>
                                                    <GetLinks child={child}/>
                                                </span>
                                            </li>
                                        })
                                    }
                                </ol>
                            </div>
                        }
                        {
                            lists?.length>0 && <ol>
                                {
                                    lists?.map(list=>{
                                        return <li key={`list-${Math.random()}`}>
                                            <span className='content content-top'>{list?.deadline_month}</span>
                                            <span className='content'>{list?.deadline_year}</span>
                                            <ol>
                                                {
                                                    list?.children?.map(child=>{
                                                        return <li key={`list-child-${Math.random()}`} style={{"--dot-color": child?.sourceitemtypeColor}}>
                                                            <span className='content fishbone-link' style={{"--fishbonelink-color":child?.color}}>
                                                                <GetLinks child={child}/>
                                                            </span>
                                                        </li>
                                                    })
                                                }
                                            </ol>
                                        </li>
                                    })
                                }
                            </ol>
                        }
                    </div>
                </Draggable>
            </div>
        </React.Fragment>
    );
}

FishboneChart.defaultProps = {
    legends:[],
    lists:[],
    tbdData:[],
}

export default FishboneChart;
