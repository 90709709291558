import "./ApplicationList.scss";
import React, { useEffect, useState,useRef } from "react";
import { Spin, message, Tooltip, Button, Flex, Pagination } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router";
import ApplicationCapabilityList from "./ApplicationCapabilityList";
import BusinessApplicationServices from "../../services/services/BusinessApplicationService";
import { useBusinessApplication } from "../../Context/businessApplication";
import ActionsBtn from "./ActionsBtn";
import listSvg from "./../../assets/List.svg";
import gridSvg from "./../../assets/Two columns layout.svg";
import matrixSvg from "./../../assets/Matrix.svg";
import ApplicationCapabilityGrid from "./ApplicationCapabilityGrid";
import ApplicationCapabilityMatrix from "./ApplicationCapabilityMatrix";
import { usePortfolioSetting } from "../../Context/portfolioSettingProvider";
import usePortfolioFetcher from "../../hooks/usePortfolioFetcher";
import { staticSettingPortfolioList } from "../../utils/settingCommon";
import {
  PageTitleHeading,
  debounceTimeOut,
  deepFindByName,
  default_pageSize,
  default_pageSizeOptions,
  getSelectProperties,
  removeEmptyArrObjOrval,
  removeEmptyKeys,
} from "../../utils/Common";
import FilterRightSideBar from "./filter/FilterRightSideBar";
import useDebounce from "../../helper/useDebounce";
import PortfolioSettingServices from "../../services/services/PortfolioSettingsService";
import { useParams } from "react-router-dom";
import LoadingBar from 'react-top-loading-bar'
import { usePortfolioTag } from "../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../hooks/useFetchPortfolioTag";
import UpdateGlobalState from "../portfolio/common/UpdateGlobalState";

const lifeCycleStagesColors = {
  active: "#FFB381",
  phaseIn: "#FFF280",
  phaseOut: "#FC819E",
  endOfLife: "#B2B377",
  color5: "#B5C0D0",
  color6: "#BFEF82",
  color7: "#90DDF9",
};

const ApplicationList = () => {
  const ref = useRef(null)
  const {updateLocale} = UpdateGlobalState();
  const { loading: PortfolioLoading, fetchPortfolio } = usePortfolioFetcher();
  const { state: portfolioSettingState, dispatch: portfolioSettingDispatch } =
    usePortfolioSetting();
  const navigate = useNavigate();
  const { state, dispatch } = useBusinessApplication();
  const [activeGridStyle, setActiveGridStyle] = useState(1);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalRecords, setTotalRecords] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(default_pageSize);
  const [showFilter, setShowFilter] = useState(false);
  const [portfolioData, setPortfolioData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState({});
  const [swimLaneSelectedFilters, setSwimLaneSelectedFilters] = useState({});
  let { moduleName: moduleNamePath } = useParams();

  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;
  const { error: errorTag, fetchTagsData } = useFetchPortfolioTag();

   // Submit filter state it should work only apply is clicked
   const [filterSubmit, setFilterSubmit] = useState(false);
   const [selectedRelationFilters, setSelectedRelationFilters] = useState({});

  // useEffect(() => {
  //   fetchTagsData();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // Get all tags
  // const fetchTagsData = async () => {
  //   if (ref.current) {
  //     ref.current.continuousStart();
  //   }
  //   try {
  //     setLoading(true);
  //     const response = await BusinessApplicationServices.getAllTagsList();
  //     console.log("response-tags-list", response.data.data);
  //     const newData = response.data.data;
  //     await dispatch({ type: "SET_ALL_TAGS", payload: newData });
  //   } catch (error) {
  //     setLoading(false);
  //     console.error("Error fetching data:", error);
  //   }
  // };

  const fetchFilteredData = async (filter) => {
    try {
      setLoading(true);
      if(portfolioSettingState?.data?.length > 0){
        const portfolio = await deepFindByName(
          portfolioSettingState?.data,
          staticSettingPortfolioList?.application,
          false
        );
        let  updatedFilter = {
          moduleName: portfolio?.moduleName || "",
          ...filter,
        };
        updatedFilter = removeEmptyKeys(updatedFilter);
        const response = await PortfolioSettingServices?.getFilteredPortfolios(
          updatedFilter,page,limit
        );
        if (response?.data?.data?.length) {
          setTotalRecords(response?.data?.totalCount);
          setList(response?.data?.data);
          updateLocale({key:portfolio?.moduleName,items:response?.data?.data})
        } else {
          //message.info("No records found.");
          setTotalRecords(response?.data?.totalCount || 0);
          setList(response?.data?.data);
        }
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }

    if (ref.current) {
      ref.current.complete();
      setFilterSubmit(false);
  }
  };

  // Filter and debounce
  const debouncedFilter = useDebounce(selectedFilters, debounceTimeOut);

  useEffect(() => {
    fetchFilteredData(selectedFilters);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioSettingState]);

  //Get portfolios
  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(portfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (portfolioSettingState?.data?.length>0) {
      const portfolioValues = deepFindByName(
        portfolioSettingState?.data,
        staticSettingPortfolioList?.application,
        false
      );
      if (portfolioValues?.portfolioSections?.length) {
        if (portfolioValues?.moduleName) {
          dispatchTag({ type: "EMPTY_TAGS" });
          dispatchTag({
            type: "SET_MODULE_NAME",
            payload: portfolioValues?.moduleName,
          });
        }
        setPortfolioData(
          getSelectProperties(portfolioValues?.portfolioSections, true)
        );
      } else {
        setPortfolioData([]);
      }
    } else {
      fetchAllPortfolio();
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portfolioSettingState?.data]);

  //Fetch tags items
  const fetchAllPortfolioTags = async () => {
    try {
      await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    if (stateTag?.moduleName) {
      fetchAllPortfolioTags();
    }
  }, [stateTag?.moduleName]);

  useEffect(() => {
    if (filterSubmit) {
      fetchFilteredData(removeEmptyArrObjOrval(debouncedFilter));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterSubmit,debouncedFilter,page, limit]);

  return (
    <>
    <LoadingBar color='#1f1f1f' ref={ref} />    
    <div className="applicationlist-page">
      <div className="left_pnlbx">
        <div className="container-fluid">


        <div className="headingbx">
          <PageTitleHeading text={`Application`} />
          <Flex wrap gap="small" className="ml-2">
            <Button
              icon={<img src={listSvg} alt="List" width={20} />}
              onClick={() => setActiveGridStyle(1)}
              {...(activeGridStyle === 1
                ? { type: "primary", ghost: true }
                : { block: true })}
              style={{ width: "30px" }}
            />
            <Button
              icon={<img src={gridSvg} alt="Grid" width={20} />}
              onClick={() => setActiveGridStyle(2)}
              {...(activeGridStyle === 2
                ? { type: "primary", ghost: true }
                : { block: true })}
              style={{ width: "30px" }}
            />
            <Button
              icon={<img src={matrixSvg} alt="Matrix" width={25} />}
              onClick={() => setActiveGridStyle(3)}
              {...(activeGridStyle === 3
                ? { type: "primary", ghost: true }
                : { block: true })}
              style={{ width: "30px" }}
              className="pb-1"
            />
            <Tooltip title="Application">
              <Button
                className=""
                icon={<PlusOutlined style={{ color: "#2A609D" }} />}
                onClick={() => {
                  navigate("/portfolio/" + moduleNamePath+'/add', {
                    state: {},
                  });
                }}
              />
            </Tooltip>
            <ActionsBtn name={`Application`} />
          </Flex>
        </div>
        <div className="mr-3 mt-2">
          <Spin className='loading_bx' size="small" spinning={loading}>
            {activeGridStyle === 1 ? (
              <React.Fragment>
                <ApplicationCapabilityList
                  data={list || []}
                  moduleName={moduleNamePath}
                  lifeCycleStagesColors={lifeCycleStagesColors}
                  showFilter={showFilter}
                  loading={loading}
                  isListCheckbox={true}
                />
                {totalRecords>0 &&
                  <Pagination
                    className="mt-2 text-right"
                    total={totalRecords}
                    showTotal={(total) => `Total ${total} items`}
                    defaultPageSize={default_pageSize}
                    defaultCurrent={1}
                    pageSizeOptions={default_pageSizeOptions}
                    showSizeChanger
                    onChange={(p, ps)=>{
                      setFilterSubmit(true);
                      setLimit(ps)
                      setPage(p);
                    }}
                  />
                }
              </React.Fragment>
            ) : (
              ""
            )}
            {activeGridStyle === 2 ? (
              <ApplicationCapabilityGrid
                data={list || []}
                lifeCycleStagesColors={lifeCycleStagesColors}
                showFilter={showFilter}
                selectedFilters={swimLaneSelectedFilters}
              />
            ) : (
              ""
            )}
            {activeGridStyle === 3 ? (
              <ApplicationCapabilityMatrix
                data={list || []}
                lifeCycleStagesColors={lifeCycleStagesColors}
                showFilter={showFilter}
              />
            ) : (
              ""
            )}
          </Spin>
        </div>
        </div>
      </div>
      {/* {(portfolioData?.length && ( */}
      <>
        {/* <ACFilterRightSideBar
            activeGridStyle={activeGridStyle}
            lifeCycleStagesColors={lifeCycleStagesColors}
            showFilter={showFilter}
            setShowFilter={setShowFilter}
            portfolioData={portfolioData}
            selectedFilters={selectedFilters}
            setSelectedFilters={setSelectedFilters}
          /> */}
        <FilterRightSideBar
          activeGridStyle={activeGridStyle}
          lifeCycleStagesColors={lifeCycleStagesColors}
          showFilter={showFilter}
          setShowFilter={setShowFilter}
          portfolioData={portfolioData}
          selectedFilters={selectedFilters}
          // setSelectedFilters={setSelectedFilters}
          setSelectedFilters={(value)=>{ setPage(1);setSelectedFilters(value); }}
          optionalLevel={false}
          setSwimLaneSelectedFilters={setSwimLaneSelectedFilters}
          //Filter relations
          filterSubmit={filterSubmit}
          setFilterSubmit={(value)=>{setPage(1);setFilterSubmit(value)}}
          selectedRelationFilters={selectedRelationFilters}
          setSelectedRelationFilters={setSelectedRelationFilters}
        />
      </>
      {/* )) || <></>} */}
    </div>
    </>
  );
};

export default ApplicationList;
