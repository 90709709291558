import React from 'react';
import { Card } from 'antd'
import { COLORS } from '../../../utils/Common';

const CardItems = ({...props}) => {
    let cardStyle= (item,index) =>{
        if(item?.level===1){
            return {"--card-color":COLORS[index],"--text-color":contrastColorStyle(COLORS[index])?.color}
        }
        return {};
    }

    return (
        <React.Fragment>
            {
                props?.items?.map((item,index)=>{
                    return <div key={Math.random()}>
                        <Card 
                            title={item?.title}
                            extra={<span className='cu-text-primary'>L1</span>}
                            size="small"
                            style={{width:300}}
                            styles={{body:{padding:0}}}
                            className='card-item'
                        >
                            <ul class="list-group list-group-flush">
                                {
                                    item?.childrens?.map(item=>{
                                        return <li className='list-group-item d-flex' key={Math.random()}>
                                            <div className='relationship' style={{"--portfolio-color":"#FFB381"}}>APP</div>
                                            <div>{item?.title}</div>
                                        </li>
                                    })
                                }
                            </ul>
                        </Card>
                        {/* <div style={cardStyle(item,index)} className='card-item'>
                            <div className='level-1'>{item?.title}</div>
                            <ul>
                                {
                                    item?.childrens?.map(item=>{
                                        return <li key={Math.random()}>
                                            <LevelCard2 {...item}/>
                                        </li>
                                    })
                                }
                            </ul>
                        </div> */}
                    </div>
                })
            }
        </React.Fragment>
    )
}

CardItems.defaultProps = {
    level:0,
}

export default CardItems


const contrastColorStyle = (hexColor) => {
    var r = parseInt(hexColor.substring(1,3),16);
    var g = parseInt(hexColor.substring(3,5),16);
    var b = parseInt(hexColor.substring(5,7),16);
    var yiq = ((r*299)+(g*587)+(b*114))/1000;
    var color = (yiq >= 125) ? '#000' : '#fff';
    return {
        backgroundColor:hexColor,
        color,
    };
}

const LevelCard2 = ({...props}) =>{
    return <React.Fragment>
        {
            props?.childrens?.map(item=>{
                return <li key={Math.random()}>
                    <div className='level-3' style={{"--portfolio-color":"#FFFFFF"}}>{item?.title}</div>
                </li>
            })
        }
    </React.Fragment>
}