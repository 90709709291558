import React from "react";
import {
  Typography,
  Row,
  Col,
  Input,
  Divider,
  Button,
  Modal,
  Form,
  Tag,
  Select,
  message,
} from "antd";
import { useState, useEffect } from "react";
import { json, useLocation, useNavigate, useParams } from "react-router-dom";
import businessCapabilityServices from "../../../../services/services/BusinessCapabilityService";

import { UpdateChangeLog } from "../../../../utils/ChangeLogs";
import { forEach } from "jszip";
import { useBusinessCapability } from "../../../../Context/businessCapability";
import { tabsList } from "../../../../utils/linksCommon";
import { applicationsList } from "../../../../utils/businessApplication";
import { useApplicationlink } from "../../../../Context/linkProvider";
import DynamicForm from "../../../dynamicForm/DynamicForm";
import usePortfolioFetcher from "../../../../hooks/usePortfolioFetcher";
import { usePortfolioSetting } from "../../../../Context/portfolioSettingProvider";
import {
  transformText,
  SavePortfolioItemsCheckModified,
} from "../../../../utils/Common";

import {
  addIsTextToProperties,
  deepFindByName,
} from "../../../../utils/Common";
import {
  getMenuPortfolio,
  staticSettingPortfolioList,
} from "../../../../utils/settingCommon";
import { useMenu } from "../../../../Context/MenuProvider";
import { SaveRecentlyView_Portfolio } from "../../../../utils/ResentViewedPortfolio";
import { compileString } from "sass";
import { useApplicationChangeLogs } from "../../../../Context/changeLogsProvider";
import ChangeLogsServices from "../../../../services/services/ChangeLogsServices";

import linkServices from "../../../../services/services/LinkService";
import { usePortfolioTag } from "../../../../Context/portfolioTagProvider";
import useFetchPortfolioTag from "../../../../hooks/useFetchPortfolioTag";

import useFetchGlobalProp from "../../../../hooks/useFetchGlobalProp";
import { useGlobalTag } from "../../../../Context/GlobalTagProvider";
import { getRecentlyView_ScenarioName } from "../../../../utils/Common";
import ScenarionServices from "../../../../services/services/ScenarioServices";

const { TextArea } = Input;

const TabBCBasicInformation = ({
  setActiveTabKey,
  toggleTabDisabled,
  id: modelId,
  setBusinessCapabilityData,
  defaultFormData,
  locationData,
  ...props
}) => {
  const navigate = useNavigate();
  const { loading, fetchPortfolio } = usePortfolioFetcher();
  const { stateMenu, dispatchMenu } = useMenu();
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const leveldata = location.state;
  const [list, setList] = useState([]);
  const [newList, setNewList] = useState([]);

  const [formData, setFormData] = useState({});
  const [parentList, setParentlist] = useState({});
  const [field_OldData, setfield_OldData] = useState({
    displayName: "",
    LevelId: "",
    description: "",
  });
  const [level, setLevel] = useState();
  const [parentLevel, setParentLevel] = useState();
  const [levelID, setLevelID] = useState();
  const { state, dispatch } = useBusinessCapability();
  const { state: PortfolioSettingState, dispatch: PortfolioSettingDispatch } =
    usePortfolioSetting();
  const { state: stateChangeLog, dispatch: dispatchChangeLog } =
    useApplicationChangeLogs();

  const [formItems, setFormItems] = useState({});
  //let { moduleName } = useParams();
  let { id = null, moduleName, scenarioId } = useParams();

  const { state: businessLinkState, dispatch: businessLinkDispatch } =
    useApplicationlink();
  const { state: stateTag, dispatch: dispatchTag } = usePortfolioTag();
  const { loading: tagLoading, error: tagError } = stateTag;
  const { error, fetchTagsData } = useFetchPortfolioTag();
  const [allTagsList, setAllTagsList] = useState([]);

  const { state: stateGlobalTag, dispatch: dispatchGlobalTag } = useGlobalTag();
  const [globalTagsList, setGlobalTagsList] = useState([]);
  const { error: errorGTag, fetchGlobalPropsData } = useFetchGlobalProp();
   
  useEffect(() => {
    if (moduleName && modelId) {
      fetchData(moduleName, modelId);
      fetchData_Link(moduleName, modelId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [moduleName, modelId]);

  const fetchData = async (moduleName1, modelId1) => {
    try {
      const objParam = { moduleName: moduleName1, modelId: modelId1 };

      const response = await ChangeLogsServices.getChangeLogsList(objParam);

      const newData = response.data.data;

      await dispatchChangeLog({ type: "SET_LINK", payload: newData });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchData_Link = async (moduleName, moduleID) => {
    try {
      const response = await linkServices.getListByModule({
        moduleName,
        moduleID,
      });

      const newData = response.data.data;

      await businessLinkDispatch({ type: "SET_LINK", payload: newData });
    } catch (error) {
      console.error("Error fetching Link data:", error);
    }
  };

  // useEffect(()=>{
  //   if (!stateMenu?.MenuID || stateMenu?.MenuID==undefined)
  //   {
  //     const menuDetail= JSON.parse(sessionStorage.getItem("sessionMenuID"));

  //     dispatchMenu({
  //       type: "MenuDetail",
  //       payload: menuDetail,
  //     });
  //   }
  // },[stateMenu])

  const updateBussinessModuleId = async (id) => {
    await businessLinkDispatch({
      type: "SET_BUSINESSAPPLICATIONID",
      payload: id,
    });
    await businessLinkDispatch({
      type: "SET_BUSINESS_APPLICATION_MODEL",
      //payload: applicationsList.businessCapability,
      payload: moduleName,
    });
    await dispatch({ type: "SET_IS_EDIT", payload: true });
  };

  //////////////////////////////////////////////////////////////////////////
  //Fetch tags items
  const fetchGlobalProp = async () => {
    try {
      await fetchGlobalPropsData();
    } catch (error) {
      console.error("Error fetching Global Prop:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchGlobalProp();
  }, []);

  useEffect(() => {
    setGlobalTagsList(stateGlobalTag.data);
  }, [stateGlobalTag?.data, moduleName, modelId]);

  //////////////////////////////////////////////////////////////////////////

  //Get all settings
  const fetchAllPortfolio = async () => {
    try {
      const response = await fetchPortfolio(PortfolioSettingDispatch, 1, 100);
      if (response) {
        // message.success("Portfolio fetched successfully.");
      } else {
        message.error("Portfolio not found.");
      }
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  useEffect(() => {
    fetchAllPortfolio();
  }, []);

  // useEffect(()=>{
  //   console.log("Change Log.....List", list)
  //   console.log("Change Log..... NewList", newList)
  // },[])

  useEffect(() => {
    if (PortfolioSettingState?.data?.length) {
      // const portfolioValues = deepFindByName(
      //   PortfolioSettingState?.data,
      //   staticSettingPortfolioList?.businessCapability,
      //   false
      // );

      const portfolioValues = PortfolioSettingState?.data.find(
        (item) => transformText(item.moduleName, "pascal") === moduleName
      );

      if (portfolioValues?.moduleName) {
        dispatchTag({ type: "EMPTY_TAGS" });
        dispatchTag({
          type: "SET_MODULE_NAME",
          payload: portfolioValues?.moduleName,
        });

        if (portfolioValues?.moduleName) {
         //getMaxLevelID(portfolioValues?.moduleName);
        }
      }

      setFormItems(addIsTextToProperties(portfolioValues, "Level"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PortfolioSettingState?.data, moduleName, modelId]);

  //Fetch tags items
  const fetchAllPortfolioTags = async () => {
    try {
      await fetchTagsData();
    } catch (error) {
      console.error("Error fetching portfolio:", error);
      message.error("Something went wrong.");
    }
  };

  const getMaxLevelID = async (portfolioModuleName) => {
    console.log("object ScenarioItemLevelID");

    //LevelID for Scenario Creation
    const ScenarioItemLevelID = await businessCapabilityServices.getmaxlevelid({
      modulename: portfolioModuleName,
    });
    if (ScenarioItemLevelID) {
      console.log("object ScenarioItemLevelID", ScenarioItemLevelID);
    }
  };

  // Tag useEffect start
  useEffect(() => {
    if (stateTag?.moduleName) {
      // fetchAllPortfolioTags();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateTag?.moduleName, moduleName, modelId]);
  // Tag useEffect end

  useEffect(() => {
    setAllTagsList(stateTag.data);
  }, [stateTag?.data, moduleName, modelId]);

  useEffect(() => {
    if (modelId != null) {
      updateBussinessModuleId(modelId);
    } else {
      dispatch({ type: "SET_IS_EDIT", payload: false });

      //Cheking Level Data to Populate the Parent Detail
      if (leveldata != null) {
        // console.log("Level Data", leveldata);
        if (leveldata.Level == "1") {
          setLevel("Level 1");
          setParentLevel("");
          setLevelID("");
        } else if (leveldata.Level == "2") {
          setLevel("Level 2");
          setParentLevel(leveldata.underLevel);
          setLevelID(leveldata.levelID);
        } else if (leveldata.Level == "3") {
          setLevel("Level 3");
          setParentLevel(leveldata.underLevel);
          setLevelID(leveldata.levelID);
        } else if (leveldata.Level > 3) {
          setLevel("Level " + leveldata?.Level);
          setParentLevel(leveldata?.underLevel);
          setLevelID(leveldata?.levelID);
        }
      } else {
        setLevel("Level 1");
        setParentLevel("");
        setLevelID("");
      }

      setFormData({
        ...formData,
        ...defaultFormData,
        parentid: props?.locationData?.ParentID || leveldata?.ParentID,
      });
    }
  }, [modelId, state.isEdit, moduleName]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    // console.log("formData.....", formData);
  }, [formData]);

  useEffect(() => {
    //console.log("isEdit", state.isEdit);

    if (modelId && state.isEdit) {
      getData();
    }
  }, [modelId, state.isEdit, moduleName]);

  // useEffect(() => {
  //     getData();
  // }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //console.log("Under Level Top", leveldata?.underLevel );

  const tagRender = (props) => {
    const { label, value, closable, onClose } = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        color={value}
        onMouseDown={onPreventMouseDown}
        closable={closable}
        onClose={onClose}
        style={{
          marginInlineEnd: 4,
        }}
      >
        {label}
      </Tag>
    );
  };

  const createAndUpdate = async () => {
    try {
      // Validate form fields
      const values = await form.validateFields();

      // Prepare the object for the API request
      const obj = {
        ...values,
        tags: values?.tags?.map((item) => item?.id),
        referenceid: values?.referenceid || 413,
        attributes: values?.attributes || [],
        parentid: values?.parentid || 0,
        scenarioId: scenarioId,
      };

      // Validate display name
      if (!obj?.displayname) {
        return message.error("Display Name is Required");
      }

      // Get old and new values for the change log
      const oldValues = updateValuesForChangeLog(
        list,
        formItems?.portfolioSections
      );
      const newValues = updateValuesForChangeLog(
        obj,
        formItems?.portfolioSections
      );

      let result;

      if (state.isEdit) {
        // Handle update case
        obj.id = modelId;

        result = await businessCapabilityServices.update(obj);

        if (result?.data?.code === "200") {
          message.success(result?.data?.msg);

          // Add Change Log
          await UpdateChangeLog(
            obj.id,
            oldValues,
            newValues,
            "Update",
            moduleName,
            formItems?.portfolioSections
          );

          await fetchData(moduleName, modelId);
          await fetchData_Link(moduleName, modelId);
          await getData();

          //Update Data modification for scenario portfolio Item
          if (scenarioId) {
            const portfolioValues = PortfolioSettingState?.data.find(
              (item) => transformText(item.moduleName, "pascal") === moduleName
            );
            if (portfolioValues?.moduleName) {
              // await SavePortfolioItemsCheckModified({
              //   moduleName: portfolioValues?.moduleName,
              //   sourceItemId: parseInt(obj.id, 10),
              //   targetItemId: result?.data?.sourceItemId,
              // });
              const objdata = {
                source_id: parseInt(obj.id, 10),
                modulename: portfolioValues?.moduleName,
                tab_name: "Information",
              };
              await ScenarionServices.checkscenariomodifyrecord(objdata);
            }

            setTimeout(() => {
              navigate(`/scenario-list-v2/${scenarioId}`);
            }, 1000);
          } ///
        } else {
          message.error(result?.data?.msg);
        }
      } else {
        // Handle create case
        // if (props?.locationData?.scenarioId) {
        //   obj.scenarioId = props?.locationData?.scenarioId;
        // }

        if (scenarioId) {
          obj.scenarioId = scenarioId;
          obj.sourceItemType = "New";
        }

        result = await businessCapabilityServices.create(obj);

        if (result.data?.code === "200") {
          // Add Change Log
          await UpdateChangeLog(
            result.data?.data.id,
            oldValues,
            newValues,
            "Add",
            moduleName,
            formItems?.portfolioSections
          );

          await fetchData(moduleName, result.data?.data.id);
          message.success(result?.data?.msg);

          const newDataID = result.data?.data.id || null;
          updateBussinessModuleId(newDataID);

          modelId = newDataID;
          if (scenarioId) {
            setTimeout(() => {
              navigate(`/scenario-list-v2/${scenarioId}`);
            }, 1000);
          } else {
            await getData();

            setTimeout(() => {
              navigate(
                `/business-capability-master/${moduleName}/${newDataID}`
              );
            }, 1000);
          }
        } else {
          message.error(result?.data?.msg);
        }
      }
    } catch (error) {
      console.error("Error:", error);
      message.error(
        `Failed to submit form. ${error.response?.data?.error || error.message}`
      );
    }
  };

  const updateValuesForChangeLog = (data, propss) => {
    let oldList = {};
    Object.keys(data).map((dataItem) => {
      propss.map((section, index) => {
        section?.properties?.length > 0 &&
          section?.properties
            ?.sort((a, b) =>
              //formatPropertyName(a.name).localeCompare(formatPropertyName(b.name))
              a.name.localeCompare(b.name)
            )
            ?.map((property, index) => {
              //if (dataItem === checkTextCase(property.name)) {
              if (dataItem === property.name) {
                if (property?.type === "Single select") {
                  property?.propertyOptions.map((propvalue) => {
                    if (data[dataItem] == null) {
                      oldList[dataItem] = "";
                    } else if (propvalue?.id === data[dataItem]) {
                      oldList[dataItem] = propvalue?.name;
                    }
                  });
                } else if (property?.type === "Multi select") {
                  let lst = data[dataItem];
                  if (lst?.length) {
                    let propValue = "";
                    for (let i = 0; i < lst?.length; i++) {
                      property?.propertyOptions.map((propvalue) => {
                        if (propvalue?.id === lst[i]) {
                          // console.log("data  Select Name >>>>", propvalue?.name);
                          if (propValue == "") {
                            propValue = propvalue?.name;
                          } else {
                            propValue = propValue + ", " + propvalue?.name;
                          }
                        }
                      });
                    }
                    oldList[dataItem] = propValue;
                  } else {
                    oldList[dataItem] = "";
                  }
                } else {
                  oldList[dataItem] = data[dataItem];
                }
              }
            });
      });
    });
    return oldList;
  };

  // const updateValuesForChangeLog=( data, propss)=>{
  //   let oldList={};
  //   Object.keys(data).map((dataItem) => {
  //     propss.map((section, index) => {
  //       section?.properties?.length > 0 &&
  //         section?.properties
  //           ?.sort((a, b) =>
  //             formatPropertyName(a.name).localeCompare(formatPropertyName(b.name))
  //           )
  //           ?.map((property, index) => {
  //             if (dataItem === checkTextCase(property.name)) {
  //               if (
  //                 property?.type === "Single select" ||
  //                 property?.type === "Multi select"
  //               ) {
  //                 property?.propertyOptions.map((propvalue) => {
  //                   if (data[dataItem]==null){
  //                     oldList[dataItem]="";
  //                   }else
  //                   if (propvalue?.id === data[dataItem]) {

  //                     oldList[dataItem]=propvalue?.name;
  //                   }
  //                 });
  //               } else {
  //                 oldList[dataItem]=data[dataItem]
  //               }
  //             }
  //           });
  //     });
  //   });
  //   return oldList ;
  //   }

  const getData = async () => {
    // if (leveldata.type==='edit')
    // {

    const list1 = await businessCapabilityServices.getListByID(modelId);

    setList(list1?.data);

    //  console.log("Parent ID", list1?.data.ParentID);
    //  console.log( "Under Level", leveldata?.underLevel );

    if (list1?.data.level == "L1") {
      setLevel("Level 1");
    } else if (list1?.data.level == "L2") {
      setLevel("Level 2");
    } else if (list1?.data.level == "L3") {
      setLevel("Level 3");
    } else if (list1?.data?.levelid?.split(".").length > 3) {
      setLevel("Level " + list1?.data?.levelid?.split(".").length);
    }

    let listParent;
    if (list1?.data.parentid != null && list1?.data.parentid != 0) {
      listParent = await businessCapabilityServices.getListByID(
        list1?.data.parentid
      );

      setParentlist(listParent?.data);

      setParentLevel(listParent?.data.displayname);
      setLevelID(listParent?.data.levelid);
    } else {
      setLevel("Level 1");
      setParentLevel("");
    }

    ////////////////////////////////////
    setFormData(list1?.data);
    setBusinessCapabilityData(list1?.data);
    // console.log("List Data", list);

    form.setFieldsValue({
      name: list1?.data.DisplayName,
      LevelId: list1?.data.LevelID,
      description: list1?.data.Description,
    });

    setfield_OldData({
      displayName: list1?.data.DisplayName,
      LevelId: list1?.data.LevelID,
      description: list1?.data.Description,
    });

    if (stateMenu.MenuID != undefined) {
      const portfolioData = PortfolioSettingState?.data.find(
        (item) => transformText(item.moduleName, "pascal") === moduleName
      );

      SaveRecentlyView_Portfolio(
        //stateMenu.MenuID.moduleName,
        portfolioData.name,
        window.location.href,
        list1?.data.displayname,
        list1?.data.id,
        // stateMenu.MenuID.color
        portfolioData.color
      );
    }

    //console.log("Old Data", field_OldData);

    ////////////////////////////////////////////

    // if (leveldata.Level === 1) {

    //     let level=list?.data?.data.find(item=> item.id==leveldata.ParentID);

    //      setFormData( level);

    //     form.setFieldsValue({
    //         name: level.DisplayName,
    //         LevelId: level.LevelID,
    //         description: level.Description
    //     });

    //     setfield_OldData({
    //         displayName:level.DisplayName,
    //         LevelId:level.LevelID,
    //         description:level.Description
    //     });

    // }else  if (leveldata.Level === 2) {

    //     //let level=list?.data?.data.find(item=> item.id==leveldata.ParentID);
    //     let level=[];

    //     list.data.data.forEach((curr, index, arr)=>{

    //         curr?.child.forEach((c,i,a)=>{
    //             if (c.id==leveldata.ParentID)
    //             {

    //                 level=c ;
    //             }
    //         })

    //     });

    //     setFormData( level);

    //     form.setFieldsValue({
    //         name: level.DisplayName,
    //         LevelId: level.LevelId,
    //         description: level.description
    //     });

    //     setfield_OldData({
    //         displayName:level.DisplayName,
    //         LevelId:level.LevelID,
    //         description:level.Description
    //     });

    // }
    // else  if (leveldata.Level === 3) {
    //     //let level=list?.data?.data.child.child.find(item=> item.id==leveldata.ParentID);

    //     let level=[];

    //     list.data.data.forEach((curr, index, arr)=>{

    //         curr?.child.forEach((c,i,a)=>{

    //             c?.child.forEach((cr, ind, arr3)=>{

    //                 if (cr.id==leveldata.ParentID)
    //                 {

    //                     level=cr ;
    //                 }
    //             });

    //         });

    //     });

    //     setFormData( level);

    //     form.setFieldsValue({
    //         name: level.DisplayName,
    //         LevelId: level.LevelId,
    //         description: level.description
    //     });

    //     setfield_OldData({
    //         displayName:level.DisplayName,
    //         LevelId:level.LevelID,
    //         description:level.Description
    //     });

    // }

    //}

    //console.log("Old Data", field_OldData);
  };

  const getFormFields = () => {
    const portfolioValues = getMenuPortfolio({ routeModuleName: moduleName });
    return (
      addIsTextToProperties(portfolioValues, "Level")?.portfolioSections || []
    );
  };

  return (
    <>
      <DynamicForm
        data={getFormFields() || []}
        form={form}
        formData={formData}
        labelCol={8}
        wrapperCol={16}
        createAndUpdate={createAndUpdate}
        level={level}
        levelID={levelID}
        parentLevel={parentLevel}
        tagOptions={allTagsList}
        tagLoading={tagLoading}
        moduleName={formItems?.moduleName || null}
        GlobalTagOptions={globalTagsList}
      />

      {/*<Typography.Title level={5} className='mb-2'>Additional Information</Typography.Title>
            
             <Row gutter={[16, 24]} className='mb-2'>
                 <TabBCAdditionalInfo/>
            </Row>

            <Row gutter={[16, 24]} className='mb-2'>
                <Col className="gutter-row" span={8}>
                    <Button type="primary" className='btn btn-theme py-1' ghost
                    icon={<PlusOutlined />} 
                    onClick={showModal}
                     > Add New Property </Button>
                </Col> 
            </Row>
            
            <Divider/>

            <Typography.Title level={5} className='mb-2'>Tags</Typography.Title>
             
            <Select
                mode="multiple"
                tagRender={tagRender}
                defaultValue={['gold', 'cyan']}
                style={{
                width: '100%',
                }}
                options={options}
            /> */}

      <Modal
        title=""
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Form
          form={form}
          labelCol={{ span: 12 }}
          wrapperCol={{ span: 12 }}
          className="mt-5"
        >
          <Form.Item
            label="Additional Information Caption"
            name="AdditionalInfoCaption"
            rules={[
              {
                required: true,
                message: "Please input the additional information caption",
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            name="AdditionalInfoValue"
            label="Additional Information Value"
            rules={[
              {
                required: true,
                message: "Please input the additional information caption",
              },
            ]}
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default TabBCBasicInformation;
