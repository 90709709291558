import { ExclamationCircleFilled, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import { Carousel, Modal, Spin, message } from 'antd';
import React, { useEffect, useState, useRef } from 'react';
import BPMLevel1 from './grid/BPMLevel1';
import BusinessProcessServices from '../../services/services/BusinessProcessServices';
import { COLORS } from '../../utils/Common';
import { UpdateChangeLog } from '../../utils/ChangeLogs';
import LoadingBar from 'react-top-loading-bar'
import { useLocation } from 'react-router';
import ApqcProcessServices from '../../services/services/ApqcProcessServices';

const SampleNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style}}
        onClick={onClick}
        >
          <RightCircleOutlined/>
        </div>
    );
  }
  
  const SamplePrevArrow = (props) =>{
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style}}
        onClick={onClick}
      >
          <LeftCircleOutlined />
      </div>
    );
}

const BusinessProcessGrid = ({HeaderContnet,moduleName,...props}) => {
    const ref = useRef(null)
    const [loading,setLoading] = useState(false);
    const [lists,setLists] = useState([]);
    const location = useLocation();

  
  const getProcessList =()=>{
    if (moduleName === "BusinessProcesses"){
      return BusinessProcessServices
    } else if (moduleName === "ApqcsProcesses"){
      return ApqcProcessServices
    }
  }

    useEffect(()=>{
        getBusinessProcessList();
    },[])

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        initialSlide: 0,
        swipeToSlide: true,
        draggable:true,
        arrows:true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };

    const sortBPMData = (lists) => {
        return lists?.sort((a, b) => {
            return a?.LevelID?.localeCompare(b?.LevelID, undefined, {numeric: true, sensitivity: 'base'});
        })
    }

    const getBusinessProcessList = async (filter_By, filter_ByText,filter_levels,filterOrder) => {
        setLoading(true);  
        if (ref.current) {
            ref.current.continuousStart();
        }    
        try {
            const list = await getProcessList().getList(filter_By,filter_ByText,filter_levels,filterOrder);
            const resultData = list?.data?.data
            setLists(resultData);
            setLoading(false);
            if (ref.current) {
                ref.current.complete();
            }
        } catch (error) {
            setLoading(false);
            if (ref.current) {
                ref.current.complete();
            }
        }
    }

    const showConfirmDelete = (item,type) => {
        const id = item?.id || 0;
        Modal.confirm({
            title: 'Are you sure want to delete?',
            icon: <ExclamationCircleFilled />,
            async onOk() {
                try {
                    let result;
                    result = await getProcessList().deletebusinessProcess(id);
                    if (result) {
                        let obj = {
                            id:id,
                            NameInSourceSystem: item?.NameInSourceSystem || "",
                            DisplayName: item?.DisplayName || "",
                            Description:item?.Description || "",
                            LevelID:item?.LevelID || "",
                        } 

                        let field_OldData = {
                            DisplayName: item?.DisplayName || "",
                            Description:item?.Description || "",
                            LevelID:item?.LevelID || "",
                        }
                     

                        await UpdateChangeLog(obj.id,field_OldData,obj, "Delete", moduleName)

                        await getBusinessProcessList();
                        message.success(result?.data?.msg);
                    } else {
                        message.error(result?.data?.msg)
                    }
                } catch (error) {
                    message.error(error?.message)
                }
            },
            onCancel() {
                console.log('Cancel');
            },
        });
    };

    return (
        <>
        <LoadingBar color='#1f1f1f' ref={ref} />
        <Spin className="loading_bx" size='small' spinning={loading}>
            {HeaderContnet}

            <div className='applicationlist-page'>
                <div style={{ margin: 24 }} className="slider-container">
                    <Carousel {...settings} style={{width:window?.innerWidth-400}}>
                    {
                        sortBPMData(lists).map((item, index) => {
                            let color = COLORS[parseInt(index % COLORS.length)];
                            return <div key={index} className='align-items-start text-center'><BPMLevel1 index={index} backgroundColor={color} setItemDelete={showConfirmDelete} openModal={props.openModal} visibleModal={props.visibleModal} setModalData={props.setModalData} data={item} basePath={location?.pathname}/></div>
                        })
                    }
                    </Carousel>
                </div>
            </div>
        </Spin>
        </>
    )
}

BusinessProcessGrid.defaultProps = {}

export default BusinessProcessGrid